import * as React from 'react';
import styles from '~/components/Popups/popups.scss';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { changeVisiblePopup, updateQuantity } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import classNames from 'classnames';
import { t } from '~/utils/localization';
import { _purchase as apiPurchaseBundle, calculateBundlePrices } from '~/utils/purchase';
import PurchaseButton from '~/components/Button/PurchaseButton';
import useClickAway from '~/hooks/useClickAway';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import { resetCoupon, updateUserAdultFlag } from '~/Actions/ActionAccount';
import Account from '~/account/Account';
import { isInGameBrowser, isMobileOrTabletWindow, updateSearchParam } from '~/utils/utils';
import { Button } from '~/components/Button/Buttons';
import dwhExport from '~/api/dwhExport';
import { CATEGORIES, DWH_EVENTS } from '~/const';
import AttentionLimitWidget from '~/components/BundleLimitWidget/Attention/AttentionLimitWidget';
import { FAST_GOLD_PLACE } from '~/containers/FastGoldLinkContainer/FastGoldLinkContainer';
import { Popup, PopupBody, PopupFooter, PopupHeader } from '~/components/Popup/Popup';
import { IChangeVisiblePopup, ICurrentPage, IUpdateQuantity } from '~/Actions/ActionAppType';
import { CopyrightContent } from '~/components/Popups/Purchase/AdditionalContent';
import PurchaseCustomizationWrapper from '~/components/Popups/Purchase/Customization/PurchaseCustomizationWrapper';
import RefManager, { RefManagerKeys } from '~/RefManager';
import { startHeadShakeAnimation } from '~/utils/auction';
import PurchasePopupDetails from '~/components/Popups/Purchase/PurchasePopupDetails';
import { canBoughtContainer, isEnabledPurchaseLimitToContainer, REASON_FAIL_PURCHASE_CONTAINER } from '~/components/BundleLimitWidget/settings';
import useRandomBundleData from '~/hooks/useRandomBundleData';
import { playButtonClickSound } from '~/api/WoWsClient';
import { BUNDLE_DECORATION, BundlePurchaseTypes } from '~/types/bundle';
import {
    canBoughtRandomBundleWithMaxAmount,
    getAvailableBundlesForPurchase,
    getDefaultExchangeCurrency,
    getProgressiveDiscountFromBundle,
    getPurchaseExchangeRates,
    getPurchaseLimit,
    getSerialBundlesById,
    hasLootboxRestrictionInSerialSequence,
    hasUniqueItems,
    isAvailableMultiplePurchaseForSerialBundle,
    isContainLootbox,
    isEnoughCurrencyForPurchaseSerialSequence,
    isFreeBundle,
    isMultiplePurchaseAvailable,
    isNeedToShowAdultCopyright,
    isPeriodicBundle,
    isSteelExchangeBundle,
} from '~/utils/bundles';
import Currency from '~/components/Currency/Currency';
import equal from 'fast-deep-equal/react';
import { CUSTOM_PAGE, SANTA_PAGE_THEME } from '~/Layouts/Themes/ThemeManager';
import Link, { LinkColors } from '~/components/Link/Link';
import History from '~/utils/history';
import { PopupManagerContext } from '~/Contexts/PopupManagerContext';
import { urlsMap } from '~/utils/menu';
import { PurchasedItem } from '~/components/Purchased/Purchased';
import { getLandingCategory } from '~/utils/category';
import { CustomBackground } from '~/Layouts/Themes/CustomPage/CustomBackground/CustomBackground';

interface IConfirmPurchase {
    bundleId?: number;
}

interface IStateSelector {
    popupActive: IPopup;
    activeCouponId: number;
    port: IPort;
    balance: IBalance;
    bundlesInTransaction: number[];
    selectedRandomBundles: AccountSelectedRandomBundles;
    bundles: IBundleList;
    coupons: ICoupon[];
    restrictedLootboxesPurchaseCount: AccountRestrictedLootboxesPurchaseCount;
    isNoAskForConfirmationPurchase: boolean;
    activePreset: string;
    currentPage: ICurrentPage;
    isAdult: boolean;
    categories: ICategories;
    accountId: number;
    isTrusted?: boolean;
    customBackground?: State['ReducerApp']['customPageSettings']['background'];
}

const stateSelector = (state: State): IStateSelector => {
    return {
        popupActive: state.ReducerApp.popupActive,
        port: state.ReducerApp.port,
        activeCouponId: state.ReducerAccount.activeCouponId,
        balance: state.ReducerAccount.balance,
        bundlesInTransaction: state.ReducerAccount.bundlesInTransaction,
        selectedRandomBundles: state.ReducerAccount.selectedRandomBundles,
        bundles: state.ReducerApp.bundles,
        coupons: state.ReducerAccount.coupons,
        restrictedLootboxesPurchaseCount: state.ReducerAccount.restrictedLootboxesPurchaseCount,
        isNoAskForConfirmationPurchase: state.ReducerAccount.isNoAskForConfirmationPurchase,
        activePreset: state.ReducerApp.activePreset,
        currentPage: state.ReducerApp.currentPage,
        isAdult: state.ReducerAccount.isAdult,
        categories: state.ReducerApp.categories,
        accountId: state.ReducerAccount.id,
        isTrusted: state.ReducerApp.isTrusted,
        customBackground: state.ReducerApp.customPageSettings.background,
    };
};

const ConfirmPurchase = (props: IConfirmPurchase) => {
    const ref = React.useRef(null);
    const dispatch = useDispatch();
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const { popup, isPopupActive } = React.useContext(PopupManagerContext);
    const isActiveCoupon = !!state.activeCouponId;
    const popupData = popup?.data || {};
    const bundleId = props.bundleId ? props.bundleId : popupData.bundleId;
    let bundle: IBundle = state.bundles[bundleId];
    const [isBlockedInterface, setIsBlockedInterface] = React.useState(false);
    let interfaceLockTimer: NodeJS.Timeout = null;
    const _isRandomBundle = bundle.isRandom;
    const _isPeriodicBundle = isPeriodicBundle(bundle);
    const randomBundleData = useRandomBundleData(bundle);
    const isMobile = !isInGameBrowser && isMobileOrTabletWindow && !state.port?.isVisible;
    const isLandingCategory = getLandingCategory(state.categories);

    if (randomBundleData.parentRandomBundle) {
        bundle = randomBundleData.currentBundle;
    }

    const isEnabledCoupon = Account.isEnabledCouponsFromBundle(state.coupons, bundle);
    const activeCoupon = Account.getActiveCoupon(state.activeCouponId, state.coupons);

    const _isEnabledPurchaseLimitToContainer = isEnabledPurchaseLimitToContainer(bundle);
    const _isFreeBundle = isFreeBundle(bundle);

    let canBoughtWithRestriction;
    let reasonCantBoughtCode;
    if (_isRandomBundle) {
        canBoughtWithRestriction = _isFreeBundle || canBoughtRandomBundleWithMaxAmount(randomBundleData.purchaseMaxCountForRandomBundle as Nullable<number>);
        reasonCantBoughtCode = canBoughtWithRestriction ? 1 : REASON_FAIL_PURCHASE_CONTAINER.ACHIEVED_TOTAL_LIMIT_AFTER_PURCHASE;
    } else {
        canBoughtWithRestriction = canBoughtContainer(bundle, state.restrictedLootboxesPurchaseCount);
        reasonCantBoughtCode = Number(canBoughtWithRestriction);
    }

    React.useEffect(() => {
        updateSearchParam({ bundleId: bundleId, purchase: true });
        let dwhEventName = null;

        if (state.port?.isVisible) {
            dwhEventName = DWH_EVENTS.OPEN_PURCHASE_POPUP_FROM_PORT;
        } else if (state.currentPage?.isBundlePage) {
            dwhEventName = DWH_EVENTS.OPEN_PURCHASE_POPUP_FROM_BUNDLE;
        } else {
            dwhEventName = DWH_EVENTS.OPEN_PURCHASE_POPUP_FROM_CATEGORY;
        }

        if (dwhEventName) {
            const dwhMessage: Record<string, number | string> = { bundle_id: bundleId };
            if (state.activePreset) {
                dwhMessage['from_filter_preset'] = state.activePreset;
            }
            dwhExport.send(dwhEventName, dwhMessage);
        }

        return () => {
            updateSearchParam({ bundleId: null, purchase: null });

            if (!state.currentPage?.isBundlePage || popupData.fromCategory || isMobile) {
                dispatch(updateQuantity(bundle.parentBundleId ?? bundle.id, null, null));
                dispatch(resetCoupon());
            } else if (state.port?.isVisible) {
                dispatch(resetCoupon());
            }

            interfaceLockTimer && clearTimeout(interfaceLockTimer);
        };
    }, []);

    const _canChangeQuantity = !(bundle.purchaseType === BundlePurchaseTypes.REAL_CURRENCY) && (isMultiplePurchaseAvailable(bundle) || randomBundleData.isAvailableChangeQuantity);
    const _isEnabledCoupon = isEnabledCoupon && (bundle.quantityData?.quantity === 1 || !bundle.quantityData?.quantity);

    const _isNeedToShowAdultCopyright = isNeedToShowAdultCopyright(bundle);
    const _isNeedToShowCopyrightFromContainer = _isNeedToShowAdultCopyright && isContainLootbox(bundle, randomBundleData.parentRandomBundle, randomBundleData.availableAmountForFullPurchase);
    const _isNeedToShowCopyrightFromRandom = _isNeedToShowAdultCopyright && _isRandomBundle;
    const _isNeedToShownAdultCheckbox = !bundle.hideAdultCheck && (_isNeedToShowCopyrightFromContainer || _isNeedToShowCopyrightFromRandom);

    const isActiveTransaction = state.bundlesInTransaction?.includes(bundle.id);

    const [button, setButton] = React.useState<{ label?: string; isDisabled?: boolean; isDisabledByNotAdult?: boolean; isFetching?: boolean }>({
        label: t('Купить'),
        isDisabled: false,
        isDisabledByNotAdult: _isNeedToShownAdultCheckbox && !state.isAdult,
        isFetching: isActiveTransaction,
    });

    const changeUserAdultFlag = () => {
        setButton({ isDisabledByNotAdult: !!state.isAdult });
        Account.updateAccountSettings({ is_adult: !state.isAdult });
        dispatch(updateUserAdultFlag());
    };

    const onChangeQuantityForFullPurchaseRandomBundle = (amount: number) => {
        const bundle = randomBundleData.parentRandomBundle;
        const prices = calculateBundlePrices(bundle, state.balance, amount, null, getProgressiveDiscountFromBundle(bundle, amount));
        dispatch<IUpdateQuantity>(updateQuantity(bundle.id, amount, prices));
    };

    React.useEffect(() => {
        if (
            state.popupActive &&
            [
                POPUPS_NAME.ERROR_PURCHASE,
                POPUPS_NAME.SUCCESS_PURCHASE,
                POPUPS_NAME.WSMART_PURCHASE_RESULT,
                POPUPS_NAME.PURCHASE_RANDOM_BUNDLE_SUCCESS,
                POPUPS_NAME.SERIAL_SEQUENCE_SUCCESS_PURCHASE,
            ].includes(state.popupActive.name)
        ) {
            dispatch(changeVisiblePopup(POPUPS_NAME.CONFIRM_PURCHASE));
        }
    }, [state.popupActive]);

    const closePopup = function () {
        if (isBlockedInterface) {
            return;
        }

        if (state.popupActive?.name === POPUPS_NAME.BUNDLE_LIMIT_INFO) {
            return;
        }

        dispatch<IChangeVisiblePopup>(changeVisiblePopup(null));
        if (category.type === CATEGORIES.LANDING) {
            History.navigate(urlsMap.home, { replace: true });
        }
    };

    const onClickFromDisableButton = () => {
        const restrictionRef = RefManager.getRef(RefManagerKeys.PurchaseRestriction);
        if (restrictionRef) {
            startHeadShakeAnimation(restrictionRef, styles.headShakeAnim);
        }
    };

    const _purchase = () => {
        if (_isNeedToShownAdultCheckbox && !state.isAdult) {
            return;
        }

        setIsBlockedInterface(true);
        interfaceLockTimer = setTimeout(() => {
            setIsBlockedInterface(false);
        }, 7 * 1000);

        setButton({
            isFetching: true,
            label: t('Обработка'),
        });

        apiPurchaseBundle(bundle, () => {
            if (isMobileOrTabletWindow) {
                setIsBlockedInterface(false);
                setButton({
                    isDisabled: false,
                    label: t('Купить'),
                });
            }
        });
    };

    useClickAway(
        ref,
        (event) => {
            if (!isPopupActive) {
                return;
            }
            const target = event.target as HTMLDivElement;

            if ((target.parentNode as HTMLLinkElement).tagName.toLowerCase() === 'a' || target.tagName.toLowerCase() === 'a') {
                return;
            }

            if (!isMobile) {
                closePopup();
            }
        },
        [isPopupActive],
    );

    useKeyDown(() => {
        if (!isPopupActive) {
            return;
        }
        playButtonClickSound();
        closePopup.bind(this)();
    }, [KEYS_CODE.ESC]);

    if (!bundle.quantityData) {
        bundle.quantityData = {
            ...bundle.quantityData,
            ...calculateBundlePrices(bundle, state.balance, bundle.quantityData?.quantity || 1, Account.getActiveCoupon(state.activeCouponId, state.coupons)?.discount),
        };
    }

    const isNeedToShowExchangeMessage = isSteelExchangeBundle(Object.keys(bundle.quantityData.final));

    const image = { backgroundImage: `url(${bundle.icons.small})` };

    const purchaseExchangeRates = getPurchaseExchangeRates();
    const defaultExchangeCurrency = getDefaultExchangeCurrency();
    const exchangeToCurrency = purchaseExchangeRates[`${defaultExchangeCurrency}_coal`];

    const category = state.categories[state.currentPage?.name];

    const limit = state.accountId ? getPurchaseLimit(bundle) : bundle.limitedQuantity?.personalLimit;
    const isNeedToShowLimit = !_isRandomBundle && !hasUniqueItems(bundle);

    const availableAmountForFullPurchase = randomBundleData?.maxAmountForOnePurchaseWithoutSinglePurchaseLimit;
    const showAppearanceDisclaimer = bundle.decoration?.includes(BUNDLE_DECORATION.DISCLAIMER_APPEARANCE);

    const WebCopyrightContent = React.useCallback(() => {
        return (
            <div className={styles.copyrightContent}>
                <CopyrightContent
                    isNeedToShowCopyrightFromContainer={_isNeedToShowCopyrightFromContainer}
                    isNeedToShowCopyrightFromRandom={_isNeedToShowCopyrightFromRandom}
                    isNeedToHideDisclaimerAppearance={showAppearanceDisclaimer}
                />
            </div>
        );
    }, []);

    const hasRestriction = reasonCantBoughtCode < 0;

    const serualSequence = getSerialBundlesById(state.bundles, bundle.id);
    const purchasedSequence = serualSequence.filter((bundle) => bundle.isPurchased);
    const availableBundlesForPurchase = getAvailableBundlesForPurchase(state.balance, serualSequence, state.coupons);
    const hasLootboxRestrictionForSerialBundles = hasLootboxRestrictionInSerialSequence(availableBundlesForPurchase);
    const status = isEnoughCurrencyForPurchaseSerialSequence(availableBundlesForPurchase, state.balance);
    const isAvailableWholePurchase =
        isAvailableMultiplePurchaseForSerialBundle(bundle, state.bundles) &&
        purchasedSequence?.length + availableBundlesForPurchase?.length === serualSequence.length &&
        status.isEnought &&
        availableBundlesForPurchase?.length > 1 &&
        !hasLootboxRestrictionForSerialBundles;

    const openWholeSerialPurchasePopup = () => {
        dispatch(
            changeVisiblePopup(POPUPS_NAME.SERIAL_SEQUENCE_PURCHASE, true, {
                sequence: availableBundlesForPurchase,
                firstAvailableBundleId: bundle.id,
            }),
        );
    };

    const confirmBackground = bundle.icons?.confirmationBackground || state.bundles[bundle.parentBundleId]?.icons?.confirmationBackground;

    const popupWrapperStyles: React.CSSProperties = React.useMemo(() => {
        let width: React.CSSProperties['width'];
        let height: React.CSSProperties['width'];

        if (category.theme === CUSTOM_PAGE) {
            width = state.customBackground.naturalSizes.width && !isMobile ? state.customBackground.currentSizes.width : undefined;
            height = state.customBackground.naturalSizes.height && !isMobile ? state.customBackground.currentSizes.height : undefined;
        }

        return {
            width,
            height,
        };
    }, [
        state.customBackground.naturalSizes.width,
        state.customBackground.naturalSizes.height,
        state.customBackground.currentSizes.width,
        state.customBackground.currentSizes.height,
        isMobile,
        category,
    ]);

    return (
        <PurchaseCustomizationWrapper bundle={bundle} category={category}>
            {!!confirmBackground && (
                <div
                    className={styles.background}
                    style={{
                        backgroundImage: `url(${confirmBackground})`,
                        display: category?.theme === CUSTOM_PAGE ? 'none' : 'block',
                    }}
                />
            )}
            {category?.theme === CUSTOM_PAGE && (
                <CustomBackground
                    popupData={{
                        bundle: bundle,
                    }}
                />
            )}
            <Popup
                renderEscButton={!isBlockedInterface}
                onClose={closePopup}
                closeIconClassName={'armory__auto--popup_close'}
                popupClassName={classNames('armory__auto--purchase_popup', styles.purchasePopupContainer, styles[category?.theme], {
                    [styles.isPort]: state.port?.isVisible,
                    [styles.customPageWrapper]: category?.theme === CUSTOM_PAGE,
                })}
                AfterPopupComponent={WebCopyrightContent}
                className={classNames(
                    styles.purchasePopupWrapper,
                    {
                        [styles.isMobilePage]: isMobile,
                        [styles.withBackground]: !_isRandomBundle ? !!confirmBackground : bundle?.quantityData?.quantity === 1 ? !!confirmBackground : false,
                        [styles.customPageContent]: category?.theme === CUSTOM_PAGE,
                    },
                    styles[category?.theme],
                )}
                style={popupWrapperStyles}
            >
                <PopupHeader
                    title={t('Подтверждение покупки')}
                    className={classNames({
                        [styles.popupTitleSanta]: category?.theme === SANTA_PAGE_THEME,
                        [styles.customPageHeader]: category?.theme === CUSTOM_PAGE,
                    })}
                />
                <PopupBody
                    className={classNames({
                        [styles.customPageBody]: category?.theme === CUSTOM_PAGE,
                    })}
                >
                    <PurchasePopupDetails
                        popup={popup}
                        parentRandomBundle={randomBundleData.parentRandomBundle}
                        canChangeQuantity={_canChangeQuantity}
                        isActiveCoupon={isActiveCoupon}
                        isEnabledCoupon={_isEnabledCoupon}
                        bundle={bundle}
                        isRandomBundle={_isRandomBundle}
                        isPeriodicBundle={_isPeriodicBundle}
                        activeCoupon={activeCoupon}
                        activeCouponId={state.activeCouponId}
                        coupons={state.coupons}
                        isFreeBundle={_isFreeBundle}
                        changeUserAdultFlag={changeUserAdultFlag}
                        isAdult={state.isAdult}
                        isNeedToShownAdultCheckbox={_isNeedToShownAdultCheckbox}
                        imageStyles={image}
                        isEnabledPurchaseLimitToContainer={_isEnabledPurchaseLimitToContainer}
                        category={category}
                        limit={isNeedToShowLimit ? limit : null}
                        availableAmountForFullPurchase={availableAmountForFullPurchase}
                        hasProgressiveDiscount={randomBundleData.hasProgressiveDiscount && !!randomBundleData.maxAvailableProgressiveDiscount?.discount}
                        progressiveDiscount={randomBundleData.currentProgressiveDiscount}
                        isAvailableFullPurchase={randomBundleData.isAvailableFullPurchaseRandomBundle}
                        onFullPurchaseHandler={onChangeQuantityForFullPurchaseRandomBundle}
                        hasRestrictionPurchaseForRandomBundle={randomBundleData.hasRestrictionPurchaseForRandomBundle}
                        canPurchaseWholeBundle={randomBundleData.canPurchaseWholeBundle}
                        canIncrementRandomBundleAmount={randomBundleData.canIncrementQuantity}
                        bundlesInTransaction={state.bundlesInTransaction}
                        beneficialOffer={randomBundleData.beneficialOffer}
                        balance={state.balance}
                        hasRestriction={hasRestriction}
                        maxAmountForOnePurchaseRandomBundle={randomBundleData.maxAmountForOnePurchaseRandomBundle}
                        isActiveTransaction={isActiveTransaction}
                        sequence={serualSequence}
                        reasonCantBoughtCode={reasonCantBoughtCode}
                    />
                </PopupBody>
                <PopupFooter
                    className={classNames(styles.purchasePopupFooter, {
                        [styles.customPageFooter]: category?.theme === CUSTOM_PAGE,
                    })}
                >
                    {isNeedToShowExchangeMessage && (
                        <div className={styles.exchangeWrapper}>
                            <span>{t('У вас недостаточно угля для покупки данного товара. Разница будет компенсирована за счет стали.')}</span>
                            <span className={styles.exchangeWrapperContent}>
                                <span>{t('По курсу:')}</span>
                                <span className={styles.exchangeWrapperText}>
                                    <Currency currency={defaultExchangeCurrency} amount={1} withoutAnimation={true} className={styles.exchangeCurrency} />
                                    <span>=</span>
                                    <Currency currency={'coal'} amount={exchangeToCurrency} withoutAnimation={true} className={styles.exchangeCurrency} />
                                </span>
                            </span>
                        </div>
                    )}
                    {hasRestriction && (
                        <div className={styles.restrictionText}>
                            <AttentionLimitWidget reason={reasonCantBoughtCode} bundle={bundle} className={styles.attentionLimitWrapper} />
                        </div>
                    )}
                    {!bundle.isPurchased && (
                        <div className={styles.purchasePopupButtonsWrapper}>
                            <div className={styles.purchaseButton}>
                                {reasonCantBoughtCode >= 0 && (
                                    <PurchaseButton
                                        bundle={_isRandomBundle ? randomBundleData.parentRandomBundle : bundle}
                                        parentRandomBundle={randomBundleData.parentRandomBundle}
                                        label={button.label}
                                        coupon={activeCoupon}
                                        isActiveCoupon={isActiveCoupon}
                                        isDisabled={button.isDisabled || button.isDisabledByNotAdult}
                                        isFetching={button.isFetching}
                                        onClick={_purchase}
                                        onClickFromDisableButton={onClickFromDisableButton}
                                        classNameFastGoldLink={styles.fastGoldLink}
                                        classNameChinaFastGoldLink={styles.fastGoldLink}
                                        place={FAST_GOLD_PLACE.BUNDLE_PURCHASE_POPUP}
                                        buttonClassName={'armory__auto--button_purchase'}
                                    />
                                )}
                                {reasonCantBoughtCode >= 0 && isAvailableWholePurchase && (
                                    <div className={styles.serialWholePurchaseLink}>
                                        <Link label={t('Купить все')} onClick={openWholeSerialPurchasePopup} color={LinkColors.white} />
                                    </div>
                                )}
                            </div>
                            {isMobile && (
                                <div className={styles.closeButton}>
                                    <Button label={t('Закрыть')} onClick={closePopup} />
                                </div>
                            )}
                        </div>
                    )}
                    {bundle.isPurchased && (
                        <div className={styles.purchaseButtonsWrapper}>
                            <div className={styles.purchaseButtons}>{<PurchasedItem bundle={bundle} />}</div>
                        </div>
                    )}
                    {showAppearanceDisclaimer && (
                        <div className={styles.disclaimer}>{t('Обратите внимание: внешний вид и описание объектов в Адмиралтействе могут иметь отличия от представленных в клиенте игры.')}</div>
                    )}
                    <div className={styles.copyrightMobile}>
                        <CopyrightContent
                            isNeedToShowCopyrightFromContainer={_isNeedToShowCopyrightFromContainer}
                            isNeedToShowCopyrightFromRandom={_isNeedToShowCopyrightFromRandom}
                            isNeedToHideDisclaimerAppearance={showAppearanceDisclaimer}
                        />
                    </div>
                </PopupFooter>
            </Popup>
        </PurchaseCustomizationWrapper>
    );
};

export default ConfirmPurchase;
