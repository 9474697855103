import * as React from 'react';
import classNames from 'classnames';
import Spoiler from '~/components/Spoiler/Spoiler';
import styles from '../common.scss';
import { t } from '~/utils/localization';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { PresentationStyles, items } from '@wg/wows-entities/const';
import { ManagerData } from '~/core/ManagerData';
import { componentHelper } from '@wg/wows-entities';
import { InventoryCounterSize } from '~/components/InventoryCounter/InventoryCounter';
import InventoryCounterContainer from '~/containers/InventoryCounterContainer/InventoryCounterContainer';

interface IModernizationSubTitleContentProps {
    item: IBundleEntity;
    limit?: React.ReactChild;
}

export const MODERNIZATION_SPECIAL = 'special';
export const MODERNIZATION_UNIQUE = 'unique';

export const ModernizationSubTitleContent = (props: IModernizationSubTitleContentProps) => {
    const primaryItem = props.item;
    const modernization = ManagerData.getItem(primaryItem.identifier);
    let modernizationText = t('Модернизация');

    if (modernization?.tags?.includes(MODERNIZATION_SPECIAL)) {
        modernizationText = t('Особая модернизация');
    } else if (modernization?.tags?.includes(MODERNIZATION_UNIQUE)) {
        modernizationText = t('Элитная модернизация');
    }

    return (
        <React.Fragment>
            <div className={styles.flex}>
                <div className={styles.preBundleTitle}>{modernizationText}</div>
                {primaryItem.customisation.shipId && (
                    <div className={styles.preBundleTitle}>
                        <WoWSEntity
                            type={items.VEHICLES}
                            id={primaryItem.customisation.shipId}
                            presentation={{
                                withTooltip: true,
                            }}
                        />
                    </div>
                )}
                {props.limit}
                <InventoryCounterContainer item={primaryItem} className={classNames(styles.preBundleTitle, styles.inventoryWidget)} size={InventoryCounterSize.FULL} />
            </div>
        </React.Fragment>
    );
};

interface IModernizationContent {
    item: IBundleEntity;
    renderSpoiler?: boolean;
}

const ModernizationContent = (props: IModernizationContent) => {
    const primaryItem = props.item;
    const itemData = ManagerData.getItem(primaryItem?.identifier);
    const hasDescription = !!itemData?.description?.trim()?.length;

    if (props.renderSpoiler) {
        return (
            <Spoiler
                title={
                    <div className={classNames(styles.contentAdditionalBlock)}>
                        <div className={styles.contentSpoilerTitle}>
                            <WoWSEntity
                                type={primaryItem.type}
                                id={primaryItem.identifier}
                                presentation={{
                                    withoutAssetIcon: true,
                                    withText: false,
                                }}
                            />
                        </div>
                    </div>
                }
                content={
                    <div className={classNames(styles.contentBlock, styles.spoilerContent)}>
                        <div className={styles.spoilerSubTitle}>{t('Характеристики: ')}</div>
                        <WoWSEntity
                            type={primaryItem.type}
                            id={primaryItem.identifier}
                            className={styles.ttcText}
                            presentation={{
                                style: PresentationStyles.TTC,
                            }}
                        />
                    </div>
                }
            />
        );
    }

    return (
        <React.Fragment>
            {!!primaryItem?.customisation?.shipIds?.length && (
                <div className={classNames(styles.contentBlock, styles.borderBottom)}>
                    <div className={styles.modernizationContentTitle}>
                        <span>{t('Подходит для кораблей:')}</span>
                    </div>
                    <div>
                        {primaryItem?.customisation?.shipIds.map((shipId) => {
                            return (
                                <div className={styles.modernizationContentItem} key={`mod_item_${shipId}`}>
                                    <WoWSEntity type={items.VEHICLES} id={shipId} presentation={{ withTooltip: true }} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
            {itemData?.slot && <div className={classNames(styles.contentBlock, styles.borderBottom, styles.modernizationAbout)}>{componentHelper.getModernizationSlotMessage(itemData.slot)}</div>}
            {hasDescription && (
                <div className={classNames(styles.contentBlock, styles.borderBottom)}>
                    <WoWSEntity
                        type={primaryItem.type}
                        id={primaryItem.identifier}
                        className={styles.ttcText}
                        presentation={{
                            style: PresentationStyles.DESCRIPTION,
                        }}
                    />
                </div>
            )}
            <div className={classNames(styles.contentBlock)}>
                <div className={styles.contentBlockTitle}>{t('Характеристики: ')}</div>
                <WoWSEntity
                    type={primaryItem.type}
                    id={primaryItem.identifier}
                    className={styles.ttcText}
                    presentation={{
                        style: PresentationStyles.TTC,
                    }}
                />
            </div>
        </React.Fragment>
    );
};

export default ModernizationContent;
