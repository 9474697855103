import * as React from 'react';
import styles from '~/components/Popups/popups.scss';
import BundleLimitContainer from '~/components/BundleLimitWidget/BundleLimitContainer';
import { BundleLimitSize } from '~/components/BundleLimitWidget/BundleLimitWidget';
import { NoticeForWholePurchaseRandomBundle, PurchaseContent, RandomBundleContent } from '~/components/Popups/Purchase/AdditionalContent';
import { t } from '~/utils/localization';
import PriceWrapper, { PRICE_WRAPPER_PLACE, PRICE_WRAPPER_SIZE } from '~/components/PriceWrapper/PriceWrapper';
import { CheckboxWithLabel } from '@wg/wows-react-uikit';
import { isMobileOrTabletWindow } from '~/utils/utils';
import { isCouponsTabHidden, isEnabledCouponsFromBundle } from '~/utils/coupons';
import MobileCouponWrapper from '~/components/Coupon/MobileCouponWrapper';
import { CUSTOM_PAGE, SANTA_PAGE_THEME } from '~/Layouts/Themes/ThemeManager';
import PurchasePopupSantaDetails from '~/components/Popups/Purchase/Customization/Santa/PurchasePopupSantaDetails';
import ProgressiveDiscount from '~/components/ProgressiveDiscount/ProgressiveDiscount';
import RefManager, { RefManagerKeys } from '~/RefManager';
import classNames from 'classnames';
import OfferMark from '~/components/OfferMark/OfferMark';
import RandomBundleImage from '~/components/RandomBundleImage/RandomBundleImage';
import { PriceMapItem } from '~/hooks/useRandomBundleData';
import BundleType from '~/components/BundleType/BundleType';
import { getAmountString } from '~/Layouts/BundlePage/helpers';
import { isHiddenAmountForBundle } from '~/utils/bundles';
import SerialBundleTitle from '~/components/SerialBundleTitle/SerialBundleTitle';
import { PurchasePopupCustomDetails } from './Customization/Custom/PurchasePopupCustomDetails';

export interface IPurchasePopupDetails {
    bundle: IBundle;
    sequence: IBundle[];
    parentRandomBundle?: IBundle;
    isEnabledPurchaseLimitToContainer: boolean;
    imageStyles: React.CSSProperties;
    isRandomBundle: boolean;
    isPeriodicBundle: boolean;
    popup: IPopup;
    isEnabledCoupon: boolean;
    isActiveCoupon: boolean;
    canChangeQuantity: boolean;
    isFreeBundle: boolean;
    activeCouponId: number;
    activeCoupon: ICoupon;
    isNeedToShownAdultCheckbox: boolean;
    isAdult: boolean;
    coupons: ICoupon[];
    changeUserAdultFlag: () => void;
    category: ICategory;
    limit: number;
    availableAmountForFullPurchase: number;
    isAvailableFullPurchase: boolean;
    hasProgressiveDiscount: boolean;
    canPurchaseWholeBundle: boolean;
    canIncrementRandomBundleAmount: boolean;
    progressiveDiscount: DiscountDiapason;
    onFullPurchaseHandler: (amount: number) => void;
    hasRestrictionPurchaseForRandomBundle: any;
    bundlesInTransaction: number[];
    beneficialOffer: PriceMapItem;
    balance: IBalance;
    hasRestriction: boolean;
    maxAmountForOnePurchaseRandomBundle: number;
    isActiveTransaction: boolean;
    reasonCantBoughtCode: number;
}

const PurchasePopupDefaultDetails = ({
    bundle,
    isEnabledPurchaseLimitToContainer,
    imageStyles,
    isRandomBundle,
    isPeriodicBundle,
    popup,
    canChangeQuantity,
    isActiveCoupon,
    isEnabledCoupon,
    isFreeBundle,
    activeCouponId,
    activeCoupon,
    isNeedToShownAdultCheckbox,
    isAdult,
    changeUserAdultFlag,
    coupons,
    limit,
    hasProgressiveDiscount,
    availableAmountForFullPurchase,
    progressiveDiscount,
    parentRandomBundle,
    isAvailableFullPurchase,
    onFullPurchaseHandler,
    hasRestrictionPurchaseForRandomBundle,
    canPurchaseWholeBundle,
    canIncrementRandomBundleAmount,
    bundlesInTransaction,
    beneficialOffer,
    balance,
    maxAmountForOnePurchaseRandomBundle,
    sequence,
    isActiveTransaction,
    reasonCantBoughtCode,
}: IPurchasePopupDetails) => {
    const setRestrictionRef = (_ref: HTMLDivElement) => {
        if (_ref) {
            RefManager.setRef(RefManagerKeys.PurchaseRestriction, _ref);
        }
    };

    const isNeededToShownTimer = !!bundle.activityCountdown?.isEnabled && new Date(bundle?.activityCountdown?.activeFrom).getTime() <= new Date().getTime() && !!bundle.promoTimerActiveTill;

    const renderImage = () => {
        if (isRandomBundle) {
            return (
                <RandomBundleImage
                    bundle={bundle}
                    parentBundle={parentRandomBundle}
                    imageStyles={imageStyles}
                    availableAmountForFullPurchase={availableAmountForFullPurchase}
                    hasRestriction={hasRestrictionPurchaseForRandomBundle}
                />
            );
        }

        return <div className={styles.purchasePopupImage} style={imageStyles} />;
    };

    let title = bundle.title;
    if (isRandomBundle && !isPeriodicBundle && bundle.quantityData?.quantity > 1) {
        title = parentRandomBundle.title;
    }

    const amount = bundle.primaryItem.amount * (bundle.quantityData?.quantity || 1);
    const textQuantity = getAmountString(amount, '<b>', '</b>');

    return (
        <div className={styles.purchasePopupContent}>
            <div className={styles.purchasePopupInfo}>
                <div className={styles.purchasePopupHeader}>
                    <div className={styles.purchasePopupName} dangerouslySetInnerHTML={{ __html: title }} />
                    {bundle.entitlements?.length === 1 && bundle.primaryItem && (
                        <div className={styles.purchaseBundleType}>
                            <BundleType item={bundle.primaryItem} />
                        </div>
                    )}
                    {amount > 1 && !canChangeQuantity && !isHiddenAmountForBundle(bundle) && <div className={styles.purchaseBundleQuantity} dangerouslySetInnerHTML={{ __html: textQuantity }} />}
                    {bundle.serialPurchase && (
                        <div className={styles.purchaseBundleType}>
                            <SerialBundleTitle step={bundle.serialIndex + 1} totalCount={sequence?.length} />
                        </div>
                    )}
                </div>
                {isEnabledPurchaseLimitToContainer && (
                    <div className={styles.bundleLimit}>
                        <BundleLimitContainer size={BundleLimitSize.BIG} className={styles.bundleLimitWrapper} bundle={bundle} />
                    </div>
                )}
                <RandomBundleContent bundle={bundle} />
                <div className={styles.mobilePopupImage}>{renderImage()}</div>
                <PurchaseContent
                    bundle={parentRandomBundle || bundle}
                    popup={popup}
                    canChangeQuantity={canChangeQuantity}
                    isActiveCoupon={isActiveCoupon}
                    isEnabledCoupon={isEnabledCoupon}
                    offerLimit={maxAmountForOnePurchaseRandomBundle}
                    availableAmountForFullPurchase={availableAmountForFullPurchase}
                    isAvailableFullPurchase={isAvailableFullPurchase && canPurchaseWholeBundle}
                    onFullPurchaseHandler={onFullPurchaseHandler}
                    hasRestrictionPurchaseForRandomBundle={hasRestrictionPurchaseForRandomBundle}
                    canIncrementRandomBundleAmount={canIncrementRandomBundleAmount}
                    isRandomBundle={isRandomBundle}
                    bundlesInTransaction={bundlesInTransaction}
                    beneficialOffer={beneficialOffer}
                    balance={balance}
                    isActiveTransaction={isActiveTransaction}
                    reasonCantBoughtCode={reasonCantBoughtCode}
                />
                {parentRandomBundle && (
                    <NoticeForWholePurchaseRandomBundle
                        bundle={parentRandomBundle}
                        availableAmountForFullPurchase={availableAmountForFullPurchase}
                        hasRestriction={hasRestrictionPurchaseForRandomBundle}
                    />
                )}
                {!bundle.isPurchased && (
                    <div className={styles.purchasePopupFooter}>
                        {(!bundle.originalPrice || isNeededToShownTimer || bundle.limitedQuantity) && (
                            <OfferMark
                                label={bundle.promoLabel}
                                limit={!bundle.serialPurchase && limit}
                                expiredTime={isNeededToShownTimer && bundle.promoTimerActiveTill}
                                isNeedToHiddenTimer={bundle.originalPrice && bundle.showDiscountLabel}
                            />
                        )}
                        <div className={styles.purchasePopupRow}>
                            {!isFreeBundle && (
                                <div className={styles.purchasePopupColumn}>
                                    <div className={styles.purchasePopupPriceTitle}>{t('Цена:')}</div>
                                    <div className={styles.purchasePopupPrice}>
                                        <PriceWrapper
                                            bundle={bundle}
                                            coupon={activeCoupon}
                                            progressiveDiscount={progressiveDiscount}
                                            size={PRICE_WRAPPER_SIZE.BIG}
                                            showExchangePrices
                                            showOldPriceForMobileDevices
                                            place={PRICE_WRAPPER_PLACE.POPUP}
                                            withoutAnimation={activeCouponId === null || activeCouponId === undefined}
                                            oldPriceClassName={styles.oldPrice}
                                            newPriceClassName={styles.newPrice}
                                            wrapperClassName={styles.priceWrapper}
                                            discountClassName={styles.discount}
                                        />
                                        {hasProgressiveDiscount && (
                                            <div className={styles.progressiveDiscountWrapper}>
                                                <ProgressiveDiscount
                                                    parentBundleName={parentRandomBundle.title}
                                                    childBundleName={bundle.title}
                                                    amount={availableAmountForFullPurchase}
                                                    data={parentRandomBundle.progressivePurchase?.discountDiapasons}
                                                    className={styles.progressiveDiscount}
                                                    singleLimit={parentRandomBundle.singlePurchaseMaxQuantity}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {isNeedToShownAdultCheckbox && (
                                        <div
                                            ref={setRestrictionRef}
                                            className={classNames(styles.adultCheckbox, 'armory__auto--adult-checkbox', {
                                                ['armory__auto--adult-checkbox-checked']: isAdult,
                                                [styles.isLockInterface]: isActiveTransaction,
                                            })}
                                        >
                                            <CheckboxWithLabel
                                                isChecked={isAdult}
                                                labelText={<span className={styles.ageRestrictionLabel}>{t('Я подтверждаю, что мне исполнилось 18 лет')}</span>}
                                                onChange={changeUserAdultFlag}
                                                className={classNames({
                                                    [styles.warn]: !isAdult,
                                                })}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                            {!isCouponsTabHidden() && isMobileOrTabletWindow && isEnabledCouponsFromBundle(coupons, bundle) && (
                                <div
                                    className={classNames(styles.purchasePopupColumn, {
                                        [styles.isLockInterface]: isActiveTransaction,
                                    })}
                                >
                                    <div className={styles.purchasePopupPriceTitle}>{t('Доступные купоны:')}</div>
                                    <div className={styles.purchasePopupPrice}>
                                        <MobileCouponWrapper bundle={bundle} isDisabled={bundle.quantityData?.quantity > 1} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.purchasePopupImageWrapper}>{renderImage()}</div>
        </div>
    );
};

const PurchasePopupDetails = (props: IPurchasePopupDetails) => {
    switch (props.category?.theme) {
        case SANTA_PAGE_THEME: {
            return <PurchasePopupSantaDetails {...props} />;
        }

        case CUSTOM_PAGE: {
            return <PurchasePopupCustomDetails {...props} />;
        }

        default:
            return <PurchasePopupDefaultDetails {...props} />;
    }
};

export default PurchasePopupDetails;
