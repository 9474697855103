import * as React from 'react';
import Account from '~/account/Account';
import VideoViewBackground from '~/components/ViewBackground/VideoViewBackground';
import GalleryViewBackground from '~/components/ViewBackground/GalleryViewBackground';
import { isDisabledCategoryVideoForBundlePage } from '~/utils/bundles';
import { arrayToObjectByKey, isEmptyObject, isMobileOrTabletWindow } from '~/utils/utils';
import styles from './ViewBackground.scss';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { getGlobalContent, settings } from '~/utils/settings';
import { ALLOWED_THEMES } from '~/Layouts/Themes/types';
import SantaBackground from '~/Layouts/Themes/SantaPage/SantaBackground';
import { CustomBackground } from '~/Layouts/Themes/CustomPage/CustomBackground/CustomBackground';
import AuctionBackground from '~/Layouts/AuctionLotPage/AuctionBackground';
import RefManager, { RefManagerKeys } from '~/RefManager';
import Anniversary from '~/Layouts/Themes/Anniversary/Anniversary';
import equal from 'fast-deep-equal/react';
import { BlurBackgroundStatus, ICurrentPage } from '~/Actions/ActionAppType';
import { ANNIVERSARY_PROMO_THEME, AUCTION_PAGE_THEME, CUSTOM_PAGE, SANTA_PAGE_THEME } from '~/Layouts/Themes/ThemeManager';
import { CATEGORIES } from '~/const';
import { BUNDLE_DECORATION } from '~/types/bundle';
// import { getShip } from '~/utils/auction';
import { useIsDisabledAnimation } from '~/hooks/isDisabledAnimation';
import useArmoryBackground from '~/hooks/useArmoryBackground';

type Theme = Nullable<ALLOWED_THEMES>;

interface IThemeBackground {
    theme: Theme;
}

const ThemeBackground = React.memo((props: IThemeBackground) => {
    switch (props.theme) {
        case SANTA_PAGE_THEME:
            return <SantaBackground />;

        case AUCTION_PAGE_THEME:
            return <AuctionBackground />;

        case ANNIVERSARY_PROMO_THEME:
            return <Anniversary />;
    }

    return null;
});

interface IViewBackground {
    isBlur?: boolean;
}

interface IStateSelector {
    currentPage: ICurrentPage;
    categories: ICategories;
    menu: IMenuMap;
    bundles: IBundleList;
    selectedRandomBundles: AccountSelectedRandomBundles;
    activeAuctions: IAuction[];
    isFinishedRequest: boolean;
    isFullscreen: boolean;
    zoomLotBackground: boolean;
    // shipFeatureMap: ShipFeatureMap;
    isTrusted: boolean;
    viewBackground?: string;
    port: IPort;
    mrpsEvents?: MrpsEvent[];
    mrpsAccountData?: MrpsAccountState;
    isBlurBackground: BlurBackgroundStatus;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        currentPage: state.ReducerApp.currentPage,
        categories: state.ReducerApp.categories,
        menu: state.ReducerApp.menu,
        bundles: state.ReducerApp.bundles,
        selectedRandomBundles: state.ReducerAccount.selectedRandomBundles,
        activeAuctions: state.ReducerAuction.activeAuctions,
        isFinishedRequest: state.ReducerApp.isFinishedRequest,
        isFullscreen: state.ReducerApp.isFullscreen,
        zoomLotBackground: state.ReducerAuction.zoomLotBackground,
        // shipFeatureMap: state.ReducerFeatures.shipFeatureMap,
        isTrusted: state.ReducerApp.isTrusted,
        viewBackground: state.ReducerApp.viewBackground,
        port: state.ReducerApp.port,
        mrpsEvents: state.reducerMrps.events,
        mrpsAccountData: state.reducerMrps.account,
        isBlurBackground: state.ReducerApp.isBlurBackground,
    };
};

const ViewBackground = (props: IViewBackground): React.ReactElement => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const category = state.categories?.[state.currentPage?.name];
    let bundle = state.bundles?.[state.currentPage?.bundleId];
    const configCategory = state.menu?.[state.currentPage?.name];

    useArmoryBackground();

    if (bundle && bundle.isRandom && !isEmptyObject(state.selectedRandomBundles)) {
        const selectedRandomBundleId = state.selectedRandomBundles[bundle.id];
        const randomBundlesChildren = arrayToObjectByKey(bundle.randomBundleChildren, 'id');
        if (randomBundlesChildren[selectedRandomBundleId]) {
            bundle = Account.getRandomBundleChild(state.selectedRandomBundles, bundle);
        }
    }

    let bundleVideoBackground = bundle?.videoBackground;
    let hasBundleVideoBackground = !isEmptyObject(bundleVideoBackground);
    let animationName = hasBundleVideoBackground ? bundle.name : category?.name;
    if (!hasBundleVideoBackground && bundle?.parentBundleId) {
        const { name, videoBackground } = state.bundles[bundle.parentBundleId];
        bundleVideoBackground = videoBackground;
        hasBundleVideoBackground = !isEmptyObject(bundleVideoBackground);
        if (hasBundleVideoBackground) {
            animationName = name;
        }
    }
    const noVideoDisable = hasBundleVideoBackground ? bundleVideoBackground.noVideoDisable : category?.videoBackground?.noVideoDisable;
    const _isDisabledAnimation = useIsDisabledAnimation(animationName) && !noVideoDisable;

    if (state.port?.isVisible) return null;

    if (!state.isFinishedRequest) {
        return <div className={styles.background} />;
    }

    const setRef = (ref: HTMLDivElement) => {
        if (ref) {
            RefManager.setRef(RefManagerKeys.ViewBackground, ref);
        }
    };

    const theme = (category?.theme || configCategory?.theme || getGlobalContent()?.theme) as Theme;
    const currentMrpsEventData = state.mrpsEvents?.find((event) => event.name === state.currentPage?.name);

    const hasCategoryVideoBackground = !isEmptyObject(category?.videoBackground);
    let hasVideoBackground = false;
    if (!isMobileOrTabletWindow) {
        if (bundle) {
            hasVideoBackground = hasBundleVideoBackground;
            const disableCategoryVideoBack = bundle.decoration?.includes(BUNDLE_DECORATION.DISABLE_CATEGORY_VIDEO_BACK);
            if (!disableCategoryVideoBack && !hasVideoBackground && !isDisabledCategoryVideoForBundlePage(bundle)) {
                hasVideoBackground = hasCategoryVideoBackground;
            }
        } else if (currentMrpsEventData) {
            hasVideoBackground = !isEmptyObject(currentMrpsEventData.videoBackground);
        } else {
            hasVideoBackground = hasCategoryVideoBackground;
        }
    }

    // Fullscreen gallery [images or video slider]
    if (!isMobileOrTabletWindow && bundle?.isFullscreenGallery) {
        return <GalleryViewBackground isBlur={props.isBlur} bundle={bundle} category={category} />;
    }

    if (configCategory?.theme === SANTA_PAGE_THEME && state.currentPage?.isBundlePage) {
        return <div className={styles.background} />;
    }

    if (theme === CUSTOM_PAGE) {
        return <CustomBackground />;
    }

    if (!hasVideoBackground || _isDisabledAnimation) {
        let backgroundColor = bundle?.backgroundColor || category?.backgroundColor;
        // let hiddenAuctionMask = false;
        let mrpsBackground: string;

        if (state.currentPage?.name === CATEGORIES.AUCTION) {
            if (state.currentPage?.isBundlePage) {
                let lot: ILot;
                state.activeAuctions.find((auction) => {
                    const currentLot = auction.lots.find((lot) => state.currentPage?.lotId === lot.id);
                    lot = currentLot;
                    if (lot) {
                        return currentLot;
                    }
                });
                // TODO: i don't know what to do
                // hiddenAuctionMask = !!state.shipFeatureMap[getShip(lot)?.id]?.tags?.length && !lot.disableShipFeatures;
                backgroundColor = lot?.backgroundColor;
            } else {
                if (state.activeAuctions[0]?.isNeedToShowPromoPage) {
                    backgroundColor = state.activeAuctions[0]?.promotionBackgroundColor;
                }
            }
        }

        if (currentMrpsEventData) {
            mrpsBackground =
                ((!state.mrpsAccountData || state.mrpsAccountData?.[currentMrpsEventData.name]?.rewards?.length === 0) && currentMrpsEventData.promoBackground) || currentMrpsEventData.background;
        }

        const hasBackgroundMask = !!backgroundColor;
        const style: React.CSSProperties = {};
        // @ts-ignore
        if (hasBackgroundMask) style['--bundle-background-color'] = backgroundColor;
        if (state.viewBackground) style.backgroundImage = `url(${state.viewBackground})`;
        if (mrpsBackground) style.backgroundImage = `url(${mrpsBackground})`;

        return (
            <div
                ref={setRef}
                style={style}
                key={`background_${category?.name}`}
                className={classNames(
                    styles.background,
                    state.currentPage?.name,
                    styles[state.currentPage?.name],
                    styles[configCategory?.backgroundName],
                    styles[settings.realm],
                    styles[configCategory?.theme],
                    {
                        [styles.blur]: props.isBlur,
                        [styles.blurBackground]: state.isBlurBackground === 1,
                        [styles.unblurBackground]: state.isBlurBackground === 0,
                        [styles.isBundlePage]: state.currentPage?.isBundlePage,
                        [styles[`auction_${state.activeAuctions[0]?.kind}`]]: state.currentPage?.name === CATEGORIES.AUCTION,
                        [styles.backgroundWithMask]: hasBackgroundMask,
                        [styles.isFullscreen]: state.isFullscreen,
                    },
                )}
            >
                <div
                    className={classNames(styles.backgroundMask, {
                        [styles.visibleMask]: hasBackgroundMask,
                        // [styles.auctionMask]: hiddenAuctionMask
                    })}
                />
                {category?.backgroundMask && hasBackgroundMask && <div className={styles.backgroundCustomMask} style={{ backgroundImage: `url(${category?.backgroundMask})` }} />}
                <ThemeBackground theme={theme} key={category?.name} />
            </div>
        );
    }

    const videoBackgroundKey = hasBundleVideoBackground ? `background_${category?.name}_${bundle.id}` : `background_${category?.videoBackground?.webm}`;

    return <VideoViewBackground isBlur={props.isBlur} key={videoBackgroundKey} bundle={bundle} category={category} />;
};

export default ViewBackground;
