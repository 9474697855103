import ArmoryPreloader from '~/core/AppInit/ArmoryPreloader';
import AuctionPreloader from '~/core/AppInit/AuctionPreloader';
import store from '~/Store';
import { finishLoadingResources } from '~/Actions/ActionApp';
import PremiumShopPreloader from '~/core/AppInit/PremiumShopPreloader';
import SeaBattlePreloader from '~/core/AppInit/SeaBattlePreloader';
import DockyardPreloader from '~/core/AppInit/DockyardPreloader';
import TradeInPreloader from '~/core/AppInit/TradeInPreloader';
import MrpsPreloader from './MrpsPreloader';

let initStarted = false;

async function AppInit(): Promise<void> {
    if (initStarted) return;
    initStarted = true;

    await new ArmoryPreloader().load();

    const promises = [
        new PremiumShopPreloader().load(),
        new AuctionPreloader().load(),
        new DockyardPreloader().load(),
        new SeaBattlePreloader().load(),
        new TradeInPreloader().load(),
        new MrpsPreloader().load(),
    ];

    await Promise.all(promises);

    store.dispatch(finishLoadingResources());
}

export default AppInit;
