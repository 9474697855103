import { sort } from 'fast-sort';
import { items as ITEMS_TYPES } from '@wg/wows-entities/const';
import { isCamouflage, isPermanentCamouflage } from '~/components/Camouflages/Camouflages';
import { isEnabledPortForItemInsideBundle, isEnabledPortFromAuction, openPortByItem, openPortForMrpsReward, openPortFromAuction } from '~/settings/port';
import { getValueFromInventory } from '~/utils/inventory';
import Account from '~/account/Account';

export enum PortPreviewType {
    BUNDLE = 'bundle',
    CONTAINER = 'container',
    DEFAULT = 'default',
    MRPS = 'mrps',
    AUCTION_LOT = 'auction_lot',
}

export const ARMORY_PORT_TYPES = [PortPreviewType.BUNDLE, PortPreviewType.CONTAINER, PortPreviewType.DEFAULT];

export const isArmoryPort = (portPreviewType: PortPreviewType) => {
    return ARMORY_PORT_TYPES.includes(portPreviewType);
};

export const sortBundleItems = (bundle: IBundle): IBundleEntity[] => {
    if (!bundle.entitlements) {
        return [];
    }
    return sort([...bundle.entitlements]).by([
        {
            desc: (i) => isEnabledPortForItemInsideBundle(i, bundle),
        },
        {
            asc: (i) => bundle.entitlements.findIndex((item) => item.identifier === i.identifier),
        },
    ]);
};

export function sortEntitlements(items: Array<IBundleEntity>) {
    return sort([...items]).by([
        {
            desc: (i) => isEnabledPortForItemInsideBundle(i),
        },
        {
            asc: (i) => items.findIndex((item) => item.identifier === i.identifier),
        },
    ]);
}

export const sortLotRewardsItems = (rewards: IGameRewards[]): IGameRewards[] => {
    return sort(rewards).desc((item) => {
        return isEnabledPortFromAuction(item.type, item.id);
    });
};

export interface IPortContainItem {
    itemId: number;
    id: number;
    type: string;
    amount: number;
    isBonus?: boolean;
    customisation: {
        shipId: number;
        points: number;
    };
    isEnabledPortPreview?: boolean;
    portPreviewOnClick?: (portPreviewType?: PortPreviewType) => void;
}

export const prepareItems = (type: PortPreviewType, data: IBundle | ILot | Array<IBundleEntity>, inventory?: InventoryState): IPortContainItem[] => {
    if (!data) {
        return [];
    }

    switch (type) {
        case PortPreviewType.BUNDLE:
        case PortPreviewType.DEFAULT:
        case PortPreviewType.CONTAINER: {
            const bundle = data as IBundle;
            return sortBundleItems(bundle).map((item) => {
                const [isExistsItemInInventory, inventoryCount] = Account.getInventoryInfoByType(item?.type, item?.identifier, item.customisation?.shipId);

                return {
                    itemId: item.identifier,
                    id: item.identifier,
                    type: item.type,
                    amount: item.amount,
                    isBonus: item.isBonus,
                    customisation: {
                        points: item.customisation?.points,
                        shipId: item.customisation?.shipId,
                        inventoryCount: !item.isUnique && inventoryCount,
                        isExistsInInventory: item?.isUnique && isExistsItemInInventory,
                    },
                    isEnabledPortPreview: isEnabledPortForItemInsideBundle(item, bundle),
                    portPreviewOnClick: (portPreviewType) => {
                        openPortByItem({ identifier: item.identifier, type: item.type, shipId: item.customisation?.shipId }, bundle.id, true, portPreviewType);
                    },
                };
            });
        }

        case PortPreviewType.MRPS: {
            const items = data as Array<IBundleEntity>;

            return sortEntitlements(items).map((item) => {
                const [isExistsItemInInventory, inventoryCount] = Account.getInventoryInfoByType(item?.type, item?.identifier, item.customisation?.shipId);
                const itemId = isPermanentCamouflage(item.type) ? item.shipId : item.identifier;
                const exteriorId = isPermanentCamouflage(item.type) ? item.identifier : -1;

                const mrpsItem = item as unknown as MrpsLoadedEntitlement;

                return {
                    itemId: item.identifier,
                    id: item.identifier,
                    type: item.type,
                    amount: item.amount,
                    isBonus: item.isBonus,
                    crew: mrpsItem.crew,
                    customisation: {
                        points: item.customisation?.points,
                        shipId: item.customisation?.shipId,
                        inventoryCount: !item.isUnique && inventoryCount,
                        isExistsInInventory: item?.isUnique && isExistsItemInInventory,
                    },
                    isEnabledPortPreview: isEnabledPortForItemInsideBundle(item),
                    portPreviewOnClick: () => {
                        openPortForMrpsReward(itemId, item.type, exteriorId, true);
                    },
                };
            });
        }

        case PortPreviewType.AUCTION_LOT: {
            const lot = data as ILot;
            return sortLotRewardsItems(lot.gameRewards).map((reward) => {
                return {
                    itemId: reward.id,
                    id: reward.id,
                    type: reward.type,
                    amount: reward.amount,
                    customisation: {
                        shipId: reward.customisation?.ship_id,
                        points: reward.customisation?.points,
                        inventoryCount: getValueFromInventory(inventory, reward.type, reward.id),
                    },
                    isEnabledPortPreview: isEnabledPortFromAuction(reward.type, reward.id),
                    portPreviewOnClick: () => {
                        if (reward.type === ITEMS_TYPES.VEHICLES) {
                            openPortFromAuction(reward.type, reward.id, -1, lot.id);
                        } else if (isPermanentCamouflage(reward.type) || isCamouflage(reward.type)) {
                            openPortFromAuction(reward.type, reward.customisation?.ship_id, reward.id, lot.id);
                        }
                    },
                };
            });
        }

        default:
            return null;
    }
};

export const getItemById = (items: IPortContainItem[], id: number): IPortContainItem => {
    return items.find((item) => item.id?.toString() === id?.toString());
};

export const getTotalCountPreviewItems = (items: IPortContainItem[]) => {
    return items.reduce((count: number, item) => {
        if (item.isEnabledPortPreview) {
            count += 1;
        }
        return count;
    }, 0);
};
