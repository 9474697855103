import { blurView, changeVisiblePopup, setBundleIsPurchased, setFetching } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import WowsCommerce, { detectPlatform, PLATFORMS, PURCHASE_STATUSES } from '@wg/wows-commerce';
import store from '~/Store';
import { ProductEntity } from '@wg/wows-commerce/core/entity/product.entity';
import { WoWsCommerceStorefrontNames } from '~/core/WowsCommerce/WoWsCommerceHelper';
import { CheckoutResult } from '@wg/wows-commerce/Product';
import { LocalStorageHelper } from '~/utils/storage';
import { LOCAL_STORAGE_STOREFRONT_BUNDLE_WAIT_TO_BE_ADDED_TO_INVENTORY } from '~/utils/keys';
import { isBundleDisabledByPromoTimer } from '~/utils/bundles';
import { updateSearchParam } from '~/utils/utils';

export default class WSMartPurchaseProcessor {
    private readonly product: ProductEntity;

    private bundle: IBundle;

    private readonly wowsCommerce: WowsCommerce;

    private readonly isWeb: boolean;

    private readonly storefrontName: WoWsCommerceStorefrontNames;

    private readonly hasLimitedPurchaseByUniqueItems: boolean;

    constructor(product: ProductEntity, storefrontName: WoWsCommerceStorefrontNames = WoWsCommerceStorefrontNames.METASHOP) {
        this.product = product;
        this.isWeb = [PLATFORMS.WEB, PLATFORMS.VK].includes(detectPlatform());
        this.wowsCommerce = store.getState().ReducerWSMart.wowsCommerce;
        this.storefrontName = storefrontName;
        this.hasLimitedPurchaseByUniqueItems = product?.entitlements?.some((item) => item.isUnique && !item.allowCompensation);
    }

    setBundle(bundle: IBundle) {
        this.bundle = bundle;
    }

    dispatchSuccess() {
        if (this.hasLimitedPurchaseByUniqueItems) {
            LocalStorageHelper.set(LOCAL_STORAGE_STOREFRONT_BUNDLE_WAIT_TO_BE_ADDED_TO_INVENTORY, this.bundle.id);
            store.dispatch(setBundleIsPurchased(this.bundle.id));
        }
        store.dispatch(
            changeVisiblePopup(POPUPS_NAME.WSMART_PURCHASE_RESULT, true, {
                isSuccess: true,
                product: this.product,
                bundle: this.bundle,
            }),
        );
    }

    dispatchError(errorCode?: string) {
        store.dispatch(
            changeVisiblePopup(POPUPS_NAME.WSMART_PURCHASE_RESULT, true, {
                isSuccess: false,
                product: this.product,
                errorCode: errorCode,
                bundle: this.bundle,
            }),
        );
    }

    beforePurchase() {
        store.dispatch(blurView(true));
        if (!this.isWeb) {
            store.dispatch(setFetching(true));
        }
        updateSearchParam({ bundleId: this.bundle?.id || null, purchase: !!this.bundle?.id || null });
    }

    afterPurchase() {
        if (!this.isWeb) {
            store.dispatch(setFetching(false));
        }
        store.dispatch(blurView(false));
        updateSearchParam({ bundleId: null, purchase: null });
    }

    checkStatus(checkoutResult: CheckoutResult) {
        if (checkoutResult.purchaseStatus === PURCHASE_STATUSES.SUCCESS) {
            this.dispatchSuccess();
        } else if (checkoutResult.purchaseStatus === PURCHASE_STATUSES.ERROR) {
            this.dispatchError(checkoutResult?.errorCode);
        }
    }

    isPurchaseAvailable() {
        if (!this.product?.isPurchasable) return false;
        if (!this.bundle) return true;
        return !isBundleDisabledByPromoTimer(this.bundle);
    }

    public async buy(place: string, quantity?: number) {
        if (!this.isPurchaseAvailable()) {
            this.dispatchError();
            return;
        }

        this.beforePurchase();

        this.wowsCommerce.analytics.addEvent(place, { bundle_id: this.product.externalProductId });

        try {
            if (!quantity) {
                quantity = 1;
            }
            const checkoutResult = await this.wowsCommerce.checkout(
                this.product.externalProductId,
                quantity,
                this.storefrontName,
                this.bundle
                    ? {
                          productImageUrl: this.bundle.icons.small,
                          title: this.bundle.title,
                      }
                    : {},
            );
            this.checkStatus(checkoutResult);
        } catch (err) {
            if (err.status === PURCHASE_STATUSES.ERROR) {
                this.dispatchError();
            }
        }

        this.afterPurchase();
    }

    public async updateProductPriceByQuantity(quantity: number): Promise<ProductEntity | void> {
        try {
            if (!quantity) {
                quantity = 1;
            }
            const variablePriceData = await this.wowsCommerce.getProductVariablePrice(this.product.externalProductId, quantity, this.storefrontName);

            return this.wowsCommerce.handleChangeProductPriceByQuantity(this.product, variablePriceData, quantity, this.storefrontName);
        } catch (err) {
            if (err.status === PURCHASE_STATUSES.ERROR) {
                this.dispatchError();
            }
        }
    }
}
