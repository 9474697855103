import { t } from '~/utils/localization';
import { AUCTION_PAGE_THEME } from '~/Layouts/Themes/ThemeManager';
import AuctionStorage from '~/core/AuctionStorage';
import { isAuctionTabDisabled } from '~/utils/auction';
import { CATEGORIES } from '~/const';
import Account from '~/account/Account';
import { getCategoryFeaturedTimerForMenu } from '~/utils/featuring';
import { getAvailableSubCategories, getMenuCategories, isEnableDockyard, isEnabledSeaBattleByMetashop, isRealMoneyPurchaseDisabled } from '~/utils/category';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import store from '~/Store';
import { CategoryType } from '~/types/category';
import { redirectToLogin } from './utils';

const languageCode = window.metashop.settings?.languageCode;

export const urlsMap = {
    home: `/${languageCode}`,
    categories: `/${languageCode}/category/:categoryName`,
    bundle: `/${languageCode}/category/:categoryName/:id`,
    subCategory: `/${languageCode}/category/:categoryName/:subCategoryName`,
    subCategoryBundle: `/${languageCode}/category/:categoryName/:subCategoryName/:id`,
    port: `/${languageCode}/port/:portPreviewType/:id/:shipId/:exteriorId/:itemType`,
    wallet: `/${languageCode}/wallet`,
    coupons: `/${languageCode}/coupons`,
    mobileMenu: `/${languageCode}/menu`,
    bundlePurchase: `/${languageCode}/purchase/:id`,
    auction: `/${languageCode}/auction/`,
    auctionLot: `/${languageCode}/auction/lot/:id`,
    premShop: `/${languageCode}/category/premium`,
};

export const getUrl = (url: string, params: Record<string, any>): string => {
    return Object.keys(params).reduce((str: string, key) => {
        str = str.split(`:${key}`).join(params[key]);
        return str;
    }, url);
};

function overrideCategoriesData(category: IMenuMapItem) {
    if (category.type === CategoryType.TRADEIN && !category.subCategories?.length) {
        category.isHidden = true;
    }

    if (category.type === CategoryType.FEATURED) {
        category.name = CATEGORIES.FEATURED;
        category.url = urlsMap.home;
    }

    if (category.type === CategoryType.PREMIUM) {
        category.title = category.title ?? t('Дублоны и премиум');
        category.id = 'guideSteamboat';
        category.isEnabledCategory = () => !isRealMoneyPurchaseDisabled();
    }

    if (category.type === CategoryType.WORSHIP) {
        category.title = category.title ?? t('Верфь: Стартовые наборы');
        category.id = 'guideDockyard';
        category.isHidden = true;
        category.isNotCategory = true;
        category.isEnabledCategory = () => !!isEnableDockyard();
    }

    if (category.type === CategoryType.AUCTION) {
        category.url = urlsMap.auction;
        category.title = category.title ?? t('Аукцион');
        category.name = CATEGORIES.AUCTION;
        category.isNotCategory = true;
        category.theme = AUCTION_PAGE_THEME;
        category.id = 'guideAuction';
        category.isHidden = true;
        category.isEnabledCategory = () => {
            return AuctionStorage.isAuctionCategoryEnabled && !isAuctionTabDisabled();
        };
    }

    if (category.type === CategoryType.SEA_BATTLE) {
        category.title = category.title ?? t('Морской бой');
        category.id = 'guideSeabattle';
        category.isEnabledCategory = isEnabledSeaBattleByMetashop;
        category.isHidden = true;
        category.middleware = () => {
            const state = store.getState();
            if (!state.ReducerAccount.id) {
                redirectToLogin();
                return false;
            }
            if (!state?.ReducerSeaBattle?.isEnabled) {
                store.dispatch(changeVisiblePopup(POPUPS_NAME.SEABATTLE_LOADER, true));
                return false;
            }
            return true;
        };
    }

    if (category.name === CATEGORIES.PARAGONS) {
        category.isEnabledCategory = () => Account.isParagonsEnabled();
    }
}

export const getMenuForState = (categoriesFromState: ICategories, bundles: IBundleList, featuring: IFeature[]) => {
    const categories: any = Object.assign({}, getMenuCategories(categoriesFromState, bundles));
    const firstCategories: Record<string, IMenuMapItem> = {};
    const overrideCategories: Record<string, IMenuMapItem> = {};

    Object.keys(categories).forEach((key) => {
        const category = categories[key];
        category['url'] = getUrl(urlsMap.categories, { categoryName: category.name });
        category.timer = null;

        overrideCategoriesData(category);

        if (category.type === CategoryType.FEATURED) {
            firstCategories[category.name] = category;
        }
        if (category.type === CategoryType.PREMIUM) {
            overrideCategories[category.name] = category;
        }
    });

    return addCategoriesTimers(
        categories,
        {
            ...firstCategories,
            ...categories,
            ...overrideCategories,
        },
        featuring,
    );
};

function addCategoriesTimers(allCategories: ICategories, menuMap: IMenuMap, featuring: IFeature[]) {
    Object.keys(menuMap).forEach((name) => {
        const menuCategory: IMenuMapItem = menuMap[name];

        let timer: ITimerData = _getCategoryCountdownTimer(menuCategory);

        const menuItem = allCategories[name] as IMenuMapItem;

        if (!timer) {
            timer = _getCategoryFeaturedTimerForMenu(menuItem, featuring);
        }

        if (!timer) {
            const subCategories = menuCategory?.subCategories || [];

            for (const subCategoryName of subCategories) {
                timer = _getCategoryCountdownTimer(menuItem);

                if (timer) break;
            }
            if (!timer) {
                for (const subCategoryName of subCategories) {
                    timer = _getCategoryFeaturedTimerForMenu(menuItem, featuring);

                    if (timer) break;
                }
            }
        }

        if (menuCategory) {
            menuCategory.timer = timer;
        }
    });

    return menuMap;
}

function _getCategoryCountdownTimer(menuCategory: IMenuMapItem) {
    if (menuCategory?.activeTill && menuCategory?.activityCountdown?.isEnabled) {
        const activeTill = new Date(menuCategory.activeTill);
        return !!activeTill.getTime() ? { activeTill: activeTill, title: t('До конца события:') } : null;
    }

    return null;
}

function _getCategoryFeaturedTimerForMenu(menuCategory: IMenuMapItem, featuring: IFeature[]) {
    if (!menuCategory) {
        return null;
    }

    return getCategoryFeaturedTimerForMenu(menuCategory.name, featuring);
}

export const getMenuKeysWithoutSubcategories = (menu: IMenuMap, categories: ICategories) => {
    return Object.keys(menu).filter((key: ICategoryList) => {
        const category = categories[key] as ICategory;
        if (!category) {
            return false;
        }
        if (category.subCategories?.length) {
            const availableSubCategories = getAvailableSubCategories(category.subCategories as ICategoryList[], categories);
            return !(availableSubCategories?.length === 1);
        }
        return !category?.parentCategory;
    });
};
