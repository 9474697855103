import * as React from 'react';
import ProgressiveDiscountTooltip from '~/components/ProgressiveDiscount/ProgressiveDiscountTooltip';
import { interpolate, t } from '~/utils/localization';
import styles from './ProgressiveDiscount.scss';
import { DivTooltip } from '@wg/wows-react-uikit';
import { useDispatch } from 'react-redux';
import { isMobileOrTabletDeviceByAgent } from '~/utils/agent';
import { IChangeVisiblePopup } from '~/Actions/ActionAppType';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import classNames from 'classnames';

interface IProgressiveDiscount {
    data: DiscountDiapason[];
    childBundleName: string;
    parentBundleName: string;
    singleLimit?: number;
    className?: string;
    amount: number;
    hideLabel?: boolean;
}

const ProgressiveDiscount = ({ data, childBundleName, parentBundleName, amount, hideLabel, className, singleLimit: sindleLimit }: IProgressiveDiscount) => {
    const dispatch = useDispatch();
    const maxDiscount = data[data.length - 1]?.discount;
    const text = interpolate(t('до {percent}%'), { percent: maxDiscount });

    const onClick = () => {
        if (isMobileOrTabletDeviceByAgent() && !!data?.length) {
            dispatch<IChangeVisiblePopup>(
                changeVisiblePopup(POPUPS_NAME.MOBILE_TOOLTIP_POPUP, true, {
                    contents: [
                        <ProgressiveDiscountTooltip
                            amount={amount}
                            hideFirstParagraph={true}
                            parentBundleTitle={parentBundleName}
                            childBundleTitle={childBundleName}
                            data={data}
                            singleLimit={sindleLimit}
                        />,
                    ],
                }),
            );
        }
    };

    const classesLabel = classNames(styles.label, {
        [styles.hide]: hideLabel,
    });

    return (
        <DivTooltip
            className={classNames(styles.wrapper, className)}
            onClick={onClick}
            tooltipBody={
                <ProgressiveDiscountTooltip amount={amount} hideFirstParagraph={true} parentBundleTitle={parentBundleName} childBundleTitle={childBundleName} data={data} singleLimit={sindleLimit} />
            }
        >
            {!!maxDiscount && <div className={styles.discount}>{text}</div>}
            <div className={classesLabel}>{!hideLabel && t('Максимальная скидка')}</div>
        </DivTooltip>
    );
};

export default ProgressiveDiscount;
