import * as React from 'react';
import styles from './Button.scss';
import equal from 'fast-deep-equal/react';
import { BlueButton, OrangeButton, SpecialOrangeButton } from '~/components/Button/Buttons';
import { t } from '~/utils/localization';
import { useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { getProgressiveDiscountFromBundle, isFreeBundle } from '~/utils/bundles';
import { calculateBundlePrices } from '~/utils/purchase';
import classNames from 'classnames';
import PurchaseProcessor from '~/processors/PurchaseProcessor';
import CurrencyShortageBlock from '~/components/CurrencyShortageBlock/CurrencyShortageBlock';
import FastGoldLinkContainer, { FAST_GOLD_PLACE } from '~/containers/FastGoldLinkContainer/FastGoldLinkContainer';
import { assets } from '@wg/wows-entities/const';
import { ICurrentPage } from '~/Actions/ActionAppType';
import { isCertificateShowcase } from '~/Layouts/Themes/SantaPage/settings';
import { CATEGORIES, CURRENCY } from '~/const';
import EventumCnRedirectButton from '~/components/EventumCnRedirectButton/EventumCnRedirectButton';
import { playButtonClickSound } from '~/api/WoWsClient';
import { IButtonSoundset } from '~/types/soundsets';

export enum PurchaseButtonTheme {
    orange = 'orange',
    specialOrange = 'specialOrange',
    blue = 'blue',
}

interface IPurchaseButton {
    coupon?: ICoupon;
    bundle: IBundle;
    parentRandomBundle?: IBundle;
    isActiveCoupon?: boolean;
    tooltip?: string;
    onClick?: (event: React.MouseEvent) => void;
    onClickCallback?: (event: React.MouseEvent) => void;
    onClickFromDisableButton?: (event: React.MouseEvent) => void;
    isDisabled?: boolean;
    isFetching?: boolean;
    label?: string;
    isForceRenderPurchaseButton?: boolean;
    buttonClassName?: string | string[];
    onMouseMove?: (event: React.MouseEvent) => void;
    onClickOnSerialAttention?: (event: React.MouseEvent) => void;
    classNameFastGoldLink?: string;
    place?: FAST_GOLD_PLACE;
    customQuantity?: number;
    hideFastGoldLink?: boolean;
    shortageClassName?: string;
    purchaseButtonTheme?: PurchaseButtonTheme;
    classNameChinaFastGoldLink?: string;
}

interface IStateSelector {
    currentPage: ICurrentPage;
    purchasedLimitedBundles: AccountPurchasedLimitedBundles;
    activeCouponId: number;
    bundlesInTransaction: number[];
    deniedBundlesByUniqueItems: number[];
    accountId: number;
    bundles: IBundleList;
    coupons: ICoupon[];
    balance: IBalance;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        currentPage: state.ReducerApp.currentPage,
        purchasedLimitedBundles: state.ReducerAccount.purchasedLimitedBundles,
        activeCouponId: state.ReducerAccount.activeCouponId,
        bundlesInTransaction: state.ReducerAccount.bundlesInTransaction,
        deniedBundlesByUniqueItems: state.ReducerAccount.deniedBundlesByUniqueItems,
        accountId: state.ReducerAccount.id,
        bundles: state.ReducerApp.bundles,
        coupons: state.ReducerAccount.coupons,
        balance: state.ReducerAccount.balance,
    };
};

const PurchaseButton = (props: IPurchaseButton): any => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const randomBundleChildInTransaction = (state.accountId && props.bundle.randomBundleChildren?.map((child) => child.id).filter((id) => state.bundlesInTransaction.includes(id))) || [];
    const bundleInTransaction = (state.bundlesInTransaction && state.bundlesInTransaction.includes(props.bundle.id)) || !!randomBundleChildInTransaction.length;

    if (bundleInTransaction) {
        return <OrangeButton label={t('Обработка')} isFetching />;
    }

    const isShowcaseForCertificates = isCertificateShowcase(state.currentPage?.name);

    const progressiveDiscount = getProgressiveDiscountFromBundle(props.bundle, props.customQuantity || props.parentRandomBundle?.quantityData?.quantity || 1);

    const purchaseButtonsMap = {
        [PurchaseButtonTheme.orange]: OrangeButton,
        [PurchaseButtonTheme.specialOrange]: SpecialOrangeButton,
        [PurchaseButtonTheme.blue]: BlueButton,
    };

    let quantityData = state.accountId ? state.bundles[props.bundle.id]?.quantityData : { lack: {} };
    if (state.accountId) {
        const quantity = props.customQuantity || quantityData?.quantity || props.parentRandomBundle?.quantityData?.quantity || 1;
        quantityData = calculateBundlePrices(props.bundle, state.balance, quantity, props.coupon?.discount, progressiveDiscount);
    }

    const shortage = quantityData?.lack || {};
    const shortageCurrencies = Object.keys(shortage);
    const isShortage = shortageCurrencies.length > 0;

    if (!isShortage || !state.accountId || props.isForceRenderPurchaseButton) {
        const _isFreeBundle = isFreeBundle(props.bundle);
        const tooltipContent = props.tooltip ? <DefaultTooltip text={props.tooltip} /> : null;
        let text = props.label || t('Купить');

        if (_isFreeBundle && !props.label) {
            text = t('Получить бесплатно');
        }

        if (isShowcaseForCertificates) {
            text = t('Обменять');
        }

        const ButtonComponent = purchaseButtonsMap[props.purchaseButtonTheme || PurchaseButtonTheme.orange];

        return (
            <DivTooltip tooltipBody={tooltipContent}>
                <ButtonComponent
                    label={text}
                    isFree={_isFreeBundle}
                    soundset={IButtonSoundset.MUTED}
                    onMouseMove={props.onMouseMove}
                    onFallback={props.onClickFromDisableButton}
                    onClick={(event) => {
                        event.stopPropagation();
                        playButtonClickSound();
                        if (props.onClick) {
                            props.onClick(event);
                        } else {
                            if (_isFreeBundle) {
                                new PurchaseProcessor(props.bundle).purchase();
                            } else {
                                new PurchaseProcessor(props.bundle, {
                                    popupData: {
                                        bundleId: props.bundle.id,
                                        fromCategory: !state.currentPage?.isBundlePage,
                                    },
                                }).showPopup();
                            }
                        }
                    }}
                    isFetching={props.isFetching || bundleInTransaction}
                    disabled={props.isDisabled}
                    className={props.buttonClassName}
                />
            </DivTooltip>
        );
    }

    const map = shortageCurrencies.map((currency) => {
        return {
            amount: shortage[currency],
            currency,
        };
    });

    return (
        <div className={classNames(styles.shortageWrapper, props.shortageClassName)}>
            <CurrencyShortageBlock currencies={map} />
            {!props.hideFastGoldLink && !!shortage[assets.GOLD] && (
                <FastGoldLinkContainer
                    className={props.classNameFastGoldLink}
                    place={props.place}
                    goldShortage={shortage[assets.GOLD]}
                    isSantaBundle={props.bundle.categories.includes(CATEGORIES.SANTA_CONTAINERS)}
                    groupName={props.bundle.groupName}
                    withIcon
                />
            )}
            {!props.hideFastGoldLink && !!shortage[CURRENCY.EVENTUM_CN] && <EventumCnRedirectButton className={props.classNameChinaFastGoldLink} withIcon />}
        </div>
    );
};

export default PurchaseButton;
