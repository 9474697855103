import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import styles from './Menu.scss';
import { State } from '~/Reducers';
import { getMenuKeysWithoutSubcategories } from '~/utils/menu';
import MenuItem from '~/components/Menu/MenuItem';
import { CATEGORIES } from '~/const';
import Account from '~/account/Account';
import { getFirstEventCategory, isAllSubCategoryDisplayRestricted, isDockyardCategory } from '~/utils/category';
import { GUIDE_NAMES } from '~/components/WelcomePage/steps';
import classNames from 'classnames';
import { range } from '~/utils/generate';
import Skeleton from '~/components/Skeleton/Skeleton';

interface IState {
    menu: IMenuMap;
    categories: ICategories;
    isBlurView: boolean;
    bundles: IBundleList;
}

const stateSelector = (state: State): IState => {
    return {
        menu: state.ReducerApp.menu,
        categories: state.ReducerApp.categories,
        isBlurView: state.ReducerApp.isBlurView,
        bundles: state.ReducerApp.bundles,
    };
};

const getGuideNamesFromMenu = (category: ICategoryList, isEvents = false): GUIDE_NAMES[] => {
    switch (category) {
        case CATEGORIES.AUCTION:
            return [GUIDE_NAMES.guide_auction];
        case CATEGORIES.WSMART:
            return [GUIDE_NAMES.guide_premshop];
        case CATEGORIES.DOCKYARD:
            return [GUIDE_NAMES.guide_dockyard];
        default: {
            if (isEvents) {
                return [GUIDE_NAMES.guide_events];
            }
            return null;
        }
    }
};

interface IMenuWrapper {
    showSkeleton?: boolean;
}

export const MenuWrapper = ({ showSkeleton }: IMenuWrapper) => {
    if (showSkeleton) {
        const len = 7;
        const containerStyles = { '--menu-length': len } as React.CSSProperties;
        return (
            <div className={styles.menuContainer} style={containerStyles}>
                {[...range(len, 0, 1)].map((item: number, index: number) => {
                    return (
                        <div className={classNames(styles.menuItem, styles.fetching)} key={`menu_skeleton_${index}`}>
                            <Skeleton />
                        </div>
                    );
                })}
            </div>
        );
    }

    return <Menu />;
};

const Menu = () => {
    const appState: IState = useSelector<State, IState>(stateSelector, shallowEqual);
    const menu = appState.menu;
    const menuWithoutSubcategoriesKeys = getMenuKeysWithoutSubcategories(menu, appState.categories);
    const style = { '--menu-length': menuWithoutSubcategoriesKeys.length } as React.CSSProperties;
    const firstEventCategory = getFirstEventCategory(appState.categories);

    const menuClassNames = classNames(styles.menuContainer, {
        [styles.blurElement]: isDockyardCategory() && appState.isBlurView,
    });

    return (
        <React.Fragment>
            <div className={menuClassNames} style={style}>
                {Object.keys(menu).map((key: string) => {
                    const menuMapItem = menu[key] as IMenuMapItem;

                    if (!menuMapItem.url || (typeof menuMapItem.isEnabledCategory === 'function' && !menuMapItem.isEnabledCategory())) {
                        return;
                    }

                    if (menuMapItem.isHidden) {
                        return;
                    }

                    if (!Account.isParagonsEnabled() && menuMapItem.name === CATEGORIES.PARAGONS) {
                        return;
                    }

                    if (menuMapItem.subCategories?.length && isAllSubCategoryDisplayRestricted(menuMapItem)) {
                        return null;
                    }

                    return (
                        <MenuItem
                            key={menuMapItem.name}
                            item={menuMapItem}
                            url={menuMapItem.url}
                            guideNames={getGuideNamesFromMenu(menuMapItem.name as ICategoryList, firstEventCategory?.name === menuMapItem.name)}
                            timer={menuMapItem.timer}
                            type={menuMapItem.type}
                            hasParentCategory={!!menuMapItem.parentCategoryData}
                        />
                    );
                })}
            </div>
        </React.Fragment>
    );
};

export default React.memo(Menu);
