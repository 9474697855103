import { isEmptyObject, isInGameBrowser, isMobileOrTabletWindow } from '~/utils/utils';
import { hasAnimationSetting, isDisabledAnimationByName, updateAnimationStatusByName } from '~/utils/animations/settings';
import { State } from '~/Reducers';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import React from 'react';
import { ICurrentPage } from '~/Actions/ActionAppType';

interface IStateSelector {
    categoriesAnimationStatuses: ICategoriesAnimationStatuses;
    isLowPcPerformance: boolean;
    currentPage: ICurrentPage;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        categoriesAnimationStatuses: state.ReducerApp.categoriesAnimationStatuses,
        isLowPcPerformance: state.ReducerAccount.isLowPcPerformance,
        currentPage: state.ReducerApp.currentPage,
    };
};

export function useDisabledAnimationByGraphics(name: string) {
    const { isLowPcPerformance, currentPage } = useSelector<State, IStateSelector>(stateSelector, equal);

    React.useEffect(() => {
        if (!name || hasAnimationSetting(name) || !isInGameBrowser) {
            return;
        }
        if (isLowPcPerformance && name) {
            updateAnimationStatusByName(name, true);
        }
    }, [name, currentPage?.name, isLowPcPerformance]);
}

export function useIsDisabledAnimation(name?: string, isDisabledMobileAnimation: boolean = isMobileOrTabletWindow) {
    const { categoriesAnimationStatuses, currentPage } = useSelector<State, IStateSelector>(stateSelector, equal);
    if (!name && name !== '') name = currentPage?.name;
    return isDisabledMobileAnimation || isDisabledAnimationByName(name) || !!categoriesAnimationStatuses?.[name];
}
