import * as React from 'react';
import styles from './View.scss';
import { MenuWrapper } from '~/components/Menu/Menu';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import PageHeader from '~/components/PageHeader/PageHeader';
import { scrollToBundleIfNeeded, scrollTop } from '~/utils/scrollTop';
import { IViewContainer } from '~/containers/ViewContainer/ViewContainer';
import PopupManager from '~/components/PopupManager';
import classNames from 'classnames';
import { Processing } from '@wg/wows-react-uikit';
import NotificationManager, { getNotification, getNotificationToShowIfNeeded } from '~/components/Notifications/NotificationManager';
import VideoPlayer from '~/components/VideoPlayer/VideoPlayer';
import { isInGameBrowser, isMobileOrTabletWindow } from '~/utils/utils';
import { isIEBrowser } from '~/utils/settings';
import Footer from '~/components/Footer/Footer';
import useScroll from '~/hooks/useScroll';
import MobileMenuFooter from '~/components/Footer/MobileMenuFooter';
import ViewBackground from '~/components/ViewBackground/ViewBackground';
import DevModePanel from '~/components/DevModePanel/DevModePanel';
import RewardPopupManager from '~/components/Popups/Reward/RewardPopupManager';
import ViewTopPanel from '~/components/View/ViewTopPanel';
import ScrollToTopButton from '~/components/ScrollToTopButton/ScrollToTopButton';
import RefManager, { RefManagerKeys } from '~/RefManager';
import CategoryFilter from '~/components/CategoryFilter/CategoryFilter';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import { parseFiltersInQueryParams } from '~/utils/filters';
import { useDispatch } from 'react-redux';
import { setTrusted } from '~/Actions/ActionApp';
import { isDockyardCategory } from '~/utils/category';
import useMobile from '~/hooks/useMobile';
import usePlayMainTheme from '~/hooks/usePlayMainTheme';
import { FilterPosition } from '~/types/category';
import { showPurchasePopupFromSearchParamsIfNeeded } from '~/utils/bundles';
import { applySortByQueryParams } from '~/utils/sort/settings';
import StickyBox from 'react-sticky-box';
import { playButtonClickSound } from '~/api/WoWsClient';
import CategorySkeleton from '~/components/CategorySkeleton/CategorySkeleton';
import GiftIntroWidget from '~/components/GiftPromo/GiftIntroWidget';
import { CUSTOM_PAGE, SANTA_PAGE_THEME } from '~/Layouts/Themes/ThemeManager';
import { filterNotifications } from '~/utils/notifications';

export type OutletContextType = {
    isBundlePage?: boolean;
};

export const parseQueryParams = () => {
    showPurchasePopupFromSearchParamsIfNeeded();
    parseFiltersInQueryParams();
    applySortByQueryParams();
};

let previousTime: number;
let previousPage: string;

const View = (props: IViewContainer): React.ReactElement => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ref = React.useRef<HTMLDivElement>(null);
    const mainContainer = React.useRef<HTMLDivElement>(null);

    const scrollToTop = () => {
        playButtonClickSound();

        dwhExport.send(DWH_EVENTS.SCROLL_TO_TOP, { category: props.currentPage.name });
        scrollTop(0, true);
    };

    const [isMobile] = useMobile();
    const isHiddenMobileNav = props.isHiddenMobileNav && isMobile;

    const isVisibleTopPanel = props.isVisibleTopPanel && !isHiddenMobileNav;
    const isVisibleMenu = props.isVisibleMenu && !props.port.isVisible;

    const isVisiblePopup = !!props.popupActive;
    const availableInfoScreenName = getNotificationToShowIfNeeded();
    const infoScreenName: string | boolean = !isVisiblePopup && !props.port.isVisible && !props.clientSource ? availableInfoScreenName ?? props.currentNotificationVisibleName : null;
    const filteredNotifications = filterNotifications(props.notifications);
    const isAvalaibleNotification = !!filteredNotifications?.find((notification) => notification.name === infoScreenName);
    const isBlur = (isVisiblePopup || (getNotification(infoScreenName?.toString()).display === 'popup' && isAvalaibleNotification) || props.isVisibleRewardPopup) && !props.popupActive?.data?.isNoBlur;
    const isVisibleAnyMorePopups = !!(isVisiblePopup || infoScreenName);

    const [isVisibleFilters, setFiltersVisible] = React.useState(false);
    const currentCategory = props.categories?.[props.currentPage?.name];

    const viewClassNames = classNames(styles.view, {
        [styles.blur]: (!isDockyardCategory() && props.isBlurView) || isBlur,
        [styles.ieBlur]: isBlur && isIEBrowser(),
        [styles.zIndex]: isMobileOrTabletWindow && isVisibleFilters,
        [styles.mobileBlur]: !isDockyardCategory() && isMobileOrTabletWindow && props.isBlurView,
        [styles.fullScreen]: props.isFullscreen,
        [styles.santa]: currentCategory?.theme === SANTA_PAGE_THEME,
        [`armory-theme__${currentCategory?.theme}`]: !!currentCategory?.theme,
    });

    const mobileComponentClasses = classNames({ [styles.blur]: isBlur });

    const mainContainerClassNames = classNames(styles.mainContainer, { [styles.hideScrollBar]: isInGameBrowser });

    const popupWrapperClassNames = classNames(styles.popupWrapper, {
        [styles.inPort]: props.port?.isVisible,
    });
    let popupWrapperStyles: React.CSSProperties | undefined;
    let popupItemWrapperStyles: React.CSSProperties | undefined;
    if (currentCategory?.theme === CUSTOM_PAGE && props.customPageSettings?.popupBackground?.url) {
        popupWrapperStyles = {
            backgroundImage: `url(${props.customPageSettings?.popupBackground?.url})`,
            backgroundSize: `100% 100%`,
            backgroundPosition: 'center',
        };
        popupItemWrapperStyles = {
            backgroundColor: 'unset',
        };
    }

    useScroll(
        mainContainer,
        () => {
            ref.current?.classList.add(styles.scrolled);
        },
        () => {
            ref.current?.classList.remove(styles.scrolled);
        },
    );

    usePlayMainTheme();

    React.useEffect(() => {
        const currentTime = Date.now();
        if (previousTime && previousPage) {
            const spendTime = Math.floor((currentTime - previousTime) / 1000);
            dwhExport.send(DWH_EVENTS.PAGE_CHANGED, {
                pageOpened: previousPage,
                currentTime: spendTime,
            });
        }
        previousPage = location.pathname;
        previousTime = currentTime;
    }, [location, navigate]);

    React.useEffect(() => {
        if (isMobileOrTabletWindow) {
            window.addEventListener('popstate', () => {
                setFiltersVisible(false);
            });
        }

        const onClickBody = () => {
            dispatch(setTrusted());
            document.removeEventListener('click', onClickBody);
        };
        document.addEventListener('click', onClickBody);
    }, []);

    React.useEffect(() => {
        if (props.isFinishedRequest) {
            parseQueryParams();
            scrollToBundleIfNeeded();
        }
    }, [props.isFinishedRequest]);

    const setRef = (_ref: HTMLDivElement) => {
        if (_ref) {
            RefManager.setRef(RefManagerKeys.MainContainer, _ref);
            mainContainer.current = _ref;
        }
    };

    return (
        <React.Fragment>
            <ViewBackground isBlur={isBlur || props.isBlurView} />
            {isMobileOrTabletWindow && !isHiddenMobileNav && (
                <div className={mobileComponentClasses}>
                    <PageHeader />
                </div>
            )}
            <div className={viewClassNames}>
                {!isMobileOrTabletWindow && <PageHeader />}
                <div className={styles.menuWrapper}>{isVisibleMenu && <MenuWrapper showSkeleton={!props.isFinishedRequest} />}</div>
                <div className={styles.contentWrapper}>
                    {isVisibleTopPanel && (
                        <ViewTopPanel
                            {...props}
                            onChangeFilterVisible={(visible) => {
                                setFiltersVisible(visible);
                            }}
                        />
                    )}
                    <div className="js_viewMiddle"></div>
                    <div className={mainContainerClassNames} ref={setRef}>
                        <div className={styles.content}>
                            <div className={styles.leftContent} />
                            <div className={styles.mainContent} ref={ref}>
                                {props.isFinishedRequest}
                                {!props.isFinishedRequest ? (
                                    <CategorySkeleton />
                                ) : (
                                    <Outlet
                                        context={
                                            {
                                                isBundlePage: props.currentPage?.isBundlePage,
                                            } satisfies OutletContextType
                                        }
                                    />
                                )}
                            </div>
                            <div className={styles.rightContent}>
                                <StickyBox>
                                    <CategoryFilter category={props.currentPage?.name} withoutActiveFilters={true} position={FilterPosition.RIGHT} />
                                </StickyBox>
                            </div>
                        </div>
                        {!isHiddenMobileNav && !props.port?.isVisible && <Footer />}
                        <ScrollToTopButton onClick={scrollToTop} />
                    </div>
                </div>
            </div>
            {!isHiddenMobileNav && (
                <div className={mobileComponentClasses}>
                    <MobileMenuFooter />
                </div>
            )}
            {isVisiblePopup && (
                <div className={popupWrapperClassNames} style={popupWrapperStyles}>
                    {props.popups.map((popup: IPopup, index: number) => {
                        return (
                            <div className={styles.popupItemWrapper} style={popupItemWrapperStyles} key={`${popup.id}_${popup.name}`}>
                                <PopupManager popup={popup} isPopupActive={index === props.popups.length - 1} />
                            </div>
                        );
                    })}
                </div>
            )}
            {infoScreenName && isAvalaibleNotification && <NotificationManager name={infoScreenName.toString()} notifications={filteredNotifications} />}
            {props.isFetching && <Processing isFetching />}
            {!isVisibleAnyMorePopups && props.isGiftAvailable && !props.port?.isVisible && <GiftIntroWidget />}
            {!infoScreenName && <VideoPlayer />}
            {!isVisibleAnyMorePopups && !props.isGiftAvailable && <RewardPopupManager />}
            <DevModePanel />
        </React.Fragment>
    );
};

export default View;
