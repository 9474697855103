import * as React from 'react';
import styles from '../Port.scss';
import AbstractPort from '~/components/Port/AbstractPort';
import { t } from '~/utils/localization';
import { ManagerData } from '~/core/ManagerData';
import { Button } from '~/components/Button/Buttons';

export default class DefaultPort extends AbstractPort {
    protected getCamoTitle(): string {
        if (this.props.exteriorId < 0) {
            return null;
        }

        const camoTitle = ManagerData.getPermoflage(this.props.exteriorId)?.title || ManagerData.getItem(this.props.exteriorId)?.title;
        return camoTitle || this.props.bundle?.title;
    }

    protected renderFooterContent(): React.ReactChild {
        return (
            <div className={styles.footer}>
                <Button
                    label={this.props.bundle ? t('Описание набора') : t('Назад')}
                    onClick={() => {
                        this.props.onClose();
                    }}
                />
            </div>
        );
    }

    protected renderTitle(): React.ReactChild {
        if (this.props.bundle) {
            return <div className={styles.title} dangerouslySetInnerHTML={{ __html: this.props.bundle.title }} />;
        }
        return null;
    }

    render() {
        this.isAvailableContainsWidget = this.state.containsItems?.length > 1;
        return super.render();
    }
}
