import * as React from 'react';
import equal from 'fast-deep-equal/react';
import SoundControl from '~/components/TopPanel/SoundControl';
import styles from './TopPanel.scss';
import Wallet from '~/components/Wallet/Wallet';
import { t } from '~/utils/localization';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { getUserId } from '~/utils/settings';
import { ICurrentPage, IUpdateActiveFilterPreset } from '~/Actions/ActionAppType';
import { getAvailableSubCategories, getPresetByName, openCategoryByName } from '~/utils/category';
import History from '~/utils/history';
import Account from '~/account/Account';
import classNames from 'classnames';
import { isEmptyObject, isMobileOrTabletWindow } from '~/utils/utils';
import { updateActiveFilterPreset } from '~/Actions/ActionApp';
import AnimationIcon from '~/components/AnimationIcon/AnimationIcon';
import { isEnabledCategoryVideo, startCategoryVideo } from '~/utils/video/settings';
import { SINGLE_BUNDLE_PAGE } from '~/Layouts/Themes/ThemeManager';
import PlayIcon from '~/components/PlayIcon/PlayIcon';
import { getAnimationStatusByName, isEnabledAnimationByCategory, updateAnimationStatusByName } from '~/utils/animations/settings';
import GuideWrapper from '~/components/TopPanel/GuideWrapper';
import { playButtonClickSound } from '~/api/WoWsClient';
import { isHiddenBundleCategoryTitle } from '~/utils/bundles';
import useMobile from '~/hooks/useMobile';
import { AppState } from '~/Reducers/ReducerApp';

interface ITopPanelState {
    isStartedVideo: boolean;
    currentPage: ICurrentPage;
    coupons: ICoupon[];
    activePreset: string;
    categories: ICategories;
    isTrusted: boolean;
    menu: IMenuMap;
    bundles: IBundleList;
    categoriesAnimationStatuses: ICategoriesAnimationStatuses;
}

const stateSelector = (state: State): ITopPanelState => {
    return {
        isStartedVideo: state.ReducerApp.isStartedVideo,
        currentPage: state.ReducerApp.currentPage,
        coupons: state.ReducerAccount.coupons,
        activePreset: state.ReducerApp.activePreset,
        categories: state.ReducerApp.categories,
        isTrusted: state.ReducerApp.isTrusted,
        menu: state.ReducerApp.menu,
        bundles: state.ReducerApp.bundles,
        categoriesAnimationStatuses: state.ReducerApp.categoriesAnimationStatuses,
    };
};

interface ICategoryNavigation {
    onClickCategory: () => void;
    isEnabledGoToCategory: boolean;
    preset: ICategoryPresets;
    currentPage: ICurrentPage;
    categoryConfig: ICategory;
    customTitle?: string;
}

const CategoryNavigation = ({ onClickCategory, preset, currentPage, isEnabledGoToCategory, categoryConfig, customTitle }: ICategoryNavigation) => {
    const dispatch = useDispatch();
    const [isMobile] = useMobile();

    const state = useSelector<State, ITopPanelState>(stateSelector, equal);
    const reducerApp = useSelector<State, AppState>((state) => state.ReducerApp, shallowEqual);
    const category = state.categories[currentPage?.name];
    const subCategories = category?.subCategories;
    const availableSubCategories = getAvailableSubCategories(subCategories as ICategoryList[], reducerApp.categories);

    const title = !(category.parentCategoryData?.disableFeatured && !availableSubCategories.length)
        ? customTitle || (isMobile ? currentPage.title : currentPage.titleImage || currentPage.title)
        : category.parentCategoryData.title;

    const titleStyles: React.CSSProperties = {
        color: categoryConfig?.textSettings?.titleColor,
        fontFamily: categoryConfig?.textSettings?.titleFont,
        display: categoryConfig.hideTitle ? 'none' : undefined,
    };

    const titleCategoryClassNames = classNames(styles[categoryConfig?.theme], styles[currentPage?.name], {
        [styles.pointer]: isEnabledGoToCategory || preset,
        [styles.isBundlePage]: currentPage?.isBundlePage,
    });

    const subTitleCategoryClassNames = classNames(styles.subCategoryTitle, {
        [styles.pointer]: preset && currentPage.isBundlePage,
    });

    return (
        <React.Fragment>
            <Title
                value={title}
                className={titleCategoryClassNames}
                styles={titleStyles}
                onClick={() => {
                    if (isEnabledGoToCategory || preset) {
                        dispatch<IUpdateActiveFilterPreset>(updateActiveFilterPreset(null));
                    }
                    if (isEnabledGoToCategory) {
                        playButtonClickSound();
                        onClickCategory();
                    }
                }}
            />
            {category.parentCategoryData?.disableFeatured && !availableSubCategories.length && (
                <>
                    <span className={styles.navigationArrow} />
                    <span
                        className={subTitleCategoryClassNames}
                        onClick={() => {
                            if (preset) {
                                dispatch<IUpdateActiveFilterPreset>(updateActiveFilterPreset(null));
                            }
                            if (isEnabledGoToCategory || preset) {
                                playButtonClickSound();
                                onClickCategory();
                            }
                        }}
                        style={titleStyles}
                    >
                        {currentPage?.title}
                    </span>
                </>
            )}
            {preset && (
                <React.Fragment>
                    <span className={styles.navigationArrow} />
                    <span
                        className={subTitleCategoryClassNames}
                        onClick={() => {
                            if (isEnabledGoToCategory) {
                                playButtonClickSound();
                                onClickCategory();
                            }
                        }}
                    >
                        {preset.title}
                    </span>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

interface ISubCategoryNavigation {
    onClickCategory: () => void;
    goToParentCategory: () => void;
    isEnabledGoToCategory: boolean;
    isEnabledGoToParentCategory: boolean;
    preset: ICategoryPresets;
    currentPage: ICurrentPage;
    parentCategory: ICategory;
    customTitle?: string;
}

const SubCategoryNavigation = ({
    onClickCategory,
    goToParentCategory,
    preset,
    currentPage,
    isEnabledGoToCategory,
    isEnabledGoToParentCategory,
    parentCategory,
    customTitle,
}: ISubCategoryNavigation) => {
    const dispatch = useDispatch();
    const [isMobile] = useMobile();

    const titleCategoryClassNames = classNames(styles[currentPage?.name], {
        [styles.pointer]: isEnabledGoToCategory || preset,
    });

    const subTitleCategoryClassNames = classNames(styles.subCategoryTitle, {
        [styles.pointer]: preset || currentPage.isBundlePage,
    });

    const presetTitleCategoryClassNames = classNames(styles.subCategoryTitle, {
        [styles.pointer]: preset && currentPage.isBundlePage,
    });

    const titleStyles: React.CSSProperties = {
        color: parentCategory?.textSettings?.titleColor,
        fontFamily: parentCategory?.textSettings?.titleFont,
    };

    const title = customTitle || (isMobile ? parentCategory.title : parentCategory.titleImage || parentCategory.title);

    return (
        <React.Fragment>
            <span>
                <Title
                    value={title}
                    className={titleCategoryClassNames}
                    onClick={() => {
                        if (preset) {
                            dispatch<IUpdateActiveFilterPreset>(updateActiveFilterPreset(null));
                        }
                        if (isEnabledGoToParentCategory) {
                            playButtonClickSound();
                            goToParentCategory();
                        }
                    }}
                    styles={titleStyles}
                />
                <span className={styles.navigationArrow} />
                <span
                    className={subTitleCategoryClassNames}
                    onClick={() => {
                        if (preset) {
                            dispatch<IUpdateActiveFilterPreset>(updateActiveFilterPreset(null));
                        }
                        if (isEnabledGoToCategory || preset) {
                            playButtonClickSound();
                            onClickCategory();
                        }
                    }}
                    style={titleStyles}
                >
                    {currentPage?.title}
                </span>
                {preset && (
                    <React.Fragment>
                        <span className={styles.navigationArrow} />
                        <span
                            className={presetTitleCategoryClassNames}
                            onClick={() => {
                                if (isEnabledGoToCategory) {
                                    playButtonClickSound();
                                    onClickCategory();
                                }
                            }}
                            style={titleStyles}
                        >
                            {preset.title}
                        </span>
                    </React.Fragment>
                )}
            </span>
        </React.Fragment>
    );
};

interface TitleProps {
    onClick?: () => void;
    className?: string;
    styles?: React.CSSProperties;
    value: string; // text or image url
}
const Title = (props: TitleProps) => {
    const isFile = /\.[a-zA-Z]+$/.test(props.value);
    const className = classNames(styles.titleCategory, props.className);
    return isFile ? (
        <img className={className} src={props.value} onClick={props.onClick} alt="" style={props.styles} />
    ) : (
        <span className={className} onClick={props.onClick} style={props.styles}>
            {props.value}
        </span>
    );
};

const TopPanel = function () {
    const state = useSelector<State, ITopPanelState>(stateSelector, equal);
    const refMobileTopPanel: React.RefObject<HTMLDivElement> = React.useRef(null);
    const currentPage = state.currentPage;
    const category = state.categories[currentPage?.name];
    const activePreset = state.activePreset ? getPresetByName(category?.filters?.presets || [], state.activePreset) : null;
    const isEnabledCoupon = !currentPage?.isBundlePage && Account.hasCouponFromCategory(state.coupons, currentPage?.name);
    const parentCategoryName = state.categories[currentPage?.name]?.parentCategory as ICategoryList;
    const parentCategory = state.categories[parentCategoryName];
    const currentCategoryConfig = state.menu[currentPage?.name];
    const isSingleBundlePage = category.theme === SINGLE_BUNDLE_PAGE;
    const currentBundle = state.bundles[state.currentPage?.bundleId];
    const hasVideoBackground = !isMobileOrTabletWindow && (!isEmptyObject(currentBundle?.videoBackground) || !isEmptyObject(category?.videoBackground));
    const activeCategory = currentCategoryConfig || category;
    const currentBundleWithVideoBackground = currentBundle && !isEmptyObject(currentBundle.videoBackground);

    React.useEffect(() => {
        if (isMobileOrTabletWindow) {
            let pos = 0;
            let timeout: any;

            const changePosition = (isFixed?: boolean) => {
                clearTimeout(timeout);

                if (!isFixed) {
                    refMobileTopPanel.current?.classList.remove(styles.fixed, styles.visible);
                } else {
                    refMobileTopPanel.current?.classList.add(styles.fixed);
                    timeout = setTimeout(() => {
                        clearTimeout(timeout);
                        refMobileTopPanel.current?.classList.add(styles.visible);
                    }, 0);
                }
            };

            document.addEventListener('scroll', () => {
                const top: number = document.documentElement.scrollTop;
                if (!pos) {
                    if (top >= 160) {
                        pos = top;
                        changePosition();
                    }
                } else {
                    if (top < pos) {
                        changePosition(true);
                        pos = top;
                    } else {
                        changePosition();
                        pos = top;
                    }
                }

                if (top <= 160) {
                    changePosition();
                    pos = top;
                }
            });
        }
    }, []);

    const goToParentCategory = () => {
        openCategoryByName(parentCategory.name);
    };

    const goToCategory = () => {
        openCategoryByName(currentPage.name);
    };

    const historyBack = () => {
        playButtonClickSound();

        if (!History.hasHistory() || currentBundle?.serialPurchase) {
            openCategoryByName(state.currentPage?.name);
        } else {
            History.back();
        }
    };

    let isEnabledGoToParentCategory = false;
    let isEnabledGoToCategory = false;
    if (currentPage?.isBundlePage) {
        isEnabledGoToCategory = true;
        isEnabledGoToParentCategory = true;
    } else if (parentCategory) {
        isEnabledGoToParentCategory = true;
    }

    const isEnabledButtonBack = state.currentPage?.isBundlePage || ['coupons', 'wallet'].includes(state.currentPage?.name);

    if (!state.currentPage) {
        return null;
    }

    const isMobile = isMobileOrTabletWindow;
    const classesTopPanel = classNames(styles.topPanel, styles[currentCategoryConfig?.theme], {
        [styles.walletShadow]: hasVideoBackground,
        [styles.isBundlePage]: currentPage?.isBundlePage,
        [styles.withLabel]: !isEmptyObject(currentCategoryConfig?.label),
    });

    let isBackgroundGallery = false;
    if (currentPage?.isBundlePage && currentBundle?.isFullscreenGallery) {
        isBackgroundGallery = true;
    }

    const isCategoryTitleHidden = currentPage?.isBundlePage && isHiddenBundleCategoryTitle(currentBundle);
    const customTitle = isCategoryTitleHidden ? currentBundle.title : '';
    if (isCategoryTitleHidden) {
        isEnabledGoToParentCategory = false;
        isEnabledGoToCategory = false;
    }

    return (
        <React.Fragment>
            <div className={styles.topPanelMobile} ref={refMobileTopPanel}>
                {getUserId() && <Wallet isMobile withoutAnimation />}
            </div>
            <div className={classesTopPanel}>
                <div className={styles.topPanelHeader}>
                    <div
                        className={classNames(styles.topPanelControls, {
                            [styles.topPanelControls_noTitle]: !!(currentCategoryConfig?.hideTitle || category?.hideTitle),
                        })}
                    >
                        {isEnabledCoupon && (
                            <DivTooltip tooltipBody={<DefaultTooltip text={t('У вас есть купон на товары из этой категории')} />}>
                                <div className={styles.coupon} id={'guideCoupon'} />
                            </DivTooltip>
                        )}
                        <div className={styles.title}>
                            {isEnabledButtonBack && <span className={styles.backIcon} onClick={historyBack} />}
                            {!parentCategory ? (
                                <CategoryNavigation
                                    currentPage={state.currentPage}
                                    onClickCategory={goToCategory}
                                    isEnabledGoToCategory={isEnabledGoToCategory && !isSingleBundlePage}
                                    preset={activePreset}
                                    categoryConfig={currentCategoryConfig || category}
                                    customTitle={customTitle}
                                />
                            ) : (
                                <SubCategoryNavigation
                                    currentPage={state.currentPage}
                                    onClickCategory={goToCategory}
                                    isEnabledGoToCategory={isEnabledGoToCategory}
                                    isEnabledGoToParentCategory={isEnabledGoToParentCategory}
                                    goToParentCategory={goToParentCategory}
                                    parentCategory={parentCategory}
                                    preset={activePreset}
                                    customTitle={customTitle}
                                />
                            )}
                        </div>
                        <SoundControl
                            bundle={currentBundle}
                            category={currentCategoryConfig || category}
                            isStartedVideo={state.isStartedVideo}
                            isTrusted={state.isTrusted}
                            className={classNames(styles.control, styles[currentCategoryConfig?.theme], styles[currentPage?.name], {
                                [styles.isBundlePage]: state.currentPage?.isBundlePage,
                            })}
                        />
                        {!isMobile && isEnabledAnimationByCategory(activeCategory, currentBundle) && !isBackgroundGallery && !activeCategory.videoBackground?.noVideoDisable && (
                            <div className={styles.iconWrapper}>
                                <AnimationIcon
                                    onChange={(isTurnOn) => {
                                        if (activeCategory) {
                                            if ((currentBundleWithVideoBackground || activeCategory) && activeCategory.disableAllAnimation) {
                                                activeCategory.bundles.forEach((bundleId: number) => {
                                                    updateAnimationStatusByName(state.bundles[bundleId].name, !isTurnOn);
                                                });
                                            }
                                            if (currentBundleWithVideoBackground && !activeCategory.disableAllAnimation) {
                                                updateAnimationStatusByName(currentBundle.name, !isTurnOn);
                                            } else {
                                                updateAnimationStatusByName(activeCategory.name, !isTurnOn);
                                            }
                                        }
                                    }}
                                    defaultValue={getAnimationStatusByName(activeCategory, currentBundle, activeCategory.disableAllAnimation)}
                                    className={classNames(styles.control, styles[activeCategory?.theme], styles[currentPage?.name], {
                                        [styles.isBundlePage]: state.currentPage?.isBundlePage,
                                    })}
                                />
                            </div>
                        )}
                        {!isMobile && isEnabledCategoryVideo(activeCategory) && (
                            <div className={classNames(styles.iconWrapper, { [styles.disabled]: state.isStartedVideo })}>
                                <PlayIcon
                                    className={classNames(styles.control, styles[currentCategoryConfig?.theme], styles[currentPage?.name], {
                                        [styles.isBundlePage]: state.currentPage?.isBundlePage,
                                    })}
                                    onClick={() => {
                                        startCategoryVideo(currentCategoryConfig || category);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <GuideWrapper />
                </div>
                <div className={styles.topPanelWallet}>{getUserId() && <Wallet />}</div>
            </div>
        </React.Fragment>
    );
};

export default TopPanel;
