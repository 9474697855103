import { get } from '~/utils/ajax';
import { settings } from '~/utils/settings';
import Account from '~/account/Account';
import { logError } from '~/utils/logging';
import store from '~/Store';

export const isDockyardEnabledForAccount = async () => {
    let isEnabled = false;

    const currentAccountId = Account.getAccount()?.id;
    let url = settings.urls.dockyardAccountCheck;

    if (currentAccountId && url) {
        url = url.replace('{accountId}', currentAccountId.toString());
        try {
            const result = await get(url, null, false, true, true, {
                credentials: 'include',
            });
            isEnabled = !!result?.data['show_category'];
        } catch (e) {
            logError('dockyard is unavailable', e);
        }
    }

    return isEnabled;
};

export interface dockyardSeason {
    season: number;
    shipId: number;
    isStartersBought: boolean;
    categoryBck: string;
}

export const getDockyardSeasonSettings = async () => {
    const url = settings.urls.dockyardAccountCheck + 'season-info/';
    let seasonSettings;

    if (settings.urls.dockyardAccountCheck) {
        try {
            const result = await get(url, null, false, true, true, {
                credentials: 'include',
            });
            seasonSettings = result?.data['season_info'];
        } catch (e) {
            logError('dockyard is unavailable', e);
        }
    }

    return seasonSettings as dockyardSeason;
};
