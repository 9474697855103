import * as React from 'react';
import styles from './Footer.scss';
import classNames from 'classnames';
import { isAvailableRebrandingForCIS, settings } from '~/utils/settings';
import { t } from '~/utils/localization';
import { LanguageFooter } from '~/components/Footer/LanguageFooter';
import { shallowEqual, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { AppState } from '~/Reducers/ReducerApp';
import Pegi from '~/components/Footer/Pegi';
import { getCopyrightText } from '~/components/Footer/Footer';

interface IMobileFooter {
    isVisible: boolean;
    realmsConfig: IRealmConfig[];
    currentRealm: IRealmConfig;
    changeVisible: () => void;
    currentYear: number;
}

const MobileFooter = React.forwardRef((props: IMobileFooter, ref: React.RefObject<HTMLDivElement>) => {
    const wrapper: React.RefObject<HTMLDivElement> = React.useRef(null);
    const appState = useSelector((state: State): AppState => state.ReducerApp, shallowEqual);
    const mobileWrapperClassNames = classNames(styles.mobileWrapper, {
        [styles.active]: props.isVisible,
    });

    const defaultPaddingTop = 74;
    const [paddingBottom, setPaddingBottom] = React.useState(defaultPaddingTop);

    React.useEffect(() => {
        const mobilePurchaseWrapper = document.getElementById('MobilePurchaseWrapper');
        if (mobilePurchaseWrapper) {
            setPaddingBottom(defaultPaddingTop + mobilePurchaseWrapper.offsetHeight);
        } else {
            setPaddingBottom(defaultPaddingTop);
        }
    }, [appState.currentPage]);

    const style = { paddingBottom: `${paddingBottom}px` };

    return (
        <React.Fragment>
            <div className={mobileWrapperClassNames} ref={wrapper} style={style}>
                <div className={styles.mobileLegalWrapper} ref={ref}>
                    {props.isVisible && <LanguageFooter onClose={props.changeVisible} />}
                    {!!props.currentRealm && settings.languagePickerRealms?.includes?.(props.currentRealm?.realm) && (
                        <div className={styles.mobileLanguageButton} onClick={() => props.changeVisible()}>
                            {props.currentRealm.title} ({settings.allLanguages[settings.languageCode]})
                        </div>
                    )}
                    <div className={styles.mobileLegalLinks}>
                        <a href={settings.urls.supportUrl} target={'_blank'} className={styles.link}>
                            <span>{t('Центр поддержки')}</span>
                        </a>
                        <a href={settings.urls.lawDocumentsUrl} target={'_blank'} className={styles.link}>
                            <span>{t('Юридические документы')}</span>
                        </a>
                        {!!settings.urls.pageAboutGameMechanicsUrl && (
                            <a href={settings.urls.pageAboutGameMechanicsUrl} target={'_blank'} className={styles.link}>
                                <span>{t('Правила компенсации')}</span>
                            </a>
                        )}
                    </div>
                    {/* separate styles for onetrust link, must be last one*/}
                    <span id="ot-sdk-btn" className={classNames('ot-sdk-show-settings', styles.onetrustLink)}></span>
                    <div className={styles.mobileCopyrightWrapper}>{getCopyrightText()}</div>
                    <Pegi isMobile />
                    {isAvailableRebrandingForCIS() && <div className={styles.legal12plus}></div>}
                </div>
            </div>
        </React.Fragment>
    );
});

export default MobileFooter;
