import store from '~/Store';
import { isMobileOrTabletWindow } from '~/utils/utils';
import { CATEGORIES } from '~/const';

export const getGlobalBackground = () => {
    return window.metashop.state.content.global?.background;
};

export const getCategoryBackground = (categoryName: string = CATEGORIES.FEATURED) => {
    const { categories } = store.getState().ReducerApp;
    let category = categories[categoryName];
    if (category?.parentCategory && !category?.background) {
        category = categories[category.parentCategory];
    }

    if (category.name === 'dockyard') {
        const background = store.getState().ReducerDockyard.categoryBck;
        return background || getGlobalBackground();
    }

    return category?.background || getGlobalBackground();
};

export const getBundleInnerBackground = (bundle: IBundle, categoryName?: string) => {
    let bundleInnerBackground = bundle?.icons?.innerBackground;
    if (isMobileOrTabletWindow && bundle?.icons?.mobileInnerBackground) {
        bundleInnerBackground = bundle.icons.mobileInnerBackground;
    } else if (isMobileOrTabletWindow && bundle.serialPurchase) {
        bundleInnerBackground = null;
    }
    return bundleInnerBackground || getCategoryBackground(categoryName || bundle?.categories[0]);
};

export const getConfirmPurchaseBackground = (bundle: IBundle, parentBundle: IBundle = null) => {
    const getBundleBackground = (bundle: IBundle) => bundle?.icons?.confirmationBackground || bundle?.icons?.innerBackground;

    let back = bundle && getBundleBackground(bundle);
    if (!back && parentBundle) {
        back = getBundleBackground(parentBundle);
    }

    return back || getCategoryBackground(bundle?.categories[0]);
};
