export enum CategoryType {
    PREMIUM = 'premium',
    WORSHIP = 'worship',
    FEATURED = 'featured',
    AUCTION = 'auction',
    SEA_BATTLE = 'seabattle',
    TRADEIN = 'tradein',
    MRPS = 'mrps',
}

export enum PRESETS {
    ALL = 'all',
    RECOMMENDED = 'recommended',
}

export enum SortNames {
    name = 'name',
    price = 'price',
    default = 'default',
}

export enum ISortMethods {
    asc = 'asc',
    desc = 'desc',
}

export enum FilterPosition {
    TOP = 'top',
    RIGHT = 'right',
}

export enum CategoryLabelAboutTypes {
    IFRAME = 'iframe',
    POPUP = 'popup',
    LINK = 'link',
}

declare global {
    type FILTER_INFO_NAME =
        | 'class'
        | 'tier'
        | 'nation'
        | 'currency'
        | 'ship_type'
        | 'crew_category'
        | 'crew_type'
        | 'doll_type'
        | 'discount_type'
        | 'camouflage_type'
        | 'signal_type'
        | 'boost_type'
        | 'allowed_bundles';

    type CATEGORY_PARAGONS = 'paragons';
    type CATEGORY_PREM_SHIPS = 'premium';
    type CATEGORY_PREM_ACCOUNT = 'premium_account';
    type CATEGORY_MENU = 'menu';
    type CATEGORY_COUPONS = 'coupons';
    type CATEGORY_WALLET = 'wallet';
    type CATEGORY_PURCHASE_POPUP = 'purchase-popup';
    type CATEGORY_SHIPS = 'ships';
    type CATEGORY_DOCKYARD = 'dockyard';
    type CATEGORY_AUCTION = 'auction';
    type CATEGORY_FEATURED = 'featured';
    type CATEGORY_EXTERIOR = 'exterior';
    type CATEGORY_EVENTS = 'event';
    type CATEGORY_EVENTS_3 = 'event_3';
    type NY_CONTAINERS = 'new_year_containers';
    type NY_CERTIFICATES = 'new_year_certificates';
    type NY_CERTIFICATES_ONLY = 'new_year_certificates_only';

    type ICategoryList =
        | CATEGORY_EVENTS
        | CATEGORY_EVENTS_3
        | CATEGORY_FEATURED
        | CATEGORY_PREM_ACCOUNT
        | CATEGORY_PREM_SHIPS
        | CATEGORY_SHIPS
        | CATEGORY_MENU
        | CATEGORY_COUPONS
        | CATEGORY_WALLET
        | CATEGORY_PURCHASE_POPUP
        | CATEGORY_DOCKYARD
        | CATEGORY_PARAGONS
        | CATEGORY_AUCTION
        | CATEGORY_EXTERIOR
        | NY_CONTAINERS
        | NY_CERTIFICATES
        | string;

    type ICategories = Partial<Record<ICategoryList, ICategory>>;

    type ICategoriesAnimationStatuses = Partial<Record<ICategoryList, boolean>>;

    interface IPreviewCategorySettings {
        bundlesList: PreviewCategoryBundlesList[];
    }

    type PreviewCategoryBundlesList = {
        id: string;
        image: Nullable<string>;
        width: Nullable<string>;
    };

    type ICategoryPresetRules = Partial<Record<FILTER_INFO_NAME, string[] | number[]>>;

    type IBundleCategory = Record<string, number[]>;

    interface ICategoryPresets {
        name: string;
        title: string;
        description: string;
        bundlesPreviewLimit: number;
        type: PRESETS;
        bundles: number[];
        rules?: ICategoryPresetRules;
        excludeBundles?: number[];
    }

    interface ICategoryFilters {
        settings: FILTER_INFO_NAME[];
        presets: ICategoryPresets[];
    }

    interface ICategoryTextSttings {
        titleColor?: string;
        titleFont?: string;
    }

    interface CategoryLabel {
        title: Nullable<string>;
        type: CategoryLabelAboutTypes;
        value: string;
        tooltipText: Nullable<string>;
        labelNewActivityPeriod: IActiveTime;
    }

    interface CategoryPromoTimer {
        title: Nullable<string>;
        description: Nullable<string>;
        timerTitle: string;
        timerActiveTill: string;
        timerTooltipDescription: Nullable<string>;
    }

    interface CategoryActivityCountdown {
        activeFrom?: string;
        customization: {
            title?: string;
            description?: string;
            color?: string;
        };
    }

    interface ICategory extends IDisplayRestrictedOptions {
        type: CategoryType;
        name: string;
        title: string;
        tabTitle?: string;
        titleImage?: string;
        description: string;
        icon: string;
        coloredIcon?: boolean;
        parentIconClassName: string;
        background: string;
        backgroundMask?: string;
        backgroundColor: string;
        tabBorderColor?: string;
        audioTrack: IAudioTrack;
        videoBackground: IVideo;
        entryVideo: IVideo;
        denyReorder: boolean;
        activeTill: string;
        activityCountdown?: ActivityCountdown;
        bundles: number[];
        subCategories: string[];
        theme: string;
        labelNewActivityPeriod: IActiveTime;
        filters: ICategoryFilters;
        sortSettings: SortNames[];
        parentCategory: string;
        parentCategoryData: Partial<ICategory>;
        previewCategorySettings: IPreviewCategorySettings;
        labelNewAllowed?: boolean;
        label: Nullable<CategoryLabel>;
        isContainBundlesForReal: Nullable<boolean>;
        promoTimer: Nullable<CategoryPromoTimer>;
        disableAllAnimation: boolean;
        textSettings?: ICategoryTextSttings;
        value?: string;
        hideTitle?: boolean;
        disableFeatured?: boolean;
    }

    type ISortCategories = Partial<
        Record<
            ICategoryList,
            {
                name: SortNames;
                method: ISortMethods;
            }
        >
    >;

    interface IRange {
        max: number;
        min: number;
    }

    type Diapason_Type = {
        min: number;
        max: number;
    };

    type IFilterDiapasonCategoryCurrencies = Record<string, Diapason_Type>;

    type IFiltersDiapasonCurrencies = Partial<Record<ICategoryList, IFilterDiapasonCategoryCurrencies>>;

    type Filters_Type = Partial<Record<FILTER_INFO_NAME, string[]>>;

    type IFilters = Partial<Record<ICategoryList, Filters_Type>>;

    type IFacetCurrenciesState = Partial<
        Record<
            ICategoryList,
            Record<
                string,
                {
                    min: number;
                    max: number;
                }
            >
        >
    >;
}

export default global;
