import * as React from 'react';
import styles from './purchase_popup.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import useClickAway from '~/hooks/useClickAway';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import { AppState } from '~/Reducers/ReducerApp';
import { t } from '~/utils/localization';
import classNames from 'classnames';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import { OrangeButton } from '~/components/Button/Buttons';
import { Popup, PopupBody, PopupFooter, PopupHeader } from '~/components/Popup/Popup';
import { PURCHASE_DUPLICATE_ITEM_ERROR_CODES } from '~/const';
import { getAlreadyHaveText } from '~/utils/bundles';
import { playButtonClickSound } from '~/api/WoWsClient';
import { PopupManagerContext } from '~/Contexts/PopupManagerContext';

const PurchaseErrorPopup = (): any => {
    const dispatch = useDispatch();
    const app = useSelector((state: State): AppState => state.ReducerApp, shallowEqual);
    const { popup, isPopupActive } = React.useContext(PopupManagerContext);
    const popupData = popup.data;
    const bundle = app.bundles[popupData.bundleId];
    const ref = React.useRef();

    const close = () => {
        dispatch(changeVisiblePopup(null));
    };

    useClickAway(
        ref,
        () => {
            if (!isPopupActive) {
                return;
            }
            close();
        },
        [isPopupActive],
    );

    useKeyDown(() => {
        if (!isPopupActive) {
            return;
        }
        playButtonClickSound();
        close();
    }, [KEYS_CODE.ESC]);

    const image = {
        backgroundImage: `url(${bundle.isRandom ? bundle.icons.big : bundle?.icons?.small})`,
    };

    const classesErrorBackground = classNames(styles.purchaseErrorImageWrapper);
    const classesPopupImage = classNames(styles.popupImage, styles.grayscale);

    let errorMessage = t('Произошла ошибка. Повторите попытку позже');
    if (PURCHASE_DUPLICATE_ITEM_ERROR_CODES.includes(popupData?.errorCode)) {
        errorMessage = getAlreadyHaveText(bundle);
    }

    return (
        <Popup onClose={close} className={'armory__auto--error-purchase-popup'}>
            <PopupHeader title={errorMessage} />
            <PopupBody>
                <div className={styles.purchaseSuccessContent}>
                    <div className={classesErrorBackground}>
                        <div className={classesPopupImage} style={image} />
                    </div>
                    <div className={styles.purchaseSuccessContentData}>
                        <div className={styles.purchaseSuccessContentTitle} dangerouslySetInnerHTML={{ __html: bundle?.title }} />
                    </div>
                </div>
            </PopupBody>
            <PopupFooter>
                <OrangeButton onClick={close} label={t('Закрыть')} className={classNames('armory__auto--error_purchase_button')} />
            </PopupFooter>
        </Popup>
    );
};

export default PurchaseErrorPopup;
