import * as React from 'react';
import equal from 'fast-deep-equal/react';
import ProgressiveDiscount from '~/components/ProgressiveDiscount/ProgressiveDiscount';
import styles from './PurchaseWrapper.scss';
import PriceWrapper, { PRICE_WRAPPER_SIZE } from '~/components/PriceWrapper/PriceWrapper';
import { useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { t } from '~/utils/localization';
import classNames from 'classnames';
import Account from '~/account/Account';
import { isNotEnabledCoupon } from '~/utils/coupons';
import { getProgressiveDiscountFromBundle, isFreeBundle, isMultiplePurchaseAvailable } from '~/utils/bundles';
import CouponWrapper from '~/components/CouponWrapper/CouponWrapper';
import QuantityContainer from '~/containers/QuantityContainer/QuantityContainer';

interface IPurchaseWrapper {
    bundle: IBundle;
    parentRandomBundle?: IBundle;
    useInitialPrice?: boolean;
    calculateAllPrice?: boolean;
    showExchangePrices?: boolean;
    withoutCoupon?: boolean;
    withoutAnimation?: boolean;
    withoutTimer?: boolean;
    withoutQuantityWidget?: boolean;
    availableAmountForFullPurchase?: number;
    hideProgressiveDiscount?: boolean;
}

interface IAccountState {
    id: number;
    activeCouponId: number;
    purchasedLimitedBundles: AccountPurchasedLimitedBundles;
    deniedBundlesByUniqueItems?: number[];
    coupons: ICoupon[];
    bundlesInTransaction: number[];
}

const stateSelector = (state: State): IAccountState => {
    return {
        id: state.ReducerAccount?.id,
        coupons: state.ReducerAccount?.coupons,
        activeCouponId: state.ReducerAccount?.activeCouponId,
        purchasedLimitedBundles: state.ReducerAccount?.purchasedLimitedBundles,
        deniedBundlesByUniqueItems: state.ReducerAccount?.deniedBundlesByUniqueItems,
        bundlesInTransaction: state.ReducerAccount?.bundlesInTransaction,
    };
};

const PurchaseWrapper = (props: IPurchaseWrapper): any => {
    const account = useSelector<State, IAccountState>(stateSelector, equal);
    const isEnabledCoupon = Account.isEnabledCouponsFromBundle(account.coupons, props.bundle);
    const isActiveCoupon = !!account.activeCouponId;
    const activeCoupon = Account.getActiveCoupon(account.activeCouponId, account.coupons);
    const isDisabledPurchase = Account.isDisabledForPurchase(account.purchasedLimitedBundles, account.deniedBundlesByUniqueItems, props.bundle);

    if (isDisabledPurchase) {
        return null;
    }

    const hasProgressiveDiscount = !!props.bundle?.progressivePurchase?.discountDiapasons?.length;
    const progressiveDiscount = getProgressiveDiscountFromBundle(props.bundle, props.parentRandomBundle?.quantityData?.quantity || 1);

    const couponClassNames = classNames(styles.couponWrapper);
    const quantityClassNames = classNames(styles.block, styles.quantityWidget, {
        [styles.isDisabled]: account.bundlesInTransaction?.includes(props.bundle.id),
    });
    const currencyClassNames = classNames(styles.currencyWrapper, { [styles.withProgressiveDiscount]: hasProgressiveDiscount });

    const isVisibleCouponWrapper = account.id && isEnabledCoupon && !props.withoutCoupon;
    const isNeedToRenderPrice = !isFreeBundle(props.bundle);

    return (
        <React.Fragment>
            {isVisibleCouponWrapper && (
                <React.Fragment>
                    <div className={styles.purchasePopupPriceTitle}>{t('Примените купон для получения скидки:')}</div>
                    <div className={couponClassNames}>
                        <CouponWrapper bundle={props.bundle} isDisableMultiplePurchaseWithCoupon={isNotEnabledCoupon(props.bundle)} />
                    </div>
                </React.Fragment>
            )}
            {isNeedToRenderPrice && (
                <React.Fragment>
                    <div className={styles.purchasePopupPriceTitle}>{t('Цена:')}</div>
                    <div className={currencyClassNames}>
                        <PriceWrapper
                            bundle={props.bundle}
                            progressiveDiscount={progressiveDiscount}
                            coupon={activeCoupon}
                            size={PRICE_WRAPPER_SIZE.BIG}
                            showExchangePrices={props.showExchangePrices}
                            calculateAllPrice={props.calculateAllPrice}
                            withoutAnimation={props.withoutAnimation}
                            withoutTimer={props.withoutTimer}
                            priceClassName={styles.progressiveDiscountPrice}
                        />
                    </div>
                    {!props.hideProgressiveDiscount && hasProgressiveDiscount && (
                        <div className={styles.progressiveDiscount}>
                            <ProgressiveDiscount
                                data={props.bundle.progressivePurchase?.discountDiapasons}
                                amount={props.availableAmountForFullPurchase}
                                parentBundleName={props.parentRandomBundle?.title}
                                childBundleName={props.bundle.title}
                                singleLimit={props.parentRandomBundle?.singlePurchaseMaxQuantity}
                            />
                        </div>
                    )}
                </React.Fragment>
            )}
            {!!account?.id && !props.withoutQuantityWidget && isMultiplePurchaseAvailable(props.bundle) && (
                <div className={quantityClassNames}>
                    <QuantityContainer bundle={props.bundle} />
                </div>
            )}
        </React.Fragment>
    );
};

export default PurchaseWrapper;
