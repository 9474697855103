import * as React from 'react';
import styles from './Wallet.scss';
import messageStyles from '~/components/Message/Message.scss';
import classNames from 'classnames';
import { assets as ASSETS } from '@wg/wows-entities/const/';
import { AccountState } from '~/Reducers/ReducerAccount';
import { State } from '~/Reducers';
import { shallowEqual, useSelector } from 'react-redux';
import { arrayToObjectByKey } from '~/utils/utils';
import { AppState } from '~/Reducers/ReducerApp';
import useClickAway from '~/hooks/useClickAway';
import { t } from '~/utils/localization';
import { getDefaultExchangeCurrency, getPurchaseExchangeRates } from '~/utils/bundles';
import Currency from '~/components/Currency/Currency';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { playButtonWalletClickSound, playDropdownClickSound } from '~/api/WoWsClient';
import { getCurrency } from '~/utils/currencies';
import CurrencyTooltip from '~/components/Tooltip/CurrencyTooltip';
import dwhExport from '~/api/dwhExport';
import { CURRENCY, DWH_EVENTS } from '~/const';
import equal from 'fast-deep-equal/react';
import GuideDecorator from '~/decorators/GuideDecorator/GuideDecorator';
import { GUIDE_NAMES } from '~/components/WelcomePage/steps';
import { MrpsState } from '~/Reducers/ReducerMrps';

interface IWalletPopup {
    callback: () => void;
}

const dividingArrayIntoColumns = (array: JSX.Element[], count: number, Wrapper?: React.FC<{ children: any[] }>) => {
    const columns = [];
    for (let i = 0; i <= array.length; i += count) {
        const _arr = array.slice(i, i + count);
        columns.push(Wrapper ? <Wrapper key={`currency_item_${i}`}>{_arr}</Wrapper> : _arr);
    }

    return columns;
};

interface IWalletCurrency {
    currency: ICurrencies;
    value: number;
}

const WalletCurrency = ({ currency, value }: IWalletCurrency) => {
    return (
        <DivTooltip style={{ cursor: 'initial' }} className={styles.walletCurrencyItem} tooltipBody={<CurrencyTooltip currency={currency.name} isVisibleTitle />}>
            <div className={styles.walletCurrencyItemName}>
                {getCurrency(currency.name)
                    .title?.split(' ')
                    .map((item, index) => {
                        return (
                            <span className={styles.walletCurrencyItemNamePart} key={`currency_${currency.name}_part${index}`}>
                                {item}
                            </span>
                        );
                    })}
            </div>
            <div className={styles.walletCurrencyItemDelimiter}>&nbsp;</div>
            <div className={styles.walletCurrency} key={currency.name}>
                <Currency currency={currency.name} amount={value} isProgress={value === null} isSmallIcon withoutAnimation withoutTooltip />
            </div>
        </DivTooltip>
    );
};

const CurrencyWrapper = ({ children }: { children: React.ReactChild[] }) => {
    return <div className={styles.walletPopupBodyContentItem}>{children}</div>;
};

interface IStateSelector {
    balance: IBalance;
    currencies: ICurrencies[];
}

const stateSelector = (state: State): IStateSelector => {
    return {
        balance: state.ReducerAccount.balance,
        currencies: state.ReducerApp.currencies,
    };
};

export const WalletPopup = function (props: IWalletPopup) {
    const ref = React.useRef();
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const balance = state.balance;
    const balanceObject = arrayToObjectByKey(balance, 'currency');

    const purchaseExchangeRates = getPurchaseExchangeRates();
    const defaultExchangeCurrency = getDefaultExchangeCurrency();
    const exchangeToCurrency = purchaseExchangeRates[`${defaultExchangeCurrency}_coal`];

    const commonCurrencies = state.currencies?.filter((currency: ICurrencies) => !currency.isEvent) || [];
    const eventCurrencies = state.currencies?.filter((currency: ICurrencies) => currency.isEvent) || [];

    useClickAway(ref, (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        if (target.classList.contains(styles.walletIcon)) {
            return;
        }

        props.callback && props.callback();
    });

    const arrayCommonCurrencies = commonCurrencies.map((currency: ICurrencies, index) => {
        if (!balanceObject[currency.name]) {
            return null;
        }

        const { value } = balanceObject[currency.name];

        return <WalletCurrency currency={currency} value={value} key={`common_${currency.name}_${index}`} />;
    });

    const arrayEventCurrencies = eventCurrencies.map((currency: ICurrencies, index) => {
        if (!balanceObject[currency.name]) {
            return null;
        }

        const { value } = balanceObject[currency.name];

        return <WalletCurrency currency={currency} value={value} key={`event_${currency.name}_${index}`} />;
    });

    const isNeedToDividingDefaultCurrencies = arrayCommonCurrencies?.length >= 10;
    const isNeedToDividingEventCurrencies = eventCurrencies?.length >= 3;

    const countDefaultCurrenciesColumn = isNeedToDividingDefaultCurrencies ? Math.round(commonCurrencies?.length / 2) : commonCurrencies?.length;
    const countEventCurrenciesColumn = isNeedToDividingEventCurrencies ? Math.round(eventCurrencies?.length / 2) : eventCurrencies?.length;

    const contentCommonCurrencies = countDefaultCurrenciesColumn ? dividingArrayIntoColumns(arrayCommonCurrencies, countDefaultCurrenciesColumn, CurrencyWrapper) : [];
    const contentEventCurrencies = !!eventCurrencies.length ? dividingArrayIntoColumns(arrayEventCurrencies, countEventCurrenciesColumn, CurrencyWrapper) : [];

    const classesDefaultWalletBodyContent = classNames(styles.walletPopupBodyContent, {
        [styles.row]: isNeedToDividingDefaultCurrencies,
    });

    const classesEventWalletBodyContent = classNames(styles.walletPopupBodyContent, {
        [styles.row]: isNeedToDividingEventCurrencies,
    });

    const classesPopup = classNames(styles.walletPopup, {
        [styles.big]: isNeedToDividingDefaultCurrencies || isNeedToDividingEventCurrencies,
    });

    return (
        <div className={classesPopup} ref={ref}>
            <div className={styles.walletPopupBody}>
                <div className={styles.walletPopupBodyTitle}>{t('Все валюты:')}</div>
                <div className={classesDefaultWalletBodyContent}>{contentCommonCurrencies}</div>
            </div>
            {!!eventCurrencies.length && (
                <div className={styles.walletPopupBody}>
                    <div className={classNames(styles.walletPopupBodyTitle, styles.walletPopupTitleTimer)}>{t('Временные валюты:')}</div>
                    <div className={classesEventWalletBodyContent}>{contentEventCurrencies}</div>
                </div>
            )}
            <div className={styles.walletPopupFooter}>
                <div className={classNames(styles.walletTitle, styles.tip)}>
                    {t('Если у вас недостаточно угля для обмена на внутриигровые предметы, вы сможете восполнить недостающее количество этого ресурса, используя сталь.')}
                </div>
                <DivTooltip
                    className={classNames(messageStyles.tip, styles.tipWrapper)}
                    tooltipBody={
                        <DefaultTooltip text={t('Если у вас недостаточно угля для обмена на внутриигровые предметы, вы сможете восполнить недостающее количество этого ресурса, используя сталь.')} />
                    }
                    position={'center-left'}
                >
                    <span className={messageStyles.greenText}>{t('Курс стали к углю')}</span>
                    <span className={styles.exchange}>
                        <Currency currency={defaultExchangeCurrency} amount={1} withoutAnimation className={styles.exchangeCurrency} />
                        <span>=</span>
                        <Currency currency={'coal'} amount={exchangeToCurrency} withoutAnimation className={styles.exchangeCurrency} />
                    </span>
                </DivTooltip>
            </div>
        </div>
    );
};

interface IWallet {
    isMobile?: boolean;
    isOnlyIcon?: boolean;
    withoutAnimation?: boolean;
}

const Wallet = function (props: IWallet) {
    const [isVisibleWallet, setVisibleWallet] = React.useState(false);
    const accountState = useSelector((state: State): AccountState => state.ReducerAccount, shallowEqual);
    const appState = useSelector<State, AppState>((state: State): AppState => state.ReducerApp, shallowEqual);
    const mrpsState = useSelector<State, MrpsState>((state: State): MrpsState => state.reducerMrps, shallowEqual);

    const balance = accountState.balance;
    const keys: string[] = [...(appState.categoryCurrencies || [])];
    const balanceObject = arrayToObjectByKey(balance, 'currency');

    let defaultCurrencies = appState.currencies?.filter((currency: ICurrencies) => currency.isDefault).map((currency: ICurrencies) => currency.name);

    const mrpsEvent = mrpsState?.events.find((event) => event.name === appState.currentPage.name);
    if (mrpsEvent) {
        defaultCurrencies = mrpsEvent.currencies.reduce((result, currency) => {
            if (currency.name === CURRENCY.WARSHIPS_PREMIUM) {
                return result;
            }

            return [...result, currency.name];
        }, []);
    }

    const optionalCurrencies = keys.filter((key) => !defaultCurrencies?.includes(key) && key !== CURRENCY.WARSHIPS_PREMIUM);

    const changeVisible = () => {
        if (!isVisibleWallet) {
            playButtonWalletClickSound();
        } else {
            playDropdownClickSound();
        }

        setVisibleWallet(!isVisibleWallet);

        if (!isVisibleWallet) {
            dwhExport.send(DWH_EVENTS.OPEN_WALLET);
            // придумать что нибудь получше
            const tooltip = document.getElementById('wows-react-tooltip-body');
            // @ts-ignore
            tooltip?.style.display = 'none';
        }
    };

    if (props.isMobile) {
        if (!keys.includes(ASSETS.GOLD)) {
            keys.push(ASSETS.GOLD);
        }

        return (
            <div className={styles.walletMobile}>
                {keys.map((key: string) => {
                    if (!balanceObject[key]) {
                        return null;
                    }

                    const { currency, value } = balanceObject[key];

                    if (!Object.values(ASSETS).includes(currency)) {
                        return null;
                    }

                    return (
                        <div className={styles.walletItem} key={key}>
                            <Currency currency={currency} amount={value} isProgress={value === null} showDescriptionTooltip withoutAnimation={props.withoutAnimation} />
                        </div>
                    );
                })}
            </div>
        );
    }

    if (props.isOnlyIcon) {
        return (
            <div>
                <div className={classNames(styles.walletIcon, styles.walletMobileIcon)} onClick={changeVisible} />
                {isVisibleWallet && <WalletPopup callback={changeVisible} />}
            </div>
        );
    }

    const walletIconClassNames = classNames(styles.walletIcon, {
        [styles.active]: isVisibleWallet,
    });

    return (
        <React.Fragment>
            <div className={styles.walletWrapper}>
                <div className={classNames(styles.walletItems)}>
                    {!!optionalCurrencies.length && (
                        <div className={styles.walletCategory}>
                            {optionalCurrencies.map((balanceKey: any, index) => {
                                if (!balanceObject[balanceKey]) {
                                    return null;
                                }

                                const { currency, value } = balanceObject[balanceKey];

                                if (!Object.values(ASSETS).includes(currency)) {
                                    return null;
                                }

                                return (
                                    <DivTooltip
                                        style={{ cursor: 'initial' }}
                                        className={styles.walletItem}
                                        key={`wallet_${currency.name}_${index}`}
                                        tooltipBody={<CurrencyTooltip currency={balanceKey} isVisibleTitle />}
                                    >
                                        <Currency currency={currency} amount={value} isProgress={value === null} withoutTooltip withoutAnimation />
                                    </DivTooltip>
                                );
                            })}
                        </div>
                    )}
                    <div className={classNames(styles.walletCategory, styles.defaultCurrencies)}>
                        {defaultCurrencies?.reverse().map((key: string) => {
                            if (!balanceObject[key]) {
                                return null;
                            }

                            const { currency, value } = balanceObject[key];

                            return (
                                <GuideDecorator key={`wallet_${currency}_guide`} names={currency === ASSETS.GOLD ? [GUIDE_NAMES.guide_wallet_items] : null}>
                                    <DivTooltip style={{ cursor: 'initial' }} className={styles.walletItem} key={`wallet_${currency}`} tooltipBody={<CurrencyTooltip currency={key} isVisibleTitle />}>
                                        <Currency currency={currency} amount={value} isProgress={value === null} withoutTooltip withoutAnimation />
                                    </DivTooltip>
                                </GuideDecorator>
                            );
                        })}
                    </div>
                </div>
                <div className={classNames(styles.walletCategory, styles.walletIconWrapper)}>
                    <GuideDecorator names={[GUIDE_NAMES.guide_wallet]}>
                        <DivTooltip
                            tooltipBody={
                                isVisibleWallet ? null : <DefaultTooltip title={t('Кошелёк')} text={t('Содержит информацию о всех имеющихся валютах и ресурсах')} footer={t('Подробнее')} mouseIcon />
                            }
                        >
                            <div className={walletIconClassNames} onClick={changeVisible} />
                        </DivTooltip>
                    </GuideDecorator>
                </div>
            </div>
            {isVisibleWallet && <WalletPopup callback={changeVisible} />}
        </React.Fragment>
    );
};

export default Wallet;
