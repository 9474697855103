import * as React from 'react';
import commonStyles from '../../assets/styles/common.scss';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { isDevWoWSClient } from '~/utils/devMode';
import equal from 'fast-deep-equal/react';
import { IPortProps } from '~/components/Port/AbstractPort';
import { closePort as closeClientPort, openPort } from '~/api/WoWsClient';
import { playButtonClickSound } from '~/api/WoWsClient';
import { changeVisiblePort } from '~/Actions/ActionApp';
import History from '~/utils/history';
import { openBundleById, openBundleByUrl, openCategoryByName } from '~/utils/category';
import BundlePort from '~/components/Port/BundlePort/BundlePort';
import { PortPreviewType } from '~/components/Port/settings';
import Account from '~/account/Account';
import PurchaseProcessor from '~/processors/PurchaseProcessor';
import { getChildBundle, getParentRandomBundleByChild, isFreeBundle } from '~/utils/bundles';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import store from '~/Store';
import AuctionLotPort from '~/components/Port/AuctionLotPort/AuctionLotPort';
import { getLotByDisplayId, openLotById } from '~/utils/auction';
import ContainerPort from '~/components/Port/ContainerPort/ContainerPort';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import DefaultPort from '~/components/Port/DefaultPort/DefaultPort';
import { ICurrentPage } from '~/Actions/ActionAppType';
import { updateBrowserControlState } from '@wg/web2clientapi/browser/updateBrowserControlState';

export const changeBackground = (isChange: boolean) => {
    const className = commonStyles.transparent;

    if (isDevWoWSClient()) {
        return;
    }

    if (isChange) {
        document.body.classList.add(className);
    } else {
        document.body.classList.remove(className);
    }
};

interface IPortContainer {
    match: Pick<IPort, 'shipId' | 'exteriorId' | 'id' | 'portPreviewType' | 'itemType'>;
}

interface IStateSelector {
    currentPage: ICurrentPage;
    port: IPort;
    bundles: IBundleList;
    purchasedLimitedBundles: AccountPurchasedLimitedBundles;
    deniedBundlesByUniqueItems: number[];
    selectedRandomBundles?: AccountSelectedRandomBundles;
    auctions: IAuction[];
    inventory: InventoryState;
    coupons: ICoupon[];
    balance: IBalance;
    lootboxes: ILootboxes;
    mrpsEventData?: MrpsEvent;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        currentPage: state.ReducerApp.currentPage,
        port: state.ReducerApp.port,
        bundles: state.ReducerApp.bundles,
        purchasedLimitedBundles: state.ReducerAccount.purchasedLimitedBundles,
        deniedBundlesByUniqueItems: state.ReducerAccount.deniedBundlesByUniqueItems,
        selectedRandomBundles: state.ReducerAccount.selectedRandomBundles,
        auctions: state.ReducerAuction.activeAuctions,
        inventory: state.ReducerAccount.inventory,
        balance: state.ReducerAccount.balance,
        coupons: state.ReducerAccount.coupons,
        lootboxes: state.ReducerLootbox.lootboxes,
        mrpsEventData: state.reducerMrps.events.find((event) => event.name === state.reducerMrps.currentEvent.eventName),
    };
};

const PortContainer = (props: IPortContainer): any => {
    const dispatch = useDispatch();
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const id = props.match?.id || state.port?.id;
    const shipId = state.port?.shipId || props.match?.shipId;
    const exteriorId = state.port?.exteriorId || props.match?.exteriorId;
    const itemType = state.port?.itemType || props.match?.itemType;
    const portPreviewType = state.port?.portPreviewType || props.match?.portPreviewType;
    const lootbox = state.lootboxes?.[state.port.additionalData?.lootboxId];
    const mrpsRewardItems = state.mrpsEventData?.rewards?.find((reward) => reward.isMainReward)?.entitlements;

    let bundle = state.bundles[id];
    let currentBundle = bundle;

    if (+id > 0) {
        if (bundle && bundle.isRandom) {
            bundle = Account.getRandomBundleChild(state.selectedRandomBundles, bundle);
        } else if (!bundle) {
            if (portPreviewType !== PortPreviewType.AUCTION_LOT) {
                const childBundle = getChildBundle(state.bundles, +id);
                const parentBundleId = getParentRandomBundleByChild(state.bundles, childBundle.id);
                currentBundle = state.bundles[parentBundleId];
                bundle = childBundle;
            }
        }
    }

    const onClosePort = (withoutHistoryUpdate = false) => {
        changeBackground(false);
        closeClientPort();
        dispatch(changeVisiblePort(false, shipId, exteriorId));

        if (!withoutHistoryUpdate) {
            if (!History.hasHistory()) {
                return History.backHome();
            }
            History.back();
        }
    };

    const onPurchaseBundle = () => {
        const _isRandomBundle = bundle.isRandom;
        let parentBundleId: number = null;
        if (_isRandomBundle) {
            parentBundleId = getParentRandomBundleByChild(state.bundles, bundle.id);
        }

        const callback = () => {
            onClosePort(true);

            if (!bundle.serialPurchase || !bundle.nextBundle) {
                if (_isRandomBundle && parentBundleId) {
                    openBundleById(parentBundleId, true);
                } else {
                    openCategoryByName(state.currentPage?.name, null, true);
                }
            } else {
                const nextBundle = state.bundles[bundle.nextBundle];
                if (!nextBundle) {
                    openCategoryByName(state.currentPage?.name, null, true);
                } else {
                    openBundleByUrl(state.currentPage?.name, nextBundle.id, true);
                }
            }
        };

        const purchaseProcessor = new PurchaseProcessor(currentBundle);
        purchaseProcessor.purchaseBundleFromPopup(bundle, callback.bind(this), null, isFreeBundle(bundle));
    };

    React.useEffect(() => {
        if (portPreviewType === PortPreviewType.CONTAINER && !lootbox) {
            onClosePort();
            return;
        }

        if (!state.port?.id) {
            openPort(props.match.shipId, props.match.exteriorId, props.match.itemType, props.match.portPreviewType, props.match.id, true);
        }

        updateBrowserControlState(true);

        changeBackground(true);

        let options = null;

        if (portPreviewType === PortPreviewType.BUNDLE || portPreviewType === PortPreviewType.CONTAINER) {
            options = { bundle_id: id };
        } else if (portPreviewType === PortPreviewType.AUCTION_LOT) {
            options = { lot_id: id };
        }

        if (options) {
            dwhExport.send(DWH_EVENTS.OPEN_PORT, options);
        }
    }, []);

    useKeyDown((keyCode, event) => {
        if (KEYS_CODE.ESC === keyCode) {
            const app = store.getState().ReducerApp;
            if (!!app.popupActive) {
                return;
            }
            playButtonClickSound();
            onClosePort();
        }
    }, []);

    React.useEffect(() => {
        if (!state.port?.id) {
            return;
        }

        const startTime = Date.now();

        return () => {
            const endTime = Date.now();
            const duration = Math.floor((endTime - startTime) / 1000);
            dwhExport.send(DWH_EVENTS.CLOSE_PORT, { duration });
        };
    }, []);

    const defaultProps: IPortProps = {
        onClose: onClosePort,
        shipId,
        portPreviewType,
        exteriorId,
        itemType,
        bundle: bundle,
    };

    switch (portPreviewType) {
        case PortPreviewType.BUNDLE: {
            let isDisabledPurchase = Account.isDisabledForPurchase(state.purchasedLimitedBundles, state.deniedBundlesByUniqueItems, bundle);

            if (!isDisabledPurchase && bundle.isRandom) {
                const parentBundleId = getParentRandomBundleByChild(state.bundles, bundle.id);
                isDisabledPurchase = state.selectedRandomBundles[parentBundleId] !== bundle.id;
            }

            if (isDisabledPurchase) {
                return <DefaultPort bundle={bundle} inventory={state.inventory} {...defaultProps} />;
            }

            return (
                <BundlePort
                    bundle={bundle}
                    isDisabledPurchase={isDisabledPurchase}
                    onPurchaseBundle={onPurchaseBundle}
                    inventory={state.inventory}
                    balance={state.balance}
                    coupons={state.coupons}
                    {...defaultProps}
                />
            );
        }

        case PortPreviewType.AUCTION_LOT: {
            const lot = getLotByDisplayId(id.toString(), state.auctions);

            return <AuctionLotPort lot={lot} inventory={state.inventory} {...defaultProps} />;
        }

        case PortPreviewType.CONTAINER: {
            return <ContainerPort {...defaultProps} lootbox={lootbox} />;
        }

        case PortPreviewType.MRPS: {
            return <DefaultPort itemsList={mrpsRewardItems as unknown as Array<IBundleEntity>} inventory={state.inventory} {...defaultProps} />;
        }

        case PortPreviewType.DEFAULT:
        default:
            return <DefaultPort bundle={bundle} inventory={state.inventory} {...defaultProps} />;
    }
};

export default PortContainer;
