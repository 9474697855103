import * as React from 'react';
import Carousel from '~/components/Carousel/Carousel';
import SerialBundleCarouselItem from '~/components/Bundle/Serial/Item/SerialBundleCarouselItem';
import styles from './SerialBundle.scss';
import { ICurrentPage } from '~/Actions/ActionAppType';
import { State } from '~/Reducers';
import equal from 'fast-deep-equal/react';
import { useSelector } from 'react-redux';
import type { Swiper as SwiperInstance } from 'swiper/types';
import ProgressBar from '~/components/ProgressBar/ProgressBar';
import { ErrorBoundary } from '@sentry/react';
import { getAvailabelSerialBundleForPurchase, getValuableBundleIndexes, isAllSequencePurchased } from '~/utils/bundles';
import classNames from 'classnames';
import { isMobileOrTabletWindow } from '~/utils/utils';
import { ItemsContainsTooltip } from '~/components/Tooltip/Tooltips';
import PurchasedTooltip from '~/components/Tooltip/PurchasedTooltip';
import { playTabClickSound } from '~/api/WoWsClient';

interface ISerialBundleCarousel {
    bundle: IBundle;
}

interface IStateSelector {
    bundles: IBundleList;
    purchasedLimitedBundles: AccountPurchasedLimitedBundles;
    deniedBundlesByUniqueItems: number[];
    currentPage: ICurrentPage;
    coupons: ICoupon[];
    balance: IBalance;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        bundles: state.ReducerApp.bundles,
        purchasedLimitedBundles: state.ReducerAccount.purchasedLimitedBundles,
        deniedBundlesByUniqueItems: state.ReducerAccount.deniedBundlesByUniqueItems,
        currentPage: state.ReducerApp.currentPage,
        coupons: state.ReducerAccount.coupons,
        balance: state.ReducerAccount.balance,
    };
};

export const SEPARATE_LIMIT = 40;

const SerialBundleCarousel = ({ bundle }: ISerialBundleCarousel) => {
    const [swiper, setSwiperRef] = React.useState<SwiperInstance>(null);
    const { bundles, purchasedLimitedBundles, balance, coupons } = useSelector<State, IStateSelector>(stateSelector, equal);
    const sequence = [bundle].concat(bundle.serialSequence.map((bundleId) => bundles[bundleId]));
    const valuableIndexes = getValuableBundleIndexes(sequence);
    const idsSequence = [bundle.id, ...bundle.serialSequence];

    const swiperRef = React.useRef<SwiperInstance>(null);
    const refValuableBundle = React.useRef<HTMLDivElement>(null);
    const refItems = React.useRef<Map<number, HTMLDivElement>>(new Map());

    const enabledPack = getAvailabelSerialBundleForPurchase(sequence, purchasedLimitedBundles);

    const [selectedBundleIndex, setBundleIndex] = React.useState(enabledPack?.serialIndex || bundle.serialIndex);
    const [progressBarIndex, setProgressBarIndex] = React.useState(enabledPack?.serialIndex || bundle.serialIndex);

    React.useEffect(() => {
        setBundleIndex(enabledPack?.serialIndex);
    }, [enabledPack?.serialIndex]);

    const shakeAnimation = (isValuableBundle = false, index?: number) => {
        const animate = (target: HTMLElement) => {
            if (!target || target?.classList.contains(styles.animate)) {
                return;
            }
            target.classList.add(styles.animate);
            setTimeout(() => {
                if (target) {
                    target.classList.remove(styles.animate);
                }
            }, 1000);
        };
        animate(isValuableBundle ? refValuableBundle.current : refItems.current.get(index));
    };

    const goToNextAvailableBundle = (index: number) => {
        if (lastValuableBundle && lastValuableBundle.id === index) {
            shakeAnimation(true);
            return;
        }

        if (enabledPack.serialIndex === swiper?.activeIndex) {
            shakeAnimation(false, enabledPack.serialIndex);
        }
        swiper?.slideTo(enabledPack?.serialIndex);
        setBundleIndex(enabledPack?.serialIndex);
    };

    const items = sequence.map((bundle, index) => (
        <div
            className={styles.animationWrapper}
            ref={(_ref) => {
                if (_ref) {
                    refItems.current.set(index, _ref);
                }
            }}
        >
            <SerialBundleCarouselItem key={`serial_bundle_${bundle.id}`} bundle={bundle} serialSequence={idsSequence} goToNextAvailableBundle={goToNextAvailableBundle} />
        </div>
    ));

    const tooltips = sequence.map((_bundle) => {
        return _bundle.isPurchased ? <PurchasedTooltip bundle={_bundle} /> : <ItemsContainsTooltip bundle={_bundle} limit={3} />;
    });

    const isSpecificSerialBundle = items?.length <= 2;
    const lastBundle = sequence.at(valuableIndexes.at(-1) - 1);
    const lastValuableBundle = !isMobileOrTabletWindow && !isSpecificSerialBundle && valuableIndexes?.length && !lastBundle?.nextBundle && lastBundle;

    if (lastValuableBundle) {
        items.pop();
    }

    const classesCarouseWrapper = classNames(styles.carouselWrapper, {
        [styles.withValuable]: !!lastValuableBundle,
    });

    const classesValuableItem = classNames(styles.item, 'armory__auto--serial-carousel-item');

    const onClickProgressBarHandler = (index: number) => {
        if (isSpecificSerialBundle) {
            shakeAnimation(false, index);
            setProgressBarIndex(index);
            return;
        }

        if (lastValuableBundle && lastValuableBundle.serialIndex === index) {
            setProgressBarIndex(index);
            shakeAnimation(true);
            return;
        }

        if (selectedBundleIndex === index) {
            shakeAnimation(false, index);
        } else {
            setBundleIndex(index);
            setProgressBarIndex(index);
        }

        swiper?.slideTo(index);
    };

    const onSliderScrollProgressHandler = (sliderState: number, target: HTMLElement) => {
        if (!target) {
            return;
        }
        if (sliderState > 0 && sliderState < 1) {
            target.style.webkitMaskImage = 'linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 1) 30px, rgba(255, 255, 255, 1) calc(100% - 30px), transparent 100%)';
        } else if (sliderState === 0) {
            target.style.webkitMaskImage = 'linear-gradient(to left, transparent 0%, rgba(255, 255, 255, 1) 30px)';
        } else if (sliderState === 1) {
            target.style.webkitMaskImage = 'linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 1) 30px)';
        }
    };

    let content = (
        <Carousel
            className={{
                item: classNames(
                    styles.item,
                    {
                        [styles.withValuable]: !!lastValuableBundle,
                    },
                    'armory__auto--serial-carousel-item',
                ),
                lazySlideWrapper: styles.lazySlideWrapper,
                navigationButtonNext: classNames(styles.navigationButtonNext),
                navigationButtonPrev: classNames(styles.navigationButtonPrev),
            }}
            centeredSlides={true}
            scrollToId={selectedBundleIndex}
            centeredSlidesBounds={true}
            freeMode={true}
            items={items}
            setSwiperRef={(swiper) => {
                setSwiperRef(swiper);
                swiperRef.current = swiper;
            }}
            withoutLazy={items.length < 15}
            onSliderScrollProgress={onSliderScrollProgressHandler}
            slideChange={() => {
                if (!swiperRef.current || swiperRef?.current?.destroyed) {
                    return;
                }
                setProgressBarIndex(swiperRef.current.snapIndex);
            }}
            onNavigationNext={playTabClickSound}
            onNavigationPrev={playTabClickSound}
        />
    );
    if (isMobileOrTabletWindow) {
        content = (
            <div className={styles.mobileCarousel}>
                {items.map((item, index) => {
                    return (
                        <div key={`specific_serial_bundle_${index}`} className={classNames(styles.item, styles.mobileItem)}>
                            {item}
                        </div>
                    );
                })}
            </div>
        );
    }

    const _isAllSequencePurchased = isAllSequencePurchased(sequence);
    let currentProgress = enabledPack?.serialIndex || 0;
    if (_isAllSequencePurchased) {
        currentProgress = sequence.length;
    }

    return (
        <div className={styles.wrapper} key={`serial_bundle_carousel_${bundle.id}`}>
            <ErrorBoundary>
                <div className={styles.content}>
                    <div className={classesCarouseWrapper}>
                        {isSpecificSerialBundle ? (
                            <div className={styles.specificItemsWrapper}>
                                {items.map((item, index) => {
                                    return (
                                        <div key={`specific_serial_bundle_${index}`} className={classNames(styles.item, styles.isSpecific)}>
                                            {item}
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            content
                        )}
                    </div>
                    {!!lastValuableBundle && (
                        <div
                            className={classesValuableItem}
                            ref={(_ref) => {
                                if (_ref) {
                                    refValuableBundle.current = _ref;
                                }
                            }}
                        >
                            <SerialBundleCarouselItem bundle={lastValuableBundle} serialSequence={idsSequence} goToNextAvailableBundle={goToNextAvailableBundle} />
                        </div>
                    )}
                </div>
                {!isMobileOrTabletWindow && (
                    <div className={styles.progressBarWrapper}>
                        <ProgressBar
                            itemTooltips={tooltips}
                            currentValue={currentProgress}
                            valuableIndexes={valuableIndexes}
                            maxValue={sequence.length}
                            separateLimit={SEPARATE_LIMIT}
                            selectedIndex={progressBarIndex}
                            onClickHandler={(index) => {
                                playTabClickSound();
                                onClickProgressBarHandler(index);
                            }}
                        />
                    </div>
                )}
            </ErrorBoundary>
        </div>
    );
};

export default SerialBundleCarousel;
