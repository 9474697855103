import History from '~/utils/history';
import store from '~/Store';
import { changeVisiblePort, setFetching } from '~/Actions/ActionApp';
import { logInfo } from '~/utils/logging';
import { settings } from '~/utils/settings';
import { getUrl, urlsMap } from '~/utils/menu';
import { isDevWoWSClient } from '~/utils/devMode';
import { PortPreviewType } from '~/components/Port/settings';
import { closePortPreview } from '@wg/web2clientapi/port/closePortPreview';
import { openShipPreviewInPort } from '@wg/web2clientapi/port/openShipPreviewInPort';
import { sendCommand } from '@wg/web2clientapi/core/command';
import { sendAction as web2clientSendAction } from '@wg/web2clientapi/core/action';
import { changeTTXVisibility } from '@wg/web2clientapi/port/changeTTXVisibility';

export {
    playCheckboxSound,
    playDropdownSound,
    playInputSound,
    playButtonClickSound,
    playButtonDialogClickSound,
    playButtonWalletClickSound,
    playButtonGoldClickSound,
    playCheckboxClickSound,
    playDropdownClickSound,
    playTabClickSound,
    playCardClickSound,
    playPaperCardClickSound,
    playExchangeSound,
} from '@wg/web2clientapi/sound';

interface IClientApiData {
    actionId: string;
    enabled?: boolean;
    shipId?: number;
    exteriorId?: number;
    showTTX?: boolean;
    browserHasControl?: boolean;
    soundsetName?: string;
    url?: string;
    visible?: boolean;
}

export const openPort = (
    shipId: number,
    exteriorId = -1,
    itemType: string,
    portPreviewType: PortPreviewType,
    id?: number | string,
    isVisible = false,
    isLoading = true,
    sourceUrl: string = location.pathname,
    queryParams: Record<string, string> = {},
) => {
    let showTTX = !settings.isShipTTXPreviewDisabled;
    if (exteriorId !== -1) {
        showTTX = false;
    }

    store.dispatch(setFetching(true));
    store.dispatch(changeVisiblePort(isVisible, shipId, exteriorId, portPreviewType, id, isLoading, sourceUrl, showTTX, itemType, queryParams));

    openShipPreviewInPort(shipId, exteriorId, showTTX);

    if (isDevWoWSClient()) {
        window.onClientStateChange(JSON.stringify({ actionId: 'startShipPreview' }));
    }
};

export const closePort = () => {
    store.dispatch(changeVisiblePort(false, null, null, null, null, false, null));
    changeTTXVisibility(false);
    closePortPreview();
};

export const request = (data: any) => {
    sendCommand(data);
};

export const sendAction = (params: IClientApiData) => {
    logInfo('attempt to execute a request to the client', JSON.stringify(params));
    web2clientSendAction(params);
};

export const setClientStateListener = () => {
    logInfo('client state listener init');

    window.onClientStateChange = (data: string) => {
        const event = JSON.parse(data);

        switch (event.actionId) {
            case 'startShipPreview':
                const portData = store.getState().ReducerApp.port;
                const portUrl = getUrl(urlsMap.port, {
                    id: portData.id,
                    shipId: portData.shipId,
                    exteriorId: portData.exteriorId,
                    sourceUrl: portData.sourceUrl,
                    portPreviewType: portData.portPreviewType,
                    itemType: portData.itemType,
                });

                History.navigate(portUrl, {
                    replace: portData?.isVisible,
                });

                store.dispatch(
                    changeVisiblePort(
                        true,
                        portData.shipId,
                        portData.exteriorId,
                        portData.portPreviewType,
                        portData.id,
                        false,
                        portData.sourceUrl,
                        portData.showTTX,
                        portData.itemType,
                        portData.additionalData,
                    ),
                );
                store.dispatch(setFetching(false));
                break;

            default:
                break;
        }
    };
};

export const setScrollover = (isOver: boolean) => {
    sendAction({
        actionId: 'updateBrowserOverScrollableState',
        enabled: isOver,
    });
};
