import { getClientPath, isInGameBrowser, isSafari } from './utils';

export const videoFormats = {
    ogg: 'video/ogg; codecs="theora"',
    webm: 'video/webm; codecs="vp8, vorbis"',
    vp9: 'video/webm; codecs="vp9"',
};

export function isVideoTypeSupported(type: keyof typeof videoFormats) {
    const video = document.createElement('video');
    return video.canPlayType(videoFormats[type] || type) === 'probably';
}

export function getSupportedVideo(videoMap: Partial<IVideo>) {
    const webm = getSupportedWebmVideo(videoMap);
    if (webm) return webm;

    return null;
}

export function getSupportedWebmVideo(videoMap: Partial<IVideo>) {
    if (isInGameBrowser) {
        if (!!videoMap?.clientVideoName?.trim()?.length) {
            return getClientPath(`armory/${videoMap.clientVideoName}`);
        }
        return videoMap?.webm;
    }

    if (isSafari) {
        return null;
    }

    if (videoMap?.webm && isVideoTypeSupported('webm')) {
        return videoMap.webm;
    }

    return null;
}
