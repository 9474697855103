import { Actions } from '~/Actions';
import { SAVE_DOCKYARD_SETTINGS } from '~/Actions/ActionDockyard';

export interface DockyardState {
    season: number;
    shipId: number;
    isStartersBought: boolean;
    categoryBck: string;
}

export const initialState: DockyardState = {
    season: 0,
    shipId: 0,
    isStartersBought: true,
    categoryBck: '',
};

const ReducerDockyard = (state: DockyardState = initialState, action: Actions): DockyardState => {
    switch (action.type) {
        case SAVE_DOCKYARD_SETTINGS:
            return {
                ...state,
                season: action.season,
                shipId: action.shipId,
                isStartersBought: action.isStartersBought,
                categoryBck: action.categoryBck,
            };

        default:
            return { ...state };
    }
};

export default ReducerDockyard;
