import * as React from 'react';
import classNames from 'classnames';
import { updateQuantity } from '~/Actions/ActionApp';
import { IUpdateQuantity } from '~/Actions/ActionAppType';
import PurchaseButton, { PurchaseButtonTheme } from '~/components/Button/PurchaseButton';
import RandomBundleTitle from '~/components/RandomBundleTitle/RandomBundleTitle';
import BundleContains from '~/Layouts/BundlePage/Contains/BundleContains';
import PrimaryContent from '~/Layouts/BundlePage/PrimaryContent/PrimaryContent';
import PossibleOptionsRandomBundle from '~/Layouts/RandomBundlePage/PossibleOptionsRandomBundle/PossibleOptionsRandomBundle';
import RandomBundleHistoryCarousel from '~/Layouts/RandomBundlePage/RandomBundleHistoryCarousel/RandomBundleHistoryCarousel';
import SpoilerContent from '~/Layouts/RandomBundlePage/SpoilerContent/SpoilerContent';
import { FAST_GOLD_PLACE } from '~/containers/FastGoldLinkContainer/FastGoldLinkContainer';
import PurchaseProcessor from '~/processors/PurchaseProcessor';
import { t, interpolate } from '~/utils/localization';
import { calculateBundlePrices, isQuantityIncrementOverdraftAvailable } from '~/utils/purchase';
import styles from './RandomBundleView.scss';
import { State } from '~/Reducers';
import equal from 'fast-deep-equal/react';
import { useDispatch, useSelector } from 'react-redux';
import RandomBundleFullPurchaseButton from '~/components/RandomBundleFullPurchaseButton/RandomBundleFullPurchaseButton';
import type { SwiperClass as SwiperInstance } from 'swiper/react';
import useMaskScrollEffect from '~/hooks/useMaskScrollEffect';
import { canBoughtRandomBundleWithMaxAmount, getProgressiveDiscountFromBundle, getSinglePurchaseLimitTextForRandom, isFreeBundle, isPeriodicBundle } from '~/utils/bundles';
import useRandomBundleData from '~/hooks/useRandomBundleData';
import { parsePlaceholderInBundleDescription } from '~/Layouts/BundlePage/helpers';
import PriceWrapper, { PRICE_WRAPPER_SIZE } from '~/components/PriceWrapper/PriceWrapper';
import ProgressiveDiscount from '~/components/ProgressiveDiscount/ProgressiveDiscount';
import { arrayToObjectByKey, isMobileOrTabletWindow, localizedDateTime } from '~/utils/utils';
import Purchased from '~/components/Purchased/Purchased';
import { isEnabledPurchaseLimitToContainer, REASON_FAIL_PURCHASE_CONTAINER } from '~/components/BundleLimitWidget/settings';
import { BundleLimitSize } from '~/components/BundleLimitWidget/BundleLimitWidget';
import BundleLimitContainer from '~/components/BundleLimitWidget/BundleLimitContainer';
import AttentionLimitWidget from '~/components/BundleLimitWidget/Attention/AttentionLimitWidget';
import { getPeriodicInfo } from '~/utils/time';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import { BUNDLE_LIMIT_ERROR_TEXTS } from '~/components/BundleLimitWidget/Tooltip/BundleLimitTooltip';
import Account from '~/account/Account';
import useDelegateContainerScroll from '~/hooks/useDelegateContainerScroll';
import RandomBundleBeneficialOffer from '~/components/RandomBundleBeneficialOffer/RandomBundleBeneficialOffer';
import QuantityContainer from '~/containers/QuantityContainer/QuantityContainer';
import { getLevelingRestrictionForBundle, getLevelingRestrictionTextForBundle } from '~/utils/levelingResctrictions';
import Attention from '~/components/Attention/Attention';
import BundlePageLabels from '../BundlePage/BundlePageLabels/BundlePageLabels';
import Info from '~/components/Info/Info';
import useDelegationClickForLinkHandler from '~/hooks/useDelegationClickForLinkHandler';
import { onClickByLink } from '~/Layouts/BundlePage/BundlePageDescription/handlers';

interface IRandomBundle {
    bundle: IBundle;
}

interface IStateSelector {
    accountId: number;
    selectedRandomBundles: AccountSelectedRandomBundles;
    purchasedLimitedBundles: AccountPurchasedLimitedBundles;
    randomBundlesHistory: AccountRandomBundleHistory;
    deniedBundlesByUniqueItems: number[];
    restrictedLootboxesPurchaseCount?: AccountRestrictedLootboxesPurchaseCount;
    balance: IBalance;
    popupActive: IPopup;
    bundlesInTransaction: number[];
}

const stateSelector = (state: State): IStateSelector => {
    return {
        accountId: state.ReducerAccount?.id,
        balance: state.ReducerAccount.balance,
        selectedRandomBundles: state.ReducerAccount.selectedRandomBundles,
        purchasedLimitedBundles: state.ReducerAccount.purchasedLimitedBundles,
        randomBundlesHistory: state.ReducerAccount.randomBundlesHistory,
        deniedBundlesByUniqueItems: state.ReducerAccount.deniedBundlesByUniqueItems,
        restrictedLootboxesPurchaseCount: state.ReducerAccount.restrictedLootboxesPurchaseCount,
        popupActive: state.ReducerApp.popupActive,
        bundlesInTransaction: state.ReducerAccount.bundlesInTransaction,
    };
};

const RandomBundleView = ({ bundle }: IRandomBundle) => {
    const dispatch = useDispatch();
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const [swiper, setSwiperRef] = React.useState<SwiperInstance>(null);
    const refInfoContainer = React.useRef<HTMLDivElement>(null);
    const refDescriptionWrapper = React.useRef<HTMLDivElement>(null);
    const _isPeriodicBundle = isPeriodicBundle(bundle);
    const _isFreeBundle = isFreeBundle(bundle);
    const { progressivePurchase } = bundle;

    const {
        currentBundle,
        childRandomBundle,
        totalRandomBundlesCount,
        isAvailableFullPurchaseRandomBundle,
        availableAmountForFullPurchase,
        isAvailableChangeQuantity,
        historyLength,
        isNeedToShowTimer,
        isAvailablePurchasePeriodicBundle,
        currentProgressiveDiscount,
        maxAvailableProgressiveDiscount,
        maxAmountForOnePurchaseRandomBundle,
        isAlreadyPurchased,
        isTimeUp,
        hasRestrictionPurchaseForRandomBundle,
        purchaseMaxCountForRandomBundle,
        canPurchaseWholeBundle,
        canIncrementQuantity,
        ...randomBundleData
    } = useRandomBundleData(bundle);

    const isActiveTransaction = state.bundlesInTransaction?.includes?.(currentBundle?.id);
    const [selectedBundle, selectBundle] = React.useState<IBundle>(currentBundle);

    let isEnableFirstOpen = randomBundleData.isEnableFirstOpen;
    if (!state.accountId) {
        isEnableFirstOpen = true;
    }

    const isEnabledRestrictions = isEnabledPurchaseLimitToContainer(currentBundle);
    const _canBoughtWithRestriction = _isFreeBundle || canBoughtRandomBundleWithMaxAmount(purchaseMaxCountForRandomBundle as Nullable<number>);

    const setQuantity = (amount: number) => {
        if (!state.accountId) {
            return false;
        }
        const prices = calculateBundlePrices(bundle, state.balance, amount, null, getProgressiveDiscountFromBundle(bundle, amount));
        if (!Account.canIncrementQuantity(prices, state.balance, bundle, null) && !isQuantityIncrementOverdraftAvailable(prices)) {
            return false;
        }
        dispatch<IUpdateQuantity>(updateQuantity(bundle.id, amount, prices));
        return true;
    };

    const purchase = (quantity?: number) => {
        selectBundle(currentBundle);
        quantity && setQuantity(quantity);
        const purchaseProcessor = new PurchaseProcessor(bundle);
        if (!purchaseProcessor.isPurchaseAvailable()) {
            return;
        }
        if (isEnableFirstOpen) {
            purchaseProcessor.chooseRandomBundle();
        } else {
            if (isFreeBundle(bundle)) {
                purchaseProcessor.purchase();
            } else {
                purchaseProcessor.purchaseBundleFromPopup(bundle, null, null);
            }
        }
    };

    const scrollToSlide = (index: number) => swiper?.slideTo(index + historyLength - 1);

    const onChangeQuantity = (amount: number) => {
        selectBundle(currentBundle);
        if (isActiveTransaction) {
            return;
        }
        return new Promise((resolve, reject) => {
            if (!setQuantity(amount)) {
                return reject?.();
            }
            scrollToSlide(amount);
            resolve?.(amount);
        });
    };

    const onChangeQuantityFromWidget = (amount: number) => {
        selectBundle(currentBundle);
        scrollToSlide(amount);
    };

    const setInfoRef = (_ref: HTMLDivElement) => {
        if (_ref) {
            refInfoContainer.current = _ref;
        }
    };

    React.useEffect(() => {
        selectBundle(currentBundle);
    }, [currentBundle?.id]);

    React.useEffect(() => {
        return () => {
            dispatch<IUpdateQuantity>(updateQuantity(bundle.id, null, null));
        };
    }, []);

    React.useEffect(() => {
        if (_isPeriodicBundle) {
            window.WoWSEntities.parseDocument();
        }
    }, [currentBundle?.description, selectedBundle?.id]);

    useMaskScrollEffect(refInfoContainer);

    const isNeedToRenderImageForGuest = isAlreadyPurchased || !state.accountId || isEnableFirstOpen;
    const isAvailablePurchase = _isPeriodicBundle ? isAvailablePurchasePeriodicBundle && _canBoughtWithRestriction : !isAlreadyPurchased && _canBoughtWithRestriction;

    const classesInfoWrapper = classNames(styles.info, {
        [styles.disablePurchase]: (_isPeriodicBundle && isNeedToShowTimer) || !isAvailablePurchase,
        [styles.restriction]: !_canBoughtWithRestriction,
        [styles.isFull]: !state.accountId || isEnableFirstOpen,
        [styles.reverseContent]: isNeedToRenderImageForGuest,
    });

    useDelegateContainerScroll(refInfoContainer);

    const isAvailablePartialPurchase = !_isPeriodicBundle && isAvailableChangeQuantity;
    const isAvailableOnlyFullPurchase = !_isPeriodicBundle && !isAvailableChangeQuantity && isAvailableFullPurchaseRandomBundle;

    React.useEffect(() => {
        if (!state.popupActive && isAvailableOnlyFullPurchase) {
            setQuantity(1);
            return;
        }
        if (!state.popupActive && bundle.quantityData?.quantity) {
            scrollToSlide(bundle.quantityData.quantity);
        }
    }, [state.popupActive]);

    React.useEffect(() => {
        setQuantity(bundle.quantityData?.quantity || 1);
    }, [state.balance]);

    const getInitialBundle = React.useCallback(() => {
        return Object.assign({}, currentBundle, {
            quantityData: {
                quantity: 1,
            },
        });
    }, []);

    const getFullBundle = React.useCallback(() => {
        return Object.assign({}, currentBundle, {
            quantityData: {
                quantity: availableAmountForFullPurchase,
            },
        });
    }, [availableAmountForFullPurchase]);

    const isNeedToHiddenDetailedDescription = !selectedBundle?.id || selectedBundle?.id === bundle?.id;
    const isNeedToShowFullPurchaseButton = !_isPeriodicBundle && isAvailableOnlyFullPurchase && !isEnableFirstOpen;
    const isAvailableProgressiveDiscount = !!maxAvailableProgressiveDiscount?.discount;
    const hiddenProgressiveWrapper = !bundle.progressivePurchase?.discountDiapasons || !isAvailableProgressiveDiscount;
    const alreadyCountItem = state.purchasedLimitedBundles?.[selectedBundle?.id] || 0;

    const classesFirstColumn = classNames(styles.purchaseColumn, {
        [styles.singleColumn]: !isNeedToShowFullPurchaseButton,
    });

    const classesQuantityWidget = classNames(styles.quantityWidget, {
        [styles.withoutProgressiveDiscount]: hiddenProgressiveWrapper,
    });

    const classesFullPurchaseButton = classNames(styles.fullPurchaseButton, {
        [styles.withoutProgressiveDiscount]: hiddenProgressiveWrapper,
    });

    const classesPurchaseWrapper = classNames(styles.purchaseWrapper, {
        [styles.withoutProgressiveDiscount]: hiddenProgressiveWrapper,
    });

    const classesAdditionalHeader = classNames(styles.headerAdditionalWrapper, {
        [styles.withPaddingTop]: isEnabledRestrictions,
    });

    const classedHeaderSubTitles = classNames(styles.headerSubTitle, {
        [styles.withLootboxRestriction]: isEnabledRestrictions,
    });

    const classesPurchaseButton = classNames(styles.purchaseButton, {
        [styles.withoutPrice]: isEnableFirstOpen || _isFreeBundle,
    });

    const classesPurchasePriceInfo = classNames(styles.purchasePriceInfo, { [styles.withPartialPurchase]: isAvailablePartialPurchase });

    const date = localizedDateTime(new Date(bundle.promoTimerActiveTill));
    const period = getPeriodicInfo(bundle.randomPurchaseCooldown);
    const text = interpolate(t('Доступен до: {date}'), {
        date: date,
    });
    const timeUpText = text + ' ' + t('Получены все доступные наборы');

    useDelegationClickForLinkHandler(refDescriptionWrapper);

    const getDescription = React.useCallback((id: number) => {
        const _bundle = arrayToObjectByKey(bundle.randomBundleChildren, 'id')[id] || bundle;

        return (
            <React.Fragment>
                {_bundle.description && (
                    <div className={styles.description} onClick={onClickByLink} dangerouslySetInnerHTML={{ __html: parsePlaceholderInBundleDescription(_bundle.description || '') }} />
                )}
                {_bundle.descriptionAdditional && (
                    <div
                        className={classNames(styles.description, styles.additionalDescription, {
                            [styles.commonAdditionalDescription]: !selectedBundle.description,
                        })}
                        onClick={onClickByLink}
                        dangerouslySetInnerHTML={{ __html: parsePlaceholderInBundleDescription(_bundle.descriptionAdditional || '') }}
                    />
                )}
            </React.Fragment>
        );
    }, []);

    const hasDescription = selectedBundle.description || selectedBundle.descriptionAdditional;

    let lockedPlusButtonTooltip = hasRestrictionPurchaseForRandomBundle && <DefaultTooltip text={BUNDLE_LIMIT_ERROR_TEXTS[REASON_FAIL_PURCHASE_CONTAINER.ACHIEVED_TOTAL_LIMIT_AFTER_PURCHASE]} />;
    if (!lockedPlusButtonTooltip && bundle.singlePurchaseMaxQuantity && bundle.singlePurchaseMaxQuantity < availableAmountForFullPurchase) {
        lockedPlusButtonTooltip = <DefaultTooltip text={getSinglePurchaseLimitTextForRandom(bundle.singlePurchaseMaxQuantity)} />;
    }

    const levelingResctrictions = getLevelingRestrictionForBundle(bundle, state.selectedRandomBundles);

    return (
        <div className={classNames(styles.container, 'armory__auto--bundle-page_wrapper')} data-bundle-id={currentBundle?.id?.toString()} data-bundle-type={'random'}>
            <BundlePageLabels bundle={bundle} />
            <div className={styles.header}>
                <div className={styles.headerTitle} dangerouslySetInnerHTML={{ __html: bundle?.title }} />
                <div className={classesAdditionalHeader}>
                    <div className={classedHeaderSubTitles}>
                        <RandomBundleTitle bundle={bundle} isInfinity={currentBundle.randomIsInfinite} withoutTooltip />
                    </div>
                    {isEnabledRestrictions && <BundleLimitContainer size={BundleLimitSize.BIG} bundle={currentBundle} className={styles.lootboxRestriction} />}
                </div>
                {_isPeriodicBundle && (
                    <div className={styles.periodicInfo}>
                        {isTimeUp ? (
                            <React.Fragment>{timeUpText}</React.Fragment>
                        ) : (
                            <React.Fragment>
                                <span>{t('Период обновления:')}&nbsp;</span>
                                <span>{period}&nbsp;</span>
                                <span>{text}</span>
                            </React.Fragment>
                        )}
                    </div>
                )}
            </div>
            {state.accountId && !isEnableFirstOpen && (
                <div className={styles.carousel}>
                    <RandomBundleHistoryCarousel
                        parentRandomBundle={bundle}
                        isAvailablePartialPurchase={isAvailablePurchase && isAvailablePartialPurchase}
                        bundle={childRandomBundle}
                        randomBundlesHistory={state.randomBundlesHistory}
                        selectedRandomBundles={state.selectedRandomBundles}
                        onChangeQuantity={onChangeQuantity}
                        isNeedToShowTimer={isNeedToShowTimer}
                        isTimeUp={isTimeUp}
                        setSwiperRef={(swiper) => setSwiperRef(swiper)}
                        isAlreadyPurchased={isAlreadyPurchased}
                        maxAmountForOnePurchaseRandomBundle={maxAmountForOnePurchaseRandomBundle}
                        onCarouselItemClick={selectBundle}
                    />
                </div>
            )}
            <div className={classesInfoWrapper} ref={setInfoRef}>
                <div
                    className={classNames(styles.column, {
                        [styles.noBorder]: !hasDescription,
                    })}
                >
                    {(!state.accountId || isEnableFirstOpen) && hasDescription && getDescription(selectedBundle?.id)}
                    <PossibleOptionsRandomBundle
                        purchasedLimitedBundles={state.purchasedLimitedBundles}
                        parentRandomBundle={bundle}
                        onClick={(bundle) => {
                            selectBundle(bundle);
                            refInfoContainer.current?.scrollTo?.({ top: 0, left: 0, behavior: 'smooth' });
                        }}
                        currentBundle={currentBundle}
                        selectedBundle={selectedBundle}
                        isEnableFirstOpen={isEnableFirstOpen}
                    />
                    <SpoilerContent bundle={bundle} className={styles.spoilerInfo} />
                </div>
                <div className={styles.column}>
                    {isNeedToRenderImageForGuest && !isAlreadyPurchased && (
                        <div className={styles.previewBundleImage}>
                            <img src={bundle.icons.big} />
                        </div>
                    )}
                    <div className={styles.content} key={`content_${selectedBundle.id}`} ref={refDescriptionWrapper}>
                        {!isNeedToHiddenDetailedDescription && !isEnableFirstOpen && (
                            <React.Fragment>
                                {selectedBundle?.id === currentBundle?.id && !isAlreadyPurchased ? (
                                    <div className={styles.activeBundleTitle}>{t('Доступный набор')}</div>
                                ) : (
                                    <div className={styles.selectedBundleTitle}>
                                        {alreadyCountItem === selectedBundle?.limitedQuantity?.personalLimit ? (
                                            <Purchased />
                                        ) : (
                                            <React.Fragment>
                                                <Info />
                                                <span>{t('Просматриваемый набор пока не доступен для приобретения')}</span>
                                            </React.Fragment>
                                        )}
                                    </div>
                                )}
                                <BundleContains bundle={selectedBundle} className={classNames(styles.containsWidget, styles.borderBottom)} title={selectedBundle?.title} />
                            </React.Fragment>
                        )}
                        {((!isNeedToHiddenDetailedDescription && !isEnableFirstOpen) || isAlreadyPurchased || isNeedToShowTimer) && hasDescription && getDescription(selectedBundle?.id)}
                    </div>
                </div>
            </div>
            {isAvailablePurchase ? (
                <div className={styles.purchase}>
                    <div className={classesFirstColumn}>
                        {!isEnableFirstOpen && !_isFreeBundle && (
                            <div className={classesPurchasePriceInfo}>
                                <div className={classesPurchaseWrapper}>
                                    <div className={styles.purchasePriceTitle}>{t('Цена:')}</div>
                                    <div className={styles.purchaseCurrencies}>
                                        <PriceWrapper
                                            bundle={isAvailablePartialPurchase ? currentBundle : getInitialBundle()}
                                            size={PRICE_WRAPPER_SIZE.BIG}
                                            priceClassName={styles.priceWrapper}
                                            progressiveDiscount={isAvailablePartialPurchase && currentProgressiveDiscount}
                                            withFadeDiscount={true}
                                            wrapperClassName={styles.widgetPrice}
                                        />
                                        {isAvailableProgressiveDiscount && (
                                            <div className={styles.progressiveDiscount}>
                                                {progressivePurchase?.discountDiapasons && !isNeedToShowFullPurchaseButton && (
                                                    <ProgressiveDiscount
                                                        data={progressivePurchase.discountDiapasons}
                                                        amount={availableAmountForFullPurchase}
                                                        parentBundleName={bundle.title}
                                                        childBundleName={currentBundle.title}
                                                        hideLabel={isMobileOrTabletWindow}
                                                        singleLimit={bundle.singlePurchaseMaxQuantity}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className={classNames(styles.purchaseQuantityWrapper, {
                                        [styles.isBlockedInterface]: isActiveTransaction,
                                    })}
                                >
                                    {isAvailablePartialPurchase && (
                                        <>
                                            <div className={classesQuantityWidget}>
                                                <QuantityContainer
                                                    key={`quantity_widget_${bundle.id}_${availableAmountForFullPurchase}`}
                                                    bundle={bundle}
                                                    offersLimit={maxAmountForOnePurchaseRandomBundle}
                                                    tooltips={{ lockedPlusButton: lockedPlusButtonTooltip }}
                                                    idDisabled={!canIncrementQuantity}
                                                    onUpdateHandler={(amount) => {
                                                        onChangeQuantityFromWidget(amount);
                                                    }}
                                                />
                                            </div>
                                            {isAvailableFullPurchaseRandomBundle && canPurchaseWholeBundle && (
                                                <div className={classesFullPurchaseButton}>
                                                    <RandomBundleFullPurchaseButton
                                                        bundle={bundle}
                                                        availableAmountForPurchase={availableAmountForFullPurchase}
                                                        onClick={() => {
                                                            onChangeQuantity(availableAmountForFullPurchase).then(() => swiper?.slideTo(totalRandomBundlesCount));
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                                {randomBundleData.beneficialOffer && (
                                    <div
                                        className={classNames(styles.purchaseColumn, styles.beneficialOffer, {
                                            [styles.isBlockedInterface]: isActiveTransaction,
                                        })}
                                    >
                                        <RandomBundleBeneficialOffer
                                            bundle={bundle}
                                            balance={state.balance}
                                            beneficialQuantity={randomBundleData.beneficialOffer?.quantity}
                                            currentQuantity={bundle.quantityData?.quantity || 1}
                                            discount={randomBundleData.beneficialOffer?.discount}
                                            bundleId={bundle.id}
                                            maxQuantity={maxAmountForOnePurchaseRandomBundle}
                                            onClickHanlder={() => {
                                                scrollToSlide(randomBundleData.beneficialOffer?.quantity);
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                        {!!levelingResctrictions?.length && (
                            <div className={styles.restrictionText}>
                                <Attention label={getLevelingRestrictionTextForBundle(bundle, levelingResctrictions)} level="info" isInline />
                            </div>
                        )}
                        <div className={classesPurchaseButton}>
                            <PurchaseButton
                                bundle={bundle}
                                parentRandomBundle={bundle}
                                buttonClassName={classNames(styles.purchaseButton, 'armory__auto--bundle_button_purchase')}
                                onClick={() => purchase()}
                                classNameFastGoldLink={styles.fastGoldLink}
                                isForceRenderPurchaseButton={isEnableFirstOpen}
                                place={FAST_GOLD_PLACE.BUNDLE_VIEW_PAGE}
                                label={isEnableFirstOpen ? t('Открыть') : null}
                                hideFastGoldLink={isNeedToShowFullPurchaseButton}
                            />
                        </div>
                    </div>
                    {isNeedToShowFullPurchaseButton && (
                        <div
                            className={classNames(styles.purchaseColumn, styles.wholePurchase, {
                                [styles.isBlockedInterface]: isActiveTransaction,
                            })}
                        >
                            <div className={styles.purchasePriceInfo}>
                                <div className={styles.purchaseWrapper}>
                                    <div className={styles.purchasePriceTitle}>{interpolate(t('Все наборы, {amount} шт.'), { amount: availableAmountForFullPurchase })}</div>
                                    <div className={styles.purchaseCurrencies}>
                                        <PriceWrapper
                                            bundle={getFullBundle()}
                                            size={PRICE_WRAPPER_SIZE.BIG}
                                            priceClassName={styles.progressiveDiscountPrice}
                                            progressiveDiscount={maxAvailableProgressiveDiscount}
                                            availableAmountForFullPurchase={availableAmountForFullPurchase}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.purchaseButton}>
                                <PurchaseButton
                                    bundle={bundle}
                                    parentRandomBundle={bundle}
                                    buttonClassName={classNames(styles.purchaseButton, 'armory__auto--bundle_button_full_purchase')}
                                    onClick={() => purchase(availableAmountForFullPurchase)}
                                    customQuantity={availableAmountForFullPurchase}
                                    classNameFastGoldLink={styles.fastGoldLink}
                                    place={FAST_GOLD_PLACE.BUNDLE_VIEW_PAGE}
                                    label={interpolate(t('Купить все ({amount})'), { amount: availableAmountForFullPurchase })}
                                    purchaseButtonTheme={PurchaseButtonTheme.specialOrange}
                                />
                            </div>
                        </div>
                    )}
                </div>
            ) : !_canBoughtWithRestriction ? (
                <div className={styles.restrictionWrapper}>
                    <AttentionLimitWidget reason={REASON_FAIL_PURCHASE_CONTAINER.ACHIEVED_TOTAL_LIMIT_AFTER_PURCHASE} bundle={currentBundle} />
                </div>
            ) : null}
            {isAlreadyPurchased && (
                <div className={styles.purchasedWrapper}>
                    <Purchased bundle={bundle} />
                </div>
            )}
        </div>
    );
};

export default RandomBundleView;
