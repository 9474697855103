import * as React from 'react';
import styles from './Button.scss';
import classNames from 'classnames';
import SmallPreloader from '~/components/SmallPreloader/SmallPreloader';
import AnimationDecorator from '~/decorators/AnimationDecorator/AnimationDecorator';
import { playButtonClickSound, playButtonDialogClickSound } from '~/api/WoWsClient';
import { IButtonSoundset } from '~/types/soundsets';

export enum ButtonColorPresets {
    ORANGE = 'orange',
    SPECIAL_ORANGE = 'special_orange',
    BLUE = 'blue',
}

export interface IButton {
    label: string | React.ReactChild | any;
    onClick?: (event?: React.MouseEvent) => void;
    onFallback?: (event?: React.MouseEvent) => void;
    disabled?: boolean;
    className?: string | string[];
    isFull?: boolean;
    isFree?: boolean;
    isWarn?: boolean;
    id?: string;
    autoFocus?: boolean;
    onMouseMove?: (event: React.MouseEvent) => void;
    isFetching?: boolean;
    linkTarget?: boolean;
    isSilent?: boolean;
    soundset?: IButtonSoundset;

    backgroundColor?: ButtonColorPresets | string;
    textColor?: string;
    animationColor?: string;

    isEnabledAnimation?: boolean;
}

const soundsetsMap: Record<IButtonSoundset, (() => void) | null> = {
    [IButtonSoundset.MUTED]: null,
    [IButtonSoundset.DEFAULT]: () => void playButtonClickSound(),
    [IButtonSoundset.POPUP]: () => void playButtonDialogClickSound(),
};

const playSoundset = (soundsetName: IButtonSoundset) => {
    soundsetsMap[soundsetName || IButtonSoundset.DEFAULT]?.();
};

const OrangeButton = function (props: IButton) {
    return <Button {...props} backgroundColor={ButtonColorPresets.ORANGE} animationColor={props.isFree ? '#E13C3C' : '#B23D03'} isEnabledAnimation={true} />;
};

const SpecialOrangeButton = function (props: IButton) {
    return <Button {...props} backgroundColor={ButtonColorPresets.SPECIAL_ORANGE} />;
};

const BlueButton = function (props: IButton) {
    return <Button {...props} backgroundColor={ButtonColorPresets.BLUE} animationColor="#4EBCEB" isEnabledAnimation={true} />;
};

const Button = function (props: IButton) {
    const isDisabled = props.disabled || props.isFetching;
    const isCustomColor = (!Object.values<string>(ButtonColorPresets).includes(props.backgroundColor) && props.backgroundColor != null) || props.textColor != null;
    const style: React.CSSProperties = {};

    if (isCustomColor) {
        if (props.backgroundColor) {
            // @ts-ignore
            style['--btn-background-color'] = props.backgroundColor;
        }
        if (props.textColor) {
            // @ts-ignore
            style['--btn-color'] = props.textColor;
        }
    }

    const buttonClassNames = classNames(
        styles.button,
        {
            [styles.disabled]: isDisabled,
            [styles.isFull]: props.isFull,
            [styles.isFetching]: props.isFetching,
            [styles.orangeButton]: props.backgroundColor === ButtonColorPresets.ORANGE,
            [styles.isFree]: props.isFree && props.backgroundColor === ButtonColorPresets.ORANGE,
            'armory-decorator__animation': [ButtonColorPresets.ORANGE, ButtonColorPresets.BLUE].includes(props.backgroundColor as ButtonColorPresets) || isCustomColor,
            [styles.blueButton]: props.backgroundColor == ButtonColorPresets.BLUE,
            [styles.specialOrangeButton]: props.backgroundColor === ButtonColorPresets.SPECIAL_ORANGE,
            [styles.customColor]: isCustomColor,
        },
        props.className,
    );

    const onClick = (event: React.MouseEvent) => {
        event.preventDefault();
        playSoundset(props.soundset);
        props.onClick && props.onClick(event);
    };

    const onFallback = () => {
        props.onFallback?.();
    };

    const onMouseMove = (event: React.MouseEvent) => {
        props.onMouseMove?.(event);
    };
    const buttonContent = (
        <>
            {props.isFetching && <SmallPreloader className={styles.fetching} />}
            <span className={styles.label}>{props.label}</span>
            {props.linkTarget && !props.backgroundColor && <div className={styles.arrowTarget}></div>}
        </>
    );

    return (
        <button style={style} tabIndex={-1} className={buttonClassNames} onMouseMove={onMouseMove} onClick={isDisabled ? onFallback : onClick} autoFocus={props.autoFocus} id={props.id}>
            {props.isEnabledAnimation || isCustomColor ? (
                <AnimationDecorator mainColor={props?.animationColor || props.backgroundColor} isDisabled={isDisabled}>
                    {buttonContent}
                </AnimationDecorator>
            ) : (
                buttonContent
            )}
        </button>
    );
};

const AuctionOrangeButton = function (props: IButton) {
    const buttonClassNames = classNames(
        styles.button,
        styles.orangeButton,
        {
            [styles.isFull]: props.isFull,
            [styles.isFree]: props.isFree,
            [styles.isFetching]: props.isFetching,
            [styles.disabled]: props.disabled,
        },
        props.className,
    );

    const onClick = (event: React.MouseEvent) => {
        playSoundset(props.soundset);
        props.onClick && props.onClick(event);
    };

    const onMouseMove = (event: React.MouseEvent) => {
        props.onMouseMove?.(event);
    };

    const onFallback = () => {
        props.onFallback?.();
    };

    return (
        <button tabIndex={-1} className={buttonClassNames} onMouseMove={onMouseMove} onClick={props.disabled ? onFallback : onClick} autoFocus={props.autoFocus}>
            <span className={styles.label}>{props.label}</span>
        </button>
    );
};

export { OrangeButton, Button, AuctionOrangeButton, BlueButton, SpecialOrangeButton };
