import * as React from 'react';
import { IBundleManager } from '~/components/Bundle/BundleManager';
import Bundle from '~/components/Bundle/Default/Bundle';
import { BundlesObserver } from '~/components/BundlesObserver/BundlesObserver';

interface ILazyRendering extends IBundleManager {
    bundles?: string[];
    bundle: IBundle;
    className?: string;
    image?: string;
    width?: string;
    isLazy?: boolean;
}

const LazyRenderingBundle = (props: ILazyRendering) => {
    let _ref: HTMLDivElement;
    const [isVisibleComponent, setVisibleComponent] = React.useState(false);
    const id = `${props.bundle.id}_${props.presetName}`;

    React.useLayoutEffect(() => {
        if (!_ref || !props.isLazy) {
            return;
        }

        BundlesObserver.observe(
            _ref,
            (isVisible: boolean) => {
                setVisibleComponent(isVisible);
            },
            id,
        );

        return () => {
            return BundlesObserver.unobserve(_ref, id);
        };
    });

    return (
        <Bundle
            bundle={props.bundle}
            className={props.className}
            sizeInGrid={props.width}
            presetName={props.presetName}
            isLazilyRendered={props.isLazy ? isVisibleComponent : true}
            ref={(ref) => {
                if (ref) {
                    if (!props.isLazy) {
                        return;
                    }

                    _ref = ref;
                }
            }}
            {...props}
        />
    );
};

export default LazyRenderingBundle;
