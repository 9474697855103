import store from '~/Store';
import { armoryState, settings } from '~/utils/settings';
import { getRandomBundleChild, getRandomBundleShinyLSKey, getSinglePurchaseMaxQuantity } from '~/utils/bundles';
import { LocalStorageHelper } from '~/utils/storage';
import { getLocalStorageLabelsKey } from '~/decorators/LabelDecorator/LabelDecorator';
import { authorizationRequired } from '~/decorators/ts/';
import { isEmptyObject, isMobileOrTabletWindow } from '~/utils/utils';
import { updateAccountSettings } from '~/api/account';
import { get } from '~/utils/ajax';
import { logInfo } from '~/utils/logging';
import { setInventory } from '~/Actions/ActionAccount';
import { getActiveCoupon, hasCouponFromCategory, isCouponsTabHidden, isEnabledCouponsFromBundle } from '~/utils/coupons';
import { ALTERNATIVE_ENTITY_TYPE_FOR_INVENTORY, CATEGORIES } from '~/const';
import {
    canChangeQuantity,
    canIncrementQuantity,
    getNeededAmount,
    getNextAvailableFromPurchaseSerialBundle,
    isAlreadyPurchased,
    isDisabledForPurchase,
    isDisabledSerialBundle,
    isEnoughCurrency,
} from '~/utils/purchase';
import { InventoryKeys } from '~/types/account';
import { updateBundleList, updateBundlesPurchasedInfo } from '~/Actions/ActionApp';
import equal from 'fast-deep-equal/es6';
import { items } from '@wg/wows-entities/const';
class Account {
    public getAccount() {
        return window.metashop.state?.account;
    }

    @authorizationRequired
    public getAccountLevel() {
        return this.getAccount()?.level;
    }

    @authorizationRequired
    public loadAccountInventoryFromVrtx() {
        if (settings?.disableUserInventory) {
            return;
        }
        const isDebugMode = settings.debug;
        return get(settings.urls.accountInventoryVrtx, null, null, true, true, {
            credentials: 'include',
        })
            .then((response: ResponseInventory) => {
                isDebugMode && logInfo('[account inventory] loading successful');
                let uniqueItems;
                if (!isEmptyObject(response?.data)) {
                    uniqueItems = store.getState().ReducerAccount.inventory?.uniqueItems;
                    store.dispatch(setInventory(response?.data));
                }
                const isEqualUniqueItems = equal(response?.data.unique_items, uniqueItems);
                if (uniqueItems && !isEqualUniqueItems) {
                    store.dispatch(updateBundlesPurchasedInfo(store.getState().ReducerAccount.inventory as InventoryState));
                }
            })
            .catch(() => {
                isDebugMode && logInfo('[account inventory] failed');
            });
    }

    public getInventoryInfoByType(
        type: keyof Inventory | keyof typeof ALTERNATIVE_ENTITY_TYPE_FOR_INVENTORY,
        id: number | string,
        shipId: number | string = null,
        shipShardsLevel?: number | string,
        shipShardsId?: number | string,
        inventoryState?: InventoryState,
    ): [boolean, number] {
        if (!this.getAccount()?.id) {
            return [false, 0];
        }

        id = id && parseInt(id.toString(), 10);
        shipId = shipId && parseInt(shipId.toString(), 10);
        shipShardsLevel = shipShardsLevel && parseInt(shipShardsLevel.toString(), 10);
        shipShardsId = shipShardsId && parseInt(shipShardsId.toString(), 10);

        const alternativeType = ALTERNATIVE_ENTITY_TYPE_FOR_INVENTORY[type as keyof typeof ALTERNATIVE_ENTITY_TYPE_FOR_INVENTORY] || type;
        // @ts-ignore
        const inventory = inventoryState ? inventoryState[alternativeType] : store.getState().ReducerAccount.inventory?.[alternativeType];
        const storage = inventoryState ? inventoryState.storage : store.getState().ReducerAccount.inventory?.storage;
        const itemsStorage = inventoryState ? inventoryState.itemsStorage : store.getState().ReducerAccount.inventory?.itemsStorage;

        if (!inventory) {
            return [false, 0];
        }

        switch (alternativeType) {
            case InventoryKeys.permoflages: {
                if (shipId) {
                    const isExists = (inventory as InventoryPermoflages)?.some(([itemId, _shipId]) => {
                        return itemId === id && shipId === _shipId;
                    });
                    return [isExists, Number(isExists)];
                } else {
                    const _storage = (type === items.MULTI_BOOST ? itemsStorage : storage) as Record<number, number>;
                    return _storage[id] ? [true, _storage[id]] : [false, 0];
                }
            }

            case InventoryKeys.ships: {
                const isExists = inventory.includes(id);
                return [isExists, Number(isExists)];
            }

            case InventoryKeys.itemsStorage:
            case InventoryKeys.lootboxes:
            case InventoryKeys.storage: {
                const count = inventory[id];
                return [!!count, count];
            }

            case InventoryKeys.emptySlots: {
                return [!!inventory, inventory];
            }

            case InventoryKeys.shipMaterials: {
                const count = inventory[shipShardsLevel];
                return [!!count, count];
            }

            case InventoryKeys.shipComponents: {
                const count = inventory[shipShardsId] || 0;
                return [!!count, count];
            }

            default:
                return [false, 0];
        }
    }

    @authorizationRequired
    public isAuctionDisabledForAccountCountry() {
        const country = this.getAccount()?.country?.toUpperCase();
        const auctionDisabledForCountries = window.metashop?.settings?.auctionDisabledForCountries || [];
        return auctionDisabledForCountries?.includes(country);
    }

    @authorizationRequired
    public isDeactivated() {
        return this.getAccount().isDeactivated;
    }

    @authorizationRequired
    public isParagonsEnabled() {
        return this.getAccount().isParagonsEnabled;
    }

    @authorizationRequired
    public isDockyardCategoryEnabled() {
        if (!settings.isDockyardTabEnabled || !settings.urls.dockyard || !settings.urls.dockyardAccountCheck) {
            return false;
        }
        const { categories } = armoryState.content;
        return Object.values(categories)?.find((category) => category.name === CATEGORIES.DOCKYARD);
    }

    public getAllData() {
        return store.getState().ReducerAccount;
    }

    @authorizationRequired
    public isEnabledCouponsFromBundle(coupons: ICoupon[], bundle: IBundle): boolean {
        if (!bundle) {
            return false;
        }
        return !isCouponsTabHidden() && isEnabledCouponsFromBundle(coupons, bundle);
    }

    public getCompletedActions(): string[] {
        return this.getAllData()?.completedActions || [];
    }

    @authorizationRequired
    public getPurchasedLimitedBundles() {
        return this.getAllData()?.purchasedLimitedBundles;
    }

    @authorizationRequired
    public getDeniedBundlesByUniqueItems() {
        return this.getAllData()?.deniedBundlesByUniqueItems;
    }

    @authorizationRequired
    public isAlreadyPurchased(purchasedLimitedBundles: AccountPurchasedLimitedBundles, deniedBundlesByUniqueItems: number[], bundle: IBundle): boolean {
        return isAlreadyPurchased(purchasedLimitedBundles, deniedBundlesByUniqueItems, bundle);
    }

    @authorizationRequired
    public setPurchasedFlagForBundles(bundles: IBundleList, purchasedLimitedBundles: AccountPurchasedLimitedBundles, deniedBundlesByUniqueItems: number[]) {
        const copiedBundles = { ...bundles };
        Object.values(copiedBundles).forEach((bundle: IBundle) => {
            bundle.isPurchased = isAlreadyPurchased(purchasedLimitedBundles, deniedBundlesByUniqueItems, bundle);
        });
        store.dispatch(updateBundleList(copiedBundles));
    }

    @authorizationRequired
    public isDisabledForPurchase(
        purchasedLimitedBundles: {
            [key: string]: number;
        },
        deniedBundlesByUniqueItems: number[],
        bundle: IBundle,
    ): boolean {
        return !bundle || isDisabledForPurchase(purchasedLimitedBundles, deniedBundlesByUniqueItems, bundle);
    }

    @authorizationRequired
    public hasCouponFromCategory(coupons: ICoupon[], category: string): ICoupon | null {
        return !isCouponsTabHidden() && hasCouponFromCategory(coupons, category);
    }

    @authorizationRequired
    public getActiveCoupon(activeCouponId: number, coupons: ICoupon[]): ICoupon {
        return getActiveCoupon(activeCouponId, coupons);
    }

    public isDisabledSerialBundle(purchasedLimitedBundles: AccountPurchasedLimitedBundles, bundle: IBundle) {
        return isDisabledSerialBundle(purchasedLimitedBundles, bundle);
    }

    public getNextAvailableFromPurchaseSerialBundle(deniedBundlesByUniqueItems: number[], purchasedLimitedBundles: AccountPurchasedLimitedBundles, serialSequence: number[]): number | null {
        return getNextAvailableFromPurchaseSerialBundle(deniedBundlesByUniqueItems, purchasedLimitedBundles, serialSequence);
    }

    @authorizationRequired
    public getRandomBundleChild(
        selectedRandomBundles: {
            [key: string]: number;
        },
        bundle: IBundle,
    ): IBundle {
        return getRandomBundleChild(selectedRandomBundles, bundle);
    }

    @authorizationRequired
    public canChangeQuantity(bundle: IBundle, maxQuantity: number = getSinglePurchaseMaxQuantity()) {
        return canChangeQuantity(bundle, maxQuantity);
    }

    @authorizationRequired
    public canIncrementQuantity(quantityData: any, balance: IBalance, bundle?: IBundle, discount = 0): boolean {
        return canIncrementQuantity(quantityData, balance, bundle, discount);
    }

    @authorizationRequired
    public isEnoughCurrency(balance: IBalance, bundle: IBundle, coupon?: ICoupon | null, isActiveCoupon?: boolean): boolean {
        return isEnoughCurrency(balance, bundle, coupon, isActiveCoupon);
    }

    @authorizationRequired
    public getNeededAmount(
        balance: IBalance,
        bundle: IBundle,
        discount = 0,
        isActiveCoupon = false,
    ): {
        amount: number;
        currency: string;
    } {
        return getNeededAmount(balance, bundle, discount, isActiveCoupon);
    }

    public isGiftsAvailable() {
        return !!this.getAllData()?.gifts?.status;
    }

    public isViewedLabel(name: string): boolean {
        const state = store.getState().ReducerAccount;
        const viewedLabels = state.viewedLabels || [];
        const viewedLabelsLS = LocalStorageHelper.get(getLocalStorageLabelsKey())?.names || [];

        return viewedLabels.includes(name) || viewedLabelsLS.includes(name);
    }

    public getLSKey(prefix: string, isAccountSpecific = true) {
        const postfix = isAccountSpecific ? this.getAccount()?.id || 'guest' : 'null';
        return `${prefix}:${postfix}`;
    }

    @authorizationRequired
    public isViewedShinyAnimationFromRandomBundle(bundle: IBundle, index = 0) {
        return LocalStorageHelper.get(getRandomBundleShinyLSKey(bundle, index));
    }

    @authorizationRequired
    public markViewedShinyAnimationFromRandomBundle(bundle: IBundle, index = 0) {
        return LocalStorageHelper.set(getRandomBundleShinyLSKey(bundle, index), true);
    }

    public isViewedVideo(videoName: string, isViewAccountSpecific = true) {
        if (isMobileOrTabletWindow) {
            return true;
        }
        return LocalStorageHelper.get(this.getLSKey(`video:${videoName}`, isViewAccountSpecific));
    }

    public markViewedVideo(videoName: string, isViewAccountSpecific = true) {
        return LocalStorageHelper.set(this.getLSKey(`video:${videoName}`, isViewAccountSpecific), true);
    }

    public isViewedPopup(popupName: string, isViewAccountSpecific = true) {
        return LocalStorageHelper.get(this.getLSKey(`popup:${popupName}`, isViewAccountSpecific));
    }

    public markViewedPopup(popupName: string, isViewAccountSpecific = true) {
        return LocalStorageHelper.set(this.getLSKey(`popup:${popupName}`, isViewAccountSpecific), true);
    }

    @authorizationRequired
    public async updateAccountSettings(data: Record<string, any>) {
        return await updateAccountSettings(data);
    }

    @authorizationRequired
    public getAlreadyPurchasedBundleIds(bundles: IBundleList, purchasedLimitedBundles: AccountPurchasedLimitedBundles, deniedBundlesByUniqueItems: number[]): number[] {
        const ids: number[] = [];

        Object.values(bundles).forEach((bundle) => {
            if (isAlreadyPurchased(purchasedLimitedBundles, deniedBundlesByUniqueItems, bundle)) {
                ids.push(bundle.id);
            }
        });

        return ids;
    }

    @authorizationRequired
    public isAvailableLootbox() {
        return this.getAccountLevel() >= settings.accountLevelingRestriction?.lootbox;
    }
}

export default new Account();
