import { items as ITEMS } from '@wg/wows-entities/const';
import { vehiclesIds } from '~/queries/vehicles';
import { crewsIds } from '~/queries/crews';
import { dollIds } from '~/queries/dolls';
import items from '~/queries/items';
import permoflages from '~/queries/permoflages';
import { lootbox } from '~/queries/lootbox';
import { DocumentNode } from 'graphql';

export const GRAPHQL_QUERIES_MAP: Record<string, DocumentNode> = {
    [ITEMS.VEHICLES]: vehiclesIds,
    [ITEMS.CREWS]: crewsIds,

    [ITEMS.BACKGROUND]: dollIds,
    [ITEMS.DOG_TAG]: dollIds,
    [ITEMS.EMBLEM]: dollIds,

    [ITEMS.FLAGS]: items,
    [ITEMS.SIGNAL]: items,

    [ITEMS.CAMO_BOOST]: items,
    [ITEMS.MULTI_BOOST]: items,
    [ITEMS.GLOBAL_BOOST]: items,

    [ITEMS.HOLD]: permoflages,
    [ITEMS.SKIN]: permoflages,
    [ITEMS.PERMOFLAGES]: permoflages,
    [ITEMS.STYLE]: permoflages,

    [ITEMS.CAMOUFLAGE]: items,
    [ITEMS.MODERNIZATION]: items,

    [ITEMS.LOOTBOX]: lootbox,
};
