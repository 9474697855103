import * as React from 'react';
import { State } from '~/Reducers';
import styles from './IframePopup.scss';
import { useDispatch } from 'react-redux';
import { changeVisiblePopup, setFetching } from '~/Actions/ActionApp';
import IframeProxy, { ON_ESC } from '~/api/IframeProxy';
import { ICurrentPage } from '~/Actions/ActionAppType';
import ButtonEsc from '~/components/ButtonEsc/ButtonEsc';
import { IChangeVisiblePopup, ISetFetching } from '~/Actions/ActionAppType';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import { playButtonClickSound } from '~/api/WoWsClient';
import { PopupManagerContext } from '~/Contexts/PopupManagerContext';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { MouseEvent } from 'react';

interface IPopupState {
    currentPage: ICurrentPage;
    categories: ICategories;
}

const stateSelector = (state: State): IPopupState => {
    return {
        currentPage: state.ReducerApp.currentPage,
        categories: state.ReducerApp.categories,
    };
};

const IframePopup = () => {
    const state = useSelector<State, IPopupState>(stateSelector, equal);
    const category = state.categories[state.currentPage?.name];
    const parentCategory = category?.parentCategoryData || category;

    const startTime = Date.now();
    const { popup, isPopupActive } = React.useContext(PopupManagerContext);
    const dispatch = useDispatch();
    const ref = React.useRef<HTMLIFrameElement>(null);
    const [isVisiblePopup, setVisiblePopup] = React.useState<boolean>(false);

    const close = () => {
        const endTime = Date.now();
        const duration = Math.floor((endTime - startTime) / 1000);
        dwhExport.send(DWH_EVENTS.CLOSE_INFORMER, {
            categoryName: parentCategory?.name,
            duration,
        });
        dispatch<IChangeVisiblePopup>(changeVisiblePopup(null));
        dispatch<ISetFetching>(setFetching(false));
    };

    const closeWithSound = () => {
        playButtonClickSound();
        close();
    };

    React.useEffect(() => {
        dispatch<ISetFetching>(setFetching(true));

        const _Proxy = new IframeProxy(
            dispatch,
            ref.current,
            () => {
                setVisiblePopup(true);
            },
            {
                [ON_ESC]: closeWithSound,
            },
        );

        _Proxy.listen();

        return () => {
            _Proxy.remove();
        };
    }, []);

    useKeyDown(() => {
        if (!isPopupActive) {
            return;
        }
        closeWithSound();
    }, [KEYS_CODE.ESC]);

    const setRef = (_ref: HTMLIFrameElement) => {
        if (_ref) {
            ref.current = _ref;
        }
    };

    const style = {
        display: isVisiblePopup ? 'block' : 'none',
    };

    if (!popup?.data?.iframeUrl) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.close}>
                <ButtonEsc onClick={close} />
            </div>
            <iframe src={popup.data.iframeUrl} ref={setRef} style={style} />
        </div>
    );
};

export default IframePopup;
