import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import filtersMap from '~/settings/filtersMap';
import { State } from '~/Reducers';
import { AppState } from '~/Reducers/ReducerApp';
import { flat, isMobileOrTabletWindow } from '~/utils/utils';
import { scrollTop } from '~/utils/scrollTop';
import { changeFilter, changeMultipleFilter, resetCategoryFilter, updateCategoryByFilters } from '~/Actions/ActionApp';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';
import styles from '~/components/CategoryFilter/CategoryFilter.scss';
import FiltersContent from '~/components/CategoryFilter/FiltersContent';
import { t } from '~/utils/localization';
import { IFilter } from '~/components/CategoryFilter/CategoryFilter';
import { IChangeFilter, IChangeMultipleFilter, IResetCategoryFilter } from '~/Actions/ActionAppType';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import Tag from '~/components/Tag/Tag';
import classNames from 'classnames';
import { FilterPosition } from '~/types/category';
import { playDropdownClickSound } from '~/api/WoWsClient';

interface IFilterPopup extends IFilter {
    onChangeVisible: () => void;
    category: ICategoryList;
    isDisplayDefault?: boolean;
    changeFilterPosition?: (position: FilterPosition) => void;
}

const FiltersPopup = React.forwardRef((props: IFilterPopup, ref: React.Ref<HTMLDivElement>) => {
    const dispatch = useDispatch();
    const filterNames = filtersMap[props.category];

    const onTouchEnd = (event: React.TouchEvent) => {
        event.preventDefault();
        props.onChangeVisible();
    };

    const reducerApp = useSelector((state: State): AppState => state.ReducerApp, shallowEqual);
    const filters: Record<string, string[]> = reducerApp.filters?.[props.category] || {};
    const [activeFiltersMap, setFilter] = React.useState<Record<string, string[]>>({});
    const state = { ...activeFiltersMap };
    const hasActiveFiltersValues = !!flat(Object.values(filters || {}))?.length;

    React.useEffect(() => {
        setFilter(filters);
    }, []);

    const onChangeFilter = (name: FILTER_INFO_NAME, value: string) => {
        if (!isMobileOrTabletWindow) {
            scrollTop(0, true);
        }

        dispatch<IChangeFilter>(changeFilter(props.category, name, value));
        // @ts-ignore
        dispatch(updateCategoryByFilters(props.category, name, value));

        dwhExport.send(DWH_EVENTS.FILTERS_APPLY, {
            category: props.category,
            name: name,
            value: value,
        });
    };

    const saveFilters = () => {
        if (Object.values(state).length > 0) {
            dispatch<IChangeMultipleFilter>(changeMultipleFilter(props.category, state));
            // @ts-ignore
            dispatch(updateCategoryByFilters(props.category));
        } else {
            dispatch<IResetCategoryFilter>(resetCategoryFilter(props.category));
        }

        props.onChangeVisible();
    };

    const Buttons = (
        <React.Fragment>
            {hasActiveFiltersValues && (
                <Tag
                    label={t('Сбросить все')}
                    tooltipStyles={!hasActiveFiltersValues && { cursor: 'initial' }}
                    onClick={() => {
                        scrollTop(0, true);
                        dispatch<IResetCategoryFilter>(resetCategoryFilter(props.category));
                    }}
                    classNames={{
                        tag: classNames(styles.buttonReset, {
                            [styles.buttonDisabled]: !hasActiveFiltersValues,
                        }),
                    }}
                    isActiveFiltersTag={true}
                    isApplyWrapperTag={true}
                />
            )}
            <Tag
                label={t('Применить')}
                onClick={props.onChangeVisible}
                classNames={{
                    tag: styles.buttonApply,
                }}
                isActiveFiltersTag={true}
                isApplyWrapperTag={true}
            />
        </React.Fragment>
    );

    return (
        <div className={styles.filterContainer} ref={ref}>
            {props.isDisplayDefault && (
                <DivTooltip className={styles.buttonPosition} tooltipBody={<DefaultTooltip text={t('Отображать фильтры сбоку')} />}>
                    <div
                        className={styles.filterColumnRight}
                        onClick={(event) => {
                            event.stopPropagation();
                            playDropdownClickSound();
                            props.changeFilterPosition?.(FilterPosition.RIGHT);
                        }}
                    />
                </DivTooltip>
            )}
            <div className={styles.filterContainerWrapper}>
                <div className={styles.popupClose} onClick={props.onChangeVisible} onTouchEnd={onTouchEnd} />
                {!!filterNames && <FiltersContent category={props.category} onChangeFilter={onChangeFilter} state={activeFiltersMap} />}
                {isMobileOrTabletWindow && <div className={styles.mobileButtonSaveFilters}>{Buttons}</div>}
            </div>
            <div className={styles.buttonApplyWrapper}>{Buttons}</div>
        </div>
    );
});

export default FiltersPopup;
