import store from '~/Store';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal';
import { State } from '~/Reducers';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { toggleNextRenderWithoutReset } from '~/Actions/ActionMrps';
import Account from '~/account/Account';
import dwhExport from '~/api/dwhExport';
import { isPortPreviewEnabled } from '~/utils/utils';
import { t } from '~/utils/localization';
import { openPortForMrpsReward } from '~/settings/port';
import { DWH_EVENTS } from '~/const';
import { playButtonClickSound } from '@wg/web2clientapi/sound';
import { PresentationStyles } from '@wg/wows-entities/const';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { POPUPS_NAME } from '~/components/PopupManager';

import styles from './MrpsMainReward.scss';
interface MrpsMainRewardProps {
    reward: MrpsLoadedReward;
}

interface StateSelector {
    isExchangeMenuOpen: MrpsExchangeMenuStatus;
    uniqueItemsInInventory: number | number[];
    eventName: string;
}

function stateSelector(state: State): StateSelector {
    return {
        isExchangeMenuOpen: state.reducerMrps.currentEvent.data.isExchangeMenuOpen,
        uniqueItemsInInventory: state.ReducerAccount.inventory?.uniqueItems,
        eventName: state.reducerMrps.currentEvent.eventName,
    };
}

export function MrpsMainReward(props: MrpsMainRewardProps) {
    const state = useSelector<State, StateSelector>(stateSelector, equal);
    const [isExistsItemInInventory, inventoryCount] = Account.getInventoryInfoByType(props.reward.entitlements[0].type, props.reward.entitlements[0].identifier);
    const rewardInfo = props.reward.mainEntitlementAdditionalData;
    const isUniqueItemByInventory =
        Array.isArray(state.uniqueItemsInInventory) &&
        state.uniqueItemsInInventory.some((id: number | number[]) => {
            if (typeof id === 'number') {
                return id === props.reward.entitlements[0].identifier;
            }

            if (Array.isArray(id)) {
                return id.includes(props.reward.entitlements[0].identifier);
            }

            return false;
        });
    const previewText = t('Просмотреть в порту');

    function onPortPreviewClick() {
        playButtonClickSound();
        store.dispatch(toggleNextRenderWithoutReset(true));
        openPortForMrpsReward(props.reward.mainEntitlementId, props.reward.mainEntitlementType);
    }

    function onRewardClick() {
        store.dispatch(changeVisiblePopup(POPUPS_NAME.SHIP_DESCRIPTION, true, props.reward));

        dwhExport.send(DWH_EVENTS.OPEN_DESCRIPTION_POPUP, { event_name: state.eventName });
    }

    return (
        <div className={styles.wrapper}>
            {isPortPreviewEnabled && state.isExchangeMenuOpen !== 1 && (
                <button className={styles.previewWrapper} onClick={onPortPreviewClick}>
                    <div className={styles.previewIcon} />
                    <div className={styles.previewText}>{previewText}</div>
                </button>
            )}
            <WoWSEntity
                type={props.reward.mainEntitlementType}
                id={props.reward.mainEntitlementId}
                presentation={{ style: PresentationStyles.INLINE, withTooltip: true }}
                customisation={{
                    inventoryCount: !rewardInfo?.isUnique && !isUniqueItemByInventory && inventoryCount,
                    isExistsInInventory: (rewardInfo?.isUnique || isUniqueItemByInventory) && isExistsItemInInventory,
                }}
                className={styles.rewardWrapper}
                onClick={onRewardClick}
            />
        </div>
    );
}
