import * as React from 'react';
import classNames from 'classnames';
import styles from '../common.scss';
import { getAmountString } from '~/Layouts/BundlePage/helpers';
import { items as ITEMS, PresentationStyles, items } from '@wg/wows-entities/const';
import { interpolate, t } from '~/utils/localization';
import Spoiler from '~/components/Spoiler/Spoiler';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { ManagerData } from '~/core/ManagerData';
import { getLevelFromRomanFormat } from '~/utils/utils';
import { InventoryCounterSize } from '~/components/InventoryCounter/InventoryCounter';
import InventoryCounterContainer from '~/containers/InventoryCounterContainer/InventoryCounterContainer';
import Account from '~/account/Account';
import { BUNDLE_TYPE } from '~/utils/bundles';

interface IBoostContent {
    item: IBundleEntity;
    quantity?: number;
}

export const getBoostType = (type: string) => {
    switch (type) {
        case items.GLOBAL_BOOST:
        case items.MULTI_BOOST:
            return t('Постоянный бонус');

        case items.CAMO_BOOST:
            return t('Расходуемый бонус');
    }
};

export const BoostHeaderContent = ({ item, quantity }: IBoostContent) => {
    const amount = item.amount * (quantity || 1);
    const text = getAmountString(amount, '<b>', '</b>');
    const type = getBoostType(item?.type);
    const shipId = item.customisation?.shipId;
    const [isExistsItemInInventory] = Account.getInventoryInfoByType(ITEMS.VEHICLES, shipId);
    const boostHandler = BUNDLE_TYPE[item.type];

    return (
        <React.Fragment>
            <div
                className={classNames(styles.flex, {
                    [styles.withShip]: shipId,
                })}
            >
                {!!type && <div className={styles.preBundleTitle}>{boostHandler(item)}</div>}
                {!!shipId && (
                    <div className={classNames(styles.preBundleTitle, styles.shipTitle)}>
                        <WoWSEntity
                            key={shipId}
                            type={ITEMS.VEHICLES}
                            id={shipId}
                            presentation={{
                                withTooltip: true,
                                hideInventoryIcon: true,
                            }}
                            customisation={{
                                isExistsInInventory: isExistsItemInInventory,
                            }}
                        />
                    </div>
                )}
                <div className={classNames(styles.preBundleTitle, styles.marginRight)} dangerouslySetInnerHTML={{ __html: text }} />
                {!shipId && <InventoryCounterContainer item={item} className={classNames(styles.preBundleTitle, styles.inventoryWidget)} size={InventoryCounterSize.FULL} />}
            </div>
        </React.Fragment>
    );
};

interface IBoostContent {
    item: IBundleEntity;
    renderSpoiler?: boolean;
}

const BoostContent = (props: IBoostContent) => {
    if (props.renderSpoiler) {
        return (
            <Spoiler
                title={
                    <div className={classNames(styles.contentAdditionalBlock)}>
                        <div className={styles.contentSpoilerTitle}>
                            <WoWSEntity key={props.item.identifier} type={props.item.type} id={props.item.identifier} presentation={{ withText: true, withoutAssetIcon: true }} />
                        </div>
                    </div>
                }
                content={
                    <div className={classNames(styles.contentBlock, styles.borderBottom, styles.spoilerContent)}>
                        <div className={styles.spoilerSubTitle}>{t('Характеристики: ')}</div>
                        <WoWSEntity type={props.item.type} id={props.item.identifier} className={styles.ttcText} presentation={{ style: PresentationStyles.TTC }} />
                    </div>
                }
            />
        );
    }

    const boostItem = ManagerData.getItem(props.item.identifier);
    const levelsInfo = (boostItem?.restrictions?.levels || []).map((level) => getLevelFromRomanFormat(level));
    const levelsInfoStr = interpolate(t('Может быть установлен на корабли {level} уровня'), { level: levelsInfo?.join('-') });

    return (
        <React.Fragment>
            {!!levelsInfo?.length && !props.item.customisation?.shipId && <div className={classNames(styles.contentBlock, styles.borderBottom, styles.boostAbout)}>{levelsInfoStr}</div>}
            <div className={styles.borderBottom}>
                <div className={classNames(styles.contentBlock, styles.borderBottom)}>
                    <div className={styles.contentBlockTitle}>{t('Характеристики: ')}</div>
                    <WoWSEntity type={props.item.type} id={props.item.identifier} className={styles.ttcText} presentation={{ style: PresentationStyles.TTC }} />
                </div>
            </div>
        </React.Fragment>
    );
};

export default BoostContent;
