import { State } from '~/Reducers';
import { useDispatch, useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { AUCTION_KIND, CATEGORIES, DWH_EVENTS } from '~/const';
import GuideDecorator from '~/decorators/GuideDecorator/GuideDecorator';
import { GUIDE_NAMES, PLACEMENT } from '~/components/WelcomePage/steps';
import HelperIcon from '~/components/HelperIcon/HelperIcon';
import { ICurrentPage } from '~/Actions/ActionAppType';
import dwhExport from '~/api/dwhExport';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '../PopupManager';
import { t } from '~/utils/localization';
import { CategoryLabelAboutTypes } from '~/types/category';
import store from '~/Store';
import DefaultTooltip from '../Tooltip/DefaultTooltip';
import { openUrl } from '~/utils/settings';
import LabelDecorator from '~/decorators/LabelDecorator/LabelDecorator';
import LabelContainer from '~/containers/LabelContainer/LabelContainer';
import styles from './GuideWrapper.scss';
import { getEventLabelIdFromCategory, isEventLabelEnabledForCategory } from '~/utils/labels';
import classNames from 'classnames';
import CategoryTimer from '../CategoryTimer/CategoryTimer';

interface IGuideWrapperState {
    activeAuctions: IAuction[];
    currentPage: ICurrentPage;
    menu: IMenuMap;
    categories: ICategories;
}

const stateSelector = (state: State): IGuideWrapperState => {
    return {
        currentPage: state.ReducerApp.currentPage,
        activeAuctions: state.ReducerAuction.activeAuctions,
        menu: state.ReducerApp.menu,
        categories: state.ReducerApp.categories,
    };
};

const GuideWrapper = () => {
    const dispatch = useDispatch();
    const state = useSelector<State, IGuideWrapperState>(stateSelector, equal);
    const currentPage = state.currentPage;
    const currentCategoryConfig = state.categories[currentPage?.name];
    const parentCategoryConfig = state.categories[currentCategoryConfig?.parentCategory] || currentCategoryConfig;
    const labelCategoryConfig = currentCategoryConfig?.label ? currentCategoryConfig : parentCategoryConfig;
    const label = labelCategoryConfig?.label;

    const isCurrentCategoryTimerAvailable = Boolean(currentCategoryConfig?.activityCountdown?.isEnabled && currentCategoryConfig?.activeTill);
    const isParentCategoryTimerAvailable = Boolean(parentCategoryConfig?.activityCountdown?.isEnabled && parentCategoryConfig?.activeTill);
    const isNeedToRenderTimer = isCurrentCategoryTimerAvailable || isParentCategoryTimerAvailable;
    const pageNameForTimer = (isCurrentCategoryTimerAvailable && currentCategoryConfig?.name) || (isParentCategoryTimerAvailable && parentCategoryConfig?.name) || undefined;

    if (!label || !label.value) {
        if (isNeedToRenderTimer) {
            return <CategoryTimer categoryName={pageNameForTimer} key={pageNameForTimer} />;
        }

        return null;
    }

    const auctions = state.activeAuctions;
    const auctionsKind = Array.from(new Set(auctions.map((a) => a.kind))).join(', ');

    const labelId = getEventLabelIdFromCategory(labelCategoryConfig);
    const isLabelEnabled = isEventLabelEnabledForCategory(labelCategoryConfig);

    if (currentPage.name === CATEGORIES.AUCTION && auctionsKind.includes(AUCTION_KIND.SECOND_PRICE)) {
        const onClick = () => {
            dwhExport.send(DWH_EVENTS.OPEN_INFORMER);
            dispatch(changeVisiblePopup(POPUPS_NAME.IFRAME_POPUP, true, { iframeUrl: label.value }));
        };
        return (
            <LabelDecorator id={labelId} isEnabled={isLabelEnabled} type={'event'} key={labelId}>
                <GuideDecorator names={[GUIDE_NAMES.guide_rules_of_category]} placement={PLACEMENT.CENTER} className={styles.wrapper}>
                    <HelperIcon
                        label={label.title || t('О событии')}
                        onClick={onClick}
                        className={classNames(styles.helperIcon, {
                            [styles.helperIcon_custom]: !currentCategoryConfig.hideTitle,
                        })}
                        tooltipBody={label.tooltipText && <DefaultTooltip text={label.tooltipText} />}
                    />
                </GuideDecorator>
            </LabelDecorator>
        );
    }

    const onClick = () => {
        switch (label.type) {
            case CategoryLabelAboutTypes.IFRAME:
                dwhExport.send(DWH_EVENTS.OPEN_INFORMER, {
                    categoryName: parentCategoryConfig?.name,
                });
                store.dispatch(changeVisiblePopup(POPUPS_NAME.IFRAME_POPUP, true, { iframeUrl: label.value }));
                break;

            case CategoryLabelAboutTypes.POPUP:
                if (Object.values(POPUPS_NAME).includes(label.value)) {
                    const popupName = label.value?.toUpperCase() as keyof typeof POPUPS_NAME;
                    store.dispatch(changeVisiblePopup(POPUPS_NAME[popupName], true));
                }
                break;

            case CategoryLabelAboutTypes.LINK:
                openUrl(label.value);
                break;
        }
    };

    return (
        <LabelDecorator id={labelId} isEnabled={isLabelEnabled} type={'event'} key={labelId}>
            <GuideDecorator names={[GUIDE_NAMES.guide_rules_of_category]} placement={PLACEMENT.CENTER} className={styles.wrapper}>
                <div className={styles.aboutWrapper}>
                    <HelperIcon
                        label={label.title || t('О cобытии')}
                        onClick={onClick}
                        className={classNames(styles.helperIcon, {
                            [styles.helperIcon_custom]: !!currentCategoryConfig.hideTitle,
                        })}
                        tooltipBody={<DefaultTooltip text={label.tooltipText ?? t('Читайте подробности о текущих предложениях и другую полезную информацию')} />}
                    />
                    <LabelContainer isVisible={isLabelEnabled} className={styles.labelNew} />
                </div>
                {isNeedToRenderTimer && (
                    <>
                        <div className={styles.verticalDivider} />
                        <CategoryTimer categoryName={pageNameForTimer} key={pageNameForTimer} />
                    </>
                )}
            </GuideDecorator>
        </LabelDecorator>
    );
};

export default GuideWrapper;
