import * as React from 'react';
import WelcomePageHelper from '~/components/WelcomePage/WelcomePageHelper';
import { t } from '~/utils/localization';
import { hasNewCoupons } from '~/utils/coupons';
import { arrayToObjectByKey, isInGameBrowser } from '~/utils/utils';
import { completeActions } from '~/api/account';
import store from '~/Store';
import { completeAction } from '~/Actions/ActionAccount';
import { armoryState } from '~/utils/settings';
import { Interpolate } from '@wg/wows-react-uikit';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { items } from '@wg/wows-entities/const';
import { getUnreadNotifications } from '~/utils/notifications';
import { isAuctionCategoryExists } from '~/utils/auction';
import { isEnableDockyard, isRealMoneyPurchaseDisabled } from '~/utils/category';
import { FilterPosition } from '~/types/category';
import AuctionStorage from '~/core/AuctionStorage';
import { CUSTOM_PAGE } from '~/Layouts/Themes/ThemeManager';
import { FEATURING_DESTINATION } from '~/types/contents';

export enum GUIDE_NAMES {
    guide_wallet = 'guide_wallet',
    guide_wallet_items = 'guide_wallet_items',
    guide_coupon = 'guide_coupon',
    guide_search = 'guide_search',
    guide_filters_and_presets = 'guide_filters_and_presets',
    guide_new_coupons = 'guide_new_coupons',
    guide_premshop = 'guide_premshop',
    guide_notifications = 'guide_notifications',
    guide_ship_port = 'guide_ship_port',
    guide_camouflage_port = 'guide_camouflage_port',
    guide_events = 'guide_events',
    guide_ny2021_volume = 'guide_ny2021_volume',
    guide_dockyard = 'guide_dockyard_',
    guide_auction = 'guide_auction',
    guide_rules_of_category = 'guide_rules_of_category',
    guide_crew_voice = 'guide_crew_voice',
    guide_trade_in_get_item = 'guide_trade_in_get_item',
    guide_trade_in_item = 'guide_trade_in_trade_item',
    guide_portal_company = 'guide_portal_company',
}

export enum PLACEMENT {
    TOP = 'top',
    BOTTOM = 'bottom',
    LEFT = 'left',
    RIGHT = 'right',
    CENTER = 'center',
}

export interface IGuideStepLogic {
    content?: string | React.ReactChild;
    isNeedToShown?: boolean | (() => void);
    afterShown?: boolean | (() => void);
}

export interface IGuideStep extends IGuideStepLogic {
    name: GUIDE_NAMES;
    beforeShown?: () => void;
    title?: string;
    placement: PLACEMENT;
    duration?: number;
    delay?: number;
    isQueue?: boolean;
    onlyCategories?: string[];
    checkVisibleInViewPort?: boolean;
    closeAfterScroll?: boolean;
}

const filterIsRight = window.metashop?.state?.account?.filtersSettings?.position === FilterPosition.RIGHT;

export const getSteps = (): IGuideStep[] => {
    const appData = store?.getState().ReducerApp;
    const dockyardData = store?.getState().ReducerDockyard;

    return [
        {
            name: GUIDE_NAMES.guide_ny2021_volume,
            title: t('Включить звук'),
            content: null,
            placement: PLACEMENT.CENTER,
            isQueue: true,
            delay: 2,
            isNeedToShown: () => false,
            afterShown: function () {
                store.dispatch(completeAction(this.name));
                completeActions([this.name]);
            },
        },
        {
            name: GUIDE_NAMES.guide_wallet_items,
            title: t('Ресурсы и валюты'),
            content: t('Приобретайте имущество, используя накопленные вами ресурсы и валюты.'),
            placement: PLACEMENT.CENTER,
            isQueue: true,
            isNeedToShown: () => WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_wallet_items),
            afterShown: () => {
                store.dispatch(completeAction(GUIDE_NAMES.guide_wallet_items));
                completeActions([GUIDE_NAMES.guide_wallet_items]);
            },
        },
        {
            name: GUIDE_NAMES.guide_wallet,
            title: t('Кошелёк'),
            content: t('Здесь собраны все доступные ресурсы и валюты. Получить их можно из ежедневных контейнеров, за выполнение боевых задач и другие достижения.'),
            placement: PLACEMENT.CENTER,
            isQueue: true,
            delay: 5,
            isNeedToShown: () => WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_wallet),
            afterShown: function () {
                store.dispatch(completeAction(this.name));
                completeActions([this.name]);
            },
        },
        {
            name: GUIDE_NAMES.guide_coupon,
            title: t('Купоны'),
            content: t('Приобретайте корабли и другое имущество, используя купоны. Большинство купонов регулярно обновляются.'),
            placement: PLACEMENT.BOTTOM,
            isQueue: true,
            delay: 5,
            isNeedToShown: () => WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_coupon),
            afterShown: function () {
                store.dispatch(completeAction(this.name));
                completeActions([this.name]);
            },
        },
        {
            name: GUIDE_NAMES.guide_search,
            title: t('Поиск'),
            content: t('Воспользуйтесь поиском. Это быстрее и удобнее, если вы знаете что ищете.'),
            placement: PLACEMENT.BOTTOM,
            isQueue: true,
            delay: 15,
            isNeedToShown: () => WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_search),
            afterShown: function () {
                store.dispatch(completeAction(this.name));
                completeActions([this.name]);
            },
        },
        {
            name: GUIDE_NAMES.guide_filters_and_presets,
            title: t('Фильтры и подборки'),
            content: t('Облегчают поиск и выбор имущества внутри категории.'),
            placement: filterIsRight ? PLACEMENT.LEFT : PLACEMENT.CENTER,
            isQueue: true,
            delay: 5,
            isNeedToShown: () => WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_filters_and_presets),
            afterShown: function () {
                store.dispatch(completeAction(this.name));
                completeActions([this.name]);
            },
        },
        {
            name: GUIDE_NAMES.guide_new_coupons,
            title: t('Обновлённые купоны'),
            content: t('Вы снова можете воспользоваться скидками. Большинство купонов регулярно обновляются.'),
            placement: PLACEMENT.BOTTOM,
            isQueue: true,
            isNeedToShown: () => {
                return WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_new_coupons) && hasNewCoupons();
            },
            afterShown: function () {
                store.dispatch(completeAction(this.name));
                completeActions([this.name]);
            },
        },
        {
            name: GUIDE_NAMES.guide_premshop,
            title: t('Дублоны и премиум'),
            content: window.metashop.settings?.isAvailableRebrandingForCIS
                ? t('Приобретайте корабельный премиум аккаунт, а также дублоны — специальную валюту игры.')
                : t('Приобретайте корабельный премиум аккаунт, а также дублоны — специальную валюту World of Warships.'),
            placement: PLACEMENT.RIGHT,
            isQueue: true,
            isNeedToShown: () => {
                return !isRealMoneyPurchaseDisabled() && WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_premshop);
            },
            afterShown: function () {
                store.dispatch(completeAction(this.name));
                completeActions([this.name]);
            },
        },
        {
            name: GUIDE_NAMES.guide_notifications,
            title: t('Уведомления'),
            content: t('Не пропустите информацию об обновлениях, акциях в Адмиралтействе и другие важные новости.'),
            placement: PLACEMENT.CENTER,
            isQueue: true,
            isNeedToShown: () => {
                return WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_notifications) && getUnreadNotifications();
            },
            afterShown: function () {
                store.dispatch(completeAction(this.name));
                completeActions([this.name]);
            },
        },
        {
            name: GUIDE_NAMES.guide_events,
            title: t('Предметы события'),
            content: t('Приобретайте предметы нового события. Товары этой категории доступны в течение ограниченного времени.'),
            placement: PLACEMENT.RIGHT,
            isQueue: true,
            isNeedToShown: () => {
                const havEventsCategory = armoryState.content.categories?.find((category) => category.name?.includes?.('event'));
                return havEventsCategory && WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_events);
            },
            afterShown: function () {
                store.dispatch(completeAction(this.name));
                completeActions([this.name]);
            },
        },
        {
            name: GUIDE_NAMES.guide_rules_of_category,
            title: t('Подробная информация'),
            content: t('Читайте подробности о текущих предложениях и другую полезную информацию'),
            placement: PLACEMENT.CENTER,
            isQueue: true,
            isNeedToShown: () => {
                const havGuideRules = armoryState.content.categories?.find((category) => !!category.label?.type);
                return havGuideRules && WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_rules_of_category);
            },
            afterShown: function () {
                store.dispatch(completeAction(this.name));
                completeActions([this.name]);
            },
        },
        {
            /**
             * TODO: make 'content' dynamic
             * TODO: rework show logic
             */
            name: GUIDE_NAMES.guide_portal_company,
            title: t('Портальная кампания'),

            content: t('Путешествие по России начинается здесь!'),
            placement: PLACEMENT.RIGHT,
            isQueue: true,
            isNeedToShown: () => {
                const featureContentBlock = appData.featuring?.find((featureInfo) => {
                    return featureInfo?.destination === FEATURING_DESTINATION.CATEGORY && appData.categories?.[featureInfo?.destinationId]?.theme === CUSTOM_PAGE;
                })?.contentBlocks?.[0] as IContentBlock;

                return WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_portal_company) && featureContentBlock;
            },
            afterShown: function () {
                store.dispatch(completeAction(GUIDE_NAMES.guide_portal_company));
                completeActions([GUIDE_NAMES.guide_portal_company]);
            },
        },
        {
            name: GUIDE_NAMES.guide_ship_port,
            title: t('Предварительный просмотр корабля'),
            content: t('Вы можете посмотреть, как выглядит этот корабль в порту.'),
            placement: PLACEMENT.CENTER,
            isQueue: true,
            checkVisibleInViewPort: true,
            isNeedToShown: () => isInGameBrowser && WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_ship_port),
            afterShown: function () {
                store.dispatch(completeAction(this.name));
                completeActions([this.name]);
            },
        },
        {
            name: GUIDE_NAMES.guide_camouflage_port,
            title: t('Предварительный просмотр камуфляжа'),
            content: t('Вы можете посмотреть как выглядит этот камуфляж на корабле в порту.'),
            placement: PLACEMENT.CENTER,
            isQueue: true,
            checkVisibleInViewPort: true,
            isNeedToShown: () => isInGameBrowser && WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_camouflage_port),
            afterShown: function () {
                store.dispatch(completeAction(this.name));
                completeActions([this.name]);
            },
        },
        {
            name: GUIDE_NAMES.guide_crew_voice,
            title: t('Озвучка командира'),
            content: t('Теперь вы можете прослушать случайную реплику любого командира с оригинальной озвучкой!'),
            placement: PLACEMENT.CENTER,
            isQueue: true,
            checkVisibleInViewPort: true,
            isNeedToShown: () => isInGameBrowser && WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_crew_voice),
            afterShown: function () {
                store.dispatch(completeAction(this.name));
                completeActions([this.name]);
            },
        },
        {
            name: GUIDE_NAMES.guide_auction,
            title: t('Аукцион'),
            content: t('Не упустите возможность получить в своё распоряжение уникальное имущество и ресурсы по хорошей цене!'),
            placement: PLACEMENT.RIGHT,
            isQueue: true,
            delay: 1,
            isNeedToShown: () => isAuctionCategoryExists() && AuctionStorage.isAuctionCategoryEnabled && WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_auction),
            afterShown: function () {
                store.dispatch(completeAction(this.name));
                completeActions([this.name]);
            },
        },
        {
            name: GUIDE_NAMES.guide_dockyard,
            title: t('Верфь: Стартовые наборы'),
            placement: PLACEMENT.RIGHT,
            isQueue: true,
            delay: 2,
            content: (
                <Interpolate
                    str={t('Приобретайте Стартовые наборы со скидкой, чтобы сразу получить награды и завершить необходимые этапы для постройки %(ship)s.')}
                    ship={
                        <WoWSEntity
                            type={items.VEHICLES}
                            id={dockyardData?.shipId || 0}
                            className={'dockyard-guide-ship'}
                            presentation={{
                                hideNationIcon: true,
                                hideTypeIcon: true,
                            }}
                        />
                    }
                />
            ),
            isNeedToShown: () => {
                return WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_dockyard + dockyardData?.season) && isEnableDockyard() && !dockyardData?.isStartersBought;
            },
            afterShown: function () {
                store.dispatch(completeAction(this.name + dockyardData?.season));
                completeActions([this.name + dockyardData?.season]);
            },
        },
        {
            name: GUIDE_NAMES.guide_trade_in_item,
            title: t('Корабль для обмена'),
            content: t('Выберите корабль, который вы хотите обменять.'),
            placement: PLACEMENT.TOP,
            isQueue: true,
            delay: 0.5,
            closeAfterScroll: true,
            isNeedToShown: () => WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_trade_in_item) && store.getState().ReducerTradeIn.ts,
            afterShown: function () {
                store.dispatch(completeAction(this.name));
                completeActions([this.name]);
            },
        },
        {
            name: GUIDE_NAMES.guide_trade_in_get_item,
            title: t('Корабль для получения'),
            content: t('Выберите корабль, который вы хотите получить.'),
            placement: PLACEMENT.TOP,
            isQueue: true,
            delay: 0.5,
            closeAfterScroll: true,
            isNeedToShown: () => WelcomePageHelper.isNeedToShownStep(GUIDE_NAMES.guide_trade_in_get_item) && store.getState().ReducerTradeIn.ts,
            afterShown: function () {
                store.dispatch(completeAction(this.name));
                completeActions([this.name]);
            },
        },
    ];
};

export const getStepsFromGuide = (_steps?: IGuideStep[]): IGuideStep[] => {
    if (!_steps?.length) _steps = getSteps();
    return _steps.filter((step) => {
        if (!step.isQueue) {
            return false;
        }

        if (typeof step.isNeedToShown === 'function') {
            return step.isNeedToShown();
        }

        return step.isNeedToShown;
    });
};

export const getStepByName = (name: string) => {
    const steps = getSteps();
    return arrayToObjectByKey(steps, 'name')[name];
};
