import { interpolate, ngettext, t } from '~/utils/localization';
import { flatRewards, GroupedRewardsTypes } from '~/Layouts/Lootboxes/utils/index';
import { CommonRewardType } from '~/types/bundle';

export function getTextAboutContinuousRewardsBySlot(lootbox: ILootboxStateItem, slotIndex: number) {
    const defaultString = t(
        'Если у вас уже есть все предметы одной из групп уникальных предметов то вероятность её выпадения распределяется между предметами остальных групп уникальных предметов, которых ещё нет в вашей коллекции.',
    );
    return [defaultString];
}

export function getTextAboutContinuousRewardsByGroup(groupTitle: string) {
    const defaultString = t(
        'Если у вас уже есть все предметы группы «{group}», то вероятность ее выпадения распределяется между предметами оставшихся групп этого слота, которых еще нет в вашей коллекции.',
    );

    return interpolate(defaultString, { group: groupTitle });
}

export function getTextsTooltipAboutGroup() {
    const texts = [t('Вероятность получить конкретную награду из группы одинакова для всего имущества в группе.')];

    return texts;
}

export function getTextAboutSavePoint(lootbox: ILootboxStateItem) {
    const { savePoint, savePointForValuableGroup } = lootbox;
    const strSavePoint = (lootbox.savePointForValuableGroup?.savePoint || savePoint).toString();
    const lastNumber = strSavePoint.substr(strSavePoint.length - 1, 1);

    let stringWithValuableGroup = t('Награда из группы «{group_name}» выпадет из {save_point} контейнера, если этого не произошло в предыдущих {count_lootbox}. %(icon)s');
    if (lastNumber === '2') {
        stringWithValuableGroup = t('2:Награда из группы «{group_name}» выпадет из {save_point} контейнера, если этого не произошло в предыдущих {count_lootbox}. %(icon)s');
    }

    const textWithValuableGroup = interpolate(stringWithValuableGroup, {
        group_name: savePointForValuableGroup?.groupTitle,
        save_point: savePointForValuableGroup?.savePoint,
        count_lootbox: savePointForValuableGroup?.savePoint - 1,
    });

    const textForAnyValuableGroup = interpolate(t('Награда из группы уникальных предметов выпадет из {save_point} контейнера, если этого не произошло в предыдущих {count_lootbox}. %(icon)s'), {
        save_point: savePoint,
        count_lootbox: savePoint - 1,
    });

    let defaultString = t('Вы гарантированно получите уникальный предмет из {save_point} контейнера, если этого не произошло при открытии предыдущих {count_lootbox}. %(icon)s');
    if (lastNumber === '2') {
        defaultString = t('2:Вы гарантированно получите уникальный предмет из {save_point} контейнера, если этого не произошло при открытии предыдущих {count_lootbox}. %(icon)s');
    }

    if (savePointForValuableGroup?.savePoint) {
        return interpolate(textWithValuableGroup, {
            group_name: savePointForValuableGroup.groupTitle,
            save_point: savePointForValuableGroup.savePoint,
            count_lootbox: savePointForValuableGroup.savePoint - 1,
        });
    }

    return interpolate(defaultString, {
        save_point: savePoint,
        count_lootbox: savePoint - 1,
    });
}

export function getTextAboutSavePointForTooltip(lootbox: ILootboxStateItem) {
    const { savePoint, savePointForValuableGroup } = lootbox;

    if (savePointForValuableGroup?.savePoint) {
        return interpolate(
            t('При открытии {save_point} контейнера вы гарантированно найдёте награду из группы «{group_name}", если этого не произошло после открытия {count} контейнеров «{container}».'),
            {
                group_name: savePointForValuableGroup.groupTitle,
                save_point: savePointForValuableGroup.savePoint,
                count: savePointForValuableGroup.savePoint - 1,
                container: lootbox.shortTitle,
            },
        );
    }

    return interpolate(
        t('При открытии {save_point} контейнера вы гарантированно найдёте награду из группы уникальных предметов, если этого не произошло при открытии предыдущих {count} контейнеров «{container}».'),
        {
            save_point: savePoint,
            count: savePoint - 1,
            container: lootbox.shortTitle,
        },
    );
}

export function getShortTextAboutSavePointForTooltip(lootbox: ILootboxStateItem) {
    const { shortTitle, savePoint, savePointForValuableGroup, uniqueGroupTitles } = lootbox;
    const strSavePoint = (lootbox.savePointForValuableGroup?.savePoint || savePoint).toString();
    const lastNumber = strSavePoint.substr(strSavePoint.length - 1, 1);

    let firstString = t(
        'Для контейнера «{container}» действует механизм гарантированного выпадения. При открытии {save_point} контейнера этого типа вы гарантированно найдёте награду одной из групп уникальных предметов, если этого не произошло при открытии предыдущих {count} контейнеров «{container}»',
    );
    if (lastNumber === '2') {
        firstString = t(
            '2:Для контейнера «{container}» действует механизм гарантированного выпадения. При открытии {save_point} контейнера этого типа вы гарантированно найдёте награду одной из групп уникальных предметов, если этого не произошло при открытии предыдущих {count} контейнеров «{container}»',
        );
    }

    let secondString = t(
        'Подсчёт гарантированного выпадения групп сбрасывается при выпадении награды из одной из них. Вероятность выпадения предметов указана без учёта этого механизма, так как он просто «заставляет» определённую награду выпасть несмотря на случайность.',
    );

    if (lootbox.savePointForValuableGroup?.savePoint) {
        firstString = t(
            'Для контейнера «{container}» действует механизм гарантированного выпадения. При открытии {save_point} контейнера этого типа вы гарантированно найдёте награду из группы уникальных предметов, если этого не произошло при открытии предыдущих {count} контейнеров «{container}».',
        );
        if (lastNumber === '2') {
            firstString = t(
                '2:Для контейнера «{container}» действует механизм гарантированного выпадения. При открытии {save_point} контейнера этого типа вы гарантированно найдёте награду из группы уникальных предметов, если этого не произошло при открытии предыдущих {count} контейнеров «{container}».',
            );
        }
        secondString = t(
            'Подсчёт гарантированного выпадения определённой группы сбрасывается при выпадении награды из неё. Вероятность выпадения наград указана без учёта этого механизма, так как он просто «заставляет» определённую награду выпасть несмотря на случайность.',
        );
    }

    if (!savePoint && !savePointForValuableGroup?.isUniqueGroup) {
        return [secondString];
    }

    firstString = interpolate(firstString, {
        container: shortTitle,
        save_point: savePoint || savePointForValuableGroup?.savePoint,
        count: (savePoint || savePointForValuableGroup?.savePoint) - 1,
    });

    return [firstString, secondString];
}

export function getStringForFullItemsListByGroup(type: CommonRewardType, count: number) {
    const shipStr = interpolate(ngettext('Всего {count} корабль', 'Всего {count} корабля', count), { count });
    const camoStr = interpolate(ngettext('Всего {count} камуфляж', 'Всего {count} камуфляжа', count), { count });
    const crewStr = interpolate(ngettext('Всего {count} командир', 'Всего {count} командира', count), { count });
    const defaultStr = interpolate(ngettext('Всего {count} предмет', 'Всего {count} предмета', count), { count });

    switch (type) {
        case CommonRewardType.SHIP:
            return shipStr;

        case CommonRewardType.CAMO:
            return camoStr;

        case CommonRewardType.CREW:
            return crewStr;

        default:
            return defaultStr;
    }
}

export function getSubTitleForCommonLayout(container: ILootboxStateItem) {
    const slotCount = container.slots.length;
    const containerTitle = !!container.shortTitle?.trim()?.length ? container.shortTitle : container.title;
    const stringForOnlyCommonGroupWithAnyRewards = interpolate(t('Каждый контейнер «{container}» содержит один предмет из списка'), {
        container: containerTitle,
    });
    const stringForOnlyCommonGroupWithOneReward = interpolate(t('Каждый контейнер «{container}» содержит'), {
        container: containerTitle,
    });
    const map: Record<number, string> = {
        1: t('Каждый контейнер «{container}» содержит один предмет.'),
        2: t('Каждый контейнер «{container}» содержит два предмета.'),
        3: t('Каждый контейнер «{container}» содержит три предмета.'),
        4: t('Каждый контейнер «{container}» содержит четыре предмета.'),
        5: t('Каждый контейнер «{container}» содержит пять предметов.'),
    };
    const subTitleDefault = interpolate(ngettext('Каждый контейнер «{container}» содержит {count} предмет.', 'Каждый контейнер «{container}» содержит {count} предмета.', slotCount), {
        count: slotCount,
        container: containerTitle,
    });

    if (!container.hasValuableRewards && slotCount === 1) {
        const arrayRewards = flatRewards(container.slots[0].commonRewards);
        if (arrayRewards.length > 1) {
            return stringForOnlyCommonGroupWithAnyRewards;
        }
        return stringForOnlyCommonGroupWithOneReward;
    }

    if (map[slotCount]) {
        return interpolate(map[slotCount], {
            container: containerTitle,
        });
    }

    return subTitleDefault;
}

export function getTitleForPreviewRewards(count: number, containerName?: string) {
    const map: Record<number, string> = {
        1: t('Контейнер содержит один предмет:'),
        2: t('Контейнер содержит два предмета:'),
        3: t('Контейнер содержит три предмета:'),
        4: t('Контейнер содержит четыре предмета:'),
        5: t('Контейнер содержит пять предметов:'),
    };

    const defaultStr = interpolate(ngettext('Контейнер содержит {count} предмет:', 'Контейнер содержит {count} предмета:', count), {
        count,
    });

    return map[count] || defaultStr;
}

export const commonRewardsTypesString = {
    [GroupedRewardsTypes.BATTLE_SIGNAL]: t('Один из боевых сигналов x{amount}'),
    [GroupedRewardsTypes.SIGNAL]: t('Один из сигналов x{amount}'),
    [GroupedRewardsTypes.ECO_SIGNAL]: t('Один из экономических сигналов x{amount}'),
    [GroupedRewardsTypes.SPECIAL_SIGNAL]: t('Один из особых сигналов x{amount}'),
    [GroupedRewardsTypes.CAMOUFLAFE]: t('Один из камуфляжей x{amount}'),
};

export const groupedRewardsTextForTooltip = t('Один из списка:');
