import * as React from 'react';
import classNames from 'classnames';
import styles from './ViewBackground.scss';
import equal from 'fast-deep-equal/react';
import { useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { isEmptyObject } from '~/utils/utils';
import { settings } from '~/utils/settings';
import { ICurrentPage } from '~/Actions/ActionAppType';
import VideoBackground from '~/components/VideoBackground/VideoBackground';
import { useAvailableSound } from '~/hooks/useAvailableSound';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade } from 'swiper/modules';
import type { Swiper as SwiperInstance } from 'swiper/types';

interface IStateSelector {
    isTrusted: boolean;
    bundles: IBundleList;
    port: IPort;
    currentPage: ICurrentPage;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        port: state.ReducerApp.port,
        isTrusted: state.ReducerApp.isTrusted,
        bundles: state.ReducerApp.bundles,
        currentPage: state.ReducerApp.currentPage,
    };
};

interface IGalleryViewBackground {
    isBlur: boolean;
    bundle: IBundle;
    category: ICategory;
}

const GalleryViewBackground = ({ isBlur, bundle, category }: IGalleryViewBackground) => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    let currentBundle = bundle;
    if (bundle?.parentBundleId) {
        currentBundle = state.bundles[bundle.parentBundleId];
    }
    const [swiper, setSwiper]: [SwiperInstance, (sw: SwiperInstance) => void] = React.useState(null);

    const [isBlurView, setBlur] = React.useState(false);
    const isAvailableSoundEffect = useAvailableSound(category, currentBundle);
    let muted = !isAvailableSoundEffect;
    if (!state.isTrusted) {
        muted = true;
    }

    React.useEffect(() => {
        setBlur(isBlur);
    }, [isBlur]);

    React.useEffect(() => {
        swiper?.slideTo(state.currentPage?.bgGallerySlideIndex);
    }, [state.currentPage]);

    if (state.port?.isVisible) {
        return null;
    }

    const backgroundColor = bundle?.backgroundColor || category?.backgroundColor;
    const hasBackgroundMask = !!backgroundColor;
    const style = !!backgroundColor ? ({ '--bundle-gallery-background-color': backgroundColor } as React.CSSProperties) : {};

    const classesWrapper = classNames(styles.galleryBackgroundWrapper, {
        [styles.blur]: isBlurView,
        [styles.galleryWithMask]: hasBackgroundMask,
    });

    const gallery = !!bundle.gallery?.length ? bundle.gallery : [];

    return (
        <div className={classesWrapper} style={style}>
            <Swiper
                onSwiper={(swiper: SwiperInstance) => {
                    setSwiper(swiper);
                }}
                className={styles.galleryBackgroundSlider}
                slidesPerView={1}
                initialSlide={state.currentPage?.bgGallerySlideIndex || 0}
                updateOnWindowResize={true}
                effect="fade"
                modules={[EffectFade]}
            >
                {gallery.map((_, index) => {
                    if (!gallery[index].video || isEmptyObject(gallery[index].video)) {
                        const itemStyles = {
                            backgroundImage: `url(${gallery[index].image})`,
                        };
                        return <SwiperSlide className={styles.galleryBackgroundImage} style={itemStyles} key={`slide_${index}`} />;
                    }

                    return (
                        <SwiperSlide key={`slide_${index}`}>
                            <VideoBackground
                                muted={(state.currentPage?.bgGallerySlideIndex || 0) !== index || muted}
                                poster={gallery[index].image}
                                video={gallery[index].video}
                                className={classNames(state.currentPage?.name, styles[state.currentPage?.name], styles[settings.realm], {
                                    [styles.videoBackground]: !!backgroundColor,
                                })}
                                withMask={!!backgroundColor}
                            >
                                {!!backgroundColor && <div className={styles.videoBackgroundMask}></div>}
                            </VideoBackground>
                        </SwiperSlide>
                    );
                })}
                <div className={classNames(styles.backgroundMask, { [styles.visibleMask]: hasBackgroundMask })} />
            </Swiper>
        </div>
    );
};

export default React.memo(GalleryViewBackground);
