import styles from '~/components/GiftPromo/GiftPromo.scss';
import ButtonEsc from '~/components/ButtonEsc/ButtonEsc';
import Attention from '~/components/Attention/Attention';
import { t } from '~/utils/localization';
import { Button, ButtonColorPresets } from '~/components/Button/Buttons';
import classNames from 'classnames';
import * as React from 'react';
import { State } from '~/Reducers';
import { useDispatch, useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { isSafari, redirectToLogin } from '~/utils/utils';
import { markShownWelcomeGiftScreen, removeGift, takeGift } from '~/utils/gift';
import { updateBrowserControlState } from '@wg/web2clientapi/browser/updateBrowserControlState';
import useKeyDown, { KEYS_CODE } from '~/hooks/useKeyDown';
import { playButtonClickSound } from '~/api/WoWsClient';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import { PopupManagerContext } from '~/Contexts/PopupManagerContext';
import { logError } from '~/utils/logging';
import VideoBackground from '~/components/VideoBackground/VideoBackground';
import { SANTA_GIFT_VIDEO, SANTA_SECRET_GIFT_VIDEO } from '~/Layouts/Themes/SantaPage/settings';
import { CATEGORIES, SECRET_SANTA_GIFT_NAME } from '~/const';
import { useIsDisabledAnimation } from '~/hooks/isDisabledAnimation';
import dwhExport from '~/api/dwhExport';
import { DWH_EVENTS } from '~/const';

interface IStateSelector {
    accountId: number;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        accountId: state.ReducerAccount.id,
    };
};

const INITIAL = 0;
const IN_PROGRESS = 1;
const ERROR = 2;
const SUCCESS = 3;

export default function GiftPopup() {
    const dispatch = useDispatch();
    const { popup } = React.useContext(PopupManagerContext);
    const currentGift = popup.data?.gift as IGift;
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const isGuest = !state.accountId;
    const [status, setStatus] = React.useState<number>(INITIAL);
    const isSanta = currentGift.name.includes('ny_gift_armory') || currentGift.name === SECRET_SANTA_GIFT_NAME;
    const isDisabledAnimation = useIsDisabledAnimation(isSanta ? CATEGORIES.SANTA_CONTAINERS : '');
    const startTime = Date.now();

    useKeyDown(() => {
        if (status === ERROR || status === SUCCESS) {
            playButtonClickSound();
            close();
        }
    }, [KEYS_CODE.ESC]);

    const takeThisGift = async () => {
        const endTime = Date.now();
        const stopTime = Math.floor((endTime - startTime) / 1000);

        dwhExport.send(DWH_EVENTS.OPEN_GIFT, {
            giftName: currentGift.name,
            timeSpent: stopTime,
        });
        if (isGuest) {
            redirectToLogin();
            return;
        }

        setStatus(IN_PROGRESS);
        const result = await takeGift(currentGift);

        removeGift(currentGift);
        if (!result) {
            setStatus(ERROR);
            return;
        }

        setStatus(SUCCESS);
        setTimeout(close, 3000);
    };

    const close = () => {
        if (isGuest) {
            markShownWelcomeGiftScreen(currentGift.name);
        } else {
            updateBrowserControlState(false);
        }

        dispatch(changeVisiblePopup(POPUPS_NAME.GIFT_ACCEPT, false));
        setStatus(INITIAL);
    };

    if (!currentGift) {
        logError('GiftPopup has no gift');
        return null;
    }

    const label = status === IN_PROGRESS ? t('Обработка') : t('Забрать подарок');

    const buttonLabel = currentGift.buttonLabel || label;
    const backgroundColor = currentGift.buttonBackgroundColor;
    const textColor = currentGift.buttonTextColor;

    const isDisabled = status !== INITIAL && status !== ERROR;

    const staticBg = currentGift.icons.innerBackground;

    let video = currentGift.videoBackground;

    // for santa
    if (currentGift.name.includes('ny_gift_armory')) video = SANTA_GIFT_VIDEO;
    if (currentGift.name === SECRET_SANTA_GIFT_NAME) video = SANTA_SECRET_GIFT_VIDEO;

    const withVideo = video && !isDisabledAnimation && !isSafari;

    return (
        <div className={classNames(styles.giftPromo, { [styles.santa]: isSanta, [styles.withVideo]: withVideo })} style={withVideo ? {} : { backgroundImage: `url(${staticBg})` }}>
            {withVideo && <VideoBackground video={video} poster={staticBg} className={styles.videoBackground} disableLoop showPosterOnTheEnd hideStaticBackground isInPopup />}
            {(isGuest || status === ERROR || status === SUCCESS) && (
                <div className={styles.close}>
                    <ButtonEsc onClick={close} />
                </div>
            )}
            <div className={styles.content}>
                <div className={styles.main}>
                    <div className={styles.title}>{currentGift.title}</div>
                    <div className={styles.description} dangerouslySetInnerHTML={{ __html: currentGift.description }} />
                    <div className={styles.status}>
                        {status === ERROR && <Attention label={t('Произошла ошибка. Повторите попытку или заберите подарок позднее.')} level={'error'} className={styles.attention} />}
                        {status === SUCCESS && <Attention label={t('Подарок успешно получен')} level={'done'} className={styles.attention} />}
                    </div>
                    {status !== SUCCESS && (
                        <div className={styles.buttonWrapper}>
                            <Button
                                animationColor={backgroundColor || '#b23d03'}
                                label={buttonLabel}
                                onClick={takeThisGift}
                                isEnabledAnimation={true}
                                disabled={isDisabled}
                                className={classNames('armory__auto--gift_button', styles.button, styles.commonButton)}
                                backgroundColor={backgroundColor || ButtonColorPresets.ORANGE}
                                textColor={textColor}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
