import * as React from 'react';
import styles from '../Port.scss';
import AbstractPort from '~/components/Port/AbstractPort';
import AuctionLotHeader from '~/components/Auction/AuctionHeader';
import { t } from '~/utils/localization';
import { ManagerData } from '~/core/ManagerData';
import Contains, { IContainsItem } from '~/components/ItemContains/Contains';
import { setScrollover } from '~/api/WoWsClient';
import { isEnabledPortFromAuction } from '~/settings/port';

export default class AuctionLotPort extends AbstractPort {
    textButtonBack = t('Описание лота');

    isAvailableContainsWidget = true;

    protected renderTitle(): React.ReactElement {
        return (
            <div className={styles.title}>
                <AuctionLotHeader {...this.props.lot} />
            </div>
        );
    }

    protected getCamoTitle(): string {
        if (this.props.exteriorId < 0) {
            return null;
        }

        const camoTitle = ManagerData.getPermoflage(this.props.exteriorId)?.title || ManagerData.getItem(this.props.exteriorId)?.title;
        return camoTitle || this.props.lot.title;
    }

    protected renderContainsContent(): React.ReactChild {
        if (!this.isAvailableContainsWidget || !this.state.containsItems) {
            return null;
        }

        const items = this.formItems();
        let limit = 0;
        items.map((item) => {
            if (isEnabledPortFromAuction(item.type, Number(item.id), this.props.lot)) limit += 1;
        });

        return (
            <div
                className={styles.contains}
                onMouseEnter={() => {
                    setScrollover(true);
                }}
                onMouseLeave={() => {
                    setScrollover(false);
                }}
                ref={(ref) => {
                    this.setRef(ref);
                }}
            >
                <Contains items={items} limit={limit} isLarge={true} />
            </div>
        );
    }

    protected renderNoticeContent(): React.ReactElement {
        return <div className={styles.notice}>{t('Может быть получен при победе в аукционе')}</div>;
    }
}

export const getTotalCountPreviewItemsForAuction = (items: IContainsItem[]) => {
    return items.reduce((count: number, item) => {
        if (isEnabledPortFromAuction(item.type, Number(item.id))) {
            count += 1;
        }
        return count;
    }, 0);
};
