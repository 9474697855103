import { request } from '~/api/WoWsClient';
import { blurView, setFetching } from '~/Actions/ActionApp';
import { logInfo } from '~/utils/logging';
import { Dispatch } from 'redux';
import { openUrl } from '~/utils/settings';

export const ON_APPLICATION_INIT = 'ON_APPLICATION_INIT';
export const ROUTE_PUSH = 'ROUTE_PUSH';
export const OPEN_LINK = 'OPEN_LINK';
export const JS_HOST_QUERY = 'JS_HOST_QUERY';
export const LOADER_STATE = 'LOADER_STATE';
export const REDIRECT_TO = 'REDIRECT_TO';
export const BLUR_STATE = 'BLUR_STATE';
export const ON_ESC = 'ON_ESC';

type IframeProxyOnEvents_Type = {
    [key: string]: () => void;
};

export default class IframeProxy {
    private readonly dispatch: Dispatch;

    private iframe: HTMLIFrameElement;

    private readonly onReadyCallback: () => void;

    private readonly onEventsHandlers: IframeProxyOnEvents_Type;

    constructor(dispatch: Dispatch, iframe: HTMLIFrameElement, onReady?: () => void, onEventsHandlers?: IframeProxyOnEvents_Type) {
        this.dispatch = dispatch;
        this.iframe = iframe;
        this.onReadyCallback = onReady;
        this.onEventsHandlers = onEventsHandlers;
        this.onMessage = this.onMessage.bind(this);
    }

    onMessage(event: MessageEvent): void {
        const { action, payload } = event.data || {};

        switch (action) {
            case ON_APPLICATION_INIT:
                this.onReadyCallback && this.onReadyCallback();
                break;

            case JS_HOST_QUERY:
                request(payload);
                break;

            case OPEN_LINK:
                openUrl(payload.url);
                break;

            case REDIRECT_TO:
                window.location.href = payload.url;
                break;

            case BLUR_STATE:
                this.dispatch(blurView(payload.blur));
                break;

            case LOADER_STATE:
                this.dispatch(setFetching(payload.visibility));
                break;

            case ON_ESC:
                this.onEventsHandlers?.[ON_ESC]?.();
                break;
        }
    }

    send({ action, payload }: any): void {
        logInfo(`[METASHOP API] sending post message. action - ${action}`);
        this.iframe.contentWindow?.postMessage({ action, payload }, `*`);
    }

    listen(): void {
        this.pushCategoryPathIfNeeded();
        window.addEventListener('message', this.onMessage);
    }

    remove(): void {
        window.removeEventListener('message', this.onMessage);
    }

    pushCategoryPathIfNeeded(): void {
        const path = `${location.hash}`.split('#')[1];

        if (!path) {
            return;
        }

        this.send({
            action: ROUTE_PUSH,
            payload: {
                path,
            },
        });
    }
}
