import * as React from 'react';
import styles from './HoverVideo.scss';
import classNames from 'classnames';
import { getSupportedVideo } from '~/utils/video';
import { isMobileOrTabletWindow } from '~/utils/utils';
import { findParentNode } from '~/utils/dom';
import { MouseEventHandler } from 'react';

interface IHoverVideo {
    previewVideo?: IVideo;
    video: IVideo;
    className?: string;
    children: React.ReactNode | React.ReactNode[];
    isDisableAnimation?: boolean;
    delay?: number;
    muted?: boolean;
    parentClassName: string;
    onClick?: MouseEventHandler;
    attributes?: Record<string, any>;
    isBanner?: boolean;
    isSerial?: boolean;
}

const HoverVideo = (props: IHoverVideo) => {
    const { previewVideo, video, isDisableAnimation, children, className, parentClassName, muted, delay, onClick, attributes = {} } = props;
    const [isVisibleVideo, toggleVisibilityVideo] = React.useState(false);
    const videoRef = React.useRef<HTMLVideoElement>(null);
    const hoverVideoUrl = getSupportedVideo(video);
    const previewVideoUrl = getSupportedVideo(previewVideo);
    const isHoverVideoEnabled = !isDisableAnimation && !!hoverVideoUrl && !isMobileOrTabletWindow;
    const isPreviewVideoEnabled = !!previewVideoUrl && !isMobileOrTabletWindow;
    let timer: NodeJS.Timeout;
    const isBannerVideo = props.isBanner;
    const isSerialVideo = props.isSerial;

    const mouseEnter = () => {
        if (isHoverVideoEnabled) {
            if (videoRef.current) {
                const play = () => {
                    videoRef.current.currentTime = 0;
                    toggleVisibilityVideo(true);
                    videoRef.current.play();
                };
                if (!delay) {
                    play();
                } else {
                    timer = setTimeout(() => {
                        play();
                        clearTimeout(timer);
                    }, delay);
                }
            }
        }
    };

    const mouseLeave = () => {
        clearTimeout(timer);
        if (isHoverVideoEnabled) {
            if (videoRef.current) {
                toggleVisibilityVideo(false);
                videoRef.current.pause();
            }
        }
    };

    const classesVideoWrapper = classNames(styles.videoWrapper, {
        [styles.visible]: !!isPreviewVideoEnabled || isVisibleVideo,
        [styles.isBanner]: isBannerVideo,
        [styles.isSerial]: isSerialVideo,
    });

    React.useEffect(() => {
        if (isPreviewVideoEnabled) {
            const play = () => {
                videoRef.current.play();
            };
            if (!delay) {
                play();
            } else {
                timer = setTimeout(() => {
                    play();
                    clearTimeout(timer);
                }, delay);
            }
        }

        if (isHoverVideoEnabled) {
            if (!videoRef.current) {
                return;
            }

            const element = findParentNode(parentClassName, videoRef.current);
            if (element) {
                element.addEventListener('mouseenter', mouseEnter);
                element.addEventListener('mouseleave', mouseLeave);
            }

            return () => {
                if (element) {
                    element.removeEventListener('mouseenter', mouseEnter);
                    element.removeEventListener('mouseleave', mouseLeave);
                }
            };
        }
    }, [videoRef.current]);

    return (
        <div className={classNames(styles.wrapper, className)} onClick={onClick} {...attributes}>
            {children}
            {isHoverVideoEnabled && (
                <div className={classesVideoWrapper}>
                    <video src={hoverVideoUrl} loop={true} muted={muted === undefined || muted} ref={videoRef} />
                </div>
            )}
            {isPreviewVideoEnabled && (
                <div className={classesVideoWrapper}>
                    <video src={previewVideoUrl} loop={true} muted={muted === undefined || muted} ref={videoRef} />
                </div>
            )}
        </div>
    );
};

export default HoverVideo;
