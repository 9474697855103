import * as React from 'react';
import styles from './GiftPromo.scss';
import { t } from '~/utils/localization';
import { Button, ButtonColorPresets } from '~/components/Button/Buttons';
import { State } from '~/Reducers';
import { useDispatch, useSelector } from 'react-redux';
import equal from 'fast-deep-equal/react';
import { isEmptyObject, isMobileOrTabletWindow, isSafari, redirectToLogin } from '~/utils/utils';
import { IStartVideo } from '~/Actions/ActionAppType';
import { changeVisiblePopup, startVideo } from '~/Actions/ActionApp';
import { getSupportedVideo, getSupportedWebmVideo } from '~/utils/video';
import { POPUPS_NAME } from '~/components/PopupManager';
import VideoBackground from '~/components/VideoBackground/VideoBackground';
import { SANTA_GIFT_BANNER_VIDEO } from '~/Layouts/Themes/SantaPage/settings';
import { useIsDisabledAnimation } from '~/hooks/isDisabledAnimation';
import { CATEGORIES } from '~/const';

interface IGiftIntroWidget {
    onClick?: () => void;
}

interface IStateSelector {
    accountId: number;
    gifts: IGifts;
    isStartedVideo: boolean;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        accountId: state.ReducerAccount.id,
        gifts: state.ReducerAccount.gifts,
        isStartedVideo: state.ReducerApp.isStartedVideo,
    };
};

const wasMobilePopupOpen: Record<string, boolean> = {};

const GiftIntroWidget = (props: IGiftIntroWidget) => {
    const dispatch = useDispatch();
    const introContentRef = React.useRef<HTMLDivElement>();
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const videoWasShown = React.useRef(false);
    const currentGift = state.gifts.bundles[0];
    const isGuest = !state.accountId;
    const isSanta = currentGift.name.includes('ny_gift_armory');
    const isDisabledAnimation = useIsDisabledAnimation(isSanta ? CATEGORIES.SANTA_CONTAINERS : '');

    const onClick = () => {
        if (isGuest) {
            return redirectToLogin();
        }
        if (!isEmptyObject(currentGift.entryVideo)) {
            const videoUrl = getSupportedVideo(currentGift.entryVideo);
            if (videoUrl) {
                dispatch<IStartVideo>(startVideo(`gift_${currentGift?.name}`, videoUrl, null, 0));
                const timeout = setTimeout(() => {
                    clearTimeout(timeout);
                    props.onClick?.();
                }, 1000);
                return;
            }
        }

        dispatch(changeVisiblePopup(POPUPS_NAME.GIFT_ACCEPT, true, { gift: currentGift }));

        props.onClick?.();
    };

    const setRef = (_ref: HTMLDivElement) => {
        if (_ref) {
            introContentRef.current = _ref;
        }
    };

    const onMouseEnter = () => {
        if (!introContentRef.current) {
            return;
        }
        introContentRef.current.classList.add(styles.hover);
    };

    const onMouseLeave = () => {
        if (!introContentRef.current) {
            return;
        }
        introContentRef.current.classList.remove(styles.hover);
    };

    if (isMobileOrTabletWindow) {
        return null;
    }

    const backgroundColor = currentGift.buttonBackgroundColor;
    const textColor = currentGift.buttonTextColor;
    const label = currentGift.buttonLabel;

    let video: IVideo;
    if (isSanta && getSupportedWebmVideo(SANTA_GIFT_BANNER_VIDEO)) video = SANTA_GIFT_BANNER_VIDEO;
    const withVideo = !!video && !isDisabledAnimation && !isSafari && (!state.isStartedVideo || videoWasShown.current);

    if (withVideo) videoWasShown.current = true;

    return (
        <React.Fragment>
            <div className={styles.introWrapper}>
                <div className={styles.introContent} ref={setRef} style={{ backgroundImage: `url(${currentGift.icons.medium})` }}>
                    <div className={styles.animationWrapper}></div>
                    <div className={styles.introBody}>
                        <div className={styles.intoInfo} />
                    </div>
                </div>
                {withVideo && <VideoBackground video={video} className={styles.introVideo} hideStaticBackground />}
                <div className={styles.introButtons} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                    <Button
                        animationColor={backgroundColor || '#B23D03'}
                        className={styles.introButton}
                        isEnabledAnimation={true}
                        backgroundColor={backgroundColor || ButtonColorPresets.ORANGE}
                        textColor={textColor}
                        label={label || t('Забрать подарок')}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default GiftIntroWidget;
