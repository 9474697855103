import { t } from '~/utils/localization';
import { ManagerData } from '~/core/ManagerData';
import {
    FILTER_ALLOWED_BUNDLES_NAME,
    FILTER_BOOST_TYPE,
    FILTER_BOOST_TYPE_VALUES,
    FILTER_CAMOUFLAGE_TYPE_NAME,
    FILTER_CAMOUFLAGE_TYPE_VALUES,
    FILTER_CREW_CATEGORY_NAME,
    FILTER_CREW_CATEGORY_VALUES,
    FILTER_CREW_TYPE_NAME,
    FILTER_CREW_TYPE_VALUES,
    FILTER_CURRENCY_NAME,
    FILTER_DISCOUNT_NAME,
    FILTER_DISCOUNT_VALUES,
    FILTER_DOLL_TYPE_NAME,
    FILTER_DOLL_TYPE_VALUES,
    FILTER_NATION_NAME,
    FILTER_SHIP_CLASS_NAME,
    FILTER_SHIP_TIER_NAME,
    FILTER_SHIP_TYPE_NAME,
    FILTER_SHIP_TYPE_VALUES,
    FILTER_SIGNAL_TYPE,
    FILTER_SIGNAL_TYPE_VALUES,
    SUPERSHIP_ICON,
} from '~/const';
import { isBundleDisabledByPromoTimer } from '~/utils/bundles';

type FiltersMap_Keys = {
    [key in ICategoryList]?: FILTER_INFO_NAME[];
};
const filtersMap: FiltersMap_Keys = {};

(function () {
    (window.metashop.state?.content?.categories || []).forEach((category) => {
        filtersMap[category.name as ICategoryList] = category.filters?.settings || [];
    });
})();

export type FiltersDependency_Type = {
    [key in ICategoryList]?: {
        [key in FILTER_INFO_NAME]?: FILTER_INFO_NAME[];
    };
};

export let filtersDependency: FiltersDependency_Type = null;

export type FilterDependency_Type = {
    [key in ICategoryList]?: {
        [key in FILTER_INFO_NAME]?: {
            [key in FILTER_INFO_NAME]?: FILTER_INFO_NAME[];
        };
    };
};

export let filterDependencyMap: FilterDependency_Type = null;

export const getFiltersDependencyMap = () => {
    if (filterDependencyMap) {
        return filterDependencyMap;
    }

    const map: FilterDependency_Type = {};
    Object.keys(filtersMap).forEach((categoryName: ICategoryList) => {
        if (!map[categoryName]) {
            map[categoryName] = {};
        }

        filtersMap[categoryName].forEach((filterName: FILTER_INFO_NAME) => {
            if (!map[categoryName][filterName]) {
                map[categoryName][filterName] = {};
            }

            filtersMap[categoryName].forEach((_filterName: FILTER_INFO_NAME) => {
                if (_filterName === filterName || map[categoryName][filterName][_filterName]) {
                    return;
                }

                map[categoryName][filterName][_filterName] = filtersMap[categoryName].filter((depName: FILTER_INFO_NAME) => {
                    return depName !== _filterName && depName !== filterName;
                }) as FILTER_INFO_NAME[];
            });
        });
    });

    filterDependencyMap = map;

    return filterDependencyMap;
};

export const getFiltersDependency = () => {
    if (filtersDependency) {
        return filtersDependency;
    }

    const deps: FiltersDependency_Type = {};
    Object.keys(filtersMap).forEach((categoryName: ICategoryList) => {
        if (!deps[categoryName]) {
            deps[categoryName] = {};
        }
        filtersMap[categoryName].forEach((filterName: FILTER_INFO_NAME) => {
            if (!deps[categoryName][filterName]) {
                deps[categoryName][filterName] = [];
            }
            deps[categoryName][filterName] = filtersMap[categoryName].filter((_filterName: FILTER_INFO_NAME) => {
                return _filterName !== filterName;
            }) as FILTER_INFO_NAME[];
        });
    });

    filtersDependency = deps;

    return filtersDependency;
};

export const HIDDEN_FILTERS_NAMES = [FILTER_ALLOWED_BUNDLES_NAME];

export type FilterItem_Type = {
    bundleField: string;
    tradeInField?: string;
    title: string;
    name: FILTER_INFO_NAME;
    isForcedDisplay?: boolean;
    defaultValues: string[] | number[];
    items: {
        title: string;
        value: string | number;
    }[];
    isDisabledForBundle?: (bundle: IBundle) => boolean;
};

export type IFiltersInfo = {
    [key in FILTER_INFO_NAME]?: FilterItem_Type;
};

export const filtersInfo: IFiltersInfo = {
    [FILTER_SHIP_CLASS_NAME]: {
        bundleField: 'primaryItem.filterValues.class',
        tradeInField: 'additionalData.shipType',
        name: FILTER_SHIP_CLASS_NAME,
        items: [
            {
                title: '',
                value: '',
            },
        ],
        title: t('Класс техники'),
        defaultValues: [],
    },
    [FILTER_SHIP_TIER_NAME]: {
        bundleField: 'primaryItem.filterValues.tier',
        tradeInField: 'additionalData.level',
        name: FILTER_SHIP_TIER_NAME,
        title: t('Уровень'),
        items: [
            { title: 'I', value: 1 },
            { title: 'II', value: 2 },
            { title: 'III', value: 3 },
            { title: 'IV', value: 4 },
            { title: 'V', value: 5 },
            { title: 'VI', value: 6 },
            { title: 'VII', value: 7 },
            { title: 'VIII', value: 8 },
            { title: 'IX', value: 9 },
            { title: 'X', value: 10 },
            { title: SUPERSHIP_ICON, value: 11 },
        ],
        defaultValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    },
    [FILTER_NATION_NAME]: {
        bundleField: 'primaryItem.filterValues.nation',
        tradeInField: 'additionalData.nation.name',
        title: t('Нация'),
        name: FILTER_NATION_NAME,
        items: [
            {
                title: '',
                value: '',
            },
        ],
        defaultValues: [],
    },
    [FILTER_CURRENCY_NAME]: {
        bundleField: 'primaryItem.filterValues.currency',
        tradeInField: 'currency',
        name: FILTER_CURRENCY_NAME,
        items: [
            {
                title: '',
                value: '',
            },
        ],
        title: t('Валюта'),
        defaultValues: [],
        isDisabledForBundle(bundle: IBundle) {
            return isBundleDisabledByPromoTimer(bundle);
        },
    },
    [FILTER_SHIP_TYPE_NAME]: {
        bundleField: 'primaryItem.filterValues.ship_type',
        title: t('Тип техники'),
        items: [
            {
                title: t('Премиумный'),
                value: FILTER_SHIP_TYPE_VALUES.PREMIUM,
            },
            {
                title: t('Особый'),
                value: FILTER_SHIP_TYPE_VALUES.SPECIAL,
            },
        ],
        name: FILTER_SHIP_TYPE_NAME,
        defaultValues: Object.values(FILTER_SHIP_TYPE_VALUES),
    },
    [FILTER_CREW_CATEGORY_NAME]: {
        bundleField: 'primaryItem.filterValues.crew_category',
        title: t('Категория'),
        items: [
            {
                title: t('Исторические'),
                value: FILTER_CREW_CATEGORY_VALUES.HISTORY,
            },
            {
                title: t('События'),
                value: FILTER_CREW_CATEGORY_VALUES.EVENTS,
            },
            {
                title: t('Особые'),
                value: FILTER_CREW_CATEGORY_VALUES.SPECIAL,
            },
        ],
        name: FILTER_CREW_CATEGORY_NAME,
        defaultValues: Object.values(FILTER_CREW_CATEGORY_VALUES),
    },
    [FILTER_CREW_TYPE_NAME]: {
        bundleField: 'primaryItem.filterValues.crew_type',
        title: t('Тип'),
        items: [
            {
                title: t('Легендарные'),
                value: FILTER_CREW_TYPE_VALUES.UNIQUE,
            },
            {
                title: t('Редкие'),
                value: FILTER_CREW_TYPE_VALUES.ADVANCED,
            },
            {
                title: t('Особые'),
                value: FILTER_CREW_TYPE_VALUES.SPECIAL,
            },
            {
                title: t('Обычные'),
                value: FILTER_CREW_TYPE_VALUES.COMMON,
            },
        ],
        name: FILTER_CREW_TYPE_NAME,
        defaultValues: Object.values(FILTER_CREW_TYPE_VALUES),
    },
    [FILTER_DOLL_TYPE_NAME]: {
        bundleField: 'primaryItem.filterValues.doll_type',
        title: t('Тип'),
        items: [
            {
                title: t('Форма'),
                value: FILTER_DOLL_TYPE_VALUES.BACKGROUND,
            },
            {
                title: t('Нашивка'),
                value: FILTER_DOLL_TYPE_VALUES.PATCH,
            },
            {
                title: t('Символ'),
                value: FILTER_DOLL_TYPE_VALUES.SYMBOL,
            },
        ],
        name: FILTER_DOLL_TYPE_NAME,
        defaultValues: Object.values(FILTER_DOLL_TYPE_VALUES),
    },
    [FILTER_DISCOUNT_NAME]: {
        bundleField: 'primaryItem.filterValues.discount_type',
        isForcedDisplay: true,
        title: t('Скидка'),
        items: [
            {
                title: t('Есть купон'),
                value: FILTER_DISCOUNT_VALUES.COUPON,
            },
        ],
        name: FILTER_DISCOUNT_NAME,
        defaultValues: Object.values(FILTER_DISCOUNT_VALUES),
        isDisabledForBundle(bundle: IBundle) {
            return isBundleDisabledByPromoTimer(bundle);
        },
    },
    [FILTER_CAMOUFLAGE_TYPE_NAME]: {
        bundleField: 'primaryItem.filterValues.camouflage_type',
        title: t('Тип'),
        items: [
            {
                title: t('Постоянный'),
                value: FILTER_CAMOUFLAGE_TYPE_VALUES.PERMANENT,
            },
            {
                title: t('Расходуемый'),
                value: FILTER_CAMOUFLAGE_TYPE_VALUES.EXPENDABLE,
            },
        ],
        name: FILTER_CAMOUFLAGE_TYPE_NAME,
        defaultValues: Object.values(FILTER_CAMOUFLAGE_TYPE_VALUES),
    },
    [FILTER_SIGNAL_TYPE]: {
        bundleField: 'primaryItem.filterValues.signal_type',
        title: t('Тип'),
        items: [
            {
                title: t('Живучесть'),
                value: FILTER_SIGNAL_TYPE_VALUES.HPBOOST,
            },
            {
                title: t('Наносимый урон'),
                value: FILTER_SIGNAL_TYPE_VALUES.GM,
            },
            {
                title: t('Вспомогательное вооружение'),
                value: FILTER_SIGNAL_TYPE_VALUES.ATBA,
            },
            {
                title: t('Скорость'),
                value: FILTER_SIGNAL_TYPE_VALUES.SPEED,
            },
            {
                title: t('Снаряжение'),
                value: FILTER_SIGNAL_TYPE_VALUES.CONSUMABLE,
            },
            {
                title: t('Сопротивление урону'),
                value: FILTER_SIGNAL_TYPE_VALUES.SHIFT,
            },
        ],
        name: FILTER_SIGNAL_TYPE,
        defaultValues: Object.values(FILTER_SIGNAL_TYPE_VALUES),
    },
    [FILTER_BOOST_TYPE]: {
        bundleField: 'primaryItem.filterValues.boost_type',
        title: t('Тип'),
        items: [
            {
                title: t('Постоянный'),
                value: FILTER_BOOST_TYPE_VALUES.PERMANENT,
            },
            {
                title: t('Расходуемый'),
                value: FILTER_BOOST_TYPE_VALUES.EXPENDABLE,
            },
        ],
        name: FILTER_SIGNAL_TYPE,
        defaultValues: Object.values(FILTER_BOOST_TYPE_VALUES),
    },
};

const updateFiltersMap = (data: any, key: FILTER_INFO_NAME) => {
    filtersInfo[key].items = [];
    data.forEach((item: any) => {
        filtersInfo[key].items.push({ value: item.name, title: item.title });
        (filtersInfo[key].defaultValues as string[])?.push(item.name);
    });
};

export const parseVortexDataFromFilter = () => {
    const parse = (data: any) => {
        Object.keys(data).forEach((key: FILTER_INFO_NAME) => {
            switch (key) {
                case FILTER_SHIP_CLASS_NAME:
                case FILTER_CURRENCY_NAME:
                case FILTER_NATION_NAME:
                    updateFiltersMap(data[key], key);
                    break;
            }
        });
    };

    return new Promise((resolve) => {
        const data = ManagerData.getAll().data;
        parse(data);

        return resolve(filtersInfo);
    });
};

export const getFiltersInfo = function () {
    return filtersInfo;
};

export const setFiltersInfo = async () => {
    await parseVortexDataFromFilter();
};

export default filtersMap;
