import { ISaveDockyardSettings } from '~/Actions/ActionDockyardType';
import { dockyardSeason } from '~/api/dockyard';

export const SAVE_DOCKYARD_SETTINGS = 'SAVE_DOCKYARD_SETTINGS';
export const updateDockyardSeasonSettings = (seasonInfo: dockyardSeason): ISaveDockyardSettings => {
    const { season, categoryBck, shipId, isStartersBought } = seasonInfo;

    return {
        type: SAVE_DOCKYARD_SETTINGS,
        season,
        categoryBck,
        shipId,
        isStartersBought,
    };
};
