import { items as ITEMS } from '@wg/wows-entities/const';
import { CLIENT_BUTTON_COPYRIGHT_BY_ROUTE } from '~/const';
import { PortPreviewType } from '~/components/Port/settings';
import { ProductEntity } from '@wg/wows-commerce/core/entity/product.entity';
import { AVAILABLE_GIFT_STATUS } from '~/Actions/ActionAccountType';
import { ITradeInResult } from './tradein';

export enum TooltipTypes {
    WARNING = 'warning',
    DEFAULT = 'default',
}

declare global {
    interface IVideo {
        webm: string;
        loopStartTime?: number;
        clientVideoName?: string;
        hasSound?: boolean;
        noVideoDisable?: boolean;
    }

    interface IGalleryItem {
        image: string;
        video: Nullable<IVideo>;
    }

    interface IAudioTrack {
        mp3: string;
    }

    type SoundStatus = Record<string, boolean>;

    interface IDisplayRestrictedOptions {
        allowForCountries?: string[];
        denyForCountries?: string[];
        denyForPlatforms?: string[];
    }

    type ClientSource_Type = keyof typeof CLIENT_BUTTON_COPYRIGHT_BY_ROUTE;

    type WoWsEntityItems_Type = ValueOf<typeof ITEMS | 'token'>;

    interface IPort {
        isVisible: boolean;
        shipId?: number;
        exteriorId?: number;
        itemType?: string;
        id?: string | number;
        sourceUrl?: string;
        isLoading?: boolean;
        portPreviewType?: PortPreviewType;
        showTTX?: boolean;
        itemList?: Array<IBundleEntity>;
        additionalData?: Record<string, any>;
    }

    interface IMenuMapItem extends ICategory {
        url: string;
        isEnabledCategory?: () => boolean;
        middleware?: () => boolean;
        isHidden?: boolean;
        backgroundName?: string;
        id?: string;
        timer?: ITimerData;
        isNotCategory?: boolean;
    }

    interface IMenuMap {
        [key: string]: IMenuMapItem;
    }

    interface ITimerData {
        title?: string;
        activeTill: Date;
    }

    interface IPopup {
        id: number;
        name: string;
        data?: any;
    }

    interface PopupSettings {
        nestedPopup?: {
            mainBundle: IBundle;
            currentData?: ILootboxStateItem;
        };
    }

    interface INation {
        icons: {
            default: string;
            small: string;
            tiny: string;
        };
        name: string;
        title: string;
    }

    interface IVehicleClass {
        icons: {
            default: string;
        };
        name: string;
        title: string;
    }

    interface ISearchResultItem {
        bundleId: number;
        title: string;
        normalizedTitle: string;
        category: string;
        isParagonBundle: boolean;
    }

    type ATTENTION_LEVELS = 'error' | 'warning' | 'done' | 'up' | 'down' | 'info';

    interface IReward {
        id: string;
        category?: IRewardCategory;
        title: string;
        content: {
            title: any;
            imageUrl: string;
        }[];
        onComplete?: () => void;
        successButtonText?: string;
        outsideClick?: boolean;
    }

    interface ILootboxesBundlesMap {
        [key: number]: number[];
    }

    interface WsmartGoldProductWrapper {
        product: ProductEntity;
        totalGoldAmount: number;
    }

    type ACCOUNT_STATE_UPDATE = 'ACCOUNT_STATE_UPDATE';
    type PURCHASE_SUCCESS = 'PURCHASE_SUCCESS';
    type PURCHASE_ERROR = 'PURCHASE_ERROR';
    type PURCHASE_RANDOM_BUNDLE_SUCCESS = 'PURCHASE_RANDOM_BUNDLE_SUCCESS';
    type RANDOM_BUNDLE_SELECTED = 'RANDOM_BUNDLE_SELECTED';
    type PURCHASE_SERIAL_BUNDLES_SUCCESS = 'PURCHASE_SERIAL_BUNDLES_SUCCESS';
    type TRADEIN_ERROR = 'TRADEIN_ERROR';
    type TRADEIN_SUCCESS = 'TRADEIN_SUCCESS';
    type ISocketAction =
        | ACCOUNT_STATE_UPDATE
        | PURCHASE_ERROR
        | PURCHASE_SUCCESS
        | PURCHASE_RANDOM_BUNDLE_SUCCESS
        | RANDOM_BUNDLE_SELECTED
        | PURCHASE_SERIAL_BUNDLES_SUCCESS
        | TRADEIN_ERROR
        | TRADEIN_SUCCESS;

    type PurchaseRandomBundleIds = {
        [key: number]: number;
    };

    interface IPurchaseRandomBundleSuccess {
        bundleIds: PurchaseRandomBundleIds;
        invoiceId: number;
        newSelectedBundleId: number;
        parentBundleId: number;
    }

    interface ISocketResponse {
        action: ISocketAction;
        data: IPurchaseRandomBundleSuccess | ITradeInResult | any;
    }

    interface IGifts {
        status: AVAILABLE_GIFT_STATUS;
        bundles: IGift[];
        secretBundles: IGift[];
    }

    type filterValuesList = Record<string, string[]>;

    interface IItemCommonData {
        amount: number;
        customisation: IPrimaryItemCustomisation;
        identifier?: number;
        isUnique: boolean;
        type: string;
        shipId: number;
        filterValues?: filterValuesList;
    }

    interface IActiveTime {
        activeFrom: Nullable<string>;
        activeTill: Nullable<string>;
    }

    interface ActivityCountdown {
        activeFrom?: string;
        customization: {
            title?: string;
            description?: string;
        };
        isEnabled: boolean;
    }
}

export default global;
