import wowsEntities from '@wg/wows-entities';
import { ItemTypes } from '@wg/wows-entities/const';

export async function getShipFeatures(shipId?: number | string) {
    const shipData = await wowsEntities.getStorage().loadEntity(ItemTypes.VEHICLES, shipId);

    if (!shipData) return;

    return {
        id: shipData.id,
        complexity: shipData.complexity,
        featuresTags: shipData.featuresTags,
        featuresTagsPerCategory: shipData.featuresTagsPerCategory,
    } as IFeatureTagsItem;
}
