import * as React from 'react';
import equal from 'fast-deep-equal/react';
import styles from './Footer.scss';
import classNames from 'classnames';
import { interpolate, t } from '~/utils/localization';
import { isAvailableRebrandingForCIS, settings } from '~/utils/settings';
import { initCookieSettingsLink } from '~/utils/onetrust';
import useClickAway from '~/hooks/useClickAway';
import { isInGameBrowser, isMobileOrTabletWindow } from '~/utils/utils';
import MobileFooter from '~/components/Footer/MobileFooter';
import { useSelector } from 'react-redux';
import { State } from '~/Reducers';
import RefManager, { RefManagerKeys } from '~/RefManager';
import { LanguageFooter } from '~/components/Footer/LanguageFooter';
import { ICurrentPage } from '~/Actions/ActionAppType';
import { ErrorBoundary } from '@sentry/react';
import { CUSTOM_PAGE, SANTA_PAGE_THEME } from '~/Layouts/Themes/ThemeManager';
import Pegi from '~/components/Footer/Pegi';
import { CATEGORIES, IFRAME_CATEGORIES } from '~/const';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import useDelegationClickForLinkHandler from '~/hooks/useDelegationClickForLinkHandler';

interface IFooterState {
    currentPage: ICurrentPage;
    categories: ICategories;
    isFullscreen: boolean;
}

const stateSelector = (state: State): IFooterState => {
    return {
        currentPage: state.ReducerApp.currentPage,
        categories: state.ReducerApp.categories,
        isFullscreen: state.ReducerApp.isFullscreen,
    };
};

export function getCopyrightText(): string {
    const currentYear = new Date().getUTCFullYear();
    if (isAvailableRebrandingForCIS()) {
        return interpolate(
            t(
                '© Lesta Games, 2022–{year}. Игра «Мир кораблей» основана на интеллектуальной собственности третьих лиц. Все права на объекты прав третьих лиц принадлежат их законным правообладателям.',
            ),
            {
                year: currentYear,
            },
        );
    }
    return interpolate(t('Copyright © {years} Wargaming.net Все права защищены.'), {
        years: `2012–${currentYear}`,
    });
}

const Footer: React.FC<unknown> = (): React.ReactElement => {
    const ref: React.RefObject<HTMLDivElement> = React.useRef(null);
    const [isVisibleFooter, setVisibleFooter] = React.useState(false);
    const currentYear = new Date().getUTCFullYear();
    const realmsConfig = settings.realmsConfig;
    const currentRealm = realmsConfig.filter((realm) => realm.realm === settings.realm)[0];
    const appState = useSelector<State, IFooterState>(stateSelector, equal);

    const languageButtonClassList = classNames(styles.languageButton, {
        [styles.active]: isVisibleFooter,
    });

    const refFooterContent = React.useRef<HTMLDivElement>(null);
    useDelegationClickForLinkHandler(refFooterContent);

    const changeVisibleFooter = () => setVisibleFooter(!isVisibleFooter);

    useClickAway(ref, () => {
        setVisibleFooter(false);
    });

    React.useEffect(initCookieSettingsLink, []);

    if (IFRAME_CATEGORIES.includes(appState.currentPage?.name)) {
        return null;
    }

    if (!appState.currentPage?.isBundlePage && [SANTA_PAGE_THEME, CUSTOM_PAGE].includes(appState.categories[appState.currentPage?.name]?.theme)) {
        return null;
    }

    if (isMobileOrTabletWindow) {
        if (
            appState.currentPage?.isBundlePage ||
            appState.currentPage?.name === CATEGORIES.MENU ||
            appState.currentPage?.name === CATEGORIES.WALLET ||
            appState.currentPage?.name === CATEGORIES.COUPONS
        ) {
            return null;
        }

        return (
            <ErrorBoundary>
                <MobileFooter
                    ref={ref}
                    realmsConfig={realmsConfig}
                    isVisible={isVisibleFooter}
                    currentRealm={currentRealm}
                    changeVisible={() => setVisibleFooter(!isVisibleFooter)}
                    currentYear={currentYear}
                />
            </ErrorBoundary>
        );
    }

    const languagePicker =
        currentRealm && settings.languagePickerRealms?.includes?.(currentRealm?.realm) ? (
            <div className={styles.languageFooterWrapper} ref={ref}>
                {isVisibleFooter && <LanguageFooter />}
                <div className={languageButtonClassList} onClick={changeVisibleFooter}>
                    <span>
                        {currentRealm.title} ({settings.allLanguages[settings.languageCode]})
                    </span>
                </div>
            </div>
        ) : null;

    const _isAvailableRebrandingForCIS = isAvailableRebrandingForCIS();
    const isAllowedBigFooter = _isAvailableRebrandingForCIS || settings.showPegi;

    const classesFooterContent = classNames(styles.content, {
        [styles.cisContent]: _isAvailableRebrandingForCIS,
        [styles.availableBigFooter]: isAllowedBigFooter,
    });

    return (
        <div
            className={classNames(styles.wrapper, {
                [styles.active]: isVisibleFooter,
                [styles.fullScreen]: appState.isFullscreen,
            })}
            ref={(_ref) => {
                RefManager.setRef(RefManagerKeys.Footer, _ref);
            }}
        >
            <div className={classesFooterContent}>
                <div
                    className={styles.footerContent}
                    ref={(_ref) => {
                        if (_ref) {
                            refFooterContent.current = _ref;
                        }
                    }}
                >
                    <div className={styles.legalInfo}>
                        {!isInGameBrowser && languagePicker}
                        <div className={styles.legalLinks}>
                            <a href={settings.urls.supportUrl} target={'_blank'} className={styles.link}>
                                <span>{t('Центр поддержки')}</span>
                            </a>
                            <a href={settings.urls.lawDocumentsUrl} target={'_blank'} className={styles.link}>
                                <span>{t('Юридические документы')}</span>
                            </a>
                            {!!settings.urls.pageAboutGameMechanicsUrl && (
                                <DivTooltip
                                    className={styles.compensationUrl}
                                    tooltipBody={<DefaultTooltip text={t('Правила компенсации, вероятность выпадения имущества из контейнеров и другая полезная информация')} />}
                                >
                                    <a href={settings.urls.pageAboutGameMechanicsUrl} target={'_blank'} className={styles.link}>
                                        <span>{t('Дополнительная информация и условия')}</span>
                                    </a>
                                </DivTooltip>
                            )}
                            {/* separate styles for onetrust link, must be last one*/}
                            <span id="ot-sdk-btn" className={classNames('ot-sdk-show-settings', styles.onetrustLink)}></span>
                        </div>
                    </div>
                    <div className={styles.rightContent}>
                        {!isAllowedBigFooter && <div className={styles.legal}>{getCopyrightText()}</div>}
                        <Pegi />
                        {_isAvailableRebrandingForCIS && <div className={styles.legal12plus}></div>}
                    </div>
                </div>
                {isAllowedBigFooter && (
                    <div className={styles.copyrightWrapper}>
                        <div className={styles.legal}>{getCopyrightText()}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Footer;
