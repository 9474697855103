import * as React from 'react';
import styles from './AuctionLotPage.scss';
import bundleContainsStyles from '../BundlePage/Contains/BundleContains.scss';
import Contains from '~/components/ItemContains/Contains';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { isEnabledPortFromAuction, openPortByReward } from '~/settings/port';
import { items as ITEMS_TYPES } from '@wg/wows-entities/const';
import { getValueFromInventory } from '~/utils/inventory';
import { ManagerData, getSectionName } from '~/core/ManagerData';
import { isAvailableAdditionalContentForType } from '../BundlePage/helpers';
import store from '~/Store';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import classNames from 'classnames';

interface IAuctionContains {
    lot: ILot;
    items: IGameRewards[];
    lotId: string;
    inventory: InventoryState;
    previewEnabled?: boolean;
}

const AuctionContains = ({ lot, items, lotId, inventory, previewEnabled = true }: IAuctionContains) => {
    const containsItems: any =
        items
            ?.filter((item: IGameRewards) => !!item.type)
            .map((item: IGameRewards) => {
                const isAvailableAdditionalContent = isAvailableAdditionalContentForType(item.type, item.id);
                const data = ManagerData.getData(getSectionName(item.type), item.id, true);

                return {
                    id: item.id,
                    isPortPreviewEnabled: isEnabledPortFromAuction(item.type, item.id, lot) && previewEnabled,
                    portPreviewOnClick: () => {
                        openPortByReward(item, lotId);
                    },
                    content: (
                        <div
                            className={classNames({
                                [bundleContainsStyles.WoWSEntityWrapper]: isAvailableAdditionalContent,
                                [bundleContainsStyles.gold]: (item.type !== ITEMS_TYPES.LOOTBOX && data?.isPremium) || data?.isSpecial || data?.isUnique,
                            })}
                            onClick={() => {
                                if (!isAvailableAdditionalContent) {
                                    return;
                                }
                                store.dispatch(
                                    changeVisiblePopup(POPUPS_NAME.ITEM_DESCRIPTION_POPUP, true, {
                                        item: { identifier: item.id, type: item.type, customisation: item.customisation || { points: null } },
                                        autoDescriptionSettings: null,
                                    }),
                                );
                            }}
                        >
                            <WoWSEntity
                                type={item.type}
                                id={item.id}
                                amount={item.amount}
                                className={styles.containItem}
                                customisation={{
                                    shipId: item.customisation?.ship_id,
                                    points: item.customisation?.points,
                                    inventoryCount: getValueFromInventory(inventory, item.type, item.id),
                                }}
                                presentation={{
                                    withText: true,
                                    withTooltip: true,
                                    iconPosition: 'left',
                                    hiddenInlineShip: item.type === ITEMS_TYPES.CREWS,
                                }}
                            />
                        </div>
                    ),
                };
            }) || [];

    if (!containsItems?.length) {
        return null;
    }

    return (
        <div className={styles.contains}>
            <Contains isLarge={true} items={containsItems} />
        </div>
    );
};

export default AuctionContains;
