import * as React from 'react';
import equal from 'fast-deep-equal/react';
import styles from './PageHeader.scss';
import { t } from '~/utils/localization';
import { useDispatch, useSelector } from 'react-redux';
import { changeVisiblePopup, changeVisiblePort } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import NotificationsWrapper from '~/components/Notifications/NotificationsWrapper';
import store from '~/Store';
import { isDockyardCategory, isRealMoneyPurchaseDisabled, openBundleById, openBundleByUrl, openCategoryByName } from '~/utils/category';
import SearchPanel from '~/components/SearchPanel/SearchPanel';
import { State } from '~/Reducers';
import { closePort, playButtonClickSound, playButtonGoldClickSound } from '~/api/WoWsClient';
import { changeBackground } from '~/containers/PortContainer/PortContainer';
import History from '~/utils/history';
import classNames from 'classnames';
import className from 'classnames';
import User from '~/components/User/User';
import { isInGameBrowser } from '~/utils/utils';
import { hasNewCoupons, isCouponsTabHidden } from '~/utils/coupons';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip from '~/components/Tooltip/DefaultTooltip';
import MobileHeader from '~/components/PageHeader/MobilePageHeader';
import PremiumAccountLink from '~/components/PremiumAccountLink/PremiumAccountLink';
import { getCountCouponsWithLabelNew } from '~/utils/labels';
import LabelContainer from '~/containers/LabelContainer/LabelContainer';
import { urlsMap } from '~/utils/menu';
import dwhExport from '~/api/dwhExport';
import { CATEGORIES, CLIENT_BACK_BUTTON_BY_ROUTE, CLIENT_BUTTON_COPYRIGHT_BY_ROUTE, DWH_EVENTS } from '~/const';
import { ICurrentPage } from '~/Actions/ActionAppType';
import GuideDecorator from '~/decorators/GuideDecorator/GuideDecorator';
import { GUIDE_NAMES } from '~/components/WelcomePage/steps';
import { isArmoryPort, PortPreviewType } from '~/components/Port/settings';
import { openLotById } from '~/utils/auction';
import { getParentRandomBundleByChild } from '~/utils/bundles';
import { closeBrowser } from '@wg/web2clientapi/browser/closeBrowser';
import BackButton from '~/components/BackButton/BackButton';

export const showMyCoupons = () => {
    dwhExport.send(DWH_EVENTS.OPEN_COUPONS_POPUP);
    window.metashop.state.content.newCouponsCount = null;
    playButtonClickSound();
    store.dispatch(changeVisiblePopup(POPUPS_NAME.COUPON_POPUP, true));
};

interface IStateSelector {
    port: IPort;
    bundles: IBundleList;
    currentPage: ICurrentPage;
    accountId: number;
    clientSource: ClientSource_Type;
    isBlurView: boolean;
    shopUnavailable: boolean;
    menu: IMenuMap;
    categories: ICategories;
    isFinishedRequest: boolean;
    isFullscreen: boolean;
    coupons: ICoupon[];
}

const stateSelector = (state: State): IStateSelector => {
    return {
        port: state.ReducerApp.port,
        bundles: state.ReducerApp.bundles,
        currentPage: state.ReducerApp.currentPage,
        accountId: state.ReducerAccount.id,
        clientSource: state.ReducerApp.clientSource,
        isBlurView: state.ReducerApp.isBlurView,
        shopUnavailable: state.ReducerWSMart.shopUnavailable,
        menu: state.ReducerApp.menu,
        categories: state.ReducerApp.categories,
        isFinishedRequest: state.ReducerApp.isFinishedRequest,
        isFullscreen: state.ReducerApp.isFullscreen,
        coupons: state.ReducerAccount.coupons,
    };
};

const PageHeader: React.FC<unknown> = (): React.ReactElement => {
    const dispatch = useDispatch();
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const bundle = state.bundles?.[state.currentPage?.bundleId];

    const backHome = () => History.backHome();

    const back = () => {
        if (state.clientSource) {
            dwhExport.send(DWH_EVENTS.CLIENT_GO_TO, { client_source: state.clientSource });
            closeBrowser();
            return;
        }

        if (state.port.isVisible) {
            closePort();
            dispatch(changeVisiblePort(false, state.port.shipId, state.port.exteriorId));
            changeBackground(false);
            if (!History.hasHistory()) {
                return backHome();
            }
            History.back();
        } else if (!History.hasHistory() || bundle?.serialPurchase) {
            openCategoryByName(state.currentPage?.name);
        } else {
            History.back();
        }
    };

    const goToPremShop = () => {
        playButtonGoldClickSound();

        dwhExport.send(DWH_EVENTS.OPEN_PREM_SHOP_FROM_TOP_MENU);
        openCategoryByName(CATEGORIES.WSMART);
    };

    const countNewCoupons = hasNewCoupons();

    const renderUserContent = () => {
        if (!state.accountId) {
            return null;
        }

        const isCouponsShown = !isCouponsTabHidden();

        const menu = state.menu;
        const premiumCategory = menu[CATEGORIES.WSMART];

        const isPremiumCategoryEnabled = !state.shopUnavailable && !isRealMoneyPurchaseDisabled() && premiumCategory?.url;

        const totalNewCoupons = getCountCouponsWithLabelNew() || countNewCoupons || 0;

        return (
            <React.Fragment>
                <div className={styles.delimiter}>
                    <div className={styles.notificationIcon} />
                    <NotificationsWrapper />
                </div>
                <div className={className({ [styles.delimiter]: isCouponsShown || isPremiumCategoryEnabled })}>
                    {isCouponsShown && (
                        <GuideDecorator names={[GUIDE_NAMES.guide_coupon, GUIDE_NAMES.guide_new_coupons]}>
                            <DivTooltip tooltipBody={<DefaultTooltip text={t('Купоны')} />}>
                                <div className={styles.couponWrapper} onClick={showMyCoupons} id={'guideNewCoupons'}>
                                    <span className={styles.couponText}>{t('Купоны')}</span>
                                    <LabelContainer className={styles.label} isVisible={!!totalNewCoupons} text={totalNewCoupons > 1 ? totalNewCoupons.toString() : null} />
                                </div>
                            </DivTooltip>
                        </GuideDecorator>
                    )}
                    {isPremiumCategoryEnabled && (
                        <DivTooltip tooltipBody={<DefaultTooltip text={t('Дублоны, дни Корабельного премиум аккаунта, </br> кредиты и другие наборы')} />}>
                            <div className={styles.goldPurchaseWrapper} onClick={goToPremShop}>
                                <span className={styles.goldText}>{t('Купить дублоны')}</span>
                            </div>
                        </DivTooltip>
                    )}
                    <PremiumAccountLink />
                </div>
            </React.Fragment>
        );
    };

    const isFullscreen = state.isFullscreen;
    const isEnabledButtonBack = state.clientSource || state.currentPage?.isBundlePage || state.port?.isVisible || isFullscreen;

    const headerClassNames = classNames(styles.header, {
        [styles.isInGame]: isInGameBrowser,
        [styles.transparent]: state.port?.isVisible,
        [styles.blurElement]: isDockyardCategory() && state.isBlurView,
        [styles.fullScreen]: isFullscreen,
    });

    const classesHeaderWrapper = classNames(styles.headerWrapper, {
        [styles.isVisible]: state.isFinishedRequest,
    });

    const buttonBackLabel = state.clientSource ? CLIENT_BACK_BUTTON_BY_ROUTE[state.clientSource] || CLIENT_BUTTON_COPYRIGHT_BY_ROUTE[state.clientSource] : t('назад');

    return (
        <>
            <MobileHeader />
            <div className={headerClassNames}>
                {isEnabledButtonBack && (
                    <div
                        className={classNames(styles.navigationWrapper, {
                            [styles.port]: state.port?.isVisible,
                        })}
                    >
                        <BackButton
                            label={buttonBackLabel}
                            onClick={isFullscreen ? backHome : back}
                            className={classNames(styles.navigationBack, {
                                [styles.port]: state.port?.isVisible,
                            })}
                        />
                    </div>
                )}

                {!state.port?.isVisible && (
                    <div className={classesHeaderWrapper}>
                        <div className={styles.panel}>
                            <div className={styles.delimiterWithoutBorder}>
                                <SearchPanel
                                    className={{
                                        input: styles.searchInput,
                                    }}
                                />
                            </div>
                            {state.isFinishedRequest && (
                                <React.Fragment>
                                    {renderUserContent()}
                                    {!isInGameBrowser && (
                                        <div className={styles.delimiter}>
                                            <User />
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default PageHeader;
