import * as React from 'react';
import { items } from '@wg/wows-entities/const';
import styles from './CommonRewardsLayout.scss';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import ChanceLabel from '~/components/ChanceLabel/ChanceLabel';
import classNames from 'classnames';
import PortPreviewButton from '~/components/PortPreviewButton/PortPreviewButton';
import { openPortByItem } from '~/settings/port';
import { PortPreviewType } from '~/components/Port/settings';
import { settings } from '~/utils/settings';
import { useDispatch } from 'react-redux';
import { hidePopup } from '~/Actions/ActionApp';
import store from '~/Store';
import { changeVisiblePopup } from '~/Actions/ActionApp';
import { POPUPS_NAME } from '~/components/PopupManager';
import { isCurrency } from '~/utils/currencies';
import { ALLOWED_THEMES } from '~/Layouts/Themes/types';

interface ICommonReward {
    reward: ILootboxReward;
    className?: string;
    chanceLabelClassName?: string;
    inventoryCount?: number;
    bundleId?: number;
    isAvailablePortPreview?: boolean;
    lootboxId: number;
    theme?: ALLOWED_THEMES;
}

const CommonReward = React.memo(({ reward, className, chanceLabelClassName, inventoryCount, bundleId, isAvailablePortPreview, lootboxId, theme }: ICommonReward) => {
    const dispatch = useDispatch();

    const openPort = () => {
        dispatch(hidePopup());
        openPortByItem(
            {
                shipId: settings.camouflagePreviewDefaultShip,
                type: reward.type,
                identifier: reward.id,
            },
            bundleId,
            false,
            PortPreviewType.CONTAINER,
            { lootboxId },
        );
    };

    const DescriptionPopupWrapper = ({ children }: { children: any }) => {
        if (reward.type === items.LOOTBOX) {
            const onClick = () => {
                store.dispatch(
                    changeVisiblePopup(POPUPS_NAME.ITEM_DESCRIPTION_POPUP, true, {
                        item: {
                            identifier: reward.id,
                            type: reward.type,
                        },
                        autoDescriptionSettings: {},
                        theme: theme,
                    }),
                );
            };
            return (
                <div onClick={onClick} className={classNames(styles.wowsEntity, styles.wowsEntityClickable)}>
                    {children}
                </div>
            );
        }

        return children;
    };

    return (
        <div className={classNames(styles.item, className)}>
            {isAvailablePortPreview && <PortPreviewButton onlyIcon className={styles.portPreviewButton} onClick={openPort} />}
            <DescriptionPopupWrapper>
                <WoWSEntity
                    id={reward.id}
                    type={reward.type}
                    className={styles.wowsEntity}
                    amount={reward.amount}
                    presentation={{
                        withTooltip: true,
                        withText: true,
                        iconPosition: 'left',
                        renderWithoutFade: true,
                        hideTooltipAmount: isCurrency(reward.type),
                    }}
                    customisation={{
                        inventoryCount: inventoryCount,
                    }}
                />
            </DescriptionPopupWrapper>
            <ChanceLabel chance={reward.probabilityDisplayed} className={classNames(styles.changeLabel, chanceLabelClassName)} />
        </div>
    );
});

export default CommonReward;
