import store from '~/Store';
import { isBundlePurchased, isDisplayRestricted } from '~/utils/bundles';
import { getUrl, urlsMap } from '~/utils/menu';
import { arrayToObjectByKey, updateSearchParam } from '~/utils/utils';
import { changeMultipleFilter, resetCategoryFilter, updateActiveFilterPreset, updateCategoryByFilters } from '~/Actions/ActionApp';
import Account from '~/account/Account';
import { FEATURING_TEMPLATES } from '~/Layouts/FeaturingLayout';
import dwhExport from '~/api/dwhExport';
import { CATEGORIES, DOCKYARD_CATEGORY_TYPE, DWH_EVENTS, FILTER_ALLOWED_BUNDLES_NAME, FILTER_CURRENCY_NAME, FILTER_DISCOUNT_NAME, FILTER_DISCOUNT_VALUES } from '~/const';
import { isEnabledCouponsFromBundle } from '~/utils/coupons';
import { SINGLE_BUNDLE_PAGE } from '~/Layouts/Themes/ThemeManager';
import { t } from '~/utils/localization';
import { getAvailableFilters, hasCouponFilterFromCategory, isNeedToRenderFilter } from '~/utils/filters';
import { armoryState, getPlatform, getUserId, settings } from '~/utils/settings';
import { CategoryType, PRESETS } from '~/types/category';
import { BundlePurchaseTypes } from '~/types/bundle';
import History from '~/utils/history';
import { State } from '~/Reducers';
import { FEATURING_DESTINATION } from '~/types/contents';

export const prepareCategoriesForState = (bundles: IBundleList) => {
    const categories = [...armoryState.content.categories];
    const maps = arrayToObjectByKey(categories, 'name');

    if (!Array.isArray(categories)) {
        return {};
    }

    const subCategoryNames: string[] = [];

    const result = categories.reduce((state: Record<string, Partial<ICategory>>, category) => {
        if (category.parentCategory && isCategoryDisplayRestricted(maps[category.parentCategory], bundles)) {
            category.parentCategory = null;
        }

        if (isCategoryDisplayRestricted(category, bundles)) {
            return state;
        }

        if (category.subCategories?.length) {
            category.subCategories = category.subCategories.filter((subCategoryName) => {
                return !isCategoryDisplayRestricted(maps[subCategoryName], bundles);
            });
            subCategoryNames.push(...category.subCategories);
        }

        category.isContainBundlesForReal = category.bundles.some((bundleId: number) => {
            return !!bundles[bundleId]?.productCode;
        });

        if (category.activityCountdown?.activeFrom) {
            category.activityCountdown.isEnabled = new Date(category.activityCountdown?.activeFrom).getTime() <= new Date().getTime();
        }

        state[category.name] = category;
        return state;
    }, {});

    subCategoryNames.forEach((subCategoryName) => {
        result[subCategoryName].parentCategoryData = result[result[subCategoryName].parentCategory];
    });

    result[CATEGORIES.FEATURED] = Object.assign(result[CATEGORIES.FEATURED] || {}, {
        url: urlsMap.home,
        name: CATEGORIES.FEATURED,
        title: t('Рекомендованное: главная'),
        subCategories: [],
    });

    return result as ICategories;
};

export const openBundleById = (bundleId: number, replace = false) => {
    const bundle = store.getState().ReducerApp.bundles[bundleId];
    openBundleByUrl(bundle.categories[0], bundleId, replace);
};

export const openBundleByName = (bundleName: string, replace = false) => {
    const bundle: IBundle = Object.values(store.getState().ReducerApp.bundles).find((bundle) => bundle.name === bundleName);
    openBundleByUrl(bundle.categories[0], bundle.id, replace);
};

export const openBundleFromCurrentPage = (currentPageName: ICategoryList, bundle: IBundle) => {
    let categoryLinkName = currentPageName;
    if (bundle.categories.length && !bundle.categories.includes(currentPageName)) {
        categoryLinkName = bundle.categories[0];
    }
    openBundleByUrl(categoryLinkName || CATEGORIES.FEATURED, bundle.id);
};

export const openBundleByUrl = (categoryName: ICategoryList, bundleId: number, replace = false) => {
    const { categories, activePreset } = store.getState().ReducerApp;
    let params;

    if (categoryName) {
        const parentCategoryName = categories?.[categoryName]?.parentCategory as ICategoryList;
        const parentCategory = categories[parentCategoryName];
        params = { categoryName: parentCategory ? parentCategory.name : categoryName, id: bundleId };
    } else {
        params = { categoryName: CATEGORIES.FEATURED, id: bundleId };
    }

    const meta: Record<string, number | string> = {
        bundle_id: bundleId,
    };

    if (categoryName) {
        meta['from_category'] = categoryName;
    }

    if (activePreset) {
        meta['from_filter_preset'] = activePreset;
    }

    dwhExport.send(DWH_EVENTS.OPEN_BUNDLE, meta);

    const url = getUrl(urlsMap.bundle, params);
    History.navigate(url, {
        replace,
    });
};

export type COUPON_PLACE = 'coupon';
export type PURCHASE_PLACE = 'purchase';

export const prepareCategoryFromCoupon = (couponId: number) => {
    const state = store.getState();
    const appState = state.ReducerApp;
    const accountState = state.ReducerAccount;
    const coupons = arrayToObjectByKey(accountState.coupons, 'id');
    const coupon = coupons[couponId];

    const availableCurrenciesFilters = getAvailableFilters(coupon.category, FILTER_CURRENCY_NAME);
    const availableDiscountFilters = getAvailableFilters(coupon.category, FILTER_DISCOUNT_NAME);

    const availableCurrencies = getCurrenciesByCategoryName(appState.bundles, appState.categories, coupon.category).filter((currency: string) => {
        return !coupon.deniedCurrencies.includes(currency) && availableCurrenciesFilters.includes(currency);
    });

    const filtersData: Record<string, string[]> = {};

    let triggerFilterName: FILTER_INFO_NAME = FILTER_CURRENCY_NAME;

    if (availableCurrenciesFilters.length > 1) {
        filtersData[FILTER_CURRENCY_NAME] = availableCurrencies;
    }

    if (!coupon.isDisabled && availableDiscountFilters.includes('coupon')) {
        triggerFilterName = FILTER_DISCOUNT_NAME;
        filtersData[FILTER_DISCOUNT_NAME] = [FILTER_DISCOUNT_VALUES.COUPON];
    }

    if (coupon.allowedBundles?.length) {
        filtersData[FILTER_ALLOWED_BUNDLES_NAME] = coupon.allowedBundles;
    }

    if (!Object.keys(filtersData).length) {
        return;
    }

    if (appState.activePreset) {
        store.dispatch(updateActiveFilterPreset(null));
    }

    store.dispatch(changeMultipleFilter(coupon.category, filtersData, triggerFilterName));

    // @ts-ignore
    store.dispatch(updateCategoryByFilters(coupon.category));
};

export const resetCouponFiltersIfNeeded = () => {
    const state = store.getState();
    const appState = state.ReducerApp;
    const accountState = state.ReducerAccount;
    const categoryName = appState.currentPage?.name as ICategoryList;
    const isEnabledCouponForCategory = Account.hasCouponFromCategory(accountState.coupons, categoryName);
    const isAvailableCouponForCategoryBundles = appState.bundleCategory[categoryName]?.some((bundleId: number) => {
        if (!appState.bundles[bundleId]) {
            return false;
        }
        return isEnabledCouponsFromBundle(accountState.coupons, appState.bundles[bundleId]);
    });

    // @ts-ignore
    const filters = { ...(appState.filters?.[categoryName] || {}) };
    const hasDiscountType = Array.isArray(filters?.discount_type);

    if (hasDiscountType && (!isEnabledCouponForCategory || !isAvailableCouponForCategoryBundles)) {
        filters.discount_type = filters.discount_type.filter((filter: string) => filter !== 'coupon') || [];
        updateSearchParam({ [FILTER_DISCOUNT_NAME]: filters.discount_type });
        if (!filters.discount_type.length) {
            delete filters.discount_type;
        }
        if (filters.allowed_bundles) {
            delete filters.allowed_bundles;
        }
        store.dispatch(changeMultipleFilter(categoryName, filters, FILTER_CURRENCY_NAME));
    } else {
        store.dispatch(changeMultipleFilter(categoryName, filters, hasDiscountType ? FILTER_DISCOUNT_NAME : FILTER_CURRENCY_NAME));
    }

    // @ts-ignore
    store.dispatch(updateCategoryByFilters(categoryName));
};

export function reloadCurrentCategoryFilter() {
    const state = store.getState();
    const appState = state.ReducerApp;
    const categoryName = appState.currentPage?.name as ICategoryList;
    store.dispatch(updateCategoryByFilters(categoryName));
}

export type IOptions = {
    place?: COUPON_PLACE | PURCHASE_PLACE | null;
    couponId: number;
};

export const openCategoryByName = (categoryName?: string, options?: IOptions, replace = false) => {
    if (!categoryName) {
        History.navigate(urlsMap.home, { replace });
        return;
    }

    const { categories, menu, filters } = store.getState().ReducerApp;
    const categoryConfig = menu?.[categoryName];
    if (categoryConfig && categoryConfig.middleware && !categoryConfig.middleware()) {
        return;
    }
    if (categoryConfig?.isNotCategory) {
        History.navigate(categoryConfig.url, { replace });
        return;
    }

    const parentCategoryName = categories?.[categoryName as ICategoryList]?.parentCategory as ICategoryList;
    const parentCategory = categories?.[parentCategoryName];
    let params;
    let url;

    if (!parentCategory) {
        url = urlsMap.categories;
        params = { categoryName: categoryName };
    } else {
        url = urlsMap.subCategory;
        params = { categoryName: parentCategory.name, subCategoryName: categoryName };
    }

    History.navigate(getUrl(url, params), { replace });

    const isValidPlaceForFilters = options?.couponId && (options?.place === 'coupon' || options?.place === 'purchase');
    if (isNeedToRenderFilter(categoryName) && hasCouponFilterFromCategory(categories[categoryName]) && isValidPlaceForFilters) {
        prepareCategoryFromCoupon(options.couponId);
    } else if (filters && Object.keys(filters[categoryName as keyof typeof filters] || {}).length > 0) {
        store.dispatch(resetCategoryFilter(categoryName));
    }
};

export const getCurrenciesByCategoryName = (bundles: IBundleList, categories: ICategories, categoryName: string): string[] => {
    let categoryBundles: number[] = [];

    if (categoryName === CATEGORIES.FEATURED) {
        const featuring = armoryState.content.featuring;
        categoryBundles = featuring.reduce((array: number[], content: any) => {
            content.contentBlocks.forEach((block: IContentBlock) => {
                const data = block.data as IContentBlockData;
                if (block.type !== FEATURING_TEMPLATES.CATEGORY_PREVIEW && data.bundles) {
                    const _bundles = data.bundles.map((item) => parseInt(item.id.toString(), 10));
                    array.push(..._bundles);
                }
            });
            return array;
        }, []);
    } else {
        if (!!categories[categoryName]?.bundles?.length) {
            categoryBundles = categories[categoryName]?.bundles || [];
        } else {
            categoryBundles =
                categories[categoryName]?.subCategories?.reduce((array: number[], category: string) => {
                    const _bundles = categories[category]?.bundles;
                    if (!_bundles) {
                        return array;
                    }
                    array.push(..._bundles);
                    return array;
                }, []) || [];
        }
    }

    const currencies = categoryBundles.reduce((state: any, bundleId: number) => {
        const bundle = bundles[bundleId];
        if (!bundle || bundle.price === undefined || bundle.purchaseType === BundlePurchaseTypes.REAL_CURRENCY) {
            return state;
        }

        state.add(bundle.currency);
        bundle.additionalCurrency && state.add(bundle.additionalCurrency);

        if (bundle.serialSequence) {
            bundle.serialSequence.forEach((id) => {
                const serialBundle = bundles[id];
                if (serialBundle) {
                    state.add(serialBundle.currency);
                    serialBundle.additionalCurrency && state.add(serialBundle.additionalCurrency);
                }
            });
        }

        return state;
    }, new Set());

    return [...currencies];
};

const categoryTypesWithoutBundles = [CategoryType.FEATURED, CategoryType.WORSHIP, CategoryType.AUCTION, CategoryType.SEA_BATTLE, CategoryType.TRADEIN, CategoryType.MRPS];
export const isCategoryDisplayRestricted = (item: ICategory, bundles: IBundleList) => {
    if (!item) {
        return true;
    }

    if (isDisplayRestricted(item)) {
        return true;
    }

    const type = item.type as CategoryType;

    if (categoryTypesWithoutBundles.includes(type)) {
        return false;
    }

    if (item.parentCategory && !item?.bundles?.length) {
        return true;
    }

    if (item.subCategories?.length) {
        return false;
    }

    if (item.promoTimer && !item.bundles?.length) {
        return false;
    }

    if (!bundles || !item || !Array.isArray(item?.bundles)) {
        return false;
    }

    return item.bundles.every((id) => {
        return !bundles[id] || isDisplayRestricted(bundles[id]);
    });
};

export const isAllSubCategoryDisplayRestricted = (category: ICategory) => {
    const categories = store.getState().ReducerApp.categories;

    if (category.type === CategoryType.TRADEIN) {
        return false;
    }

    return category?.subCategories?.every((categoryName: ICategoryList) => {
        return !categories?.[categoryName]?.bundles?.length;
    });
};

export const getAvailableSubCategories = (subCategories: ICategoryList[], categories: ICategories) => {
    return [...subCategories].splice(1, subCategories.length).filter((_subCategoryName: ICategoryList) => {
        const category = categories?.[_subCategoryName];
        if (category?.type === CategoryType.TRADEIN) {
            return true;
        }

        if (category?.type === CategoryType.MRPS) {
            return true;
        }
        return !!category?.bundles?.length;
    });
};

export const getFirstEventCategory = (categories: ICategories) => {
    return Object.values(categories).find((category) => category.name?.includes?.('event'));
};

export const getPresetByName = (presets: ICategoryPresets[], name: string): ICategoryPresets => {
    return presets?.find((preset: ICategoryPresets) => preset.name === name);
};

export const getPresetByType = (presets: ICategoryPresets[], type: PRESETS): ICategoryPresets => {
    return presets?.find((preset: ICategoryPresets) => preset.type === type);
};

export const isSingleBundleCategory = (category: ICategory) => {
    return category?.theme === SINGLE_BUNDLE_PAGE;
};

export const isDockyardCategory = () => {
    const state = store.getState().ReducerApp;

    return state.categories[state.currentPage?.name]?.type === DOCKYARD_CATEGORY_TYPE;
};

type ReplacementCategoryMap_Type = {
    [key in ICategoryList]?: {
        categoryName: ICategoryList;
        data: ICategory;
    };
};
export const getMenuCategories = (categoriesFromState: ICategories, bundles: IBundleList) => {
    const userId = getUserId();
    const replacementCategoryMap: ReplacementCategoryMap_Type = {};

    let categories: ICategory[] = Object.values(categoriesFromState)
        .map((category) => {
            const availableSubCategoryBundles =
                bundles && category?.parentCategory && !!category?.bundles?.length
                    ? [...(category?.bundles || [])].filter((bundleId) => {
                          const bundle = bundles[bundleId];
                          if (!bundle) {
                              return false;
                          }
                          return !isDisplayRestricted(bundle);
                      })
                    : null;

            if (Array.isArray(availableSubCategoryBundles) && !availableSubCategoryBundles.length) {
                category.bundles = [];
            }

            return category;
        })
        .map((category) => {
            if (!category.subCategories?.length) {
                return category;
            }

            const availableSubCategories = getAvailableSubCategories(category.subCategories as ICategoryList[], categoriesFromState);
            if (availableSubCategories?.length === 1) {
                replacementCategoryMap[category.name as ICategoryList] = {
                    categoryName: availableSubCategories[0],
                    data: Object.assign(categoriesFromState[availableSubCategories[0]], {
                        parentCategory: null,
                        subCategories: [],
                        icon: categoriesFromState[availableSubCategories[0]]?.icon || category.icon,
                        parentIconClassName: category.name,
                        url: getUrl(urlsMap.categories, { categoryName: availableSubCategories[0] }),
                    }),
                };
            }

            return category;
        });

    Object.keys(replacementCategoryMap).forEach((categoryName: ICategoryList) => {
        categories = categories
            .filter((category) => {
                return category.name !== replacementCategoryMap[categoryName].categoryName;
            })
            .map((category) => {
                if (category.name === categoryName) {
                    category = replacementCategoryMap[categoryName].data;
                }
                return category;
            });
    });

    const excludeCategories = categories.reduce((list: string[] | any, item) => {
        if ((userId && !armoryState.account.isParagonsEnabled && item.name === 'paragons') || (!userId && item.name === 'paragons') || isCategoryDisplayRestricted(item, bundles)) {
            list.push(item.name);
        }

        if (item.subCategories.length) {
            item.subCategories.forEach((category) => {
                if (item.name !== category) {
                    list.push(category);
                }
            });
        }

        return list;
    }, []);

    return categories
        .filter((item) => {
            return !excludeCategories.includes(item.name);
        })
        .reduce((result: any, item) => {
            result[item.name] = item;
            return result;
        }, {});
};

export const prepareSubCategories = () => {
    armoryState.content.categories.forEach((category) => {
        if (category.subCategories.length) {
            category.subCategories.unshift(category.name);
        }
    });
};

export const isAllPreviewCategoryBundlesPurchased = (category: ICategory): boolean => {
    const state = store.getState() as State;
    if (category.previewCategorySettings?.bundlesList?.length) {
        return category.previewCategorySettings?.bundlesList.every((bundleToPreview) => {
            const bundle = state?.ReducerApp?.bundles[bundleToPreview.id];
            return !bundle || isBundlePurchased(bundle);
        });
    }

    const bundleIds: number[] = state.ReducerApp?.bundleCategory?.[category.name];
    return !!bundleIds?.every((bundleId) => {
        const bundle = state?.ReducerApp?.bundles[bundleId];
        return !bundle || isBundlePurchased(bundle);
    });
};

export const isRealMoneyPurchaseDisabledBySettings = () => {
    const disabledPlatforms = settings?.disableRealMoneyPurchaseForPlatforms || [];
    if (!settings.urls.wsmartUrl) {
        return true;
    }

    return settings.isRealMoneyPurchaseDisabled || disabledPlatforms.includes(getPlatform());
};

export const isRealMoneyPurchaseDisabled = () => {
    const { categories } = store.getState().ReducerApp;
    if (!categories[CATEGORIES.WSMART]) {
        return true;
    }

    return isRealMoneyPurchaseDisabledBySettings();
};

export function isCategoryWithoutBundles(category: ICategory): boolean {
    switch (category.type) {
        case CategoryType.MRPS: {
            return true;
        }

        default:
            return false;
    }
}

export const isEnableDockyard = () => Account.isDockyardCategoryEnabled();

export const isEnabledSeaBattleByMetashop = (): boolean => !!settings.urls?.seaBattleSettings;

export const getLimitForFeaturedBundlesForSubCategory = (bundles: IBundleList, categoryBundlesIds: number[], defaultWidth = 1) => {
    let limit = 1;
    let width = 0;
    let i = 0;
    while (true) {
        const bundle = bundles[categoryBundlesIds[i]];
        if (!bundle) {
            break;
        }
        const bundleWidth = bundle.sizeInGrid;
        if (bundleWidth?.length > 1) {
            const [leftNumber, rightNumber] = bundleWidth.split('/');
            width += parseInt(leftNumber, 10) / parseInt(rightNumber, 10);
        } else {
            width += parseInt(bundleWidth, 10);
        }
        if (width >= defaultWidth) {
            break;
        }
        limit++;
        i++;
    }
    return limit;
};

export function getTabTitle(category: ICategory, parentCategoryName: string) {
    return category?.tabTitle || (parentCategoryName === category?.name ? t('Рекомендованное') : category.title);
}

export function* generateAllCategoryBundlesDeeply(categoryName: ICategoryList, bundleSplice?: number, deep = 3): Generator<IBundle> {
    const appState = store.getState().ReducerApp;
    const category = appState.categories?.[categoryName];
    const bundles = appState.bundles;

    if (category.name === CATEGORIES.FEATURED) {
        yield* generateFeaturingBundles();
        return;
    }

    for (const bundleId of category.bundles) {
        if (bundleSplice === 0) return;
        if (bundleSplice) bundleSplice--;
        yield bundles[bundleId];
    }

    if (!category.subCategories?.length || !deep) return;

    for (const subCategoryName of category.subCategories) {
        if (subCategoryName === categoryName) continue;
        yield* generateAllCategoryBundlesDeeply(subCategoryName, bundleSplice, deep - 1);
    }
}

export function* generateFeaturingBundles(): Generator<IBundle> {
    const appState = store.getState().ReducerApp;
    const featuring = (appState.featuring as IFeature[]).filter((featuring: IFeature) => featuring.destination === FEATURING_DESTINATION.MAIN)[0] as IFeature;
    if (!featuring || !featuring.contentBlocks?.length) return;

    for (const contentBlock of featuring.contentBlocks) {
        const data = contentBlock.data as IContentBlockData;

        if (contentBlock.type === FEATURING_TEMPLATES.BUNDLE_LIST) {
            if (!data.bundles?.length) continue;
            for (const contentBlockBundle of data.bundles) {
                yield appState.bundles[contentBlockBundle.id];
            }
        } else if (contentBlock.type === FEATURING_TEMPLATES.CATEGORY_PREVIEW) {
            const category = appState.categories[data.categoryName];
            if (!category || !category.bundles?.length) continue;
            let categoryBundles = category.bundles;
            categoryBundles = data.bundlesCount ? categoryBundles.slice(0, data.bundlesCount) : categoryBundles;
            for (const bundleId of categoryBundles) {
                yield appState.bundles[bundleId];
            }
        }
    }
}

export function getLandingCategory(categories: ICategories): ICategory | null {
    let result = null;
    const categoriesArray = Object.values(categories);
    categoriesArray.forEach((item: ICategory) => {
        if (item.type === CATEGORIES.LANDING) {
            result = item;
        }
    });
    return result;
}
