import store from '~/Store';
import { showMenuItem } from '~/Actions/ActionApp';
import { CATEGORIES } from '~/const';
import AbstractPreloader from '~/core/AppInit/AbstractPreloader';
import { isDockyardEnabledForAccount, getDockyardSeasonSettings } from '~/api/dockyard';
import { getStepByName, getStepsFromGuide, GUIDE_NAMES } from '~/components/WelcomePage/steps';
import { hideGuideStep, initGuideSteps } from '~/Actions/ActionAccount';
import { updateDockyardSeasonSettings } from '~/Actions/ActionDockyard';

export default class DockyardPreloader extends AbstractPreloader {
    async load(): Promise<any> {
        isDockyardEnabledForAccount().then((isEnabled) => {
            if (isEnabled) {
                store.dispatch(showMenuItem(CATEGORIES.DOCKYARD));

                getDockyardSeasonSettings().then((seasonSettings) => {
                    store.dispatch(updateDockyardSeasonSettings(seasonSettings));
                    store.dispatch(initGuideSteps(getStepsFromGuide()));
                });
            } else {
                const guide = getStepByName(GUIDE_NAMES.guide_dockyard);
                if (guide.isNeedToShown()) {
                    store.dispatch(hideGuideStep(guide));
                }
            }
        });
        return Promise.resolve(1);
    }
}
