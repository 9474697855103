import * as React from 'react';
import classNames from 'classnames';
import styles from '~/Layouts/Layouts.scss';
import { PresentationStyles, items } from '@wg/wows-entities/const';
import { BUNDLE_TYPES } from '~/components/Bundle/BundleManager';
import { openBundleByUrl } from '~/utils/category';
import { CATEGORIES, BUNDLE_GRID_SIZE, BUNDLE_HEIGHT_GRID } from '~/const';
import { DivTooltip } from '@wg/wows-react-uikit';
import { isMobileOrTabletWindow } from '~/utils/utils';
import store from '~/Store';
import { shallowEqual, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import Account from '~/account/Account';
import LabelDecorator, { LABEL_TYPE } from '~/decorators/LabelDecorator/LabelDecorator';
import { ICurrentPage } from '~/Actions/ActionAppType';
import { isAllowedNewLabelFromBundle } from '~/utils/labels';
import LabelContainer from '~/containers/LabelContainer/LabelContainer';
import CrewPoints from '~/components/CrewPoints/CrewPoints';
import GlowEffectDecorator from '~/components/GlowEffect/GlowEffectDecorator';
import BundleHeaderContent from '~/components/Bundle/Default/BundleHeaderContent';
import BundleFooterContent from '~/components/Bundle/Default/BundleFooterContent';
import BundleDecoration from '~/components/Bundle/Decoration/BundleDecoration';
import { isAdmiralPack, isCommanderPack, isFreeBundle, isEnabledTooltipFromItemByType, isOnlyShipBundle, isModernization } from '~/utils/bundles';
import SpecialBundleFooterContent from '~/components/Bundle/Content/SpecialBundleFooterContent/SpecialBundleFooterContent';
import HoverVideo from '~/customization/HoverVideo/HoverVideo';
import { BundlePurchaseTypes } from '~/types/bundle';
import { playCardClickSound } from '~/api/WoWsClient';
import PromoTimer from '~/components/Bundle/PromoTimer/PromoTimer';
import { LoadAmountToReward } from '~/components/Bundle/LoadAmountToReward';
import { Howl } from 'howler';
import { getShipFeatures } from '~/utils/getShipFeatures';
import { getSoundKeyForCurrentPage, isEnabledSounds } from '~/utils/sounds/settings';

interface IBundleItemProps {
    bundle: IBundle;
    fromCategory?: string;
    templateLayout?: string;
    image?: string;
    sizeInGrid?: string;
    heightInGrid?: string;
    isLazilyRendered?: boolean;
    isAlreadyPurchased?: boolean;
    presetName?: string;
}

export const isSerialRandomBundle = (bundle: IBundle): boolean => {
    return bundle.type === BUNDLE_TYPES.serial && !!bundle.randomBundleChildren?.length;
};

interface IBundleWidthMap {
    [key: string]: string;
}

interface IBundleHeightMap {
    [key: string]: string;
}

export const BUNDLE_WIDTH_MAP: IBundleWidthMap = {
    [BUNDLE_GRID_SIZE.FULL]: styles.full,
    [BUNDLE_GRID_SIZE.THREE_SECOND]: styles.threeSecond,
    [BUNDLE_GRID_SIZE.HALF]: styles.half,
    [BUNDLE_GRID_SIZE.ONE_THIRD]: styles.third,
    [BUNDLE_GRID_SIZE.ONE_FOUR]: styles.default,
    [BUNDLE_GRID_SIZE.THREE_QUARTERS]: styles.threeQuarters,
    [BUNDLE_GRID_SIZE.TWO_THIRDS]: styles.twoThirds,
};

export const BUNDLE_HEIGHT_MAP: IBundleHeightMap = {
    [BUNDLE_HEIGHT_GRID.ONE_HEIGHT]: styles.oneHeight,
    [BUNDLE_HEIGHT_GRID.TWO_HEIGHT]: styles.twoHeight,
};

const getLayoutWidthClassName = (width: string, bundle: IBundle, isShipsCategory: boolean) => {
    if (width) {
        return BUNDLE_WIDTH_MAP[width];
    }

    if (isShipsCategory) {
        return BUNDLE_WIDTH_MAP[BUNDLE_GRID_SIZE.ONE_THIRD];
    }

    const primaryItem = bundle.primaryItem;
    if (!bundle.sizeInGrid && primaryItem && primaryItem.type === items.VEHICLES) {
        return BUNDLE_WIDTH_MAP[BUNDLE_GRID_SIZE.ONE_THIRD];
    }

    return BUNDLE_WIDTH_MAP[bundle.sizeInGrid || BUNDLE_GRID_SIZE.ONE_FOUR];
};

const getLayoutHeightClassName = (height: string, bundle: IBundle) => {
    if (height) {
        return BUNDLE_HEIGHT_MAP[height];
    }

    return BUNDLE_HEIGHT_MAP[bundle.height || BUNDLE_HEIGHT_GRID.ONE_HEIGHT];
};

const SpecialContentByPrimaryType = (props: { primaryItem: IBundleEntity }) => {
    if (!props.primaryItem) {
        return null;
    }

    switch (props.primaryItem.type) {
        case items.CREWS:
            return (
                <div className={styles.crewsPoints}>
                    <CrewPoints points={props.primaryItem.customisation.points} />
                </div>
            );
    }

    return null;
};

export const getBundleByType = (bundle: IBundle) => {
    const accountState = store.getState().ReducerAccount;

    switch (bundle.type) {
        case BUNDLE_TYPES.random:
            return Account.getRandomBundleChild(accountState.selectedRandomBundles || {}, bundle);

        default:
            return bundle;
    }
};

interface IStateSelector {
    categories: ICategories;
    currentPage: ICurrentPage;
    inventory: InventoryState;
    viewedLabels: string[];
    isTrusted: boolean;
    bundles: IBundleList;
}

const stateSelector = (state: State): IStateSelector => {
    return {
        categories: state.ReducerApp.categories,
        currentPage: state.ReducerApp.currentPage,
        inventory: state.ReducerAccount.inventory,
        viewedLabels: state.ReducerAccount.viewedLabels,
        isTrusted: state.ReducerApp.isTrusted,
        bundles: state.ReducerApp.bundles,
    };
};

const Bundle = React.forwardRef((props: IBundleItemProps, ref: React.RefObject<HTMLDivElement>) => {
    const state = useSelector<State, IStateSelector>(stateSelector, shallowEqual);
    const bundle = getBundleByType(state.bundles[props.bundle.id]);
    const currentPageName = state.currentPage?.name;
    const categoryConfig = state.categories?.[currentPageName];
    const parentCategoryConfig = state.categories?.[categoryConfig?.parentCategory];
    const howl = new Howl({
        src: [bundle.hoverSound?.mp3],
        html5: true,
    });

    const onMouseEnter = () => {
        const isSoundDisabled =
            isMobileOrTabletWindow ||
            !bundle.hoverSound ||
            (categoryConfig && !isEnabledSounds(getSoundKeyForCurrentPage(categoryConfig))) ||
            (parentCategoryConfig && !isEnabledSounds(getSoundKeyForCurrentPage(parentCategoryConfig)));
        if (isSoundDisabled) return;
        howl.seek(0);
        howl.volume(1);
        howl.play();
    };

    const onClick = () => {
        playCardClickSound();

        let categoryLinkName = currentPageName;

        if (bundle.categories.length && !bundle.categories.includes(currentPageName)) {
            categoryLinkName = bundle.categories[0];
        }

        openBundleByUrl(categoryLinkName || CATEGORIES.FEATURED, bundle.id);
    };

    const isShipsCategory = bundle.categories.includes(CATEGORIES.SHIPS) || bundle.categories.includes(CATEGORIES.PREM_SHIPS);

    const _commanderPack = isCommanderPack(bundle);
    const _isAdmiralPack = isAdmiralPack(bundle);
    const _isModernization = isModernization(bundle);

    const primaryItem = bundle.primaryItem;
    const offerWrapperClassName = classNames(
        {
            [styles.offerItem]: true,
            [styles.alreadyPurchased]: bundle.isPurchased,
            [styles.commander]: _commanderPack,
            [styles.isModernization]: _isModernization,
            'armory__auto--bundle_default': !bundle.purchaseType || bundle.purchaseType === BundlePurchaseTypes.INGAME_CURRENCY,
            'armory__auto--bundle_real_purchase': bundle.purchaseType === BundlePurchaseTypes.REAL_CURRENCY,
        },
        getLayoutWidthClassName(props.sizeInGrid, bundle, isShipsCategory),
        getLayoutHeightClassName(props.heightInGrid, bundle),
        styles[primaryItem?.type],
        styles[`w${props.sizeInGrid?.split('/').join('-')}`],
        ...(bundle.decoration || []),
    );

    let backgroundImage = props.image || bundle.icons.medium;
    if (isMobileOrTabletWindow) {
        backgroundImage = bundle.icons.small;
    }

    const isAvailableContainsWidget = bundle.entitlements?.length > 1;

    const _isFreeBundle = isFreeBundle(bundle);
    const isAllowedNewLabel = isAllowedNewLabelFromBundle(bundle);

    const isEnableGlowEffect = _isFreeBundle && !bundle.isPurchased;

    const [isAvailableShipFeatures, setIsAvailableShipFeatures] = React.useState(false);

    const contentClassNames = classNames(
        styles.offerContent,
        {
            [styles.offerItemHover]: !bundle.isPurchased,
            [styles.isLoading]: bundle.isLoading,
            [styles.containsHover]: isAvailableContainsWidget,
            [styles.purchasedShipFeatures]: bundle.isPurchased && isAvailableShipFeatures,
        },
        'lazy',
        styles[props.image],
        styles[props.bundle.categories.join(' ')],
        ...(bundle.decoration || []),
    );

    const classesBundleImage = classNames(styles.bundleImage, ...(bundle.decoration || []));

    const admiralClassNames = classNames({
        [styles.admiralHalf]: _isAdmiralPack && bundle.sizeInGrid === BUNDLE_GRID_SIZE.HALF,
        [styles.admiralFull]: _isAdmiralPack && bundle.sizeInGrid === BUNDLE_GRID_SIZE.FULL,
    });

    const [isExistsItemInInventory, inventoryCount] = Account.getInventoryInfoByType(primaryItem?.type, primaryItem?.identifier, primaryItem.customisation?.shipId);

    const otherProps: Record<string, any> = {};
    if (bundle.primaryItem?.type) {
        otherProps['data-primary-type'] = bundle.primaryItem.type;
    }

    React.useLayoutEffect(() => {
        isOnlyShipBundle(bundle)
            ? getShipFeatures(bundle?.primaryItem?.identifier).then((data) => setIsAvailableShipFeatures(data.featuresTags.length ? true : false))
            : setIsAvailableShipFeatures(false);
    }, []);

    return (
        <div
            onMouseEnter={onMouseEnter}
            className={offerWrapperClassName}
            ref={ref}
            data-id={bundle.id}
            data-is-unique={bundle.primaryItem?.isUnique}
            data-lazy-id={`${bundle.id}_${props.presetName}`}
            {...otherProps}
        >
            {props.isLazilyRendered && (
                <PromoTimer bundle={bundle} activeClassName={styles.activePromoTimer}>
                    <LabelDecorator onClick={onClick} id={bundle.name} labelTill={bundle.labelNewActivityPeriod.activeTill} isEnabled={isAllowedNewLabel} byHover type={LABEL_TYPE.bundle}>
                        <GlowEffectDecorator isEnabled={isEnableGlowEffect} size={bundle.sizeInGrid} decorations={bundle.decoration}>
                            <LabelContainer className={styles.bundleLabel} isVisible={isAllowedNewLabel} />
                            {_isAdmiralPack && <div className={admiralClassNames} />}
                            <BundleDecoration bundle={bundle}>
                                <HoverVideo
                                    parentClassName={styles.offerItem}
                                    muted={!state.isTrusted}
                                    video={bundle.bundleCardHoverVideo}
                                    isDisableAnimation={bundle.isPurchased}
                                    className={contentClassNames}
                                    previewVideo={bundle.previewCardVideo}
                                >
                                    <DivTooltip
                                        className={styles.tooltipContentWrapper}
                                        style={{ cursor: 'pointer' }}
                                        tooltipBody={
                                            isEnabledTooltipFromItemByType(primaryItem?.type) && bundle.entitlements?.length === 1 ? (
                                                <LoadAmountToReward
                                                    bundle={bundle}
                                                    id={primaryItem.identifier}
                                                    type={primaryItem.type}
                                                    amount={primaryItem.amount}
                                                    presentation={{
                                                        style: PresentationStyles.TOOLTIP_WIDGET,
                                                        withTooltip: true,
                                                    }}
                                                    customisation={{
                                                        points: primaryItem?.customisation?.points,
                                                        shipId: primaryItem?.customisation?.shipId,
                                                        inventoryCount: !primaryItem?.isUnique && inventoryCount,
                                                        isExistsInInventory: primaryItem?.isUnique && isExistsItemInInventory,
                                                    }}
                                                />
                                            ) : null
                                        }
                                    >
                                        <img className={classesBundleImage} src={backgroundImage} />
                                        <div className={styles.itemContent}>
                                            <BundleHeaderContent bundle={bundle} primaryItem={primaryItem} />
                                            <SpecialContentByPrimaryType primaryItem={primaryItem} />
                                            <BundleFooterContent bundle={bundle} isFreeBundle={_isFreeBundle} primaryItem={primaryItem} />
                                            <SpecialBundleFooterContent
                                                primaryItem={primaryItem}
                                                className={classNames({
                                                    [styles.isPurchased]: bundle.isPurchased,
                                                })}
                                            />
                                        </div>
                                    </DivTooltip>
                                </HoverVideo>
                            </BundleDecoration>
                        </GlowEffectDecorator>
                    </LabelDecorator>
                </PromoTimer>
            )}
        </div>
    );
});

export default Bundle;
