import { isInGameBrowser, isPortPreviewEnabled } from '~/utils/utils';
import { items as ITEMS_TYPES, items as ITEM_TYPES, items } from '@wg/wows-entities/const';
import { isCamouflage, isPermanentCamouflage } from '~/components/Camouflages/Camouflages';
import { settings } from '~/utils/settings';
import { openPort } from '~/api/WoWsClient';
import { PortPreviewType, sortBundleItems, sortLotRewardsItems } from '~/components/Port/settings';
import { ManagerData } from '~/core/ManagerData';

export const isEnabledPortForItemInsideBundle = (item: IBundleEntity, bundle?: IBundle) => {
    if (!isPortPreviewEnabled) {
        return false;
    }

    if (bundle?.disablePortPreview) {
        return false;
    }

    if (item.type === items.VEHICLES) {
        return true;
    }

    if (isPermanentCamouflage(item.type) && getShipIdForStylePreview(item.identifier)) {
        return true;
    }

    if (isPermanentCamouflage(item.type) && item.customisation?.shipId) {
        return true;
    }

    return !!(isCamouflage(item.type) && settings.camouflagePreviewDefaultShip);
};

export type ItemForPreviewPortType = {
    identifier: number;
    type: string;
    shipId?: number;
};
export const openPortByItem = (
    item: ItemForPreviewPortType,
    bundleId?: number,
    isVisiblePort = false,
    portPreviewType: PortPreviewType = PortPreviewType.BUNDLE,
    additionalData: Record<string, any> = {},
) => {
    if (item.type === items.VEHICLES) {
        openPort(item.identifier, -1, item.type, portPreviewType, bundleId, isVisiblePort, true, location.pathname, additionalData);
        return;
    }

    if (isPermanentCamouflage(item.type) && item.shipId) {
        openPort(item?.shipId, item.identifier, item.type, portPreviewType, bundleId, isVisiblePort, true, location.pathname, additionalData);
        return;
    }

    const shipIdForStylePreview = Number(getShipIdForStylePreview(item.identifier));
    if (isPermanentCamouflage(item.type) && shipIdForStylePreview) {
        openPort(shipIdForStylePreview, item.identifier, item.type, portPreviewType, bundleId, isVisiblePort, true, location.pathname, additionalData);
        return;
    }

    if (isCamouflage(item.type) && settings.camouflagePreviewDefaultShip) {
        openPort(settings.camouflagePreviewDefaultShip, item.identifier, item.type, portPreviewType, bundleId, isVisiblePort, true, location.pathname, additionalData);
        return;
    }
};

export const isEnabledPortFromAuction = (type: string, id: number, lot?: ILot) => {
    if (getShipIdForStylePreview(id) && isPermanentCamouflage(type)) {
        return true;
    }

    if (isCamouflage(type) && settings.camouflagePreviewDefaultShip) {
        return true;
    }

    if (type === items.VEHICLES && (lot?.enablePortPreview || lot?.enablePortPreview === null || !lot)) {
        return true;
    }

    return false;
};

export const openPortFromAuction = (type: string, shipId: number, exteriorId = -1, displayId: string, isVisiblePort = false) => {
    if (type === items.VEHICLES) {
        return openPort(shipId, exteriorId, type, PortPreviewType.AUCTION_LOT, displayId, isVisiblePort, true, location.pathname);
    }

    const shipIdForStylePreview = shipId || Number(getShipIdForStylePreview(exteriorId));
    if (isPermanentCamouflage(type) && shipIdForStylePreview) {
        return openPort(shipIdForStylePreview, exteriorId, items.PERMOFLAGES, PortPreviewType.AUCTION_LOT, displayId, isVisiblePort, true, location.pathname);
    }

    if (isCamouflage(type) && settings.camouflagePreviewDefaultShip) {
        return openPort(settings.camouflagePreviewDefaultShip, exteriorId, items.CAMOUFLAGE, PortPreviewType.AUCTION_LOT, displayId, isVisiblePort, true, location.pathname);
    }
};

export const openPortForFirstBundleItem = (bundle: IBundle, type: PortPreviewType = PortPreviewType.BUNDLE) => {
    const sortedBundleItems = sortBundleItems(bundle);
    const firstItem = sortedBundleItems[0];
    firstItem && openPortByItem({ identifier: firstItem.identifier, type: firstItem.type, shipId: firstItem.customisation?.shipId }, bundle.id, false, type);
};

export const openPortForFirstReward = (lot: ILot) => {
    const items = sortLotRewardsItems(lot.gameRewards);
    const firstItem = items[0];
    firstItem && openPortByReward(firstItem, lot.displayId);
};

export const openPortByReward = (reward: IGameRewards, displayId: string) => {
    if (reward.type === ITEMS_TYPES.VEHICLES) {
        openPortFromAuction(reward.type, reward.id, -1, displayId);
    } else if (isPermanentCamouflage(reward.type) || isCamouflage(reward.type)) {
        openPortFromAuction(reward.type, reward.customisation?.ship_id, reward.id, displayId);
    }
};

export const isEnabledPortByLot = (lot: ILot) => {
    return isInGameBrowser && !!lot.enablePortPreview;
};

export function openPortForMrpsReward(id: number, type: string, exteriorId = -1, isVisiblePort = false) {
    openPort(id, exteriorId, type, PortPreviewType.MRPS, undefined, isVisiblePort, true, location.pathname);
}

export const isEnabledPortByBundle = (bundle: IBundle, isEnableForRandomBundle = false, isPrimary = false) => {
    const sortedBundleItems = sortBundleItems(bundle);

    if (!isPortPreviewEnabled || bundle.disablePortPreview) {
        return false;
    }

    const firstItem = sortedBundleItems[0];
    if (!firstItem) {
        return false;
    }

    if (isPrimary && !firstItem.isPrimary) {
        return false;
    }

    if (!isPermanentCamouflage(firstItem.type) && !isCamouflage(firstItem.type) && firstItem.type !== ITEM_TYPES.VEHICLES) {
        return false;
    }

    if (bundle.isRandom && !isEnableForRandomBundle) {
        return false;
    }

    if (isPermanentCamouflage(firstItem.type) && !getShipIdForStylePreview(firstItem.identifier)) {
        return false;
    }

    return true;
};

export const getShipIdForStylePreview = (permoflageId: number): number | string => {
    const stylePreviewDefaultShips = settings.stylePreviewDefaultShips || [];

    if (!stylePreviewDefaultShips.length) {
        return null;
    }

    const permoflageInfo = ManagerData.getPermoflage(permoflageId);

    if (!permoflageInfo.restrictions) {
        return null;
    }

    const isAllowedField = (array: number[] | string[], field: number | string) => {
        const stringArray: string[] = [...(array || [])].map((item: number | string) => item.toString());

        return !stringArray.length || stringArray.includes(field.toString());
    };

    for (const index in stylePreviewDefaultShips) {
        const shipId = stylePreviewDefaultShips[index];
        const ship = ManagerData.getShip(Number(shipId));

        if (permoflageInfo.restrictions.specificShips?.includes(shipId.toString())) {
            return shipId;
        }

        if (permoflageInfo.restrictions.forbiddenShips?.includes(shipId.toString())) {
            continue;
        }

        if (
            isAllowedField(permoflageInfo.restrictions.types, ship.type.name) &&
            isAllowedField(permoflageInfo.restrictions.nations, ship.nation.name) &&
            isAllowedField(permoflageInfo.restrictions.levels, ship.level)
        ) {
            return shipId;
        }
    }

    return null;
};

export function isEnabledPortPreviewForItem(item: ItemForPreviewPortType, type: PortPreviewType = PortPreviewType.DEFAULT) {
    if (!isPortPreviewEnabled) {
        return false;
    }

    if (item.type === items.VEHICLES) {
        return true;
    }

    if (isPermanentCamouflage(item.type) && getShipIdForStylePreview(item.identifier)) {
        return true;
    }

    return !!(isCamouflage(item.type) && settings.camouflagePreviewDefaultShip);
}
