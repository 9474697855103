import * as React from 'react';
import equal from 'fast-deep-equal/react';
import { isMobileOrTabletWindow } from '~/utils/utils';
import styles from '~/components/View/View.scss';
import TopPanel from '~/components/TopPanel/TopPanel';
import SubCategoryMenu from '~/components/SubCategoryMenu/SubCategoryMenu';
import Filter from '~/components/CategoryFilter/CategoryFilter';
import { IViewContainer } from '~/containers/ViewContainer/ViewContainer';
import { ICurrentPage } from '~/Actions/ActionAppType';
import RefManager, { RefManagerKeys } from '~/RefManager';
import { State } from '~/Reducers';
import { useSelector } from 'react-redux';
import SortDisplay from '~/components/SortDisplay/SortDisplay';
import FilterPresets from '~/components/CategoryFilter/FilterPresets';
import { isAvailablePresetsForCategory, isNeedToRenderFilter } from '~/utils/filters';
import classNames from 'classnames';
import { isAvailableSortCategory } from '~/utils/sort/settings';
import { isDockyardCategory } from '~/utils/category';
import { CATEGORIES } from '~/const';
import { CategoryType, FilterPosition } from '~/types/category';
import ActiveCategoryFilters from '../CategoryFilter/ActiveCategoryFilters';
import TradeInSubCategories from '~/Layouts/TradeIn/components/TradeInSubCategories/TradeInSubCategories';
import { isTradeInCategory } from '~/utils/tradein';

interface IViewTopPanel extends IViewContainer {
    onChangeFilterVisible: (visible: boolean) => void;
    currentPage: ICurrentPage;
    port: {
        isVisible: boolean;
        shipId?: number;
        exteriorId?: number;
    };
}

interface IStateSelector {
    categories: ICategories;
    filterPosition: FilterPosition;
    isBlurView: boolean;
    coupons: ICoupon[];
}

const stateSelector = (state: State): IStateSelector => {
    return {
        categories: state.ReducerApp.categories,
        filterPosition: state.ReducerApp.filterPosition,
        isBlurView: state.ReducerApp.isBlurView,
        coupons: state.ReducerAccount.coupons, // for filter rerender
    };
};

interface IFiltersPanel {
    isNeedToRenderFilters: boolean;
    category: ICategory;
    onChangeFilterVisible: (visible: boolean) => void;
}

const FilterPanel = ({ isNeedToRenderFilters, category, onChangeFilterVisible }: IFiltersPanel) => {
    const _isAvailablePresetsForCategory = isAvailablePresetsForCategory(category.name);
    const isAvailableSort = !isTradeInCategory(category) && isAvailableSortCategory(category.name);

    const headerLineClasses = classNames(styles.layoutHeaderLine, {
        [styles.withFilterPresets]: _isAvailablePresetsForCategory,
    });

    if (!isAvailableSort && !isNeedToRenderFilters && !_isAvailablePresetsForCategory) {
        return null;
    }

    return (
        <div className={styles.layoutHeader}>
            <div className={headerLineClasses}>
                <div className={styles.filtersWrapper}>
                    {isNeedToRenderFilters && (
                        <div
                            className={classNames(styles.filterDelimiter, {
                                [styles.border]: _isAvailablePresetsForCategory,
                            })}
                        >
                            <Filter category={category.name} onChangeVisible={onChangeFilterVisible} position={FilterPosition.TOP} />
                        </div>
                    )}
                    {!_isAvailablePresetsForCategory && isNeedToRenderFilters && <ActiveCategoryFilters category={category.name} className={styles.activeFiltersTop} />}
                    {_isAvailablePresetsForCategory && <FilterPresets category={category.name} />}
                </div>
                {isAvailableSort && <SortDisplay category={category.name} className={styles.sortDisplay} />}
            </div>
        </div>
    );
};

const ViewTopPanel = React.forwardRef((props: IViewTopPanel, ref: React.RefObject<HTMLDivElement>) => {
    const state = useSelector<State, IStateSelector>(stateSelector, equal);
    const category = state.categories[props.currentPage?.name];
    const isNeedToRenderFilters = category && isNeedToRenderFilter(category?.name as ICategoryList) && !props.currentPage?.isBundlePage && state.filterPosition === FilterPosition.TOP;

    if (!category) {
        return null;
    }

    if (props.currentPage?.name === CATEGORIES.MENU) {
        return null;
    }

    if (props.currentPage?.name === CATEGORIES.DOCKYARD && isMobileOrTabletWindow) {
        return null;
    }

    const setRef = (ref: HTMLDivElement) => {
        if (ref) {
            RefManager.setRef(RefManagerKeys.TopPanel, ref);
        }
    };

    const categoryName = category?.name as ICategoryList;
    const _isAvailablePresetsForCategory = isAvailablePresetsForCategory(categoryName);
    const isAvailableSort = isAvailableSortCategory(categoryName);
    const isNeedToShowActiveFilters = ((state.filterPosition === FilterPosition.RIGHT || _isAvailablePresetsForCategory) && !isAvailableSort) || (_isAvailablePresetsForCategory && isAvailableSort);

    const topPanelClassNames = classNames(styles.topPanel, styles[category?.type], {
        [styles.blurElements]: isDockyardCategory() && state.isBlurView,
    });

    return (
        <div className={topPanelClassNames} ref={setRef}>
            {!props.port.isVisible && <TopPanel />}
            {category?.type === CategoryType.TRADEIN ? <TradeInSubCategories category={category} /> : <SubCategoryMenu category={props.currentPage?.name} />}
            {!props.currentPage?.isBundlePage && <FilterPanel category={category} isNeedToRenderFilters={isNeedToRenderFilters} onChangeFilterVisible={props.onChangeFilterVisible} />}
            {!props.currentPage?.isBundlePage && isNeedToShowActiveFilters && <ActiveCategoryFilters category={categoryName} />}
        </div>
    );
});

export default ViewTopPanel;
