import * as React from 'react';
import styles from './Info.scss';
import { DivTooltip } from '@wg/wows-react-uikit';
import DefaultTooltip, { IDefaultTooltipClasses } from '~/components/Tooltip/DefaultTooltip';
import classNames from 'classnames';
import { TooltipTypes } from '~/types/common';

export enum InfoSize {
    DEFAULT = 'default',
    BIG = 'big',
}

interface IInfo {
    style?: React.CSSProperties;
    color?: string;
    className?: string;
    tooltipClasses?: IDefaultTooltipClasses;
    onClick?: () => void;
    size?: InfoSize;
    tooltip?: {
        title?: string;
        text?: string | (string | React.ReactChild)[];
        type?: TooltipTypes;
    };
}

const Info = (props: IInfo): React.ReactChild | any => {
    let tooltip = null;
    if (props.tooltip) {
        tooltip = <DefaultTooltip text={props.tooltip.text} title={props.tooltip.title} type={props.tooltip.type} classes={props.tooltipClasses} />;
    }

    const classesWrapper = classNames(styles.info, styles[props.size || InfoSize.DEFAULT]);

    const style = { '--timer-color': props.color || 'rgba(158, 213, 231, .85)' } as React.CSSProperties;

    return (
        <DivTooltip tooltipBody={tooltip} className={props.className} onClick={() => props.onClick?.()}>
            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={classesWrapper} style={Object.assign(props.style || {}, style)}>
                <path d="M10 6.5V7.5H9V6.5H10Z" stroke="black" strokeOpacity="0.2" />
                <path d="M10 8.5V13.5H9V8.5H10Z" stroke="black" strokeOpacity="0.2" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 10C16 13.5899 13.0899 16.5 9.5 16.5C5.91015 16.5 3 13.5899 3 10C3 6.41015 5.91015 3.5 9.5 3.5C13.0899 3.5 16 6.41015 16 10ZM15 10C15 13.0376 12.5376 15.5 9.5 15.5C6.46243 15.5 4 13.0376 4 10C4 6.96243 6.46243 4.5 9.5 4.5C12.5376 4.5 15 6.96243 15 10Z"
                    stroke="black"
                    strokeOpacity="0.2"
                />
                <path d="M10 6.5V7.5H9V6.5H10Z" fill="white" fillOpacity="0.75" />
                <path d="M10 8.5V13.5H9V8.5H10Z" fill="white" fillOpacity="0.75" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 10C16 13.5899 13.0899 16.5 9.5 16.5C5.91015 16.5 3 13.5899 3 10C3 6.41015 5.91015 3.5 9.5 3.5C13.0899 3.5 16 6.41015 16 10ZM15 10C15 13.0376 12.5376 15.5 9.5 15.5C6.46243 15.5 4 13.0376 4 10C4 6.96243 6.46243 4.5 9.5 4.5C12.5376 4.5 15 6.96243 15 10Z"
                    fill="white"
                    fillOpacity="0.75"
                />
            </svg>
        </DivTooltip>
    );
};

export default Info;
