import store from '~/Store';
import { items as ITEMS } from '@wg/wows-entities/const';
import Account from '~/account/Account';
import { armoryState, settings } from '~/utils/settings';
import { ManagerData } from '~/core/ManagerData';
import { getBundleCurrenciesMap, getBundlesAndCategoriesMap, getPresetBundles, isFreeBundle, prepareCategoryBundlesPricesInfo } from '~/utils/bundles';
import { setFiltersByQueryParams } from '~/Actions/ActionApp';
import { CHANGE_MULTIPLE_CURRENCY_DIAPASON_VALUES, IChangeFilter, IChangeMultipleFilter, SET_FILTERS_BY_QUERY } from '~/Actions/ActionAppType';
import { LOCAL_STORAGE_FILTER_POSITION } from '~/utils/keys';
import { LocalStorageHelper } from '~/utils/storage';
import { isEnabledCouponsFromBundle } from '~/utils/coupons';
import { AppState } from '~/Reducers/ReducerApp';
import { getPresetByName } from '~/utils/category';
import dwhExport from '~/api/dwhExport';
import filtersMap, { filtersInfo, getFiltersDependency, getFiltersDependencyMap, getFiltersInfo } from '~/settings/filtersMap';
import { diff, flat, getSearchParam, isEmptyObject, updateSearchParam } from '~/utils/utils';
import {
    DWH_EVENTS,
    FILTER_ALLOWED_BUNDLES_NAME,
    FILTER_BOOST_TYPE,
    FILTER_BOOST_TYPE_VALUES,
    FILTER_CAMOUFLAGE_TYPE_NAME,
    FILTER_CAMOUFLAGE_TYPE_VALUES,
    FILTER_CREW_CATEGORY_NAME,
    FILTER_CURRENCY_NAME,
    FILTER_DISCOUNT_NAME,
    FILTER_DISCOUNT_VALUES,
    FILTER_DOLL_TYPE_NAME,
    FILTER_DOLL_TYPE_VALUES,
    FILTER_NATION_NAME,
    FILTER_SHIP_CLASS_NAME,
    FILTER_SHIP_TIER_NAME,
    FILTER_SHIP_TYPE_NAME,
    FILTER_SHIP_TYPE_VALUES,
    FILTER_SIGNAL_TYPE,
    FILTER_SIGNAL_TYPE_VALUES,
    QUERY_FILTER_CATEGORY_NAME,
    QUERY_FILTER_PRESET_NAME,
} from '~/const';
import { FilterPosition, PRESETS } from '~/types/category';

type CurrenciesDiapason_Type = Record<string, number[]>;

export type PresetFacet_Type = {
    [key in FILTER_INFO_NAME]?: string[];
} & {
    currenciesDiapason?: IFilterDiapasonCategoryCurrencies;
};

type Facet_Set = Set<unknown>;

type Facet_Type = {
    [key in FILTER_INFO_NAME]?: Facet_Set;
};

type AvailablePresetFilters_Type = {
    [key in FILTER_INFO_NAME]?: Set<string>;
};

type QueryFilter_Type = Filters_Type | { preset?: string };

export function getStateAfterChangedCurrencyDiapason(state: AppState, diapason?: IFilterDiapasonCategoryCurrencies) {
    const { activePreset } = state;
    const categoryName = state.currentPage?.name;
    const copiedState = { ...state };
    const bundleCategory = getBundlesAndCategoriesMap(state.bundles, state.categories);

    let bundles = bundleCategory[categoryName].map((bundleId: number) => state.bundles[bundleId]);
    if (activePreset) {
        bundles = getPresetBundles(categoryName, state.categories, state.bundles, activePreset);
    }

    const categoryFilterFacetState = getFacetFilters(
        state.filters[categoryName],
        state.facetState?.[categoryName],
        categoryName,
        FILTER_CURRENCY_NAME,
        bundles,
        state.activePreset,
        state.bundles,
        diapason,
    );

    return {
        facetState: {
            ...copiedState.facetState,
            [categoryName]: categoryFilterFacetState,
        },
    };
}

export function getStateAfterChangeMultipleFilters(state: AppState, action: IChangeMultipleFilter) {
    const filtersState = { [action.category]: action.data };

    if (!isEmptyObject(filtersState[action.category])) {
        addFiltersToLocation(action.category, filtersState[action.category]);
    }

    let bundles = Object.values(state.bundles).filter((bundle) => bundle.categories.includes(action.category));

    if (state.activePreset) {
        const presetConfig = getPresetByName(state.categories[action.category].filters?.presets, state.activePreset);
        const presetBundles = presetConfig?.bundles || [];
        bundles = presetBundles.map((bundleId) => state.bundles[bundleId]);
    }

    const facetState = state.activePreset
        ? getFacetPresetsFilters(bundles)
        : getFacetFilters(
              filtersState[action.category] as Filters_Type,
              {},
              action.category,
              action.triggerFilterName || FILTER_CURRENCY_NAME,
              bundles,
              state.activePreset,
              state.bundles,
              state.filtersDiapasonCurrency?.[action.category],
          );

    return {
        filters: filtersState,
        facetState: {
            [action.category]: facetState,
        },
    };
}

export function getStateFromSetFiltersByUrl(state: AppState, category: ICategoryList, action: SET_FILTERS_BY_QUERY) {
    const filtersState: IFilters = {
        ...(state.filters || {}),
    };

    if (category) {
        filtersState[category] = {
            ...(action.filters || {}),
        };
    }

    let bundles = Object.values(state.bundles).filter((bundle) => bundle.categories.includes(category));

    let categoryBundles: number[] = getBundlesAndCategoriesMap(state.bundles, state.categories)[category];

    if (action.presetName) {
        const presetConfig = getPresetByName(state.categories[category].filters.presets, action.presetName);
        const presetBundles = presetConfig?.bundles || [];
        if (presetBundles) {
            categoryBundles = presetBundles;
            bundles = presetBundles.map((bundleId) => state.bundles[bundleId]);
        }
    }

    const filtersKeysWithoutCurrencyKey = Object.keys(action.filters || {}).filter((key: FILTER_INFO_NAME) => key !== FILTER_CURRENCY_NAME);
    const facetFilterKey = !!filtersKeysWithoutCurrencyKey.length ? filtersKeysWithoutCurrencyKey[0] : FILTER_CURRENCY_NAME;

    const facetState: any = !isEmptyObject(action.filters)
        ? getFacetFilters(
              filtersState[category] as Filters_Type,
              state.facetState?.[category],
              category,
              facetFilterKey as FILTER_INFO_NAME,
              bundles,
              action.presetName,
              state.bundles,
              action.currencyDiapason,
          )
        : action.presetName
        ? getFacetPresetsFilters(bundles)
        : {};

    const filteredBundles = filteredByFilters(categoryBundles, action.filters || {}, state.bundles, action.currencyDiapason || {}, action.coupons);

    return {
        activePreset: action.presetName || null,
        filters: filtersState,
        facetState: {
            [category]: facetState,
        },
        bundleCategory: {
            ...state.bundleCategory,
            [category]: filteredBundles,
        },
        filtersDiapasonCurrency: {
            [category]: {
                ...(action.currencyDiapason || {}),
            },
        },
    };
}

export function getStateAfterChangedPreset(state: AppState, presetName: string, withoutUpdateHistory = false) {
    const categoryName = state.currentPage?.name;
    const preset = state.activePreset === presetName ? null : presetName;

    if (!withoutUpdateHistory) {
        resetQueryFilters(categoryName);
        addFiltersToLocation(categoryName, { [QUERY_FILTER_PRESET_NAME]: preset });
    }

    const categoryBundlesPricesInfo = state.categoryBundlesPricesInfo;
    const bundleCategory = getBundlesAndCategoriesMap(state.bundles, state.categories);
    let facetState: any = {};

    if (preset) {
        const config = getPresetByName(state.categories?.[categoryName]?.filters?.presets || [], preset);
        dwhExport.send(DWH_EVENTS.FILTERS_PRESET_APPLY, { preset_name: preset, category: categoryName });

        bundleCategory[categoryName] = config.bundles || [];
        const bundles = bundleCategory[categoryName].map((bundleId: number) => state.bundles[bundleId]);

        facetState = getFacetPresetsFilters(bundles);

        categoryBundlesPricesInfo[categoryName] = {
            ...state.categoryBundlesPricesInfo[categoryName],
            ...(facetState.currenciesDiapason || {}),
        };
    }

    return {
        filters: {},
        categoryBundlesPricesInfo: categoryBundlesPricesInfo,
        filtersDiapasonCurrency: {},
        facetState: {
            [categoryName]: facetState,
        },
        bundleCategory: bundleCategory,
        activePreset: preset,
    };
}

export function getStateAfterResetFilter(state: AppState, category: ICategoryList, withoutUpdateHistory = false) {
    const categoryName = state.currentPage?.name;

    if (!withoutUpdateHistory) {
        resetQueryFilters(category);
    }

    const categoryBundlesPricesInfo = state.categoryBundlesPricesInfo;
    const bundleCategory = getBundlesAndCategoriesMap(state.bundles, state.categories);
    let facetState: any = {};

    if (state.activePreset) {
        const config = getPresetByName(state.categories?.[categoryName]?.filters?.presets || [], state.activePreset);
        if (config) {
            const bundles = getPresetBundles(categoryName, state.categories, state.bundles, state.activePreset);
            bundleCategory[categoryName] = config.bundles || [];
            facetState = getFacetPresetsFilters(bundles);
            categoryBundlesPricesInfo[categoryName] = {
                ...state.categoryBundlesPricesInfo[categoryName],
                ...(facetState.currenciesDiapason || {}),
            };
        }
    }

    return {
        filters: {
            ...state.filters,
            [category]: {},
        },
        facetState: {
            ...state.facetState,
            [category]: facetState,
        },
        facetCurrenciesState: {
            ...(state.facetCurrenciesState || {}),
            [category]: facetState,
        },
        categoryBundlesPricesInfo: prepareCategoryBundlesPricesInfo(state.bundles),
        filtersDiapasonCurrency: {
            ...state.filtersDiapasonCurrency,
            [category]: {},
        },
        bundleCategory: {
            ...(state.bundleCategory || {}),
            [categoryName]: bundleCategory[categoryName],
        },
    };
}

export function getStateAfterChangedFilter(state: AppState, action: IChangeFilter) {
    let values = state.filters?.[action.category]?.[action.name] || [];
    let filtersDiapasonCurrency = state.filtersDiapasonCurrency;

    if (values.includes(action.value)) {
        updateSearchParam({ [action.value]: null });
        values = values.filter((item: any) => item !== action.value);

        if (action.name === FILTER_CURRENCY_NAME) {
            filtersDiapasonCurrency = {
                ...(filtersDiapasonCurrency || {}),
                [action.category]: {
                    ...(filtersDiapasonCurrency[action.category] || {}),
                    [action.value]: null,
                },
            };
        }
    } else {
        if (action.name === FILTER_SHIP_TIER_NAME) {
            const tiers =
                action.value
                    ?.toString()
                    .split('-')
                    .map((level) => parseInt(level, 10)) || [];
            if (tiers.length === 1 && !(values as unknown[]).includes(tiers[0])) {
                values.push(action.value);
            } else {
                values = values.filter((item: any) => !tiers.includes(item));
            }
        } else {
            values.push(action.value);
        }
    }

    const newFiltersState = {
        ...state.filters,
        [action.category]: {
            ...(state.filters?.[action.category] || {}),
            [action.name]: values,
        },
    };

    if (action.name === FILTER_DISCOUNT_NAME && newFiltersState[action.category][FILTER_ALLOWED_BUNDLES_NAME]?.length) {
        delete newFiltersState[action.category][FILTER_ALLOWED_BUNDLES_NAME];
    }

    let bundles = Object.values(state.bundles).filter((bundle) => bundle.categories.includes(action.category));
    if (state.activePreset) {
        bundles = getPresetBundles(action.category, state.categories, state.bundles, state.activePreset);
    }

    addFiltersToLocation(action.category, newFiltersState[action.category]);

    const facet = getFacetFilters(
        newFiltersState[action.category] as Filters_Type,
        state.facetState?.[action.category],
        action.category,
        action.name as FILTER_INFO_NAME,
        bundles,
        state.activePreset,
        state.bundles,
        filtersDiapasonCurrency?.[action.category],
    );

    return {
        filters: newFiltersState,
        filtersDiapasonCurrency: filtersDiapasonCurrency,
        facetState: {
            ...(state.facetState || {}),
            [action.category]: facet,
        },
    };
}

function prepareCurrenciesDiapason(currenciesDiapason: CurrenciesDiapason_Type, bundle: IBundle) {
    const prices = getBundleCurrenciesMap(bundle);
    Object.keys(prices).forEach((currencyName: string) => {
        if (!prices[currencyName]) {
            return;
        }
        if (!currenciesDiapason[currencyName]) {
            currenciesDiapason[currencyName] = [prices[currencyName]];
        } else {
            currenciesDiapason[currencyName].push(prices[currencyName]);
        }
    });
}

export function filteredBundlesByCurrencies(bundles: IBundle[], currencyDiapason: IFilterDiapasonCategoryCurrencies) {
    return bundles.filter((bundle) => {
        if (!bundle.price) {
            return false;
        }

        const prices = getBundleCurrenciesMap(bundle);

        return Object.keys(prices).some((currency) => {
            if (!prices[currency]) {
                return false;
            }

            if (!currencyDiapason[currency]) {
                return true;
            }

            const { min, max } = currencyDiapason[currency];
            return prices[currency] >= min && prices[currency] <= max;
        });
    });
}

export function getFacetPresetsFilters(bundles: IBundle[]): PresetFacet_Type {
    const currenciesDiapason: CurrenciesDiapason_Type = {};
    const availableFilters = bundles.reduce((state: AvailablePresetFilters_Type, bundle: IBundle) => {
        Object.keys(bundle.filterValues).forEach((bundleFilterKey: FILTER_INFO_NAME) => {
            if (!state[bundleFilterKey]) {
                state[bundleFilterKey] = new Set();
            }

            prepareCurrenciesDiapason(currenciesDiapason, bundle);

            if (!bundle.filterValues[bundleFilterKey]) {
                return;
            }

            bundle.filterValues[bundleFilterKey].forEach((value: string) => {
                state[bundleFilterKey].add(value);
            });
        });

        return state;
    }, {});

    const disabledFilters: Filters_Type = {};

    Object.keys(availableFilters).forEach((filterKey: FILTER_INFO_NAME) => {
        if (!filtersInfo[filterKey]) {
            return;
        }

        disabledFilters[filterKey] = [];
        disabledFilters[filterKey].push(...diff(filtersInfo[filterKey].defaultValues, Array.from(availableFilters?.[filterKey] || new Set([]))));
    });

    const facetDiapason: IFilterDiapasonCategoryCurrencies = {};

    Object.keys(currenciesDiapason).forEach((currencyName) => {
        facetDiapason[currencyName] = {
            min: Math.min(...currenciesDiapason[currencyName]),
            max: Math.max(...currenciesDiapason[currencyName]),
        };
    });

    return { ...disabledFilters, currenciesDiapason: facetDiapason };
}

export function getFacetFilters(
    filtersState: Filters_Type,
    facetState: Filters_Type,
    categoryName: ICategoryList,
    changedFilterName: FILTER_INFO_NAME,
    bundles: IBundle[],
    activePreset: string,
    allBundles: IBundleList,
    diapason?: IFilterDiapasonCategoryCurrencies,
) {
    const currenciesDiapason: CurrenciesDiapason_Type = {};
    const facetData: Facet_Type = {};
    const facetCategoryData: Filters_Type = facetState || {};
    const filtersDependency = getFiltersDependency()[categoryName]?.[changedFilterName];
    const isEmptyFilters = !flat(Object.values(filtersState || {}) || []).length;

    if (!filtersDependency || isEmptyFilters) {
        return !!activePreset ? getFacetPresetsFilters(bundles) : {};
    }

    if (!isEmptyObject(diapason)) {
        bundles = filteredBundlesByCurrencies(bundles, diapason);
    }

    const defaultFilterValues = bundles.reduce((result: Record<string, Set<string>>, bundle) => {
        const filterKeys = Object.keys(bundle.filterValues) as FILTER_INFO_NAME[];
        if (!filterKeys.length) {
            return result;
        }
        filterKeys.forEach((filterKey) => {
            if (!Array.isArray(bundle.filterValues[filterKey])) {
                return;
            }
            if (!result) {
                result[filterKey] = new Set(bundle.filterValues[filterKey]);
            } else {
                result[filterKey] = new Set([...bundle.filterValues[filterKey], ...Array.from(result[filterKey] || [])]);
            }
        });
        return result;
    }, {});

    const defaultValues = Array.from(defaultFilterValues[changedFilterName] || new Set([]));
    const selectedFilters = (!filtersState?.[changedFilterName]?.length ? defaultValues : filtersState[changedFilterName]) as string[];

    const bundleIds = bundles.map((bundle) => bundle.id);

    const selectedFilterKeys = Object.keys(filtersState);

    const getFilterStateExcludeOneFilter = (excludeFilterName: FILTER_INFO_NAME) => {
        return Object.keys(filtersState).reduce((newFilterState: Filters_Type, filterName: FILTER_INFO_NAME) => {
            if (filterName === excludeFilterName) {
                return newFilterState;
            }
            newFilterState[filterName] = filtersState[filterName];
            return newFilterState;
        }, {});
    };

    const addFacetFilter = (filterKey: FILTER_INFO_NAME, values: string[]) => {
        if (facetData[filterKey]) {
            facetData[filterKey] = new Set([...facetData[filterKey], ...values]);
        } else {
            facetData[filterKey] = new Set(values);
        }
    };

    selectedFilterKeys.forEach((filterName: FILTER_INFO_NAME) => {
        if (selectedFilterKeys.length === 1) {
            bundles = bundles.filter((bundle: IBundle) => {
                return bundle.filterValues?.[changedFilterName]?.some((value: string) => selectedFilters.includes(value));
            });
            bundles.forEach((bundle) => {
                Object.keys(bundle.filterValues).forEach((bundleFilterKey: FILTER_INFO_NAME) => {
                    if (!bundle.filterValues[bundleFilterKey]) {
                        return;
                    }
                    addFacetFilter(bundleFilterKey, bundle.filterValues[bundleFilterKey]);
                });
            });
        } else {
            filtersDependency.forEach((depFilterName: FILTER_INFO_NAME) => {
                const filteredBundles = filteredByFilters(bundleIds, getFilterStateExcludeOneFilter(depFilterName), allBundles, {});
                filteredBundles.forEach((bundleId) => {
                    const bundle = allBundles[bundleId];
                    if (!bundle.filterValues[depFilterName]) {
                        return;
                    }
                    addFacetFilter(depFilterName, bundle.filterValues[depFilterName]);
                });
            });
        }
    });

    const disabledFilters: Filters_Type = {
        [changedFilterName]: [...(facetCategoryData[changedFilterName] || [])],
    };

    filtersDependency.forEach((depFilterName: FILTER_INFO_NAME) => {
        const facetFilterDepNames = getFiltersDependencyMap()[categoryName]?.[changedFilterName]?.[depFilterName];
        facetFilterDepNames?.forEach((facetDepName) => {
            disabledFilters[facetDepName] = [];
            disabledFilters[facetDepName].push(...diff(filtersInfo[facetDepName].defaultValues, Array.from(facetData?.[facetDepName] || new Set([]))));
        });
    });

    const facetDiapason: IFilterDiapasonCategoryCurrencies = {};

    Object.keys(currenciesDiapason).forEach((currencyName) => {
        facetDiapason[currencyName] = {
            min: Math.min(...currenciesDiapason[currencyName]),
            max: Math.max(...currenciesDiapason[currencyName]),
        };
    });

    return { ...disabledFilters, currenciesDiapason: facetDiapason };
}

export function getFiltersData<PrimaryType extends number, Type extends string, Bundle extends IBundle, Coupons extends ICoupon[]>(
    primaryItemId: PrimaryType,
    type: Type,
    bundle: Bundle,
    coupons: Coupons,
) {
    const currency: string[] = [];

    if (bundle.price === null || bundle.price === undefined || !bundle.entitlements) {
        return {};
    }

    if (bundle && !isFreeBundle(bundle)) {
        currency.push(bundle.currency);
        bundle.additionalCurrency && currency.push(bundle.additionalCurrency);
    }

    const hasCoupon = !bundle.isPurchased && Account.isEnabledCouponsFromBundle(coupons, bundle);

    const defaultFilters = {
        currency,
        [FILTER_DISCOUNT_NAME]: hasCoupon ? [FILTER_DISCOUNT_VALUES.COUPON] : null,
    };

    switch (type) {
        case ITEMS.VEHICLES: {
            const item = ManagerData.getShip(primaryItemId);

            if (item) {
                return {
                    ...defaultFilters,
                    [FILTER_SHIP_TYPE_NAME]: item.isPremium ? [FILTER_SHIP_TYPE_VALUES.PREMIUM] : item.isSpecial ? [FILTER_SHIP_TYPE_VALUES.SPECIAL] : null,
                    [FILTER_SHIP_CLASS_NAME]: [item.type.name],
                    [FILTER_SHIP_TIER_NAME]: [item.level],
                    [FILTER_NATION_NAME]: [item.nation.name],
                };
            }

            break;
        }

        case ITEMS.CREWS: {
            const itemBundle = ManagerData.getCrew(primaryItemId);
            let crew_type = ['default'];

            if (itemBundle) {
                crew_type = [itemBundle.type];

                return {
                    ...defaultFilters,
                    [FILTER_CREW_CATEGORY_NAME]: [itemBundle.category],
                    [FILTER_NATION_NAME]: [itemBundle.nation?.name],
                    crew_type,
                };
            }

            break;
        }

        case ITEMS.DOG_TAG: {
            const itemBundle = ManagerData.getDoll(primaryItemId);

            if (itemBundle) {
                let type = itemBundle.type;
                if (type === ITEMS.PATCH) {
                    type = itemBundle.isPatch ? FILTER_DOLL_TYPE_VALUES.PATCH : FILTER_DOLL_TYPE_VALUES.SYMBOL;
                }
                return {
                    ...defaultFilters,
                    [FILTER_DOLL_TYPE_NAME]: [type],
                };
            }

            break;
        }

        case ITEMS.CAMOUFLAGE: {
            return {
                ...defaultFilters,
                [FILTER_CAMOUFLAGE_TYPE_NAME]: [FILTER_CAMOUFLAGE_TYPE_VALUES.EXPENDABLE],
            };
        }

        case ITEMS.MULTI_BOOST:
        case ITEMS.GLOBAL_BOOST:
            return {
                ...defaultFilters,
                [FILTER_BOOST_TYPE]: [FILTER_BOOST_TYPE_VALUES.PERMANENT],
            };

        case ITEMS.CAMO_BOOST:
            return {
                ...defaultFilters,
                [FILTER_BOOST_TYPE]: [FILTER_BOOST_TYPE_VALUES.EXPENDABLE],
            };

        case ITEMS.PERMOFLAGES:
        case ITEMS.SKIN:
        case ITEMS.STYLE:
        case ITEMS.MSKIN: {
            const itemBundle = ManagerData.getPermoflage(primaryItemId);
            const permoflageVehicle = itemBundle?.vehicles?.[0].vehicle;

            if (permoflageVehicle) {
                return {
                    ...defaultFilters,
                    [FILTER_CAMOUFLAGE_TYPE_NAME]: [FILTER_CAMOUFLAGE_TYPE_VALUES.PERMANENT],
                    [FILTER_NATION_NAME]: [permoflageVehicle.nationName],
                    [FILTER_SHIP_CLASS_NAME]: [permoflageVehicle.typeName],
                    [FILTER_SHIP_TIER_NAME]: [permoflageVehicle.level],
                };
            }

            break;
        }

        case ITEMS.SIGNAL: {
            const itemBundle = ManagerData.getItem(primaryItemId);
            if (itemBundle) {
                return itemBundle.tags.reduce(
                    (result, tag: string) => {
                        if (Object.values(FILTER_SIGNAL_TYPE_VALUES).includes(tag)) {
                            return {
                                ...result,
                                [FILTER_SIGNAL_TYPE]: [tag],
                            };
                        }
                        return result;
                    },
                    { ...defaultFilters, [FILTER_SIGNAL_TYPE]: null },
                );
            }
            break;
        }

        default: {
            return defaultFilters;
        }
    }
}

export function filteredByFilters<
    CategoryBundles extends number[],
    Filters extends Filters_Type,
    BundleList extends IBundleList,
    CurrencyDiapason extends IFilterDiapasonCategoryCurrencies,
    Coupons extends ICoupon[],
>(categoryBundles: CategoryBundles, categoryFilters: Filters, allBundles: BundleList, currenciesDiapason: CurrencyDiapason, coupons?: Coupons): number[] {
    if (!categoryFilters) {
        return categoryBundles;
    }

    if (!categoryBundles) {
        return [];
    }

    const hasSelectedCurrenciesDiapason = !isEmptyObject(currenciesDiapason);

    return categoryBundles.reduce((bundlesList: number[], bundleId: number) => {
        const bundle: any = allBundles[bundleId];
        const bundlePrices = getBundleCurrenciesMap(bundle);
        let allowed = true;

        for (const [name, values] of Object.entries(categoryFilters)) {
            if (!allowed) {
                break;
            }

            const _values = values.map((val: string) => (val.toLowerCase ? val.toLowerCase() : val));

            if (!Array.isArray(bundle.filterValues[name]) && _values.length) {
                allowed = false;
            }

            if (_values.length && Array.isArray(bundle.filterValues[name])) {
                allowed = bundle.filterValues[name].some((name: string) => _values.includes(name));
            }

            if (name === FILTER_ALLOWED_BUNDLES_NAME) {
                allowed = values.includes(bundle.id);
            }

            if (coupons && name === FILTER_DISCOUNT_NAME && values.includes(FILTER_DISCOUNT_VALUES.COUPON)) {
                allowed = isEnabledCouponsFromBundle(coupons, bundle);
            }

            if (allowed && hasSelectedCurrenciesDiapason && name === FILTER_CURRENCY_NAME && _values.length) {
                allowed = Object.keys(bundlePrices).some((currency: string) => {
                    if (!_values.includes(currency)) {
                        return false;
                    }

                    const diapasonConfig = currenciesDiapason[currency];
                    if (!diapasonConfig) {
                        return true;
                    }

                    return bundlePrices[currency] >= diapasonConfig.min && bundlePrices[currency] <= diapasonConfig.max;
                });
            }
        }

        if (allowed) {
            bundlesList.push(bundleId);
        }

        return bundlesList;
    }, []);
}

export function addFiltersToLocation<CategoryName extends ICategoryList, Filters extends QueryFilter_Type>(categoryName: CategoryName, filters: Filters) {
    const params = Object.keys(filters || {}).reduce((result: Record<string, string[]>, key: FILTER_INFO_NAME) => {
        // @ts-ignore
        const values = filters[key];
        if (!values || (Array.isArray(values) && !values.length)) {
            result[key] = null;
        } else {
            result[key] = values;
        }
        return result;
    }, {});

    updateSearchParam({ [QUERY_FILTER_CATEGORY_NAME]: categoryName, ...params });
}

export function parseFiltersInQueryParams() {
    const filterCategory = getSearchParam(QUERY_FILTER_CATEGORY_NAME) as ICategoryList;
    const { categories } = store.getState().ReducerApp;
    const { coupons } = store.getState().ReducerAccount || {};

    if (!filterCategory || !categories?.[filterCategory]) {
        return;
    }

    if (!isNeedToRenderFilter(filterCategory)) {
        return;
    }

    const state: SET_FILTERS_BY_QUERY = {
        coupons: coupons,
    };

    const filtersParams = validateFiltersParams(filterCategory, document.location.search.substring(1));
    if (!isEmptyObject(filtersParams)) {
        state.filters = filtersParams;
    }

    const hasCurrencyFilter = filtersParams[FILTER_CURRENCY_NAME];
    if (hasCurrencyFilter) {
        const diapasons = validateCurrenciesDiapasons(filterCategory, filtersParams[FILTER_CURRENCY_NAME]);
        if (!isEmptyObject(diapasons)) {
            state.currencyDiapason = diapasons;
        }
    }

    const presetFilter = getSearchParam(QUERY_FILTER_PRESET_NAME);
    if (presetFilter) {
        const preset = validateFilterPreset(filterCategory, presetFilter);
        if (preset) {
            state.presetName = preset;
        }
    }

    if (!isEmptyObject(state)) {
        store.dispatch(setFiltersByQueryParams(filterCategory, state));
    }
}

function validateFilterPreset<CategoryName extends ICategoryList, Preset extends string>(categoryName: CategoryName, presetName: Preset): string {
    const { categories } = store.getState().ReducerApp;
    const config = categories[categoryName];
    const availablePresets = (config?.filters?.presets || []).map((preset: ICategoryPresets) => preset.name) || [];
    if (!availablePresets.includes(presetName)) {
        return;
    }

    return presetName;
}

function validateCurrenciesDiapasons<CategoryName extends ICategoryList, Currencies extends string[]>(categoryName: CategoryName, currencies: Currencies) {
    const currenciesInfo = store.getState().ReducerApp.categoryBundlesPricesInfo?.[categoryName];
    if (!currenciesInfo) {
        return;
    }

    return currencies.reduce((state: CHANGE_MULTIPLE_CURRENCY_DIAPASON_VALUES, currencyName) => {
        const [queryCurrencyMin, queryCurrencyMax] = getSearchParam(currencyName)?.split?.(',') || [];
        const inputMin = parseInt(queryCurrencyMin, 10);
        const inputMax = parseInt(queryCurrencyMax, 10);

        if (!currenciesInfo[currencyName]) {
            return state;
        }

        const { min, max } = currenciesInfo[currencyName];

        if (inputMin >= min && inputMin <= max && inputMax <= max && inputMax >= min) {
            state[currencyName] = { min: inputMin, max: inputMax };
        }

        return state;
    }, {});
}

type FiltersParams_Type = {
    [key in FILTER_INFO_NAME]?: string[];
};
export function validateFiltersParams<CategoryName extends ICategoryList, Query extends string>(categoryName: CategoryName, query: Query): FiltersParams_Type {
    const availableFiltersKeys = filtersMap[categoryName];
    return query.split('&').reduce((state: FiltersParams_Type, param) => {
        const [key, values] = param.split('=') as [FILTER_INFO_NAME, string];

        if (!availableFiltersKeys.includes(key)) {
            return state;
        }

        const availableFilterValues = getAvailableFilters(categoryName, key);

        // @ts-ignore
        state[key] = values
            .toString()
            .split(',')
            .filter((value: string | number) => {
                if (key === FILTER_SHIP_TIER_NAME) {
                    value = parseInt(value.toString(), 10);
                }

                return availableFilterValues.includes(value);
            })
            .map((value: string | number) => {
                if (key === FILTER_SHIP_TIER_NAME) {
                    value = parseInt(value.toString(), 10);
                }

                return value;
            });

        return state;
    }, {});
}

export function resetQueryFilters<CategoryName extends ICategoryList>(categoryName: CategoryName) {
    const availableFiltersKeys = filtersMap[categoryName];
    const params: Record<string, Nullable<string>> = {};

    if (!availableFiltersKeys) {
        return;
    }

    availableFiltersKeys.forEach((key: FILTER_INFO_NAME) => {
        if (!getSearchParam(key)) {
            return;
        }

        if (key === FILTER_CURRENCY_NAME) {
            getFiltersInfo()[key].items.forEach((item) => {
                params[item.value] = null;
            });
        }

        if (key === FILTER_DISCOUNT_NAME && getSearchParam(FILTER_ALLOWED_BUNDLES_NAME)) {
            params[FILTER_ALLOWED_BUNDLES_NAME] = null;
        }

        params[key] = null;
    });

    if (getSearchParam(QUERY_FILTER_CATEGORY_NAME) === categoryName) {
        params[QUERY_FILTER_CATEGORY_NAME] = null;
    }

    updateSearchParam({
        ...params,
    });
}

export function isEnabledDefaultFilterPosition() {
    return document.body.offsetWidth >= 1920;
}

export function isAvailablePresetsForCategory(categoryName: ICategoryList) {
    const { categories } = store.getState().ReducerApp;
    if (!categories[categoryName]) {
        return false;
    }

    if (categories[categoryName]?.subCategories?.length) {
        return false;
    }

    if (!categories[categoryName].filters?.presets?.length) {
        return false;
    }

    return true;
}

export function getLocalStorageKey() {
    return LOCAL_STORAGE_FILTER_POSITION.replace('{}', window.metashop.state?.account?.id?.toString());
}

export function getFilterPositionFromLocalStorage() {
    return LocalStorageHelper.get(getLocalStorageKey());
}

export function saveFilterPositionToLocalStorage(position: FilterPosition) {
    LocalStorageHelper.set(getLocalStorageKey(), position);
}

export function initializationFilterPosition() {
    let filterPosition = window.metashop?.state?.account?.filtersSettings?.position || getFilterPositionFromLocalStorage();
    if (!filterPosition) {
        if (isEnabledDefaultFilterPosition()) {
            filterPosition = FilterPosition.RIGHT;
        } else {
            filterPosition = FilterPosition.TOP;
        }
    } else if (filterPosition === FilterPosition.RIGHT && !isEnabledDefaultFilterPosition()) {
        filterPosition = FilterPosition.TOP;
    }

    if (window.metashop.state.account) {
        window.metashop.state.account.filtersSettings = { position: filterPosition };
    }
}

export function getFilterPositionFromAppInit() {
    return armoryState?.account?.filtersSettings?.position || getFilterPositionFromLocalStorage() || FilterPosition.TOP;
}

function filterPresetBundles(bundleIds: number[], excludeBundlesIds: number[] = []) {
    if (!Array.isArray(excludeBundlesIds) || !excludeBundlesIds?.length) {
        return bundleIds;
    }
    return bundleIds.filter((bundleId) => !excludeBundlesIds.includes(parseInt(bundleId.toString(), 10)));
}

export const prepareFiltersPresetsFromState = (categories: ICategories, bundles: IBundleList, categoryBundles: Record<string, number[]>) => {
    const accountRecommendations = armoryState?.account?.filtersPresetsRecommended || [];
    return Object.keys(categories).reduce((result: ICategories, key: ICategoryList) => {
        result[key] = categories[key];

        const category = result[key];

        if (!category.bundles?.length || !category.filters || !category.filters?.presets?.length) {
            return result;
        }

        category.filters.presets.forEach((preset) => {
            preset.excludeBundles = preset.excludeBundles?.map((item) => parseInt(item.toString(), 10));

            if (!Array.isArray(preset.bundles)) {
                preset.bundles = [];
            } else {
                preset.bundles = filterPresetBundles(
                    preset.bundles.filter((bundleId: number) => !!bundles[bundleId]),
                    preset.excludeBundles,
                );
            }

            if (accountRecommendations!.length && preset.type == PRESETS.RECOMMENDED) {
                preset.bundles = filterPresetBundles(accountRecommendations, preset.excludeBundles);
                return;
            }

            if (preset.type === PRESETS.ALL) {
                preset.bundles = filterPresetBundles(categoryBundles[key], preset.excludeBundles);
            }

            if (isEmptyObject(preset.rules || {})) {
                return;
            }

            const keysRules = Object.keys(preset.rules || {});
            if (!keysRules?.length) {
                return;
            }

            const filters: Record<string, number[] | string[]> = {};
            keysRules.forEach((key: FILTER_INFO_NAME) => {
                if (!Array.isArray(preset.rules[key])) {
                    return;
                }
                const values = preset.rules[key] as string[];
                if (key === FILTER_SHIP_TIER_NAME) {
                    filters[key] = values.map((val) => parseInt(val, 10)) as number[];
                } else {
                    filters[key] = values;
                }
            });

            if (isEmptyObject(filters)) {
                return;
            }

            preset.bundles = filterPresetBundles(filteredByFilters(categoryBundles[category.name], filters, bundles, {}), preset.excludeBundles);
        });

        return result;
    }, {});
};

export const isNeedToRenderCurrencyFilter = (categoryName: ICategoryList, currencyName: string): boolean => {
    const { defaultCategoryBundlesPricesInfo } = store.getState().ReducerApp;
    const { defaultMax, defaultMin } = defaultCategoryBundlesPricesInfo?.[categoryName]?.[currencyName] || {};
    return defaultMax !== defaultMin;
};

export const isNeedToRenderFilter = (category: ICategoryList): boolean => {
    const filterNames = filtersMap[category];

    const availableFilters =
        filterNames?.reduce((arr: string[], filterName: FILTER_INFO_NAME) => {
            const filter = getFiltersInfo()[filterName];
            if (!filter) {
                return arr;
            }
            const availableItems = getAvailableFilters(category, filterName);

            if (!filter.isForcedDisplay && availableItems.length < 2) {
                if (!(filter.name === FILTER_CURRENCY_NAME && isNeedToRenderCurrencyFilter(category, availableItems[0]))) {
                    return arr;
                }
            }

            arr.push(...getAvailableFilters(category, filterName));

            return arr;
        }, []) || [];

    return Object.keys(filtersMap).includes(category) && availableFilters.length >= 1;
};

export const getAvailableFilters = (category: string, name: string) => {
    return settings.categoriesFilters[category]?.[name]?.map((item: any) => item.value) || [];
};

export const isActiveFilters = (filters: IFilters, categoryName: string) => {
    return flat(Object.values(filters?.[categoryName] || [])).length > 0;
};

export const hasCouponFilterFromCategory = (category: ICategory) => {
    return category?.filters?.settings?.includes(FILTER_DISCOUNT_NAME);
};
